import React, { useState } from 'react';

import { Button, Flex, Icon, Meta, Skeleton } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import UserQuery from '@Libs/clientInstances/userQuery';
import AuthenticatorModal from '@Modules/account/security/twoFA/authenticator/components/AuthenticatorModal';
import DisableAuthenticatorModal from '@Modules/account/security/twoFA/authenticator/components/DisableAuthenticatorModal';

function Authenticator() {
  const { t } = useTranslation();

  const { data } = UserQuery.useGetTwoFA('checkTwoFA', { suspense: false });

  const [qrModalActive, setQrModalActive] = useState(false);

  return (
    <>
      <Flex py="4xl" direction={{ xs: 'col', sm: 'row' }} gap="xl" justify="between" items={{ sm: 'center' }}>
        <Meta
          extra={<Icon size="lg" type="o:key" color="primary-500" />}
          position="left-top"
          title="Authenticator"
          description={t('account.security-settings.authenticator-info')}
        />
        <div>
          {data ? (
            <Button variant={data?.totp ? 'outline' : 'filled'} size="md" onClick={() => setQrModalActive(true)}>
              {data?.totp ? t('common.disable') : t('common.enable')}
            </Button>
          ) : (
            <Skeleton width="size-16" />
          )}
        </div>
      </Flex>

      {data?.totp ? (
        <DisableAuthenticatorModal modalActive={qrModalActive} setModalActive={setQrModalActive} />
      ) : (
        <AuthenticatorModal modalActive={qrModalActive} setModalActive={setQrModalActive} />
      )}
    </>
  );
}

export default Authenticator;
