import { UserInfo } from '@bilira-org/react-utils';

import Tracker from '@Libs/tracker/Tracker';

/*
This helper filters specific fields in user kyc info for the tracker.
 */
export const addTrackerUser = ({ userInfo }: { userInfo: UserInfo }) => {
  try {
    Tracker.user({
      id: userInfo.id,
      name: userInfo.first_name,
      surname: userInfo.last_name,
      kycLevel: userInfo.kyc_level,
      kyc3Eligible: userInfo.kyc3_eligible,
      email: userInfo.email,
    });
  } catch (e) {
    console.error('addTrackerUser', e);
  }
};
