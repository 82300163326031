import {
  RewardsTasksType,
  RewardTaskAction,
  RewardTaskActionType,
  UserParticipationType,
} from '@bilira-org/react-utils';
import { TFunction } from 'i18next';

export const rewardTaskActionDetailMap: Partial<
  Record<
    RewardTaskAction,
    (
      task: UserParticipationType | RewardsTasksType,
      t: TFunction,
    ) => { description?: string; rewards?: string; eligibility?: string }
  >
> = {
  transaction_count: (task, t) => {
    const isFirstType = task.action_unit_limit === '1';

    return {
      description: t(`rewards.detail.description-and-rules.${isFirstType ? '1st' : 'nth'}-${task.action_type}`, {
        number: task.action_unit_limit,
      }),
    };
  },
  transaction_volume: (task, t) => {
    let eligibility: string | undefined;

    if (task.action_type === 'otc') {
      eligibility = t('rewards.detail.eligibility.otc');
    }

    return {
      description: t(`rewards.detail.description-and-rules.${task.action_type}-volume`),
      rewards: t(`rewards.detail.rewards.${task.action_type}-volume`, { multiplier: task.point_multiplier }),
      eligibility,
    };
  },
};

export const rewardTaskActionTypeDetailMap: Partial<
  Record<
    RewardTaskActionType,
    (
      task: UserParticipationType | RewardsTasksType,
      t: TFunction,
    ) => { description?: string; rewards?: string; eligibility?: string }
  >
> = {
  kyc2: (task, t) => {
    return { description: t(`rewards.detail.description-and-rules.kyc`, { number: 2 }) };
  },
  kyc3: (task, t) => {
    return { description: t(`rewards.detail.description-and-rules.kyc`, { number: 3 }) };
  },
  overnight_holding: (task, t) => {
    let eligibility: string | undefined;

    if (task.asset === 'BMMF') {
      eligibility = t('rewards.detail.eligibility.bmmf');
    }

    return {
      description: t(`rewards.detail.description-and-rules.overnight-holding`, {
        asset: task?.asset + (task?.asset === 'BMMF' ? ' Token' : ''),
      }),
      rewards: t(`rewards.detail.rewards.overnight-holding`, {
        asset: task?.asset,
        multiplier: task.point_multiplier,
      }),
      eligibility,
    };
  },
  referral: (task, t) => {
    return {
      description: t(`rewards.detail.description-and-rules.referral`),
      rewards: t(`rewards.detail.rewards.referral`, {
        multiplier: task.point_multiplier,
      }),
    };
  },
};
