import React, { forwardRef, ReactNode } from 'react';

import classNames from 'classnames';

import { Spinner } from '../spinner';
import {
  BackgroundColorType,
  BaseComponentType,
  JustifyType,
  PaddingSizes,
  RadiusSizes,
  ShapeType,
  TextAlign,
} from '../types';
import ButtonClose from './buttonClose';

export type ButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'> &
  BaseComponentType & {
    href?: string;
    shape?: ShapeType;
    size?: PaddingSizes;
    variant?: 'outline' | 'link';
    color?: BackgroundColorType;
    stretch?: boolean | 'xs';
    align?: TextAlign;
    animation?: boolean;
    loading?: boolean;
    active?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    children?: ReactNode | React.ReactElement;
    my?: PaddingSizes;
    mx?: PaddingSizes;
    mt?: PaddingSizes;
    mb?: PaddingSizes;
    justify?: JustifyType;
    radius?: RadiusSizes;
    noWrap?: boolean;
    className?: string;
  };

const ButtonLegacy = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      href,
      shape,
      size = 'md',
      variant,
      color = 'primary-500',
      justify = 'center',
      startIcon,
      endIcon,
      stretch,
      align = 'center',
      animation = true,
      radius = 'xl',
      loading,
      active,
      disabled,
      className,
      style,
      my,
      mx,
      mb,
      mt,
      noWrap = true,
      testId,
      ...props
    },
    ref,
  ): JSX.Element => {
    let stretchClass;

    if (typeof stretch === 'boolean') {
      stretchClass = { 'btn-block': stretch };
    } else {
      stretchClass = { 'xs:w-full': stretch === 'xs' };
    }

    const leftIcon = startIcon ? <div className="shrink-0">{startIcon}</div> : null;
    const rightIcon = endIcon ? <div className="shrink-0">{endIcon}</div> : null;
    const loadingIcon = loading ? <Spinner size="sm" /> : null;

    const classes = classNames(
      'btn',
      {
        [`btn-${size}`]: size,
        [`btn-${shape}`]: shape,
        [`btn-${variant}`]: variant,
        [`btn-${color}`]: color,
        ...stretchClass,
        'no-animation': !animation,
        'btn-active': active,
        'btn-disabled': disabled,
        [`my-${my}`]: my,
        [`mx-${mx}`]: mx,
        [`mt-${mt}`]: mt,
        [`mb-${mb}`]: mb,
        [`tw-rounded-${radius}`]: radius,
        [`tw-justify-${justify}`]: justify,
        [`tw-text-${align}`]: align,
        loading: loading,
      },
      className,
    );

    if (href) {
      return (
        <a className={classes} style={style} href={href} data-testid={testId ?? 'legacy-link-button'}>
          {startIcon && leftIcon}
          {children}
          {endIcon && rightIcon}
        </a>
      );
    } else {
      return (
        <button
          {...props}
          ref={ref}
          className={classes}
          style={style}
          disabled={loading || disabled}
          data-testid={testId ?? 'legacy-button'}
        >
          {loadingIcon || leftIcon}
          {children && <span className={classNames('btn-text', { 'whitespace-nowrap': noWrap })}>{children}</span>}
          {rightIcon}
        </button>
      );
    }
  },
);

ButtonLegacy.displayName = 'ButtonLegacy';

export default Object.assign(ButtonLegacy, { Close: ButtonClose });
