import BigNumber from 'bignumber.js';

import getValueByNestedKey from './getValueByNestedKey';

type SortDirection = 'asc' | 'desc';

/**
 * The orderByPrice function is a TypeScript function that sorts an array of objects based on a specified field and
 * direction. It uses the BigNumber library to compare the values of the specified field in each object and
 * perform the sorting. The function handles cases where the input array is null or undefined, and returns
 * an empty array in those cases.
 *
 * example usage:
 * const data = [
 *   { name: 'Product A', price: '10' },
 *   { name: 'Product B', price: '5' },
 *   { name: 'Product C', price: '15' },
 * ];
 *
 * const sortedData = orderByPrice(data, 'price', 'asc');
 * console.log(sortedData);
 * // Output: [{ name: 'Product B', price: '5' }, { name: 'Product A', price: '10' }, { name: 'Product C', price: '15' }]
 *
 * @param results (array of objects or null/undefined): The array of objects to be sorted. If null or undefined, an empty array is returned.
 * @param field (string): The field in the objects to be used for sorting.
 * @param direction ('asc' | 'desc'): The direction of sorting, either ascending or descending.
 *
 * @returns T[] (array of objects): The sorted array based on the specified field and direction. If the input array is null or undefined, an empty array is returned.
 */
function orderByPrice<T extends Record<string, any>>(
  results: T[] | null | undefined,
  field: string,
  direction: SortDirection,
): T[] {
  if (!results) {
    return [];
  }

  if (direction !== 'asc' && direction !== 'desc') {
    return results;
  }

  return results.sort((a, b) => {
    const aValue = new BigNumber(getValueByNestedKey<T>(a, field));
    const bValue = new BigNumber(getValueByNestedKey<T>(b, field));

    const comparison: number = aValue.comparedTo(bValue);

    if (direction === 'asc') {
      return comparison;
    } else if (direction === 'desc') {
      return -comparison;
    } else {
      throw new Error('Invalid sorting direction');
    }
  });
}

export default orderByPrice;
