import BigNumber from 'bignumber.js';

import { isEmpty } from '../isEmpty';

import RoundingMode = BigNumber.RoundingMode;

interface FormatCurrency {
  values(): string[];

  raw: string;
  value: string;
  shortValue?: string;
}

/**
 * @deprecated use {formatPriceValue} or {formatPrice} instead.
 *
 *
 * Format a currency value with a specified precision, locale, and currency symbol.
 *
 * Example 1: Format currency value with the default options
 * @example
 * const formattedValue = formatCurrencyValue('1234.56789', 2, 'en-US', '$');
 * console.log(formattedValue.values()); // ['1,234', '.56']
 * console.log(formattedValue.value); // "$1,234.56"
 *
 * Example 2: Format currency value with zero value
 * @example
 * const formattedValue = formatCurrencyValue(null, 2, 'en-US', '$');
 * console.log(formattedValue.values()); // ['0', '0']
 * console.log(formattedValue.value); // "$0.00"
 *
 * @param {string|null|undefined} value - The value to format
 * @param {number} precision - The number of decimal places to include
 * @param {string} locale - The locale to use for formatting
 * @param {string} currency - The currency symbol to use
 * @param numeralFormat - The numeral format
 * @param roundingMode
 * @returns {FormatCurrency} An object with two properties: an array of two strings, and a string
 */
function formatCurrencyValue(
  value: string | undefined | null,
  precision?: number,
  locale = 'tr-TR',
  currency = '',
  numeralFormat?: string,
  roundingMode: RoundingMode = BigNumber.ROUND_DOWN,
): FormatCurrency {
  if (!value) {
    return {
      values: () => ['0', '0', currency],
      value: `${currency}${0}`,
      raw: `${currency}${0}`,
      shortValue: undefined,
    };
  }

  const bigValue = BigNumber(value || '0');

  if (bigValue.isZero()) {
    return {
      values: () => ['0', '0', currency],
      value: `${currency}${0}`,
      raw: `${currency}${0}`,
      shortValue: undefined,
    };
  }

  const options = { groupSeparator: ',', decimalSeparator: '.', prefix: '', suffix: `` } as BigNumber.Format;

  let decimalPlaces = precision;

  if (!isEmpty(decimalPlaces)) {
    decimalPlaces = Math.min(bigValue.decimalPlaces() || 0, precision as number);
  }

  let formattedValue;
  let numeralFormattedValue: string | undefined;
  if (bigValue.isGreaterThanOrEqualTo(1)) {
    if (typeof decimalPlaces === 'undefined' || decimalPlaces === null) {
      formattedValue = bigValue.toFormat(options);
    } else {
      formattedValue = bigValue.toFormat(decimalPlaces, roundingMode, options);
    }

    // if (numeralFormat) {
    //   Numeral.locale(locale);
    //   numeralFormattedValue = Numeral(bigValue.toString()).format(numeralFormat);
    //   if (!letters.some((d) => (numeralFormattedValue as string).includes(d))) {
    //     numeralFormattedValue = undefined;
    //   }
    // }
  } else {
    if (typeof decimalPlaces === 'undefined' || decimalPlaces === null) {
      formattedValue = bigValue.toString();
    } else {
      formattedValue = bigValue.toPrecision(decimalPlaces);
    }
  }

  const [integerPart, decimalPart] = formattedValue.split('.');

  const main = BigInt(integerPart.replace(/\D/g, '')).toLocaleString(locale);

  const nf = new Intl.NumberFormat(locale);
  const decimalSeparator = nf.formatToParts(1.1).find((part) => part.type === 'decimal')?.value;

  const decimal = decimalPart ? `${decimalSeparator}${decimalPart}` : '';

  const formattedCurrency = `${currency}${main}${decimal}`;

  const result: FormatCurrency = {
    values(): string[] {
      return [main, decimal, currency];
    },
    value: formattedCurrency,
    raw: formattedValue,
    shortValue: numeralFormattedValue,
  };

  return result;
}

type FormatCurrencyType = {
  amount: number | string;
  defaultNumber?: number;
  minimumFractionDigits?: number;
  locale?: string;
};

export function formatCurrency(value: FormatCurrencyType | number | string, defaultLocale = 'tr-TR') {
  const {
    amount,
    defaultNumber,
    minimumFractionDigits = 2,
    locale,
  } = typeof value === 'number' || typeof value === 'string'
    ? ({ amount: value, locale: defaultLocale } as FormatCurrencyType)
    : (value as FormatCurrencyType);

  if (amount === undefined || amount === null || amount === '') {
    return defaultNumber || 0;
  }

  const innerNumber = new BigNumber(amount);
  // TODO: Do not convert it toNumber.
  return new Intl.NumberFormat(locale, { minimumFractionDigits }).format(innerNumber.toNumber());
}

export default formatCurrencyValue;
