import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import getStoreUrl from '@/libs/helpers/getStoreUrl';

function DownloadApp() {
  const { t } = useTranslation();

  useEffect(() => {
    window.location.href = getStoreUrl();
  }, []);

  return <div>{t('redirect.redirect-to-mobile-app')}</div>;
}

export default DownloadApp;
