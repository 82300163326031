import isInputValid from './isInputValid';
import { formatOptionsMap } from '../currency/formatOptionsMap';
import { LanguageUniversalType } from '../types';

/**
 * Sanitizes the input string by removing unwanted characters and formatting it.
 *
 * @param {string} input The input string to sanitize.
 * @param {string} previousValue The previous value before sanitization.
 * @param {LanguageUniversalType} locale The locale to use for formatting.
 * @param {number} [decimalPlaces] The number of decimal places to allow.
 * @returns {string} The sanitized input string.
 */
export default function sanitizeInput(
  input: string,
  previousValue: string,
  locale: LanguageUniversalType,
  decimalPlaces?: number,
) {
  let result = String(input);

  // Ensure only decimal point exists in sanitized result
  const endingWithDecimalPoint = /[.,]$/.test(result);
  result = result
    .replace(/[.,]$/, '')
    .replaceAll(formatOptionsMap[locale].groupSeparator as string, '')
    .replaceAll(formatOptionsMap[locale].decimalSeparator as string, '.');
  if (endingWithDecimalPoint) {
    result += '.';
  }

  // Validate decimal places
  const [integerPart, decimalPart, invalidInput] = result.split('.');
  if (!invalidInput && decimalPart && decimalPlaces && decimalPart.length > decimalPlaces) {
    result = `${integerPart}.${decimalPart.substring(0, decimalPlaces)}`;
  }

  if (invalidInput || !isInputValid(result)) {
    return previousValue;
  }
  return result;
}
