import { useMemo } from 'react';

import { concatTicker, parseAssetPair } from '@bilira-org/react-utils';
import { useParams } from 'react-router-dom';

import CryptoQuery from '@Libs/clientInstances/cryptoQuery';
import PriceQuery from '@Libs/clientInstances/priceQuery';

const useAssetDetail = () => {
  const { assetPair } = useParams();
  const [base, quote] = parseAssetPair(assetPair);

  const assetData = CryptoQuery.useGetAssets({ suspense: false });

  const priceData = PriceQuery.useGetSymbolStatistics(concatTicker(base, quote), { suspense: false });

  const assetResult = useMemo(() => assetData.data?.find((d) => d.symbol === base), [assetData.data, base]);

  return {
    assetPair,
    base,
    quote,
    assetData: assetResult,
    hasError: priceData.isError,
    priceData: priceData.data,
  };
};

export default useAssetDetail;
