import React from 'react';

import { Block, Button, Drawer, List, Meta, Separator, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DisplayFeatureFlag from '@/components/common/DisplayFeatureFlag';
import { useGeneralStore } from '@/store/generalStore';
import { IconCustom } from '@Components/icon/CustomIcon';
import HelpContent from '@Components/layouts/components/components/HelpContent';
import { withPublicRoute } from '@Libs/hocs';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

import PreferencesContent from './components/PreferencesContent';

/**
 * Component for displaying a mobile menu drawer for anonymous users
 */
const PublicMobilMenuDrawer = () => {
  const { t } = useTranslation();
  const { publicDrawerOpen, setPublicDrawerOpen } = useGeneralStore();
  const navigate = useNavigate();
  const { getRoute } = useLocaleRoute();

  return (
    <Drawer open={publicDrawerOpen} onClose={() => setPublicDrawerOpen(false)}>
      <List spaceY="xs">
        <List.Item px="md" py="md">
          <Block gap="sm" width="size-full">
            <Button variant="outline" size="md" stretch onClick={() => navigate(getRoute('Login'))}>
              {t('navigation.login')}
            </Button>

            <Button stretch variant="filled" size="md" onClick={() => navigate(getRoute('Register'))}>
              {t('navigation.create-free-account')}
            </Button>
          </Block>
        </List.Item>
      </List>
      <Separator width="size-full" mt="md" mb="xs" />
      <PreferencesContent />
      <Separator width="size-full" my="xs" />
      <List spaceY="xs">
        <List.Item px="md" py="md" hoverBgColor="neutral-300" onClick={() => navigate('/rewards')}>
          <Meta
            space="md"
            extra={<IconCustom type="sparkles" width="size-6" />}
            title={
              <Text color="gradient-1" weight="medium" size="sm">
                {t('rewards.tracking-header')}
              </Text>
            }
          />
        </List.Item>
      </List>
      <HelpContent closeCallback={() => setPublicDrawerOpen(false)} />
    </Drawer>
  );
};

export default withPublicRoute(PublicMobilMenuDrawer);
