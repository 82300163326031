import React, { forwardRef, ReactNode } from 'react';

import cn from 'classnames';

import { BaseComponentType } from '../types';
import { wrapWithElementIfInvalid } from '../utils';

export interface ISwapProps extends React.LabelHTMLAttributes<HTMLLabelElement>, BaseComponentType {
  className?: string;
  /** The content to be displayed when the Swap is active. */
  onElement: ReactNode | ReactNode[];
  /** The content to be displayed when the Swap is inactive. */
  offElement: ReactNode | ReactNode[];
  /** Whether Swap is active or not */
  active?: boolean;
  /** Whether to rotate swap content based on active */
  rotate?: boolean;
  /** Whether to flip swap content based on active */
  flip?: boolean;
}

/**
 * Swap component for toggling between two elements.
 *
 * @example
 * <Swap
 *   onElement={<IconOn />}    // Element to display when Swap is active
 *   offElement={<IconOff />}  // Element to display when Swap is inactive
 *   active={isActive}          // Whether the Swap is currently active
 *   rotate                    // Rotate the Swap elements
 *   flip                      // Flip the Swap elements
 * />
 */
const Swap = forwardRef<HTMLLabelElement, ISwapProps>(
  ({ onElement, offElement, active, rotate, flip, className, testId, ...props }, ref): JSX.Element => {
    const classes = cn('swap', className, 'p-1', {
      'swap-active': active,
      'swap-rotate': rotate,
      'swap-flip': flip,
    });

    // These next two pieces allow classname to be added to valid elements, or wrap invalid elements with a div and the classname
    const onEl = wrapWithElementIfInvalid({
      node: onElement,
      wrapper: <div></div>,
      props: { className: 'swap-on w-6 h-6 fill-white' },
    });

    const offEl = wrapWithElementIfInvalid({
      node: offElement,
      wrapper: <div></div>,
      props: { className: 'swap-off w-6 h-6 fill-white' },
    });

    return (
      <label {...props} className={classes} ref={ref} data-testid={testId ?? 'swap'}>
        <input type="checkbox" className="hidden" />
        {onEl}
        {offEl}
      </label>
    );
  },
);

Swap.displayName = 'Swap';

export default Swap;
