import React from 'react';

import { Block } from '@bilira-org/design';
import Announcement from '@Modules/overview/components/announcements/Announcement';
import LearnCard from '@Modules/overview/components/learn/LearnCard';
import WaitingTransactions from '@Modules/overview/components/WaitingTransactions';

import PromoCode from './PromoCode';

const Sidebar = () => {
  return (
    <Block gap="4xl">
      <PromoCode />
      <Announcement />
      <LearnCard />
      <WaitingTransactions />
    </Block>
  );
};

export default Sidebar;
