import React from 'react';

import { Block, Icon, Layout } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import Menubar from '@Components/common/Menubar';

const { Hero } = Layout;

const InfoBar = () => {
  const { t } = useTranslation();

  const headers = [
    {
      text: t('account.headers.account'),
      path: '/account',
      defaultPath: true,
      icon: <Icon type="o:user-circle" size="lg" />,
    },
    {
      text: t('account.headers.security-settings'),
      path: '/account/security-settings',
      icon: <Icon type="o:shield-check" size="lg" />,
    },
    {
      text: t('account.headers.id-verification'),
      path: '/account/id-verification',
      icon: <Icon type="o:identification" size="lg" />,
    },
    {
      text: t('account.headers.preferences'),
      path: '/account/preferences',
      icon: <Icon type="o:cog-6-tooth" size="lg" />,
    },
  ];

  return (
    <Hero size="none" pxNoneUntilSm>
      <div>
        <Block row pt={{ xs: 'lg', sm: '2xl', lg: '4xl' }}>
          <Menubar data={headers} />
        </Block>
      </div>
    </Hero>
  );
};

export default InfoBar;
