import { AccountQuery } from '@bilira-org/react-utils';

import { environment } from '@Libs/constants/environment';

import getI18nLanguage from '../helpers/getI18nLanguage';

const client = new AccountQuery({
  baseUrl: environment.STABLECOIN_API_BASE_URL,
  params: { withCredentials: true },
  getLocale: getI18nLanguage,
});

Object.freeze(client);

export default client;
