import React, { ReactElement, useMemo } from 'react';

import classNames from 'classnames';

import generatePagination from './helpers/buildPager';
import { Icon } from '../icons';
import { BackgroundColorType, BaseComponentType } from '../types';
import { Text } from '../typography';

type PagerProps = {
  page?: number;
  pageCount?: number;
  perPage?: number;
  totalCount?: number;
};

export type PaginationProps = React.HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Array of items to be used in aria-label
     * @note TODO: Do we need this?
     */
    items?: ReactElement[];
    /** Class name for additional styling */
    className?: string;
    /** Background color of the pagination */
    color?: BackgroundColorType;
    /** Callback function that is triggered when the page changes */
    onPageChange: (page: number) => void;
    /** Pagination configuration options */
    pagination: PagerProps;
  };

/**
 * The `Pagination` component is used to create a pagination control with page navigation buttons.
 *
 * @example
 * <Pagination
 *   pagination={{
 *     page: 1,
 *     pageCount: 10,
 *     perPage: 10,
 *     totalCount: 100,
 *   }}
 *   onPageChange={(page) => console.log(page)}
 * />
 */
const Pagination = React.forwardRef<HTMLDivElement, PaginationProps>(
  ({ items, onPageChange, color = 'white', className, pagination, testId, ...props }, ref): JSX.Element => {
    const classes = classNames('pagination', className, 'justify-end');

    // Usage example
    const total = pagination.pageCount;
    const page = Number(pagination.page);

    const paginationItems = useMemo(() => generatePagination(total, page), [total, page]);

    return (
      <div
        aria-label={`Group of ${items?.length} buttons`}
        {...props}
        className={classes}
        ref={ref}
        data-testid={testId ?? 'pagination'}
      >
        <div className="buttons">
          <button className="arrow-button" onClick={() => onPageChange(page - 1)} disabled={pagination.page === 1}>
            <Icon type="s:chevron-left" size="sm" />
          </button>
          {paginationItems?.map((d) => {
            if (typeof d.value === 'number') {
              return (
                <button
                  className={classNames({ 'tw-bg-neutral-300': d.active })}
                  key={d.key}
                  onClick={() => onPageChange(Number(d.value))}
                >
                  {d.value}
                </button>
              );
            }
            return (
              <Text key={d.key} size="xs" weight="medium" className="px-[8.5px] select-none">
                ...
              </Text>
            );
          })}
          <button
            className="arrow-button"
            onClick={() => onPageChange(page + 1)}
            disabled={pagination.page === pagination.pageCount}
          >
            <Icon type="s:chevron-right" size="sm" />
          </button>
        </div>
      </div>
    );
  },
);

export default Pagination;
