import React, { ReactElement } from 'react';

import classNames from 'classnames';

import BreadcrumbsItem, { BreadcrumbsItemProps } from './breadcrumbsItem';
import { BaseComponentType, JustifyType } from '../types';

export type BreadcrumbsProps = React.HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Breadcrumbs items */
    children?: ReactElement<BreadcrumbsItemProps> | ReactElement<BreadcrumbsItemProps>[];
    /** Inner ul ref */
    innerRef?: React.Ref<HTMLUListElement>;
    /** Inner ul attributes */
    innerProps?: React.HTMLAttributes<HTMLUListElement>;
    /** Additional classes */
    className?: string;
    /** List type */
    listType?: 'caret' | 'disc';
    /** Justify content of the breadcrumb items */
    justify?: JustifyType;
  };

/**
 * Breadcrumbs component to display a navigation trail
 *
 * @example
 * <Breadcrumbs>
 *   <Breadcrumbs.Item href="/">
 *     Home
 *   </Breadcrumbs.Item>
 *   <Breadcrumbs.Item href="/category">
 *     Category
 *   </Breadcrumbs.Item>
 * </Breadcrumbs>
 */
const Breadcrumbs = React.forwardRef<HTMLDivElement, BreadcrumbsProps>(
  ({ children, className, listType = 'caret', innerProps, innerRef, testId, justify, ...props }, ref): JSX.Element => {
    const classes = classNames('breadcrumbs', 'text-sm', className);

    return (
      <div
        role="navigation"
        aria-label="Breadcrumbs"
        {...props}
        className={classes}
        ref={ref}
        data-testid={testId ?? 'breadcrumbs'}
      >
        <ul
          className={classNames(listType, { [`tw-justify-${justify}`]: justify }, innerProps?.className)}
          {...innerProps}
          ref={innerRef}
        >
          {children}
        </ul>
      </div>
    );
  },
);

export default Object.assign(Breadcrumbs, { Item: BreadcrumbsItem });
