import React from 'react';

import { Alert, Block, Button, Col, Form, Grid, Label, Separator, Skeleton, Text, withSuspense } from '@bilira-org/design';
import { CryptoAssetsType, ForceDecimal, isNumberInRange } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import Limits from '@/components/common/Limits';
import WithdrawAllButton from '@/components/common/WithdrawAllButton';
import FormattedInputNumber from '@Components/common/FormattedInputNumber';
import FreeBalanceLabel from '@Components/common/FreeBalanceLabel';
import TwoFAModal from '@Components/TwoFA/TwoFAModal';
import CryptoWithdrawalFee from '@Modules/crypto/withdraw/components/components/CryptoWithdrawalFee';
import useWithdrawCryptoForm, { IAmount } from '@Modules/crypto/withdraw/components/hooks/useWithdrawCryptoForm';

type Props = {
  asset?: CryptoAssetsType;
  onSuccess: () => void;
};

const WithdrawCryptoForm = ({ asset, onSuccess }: Props) => {
  const { t } = useTranslation();
  const {
    isPending,
    error,
    onSubmit,
    form,
    onAmountChange,
    twoFAData,
    activeTwoFAModal,
    setActiveTwoFAModal,
    onVerifyTwoFA,
    txMax,
    txMin,
    withdrawFee,
    withdrawFeeAsset,
    withdrawTotalAmount,
    isWithdrawalFeeLoading,
    isSubmitButtonDisabled,
    totalAmount,
    formattedTxMax,
    formattedTxMin,
    isInputDisabled,
    selectedWallet,
  } = useWithdrawCryptoForm({
    asset,
    onSuccess,
  });

  return (
    <>
      <Form<IAmount> form={form} onFinish={onSubmit}>
        <Grid>
          {error && (
            <Col>
              <Alert status="red">{error}</Alert>
            </Col>
          )}
          <Col>
            <Form.Field
              controlled
              label={
                <Block row justify="between">
                  <Label>{t('common.amount')}</Label>
                  <FreeBalanceLabel
                    labelTextProps={{ size: 'sm', weight: 'regular' }}
                    numberTextProps={{ size: 'sm', weight: 'regular' }}
                    label={`${t('common.free-balance')}:`}
                    symbol={asset?.symbol}
                    formatPriceProps={{
                      decimal: 2,
                      precision: asset?.decimals,
                      forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
                    }}
                  />
                </Block>
              }
              name="amount"
              rules={{
                validate: (value) => {
                  const errorMessage = t('common.validation.withdraw-min-max', {
                    min: `${formattedTxMin}`,
                    max: `${formattedTxMax} ${asset?.symbol}`,
                  });

                  const isValueEmpty = value === undefined || value === '';
                  const isValueInRange = !isValueEmpty && isNumberInRange(value, txMin.toString(), txMax.toString());

                  if (isValueEmpty || !isValueInRange) {
                    return errorMessage;
                  }

                  return true;
                },
              }}
            >
              {({ field, fieldState }) => (
                <FormattedInputNumber
                  decimalPlaces={asset?.decimals}
                  disabled={isInputDisabled}
                  textSize="2xl"
                  placeholder={t('crypto.withdraw-amount-placeholder', {
                    'min-amount': `${formattedTxMin} ${asset?.symbol || ''}`,
                  })}
                  iconEnd={
                    <Block row gap="sm" items="center">
                      <WithdrawAllButton
                        decimalPlaces={asset?.decimals}
                        disabled={isInputDisabled}
                        symbol={asset?.symbol}
                        onClick={(amount) => {
                          form.setValue('amount', amount, {
                            shouldDirty: true,
                            shouldValidate: true,
                            shouldTouch: true,
                          });
                          onAmountChange(amount);
                        }}
                        network={selectedWallet?.network}
                      />
                      <Separator height="size-6" />
                      <Text
                        loading={!asset?.symbol}
                        skeleton={<Skeleton rounded="sm" height="size-4" width="size-10" />}
                        size="sm"
                        color={isInputDisabled ? 'neutral-500' : 'neutral-700'}
                      >
                        {asset?.symbol}
                      </Text>
                    </Block>
                  }
                  value={field.value || ''}
                  onChange={(value) => {
                    field.onChange(value);
                    onAmountChange(value as string);
                  }}
                  invalid={fieldState.error !== undefined}
                />
              )}
            </Form.Field>
            <Col>
              <Separator width="size-full" my="md" />
            </Col>
            <Limits direction="withdrawal" domain="crypto" asset={asset} wallet={selectedWallet} />
          </Col>
          <Col>
            <Separator width="size-full" my="md" />
          </Col>
          <Col>
            <CryptoWithdrawalFee
              asset={withdrawFeeAsset}
              loading={isWithdrawalFeeLoading}
              withdrawFee={withdrawFee}
              totalAmount={totalAmount}
              withdrawTotalAmount={withdrawTotalAmount}
            />
          </Col>
          <Col>
            <Button
              variant="filled"
              justify="center"
              disabled={isSubmitButtonDisabled}
              size="xl"
              mt="lg"
              type="submit"
              stretch
              loading={isPending}
            >
              {t('common.accept')}
            </Button>
          </Col>
        </Grid>
      </Form>

      <TwoFAModal
        type="cryptoWithdrawal"
        twoFAData={twoFAData}
        active={activeTwoFAModal}
        setActive={setActiveTwoFAModal}
        callback={onVerifyTwoFA}
        additionalPayload={{
          wallet: selectedWallet?.address,
          asset: asset?.symbol,
          amount: totalAmount,
          network: selectedWallet?.network,
        }}
      />
    </>
  );
};

export default withSuspense(
  WithdrawCryptoForm,
  <Grid>
    <Col>
      <Block row justify="between" mb="xs">
        <Skeleton height="size-4" width="size-12" />
        <Skeleton height="size-4" width="size-12" />
      </Block>
      <Skeleton height="size-12" width="size-full" rounded="xl" />
      <Col>
        <Separator width="size-full" my="md" />
      </Col>
      <Block row justify="between">
        <Skeleton height="size-5" width="size-10" />
        <Block gap="sm" items="end">
          <Skeleton height="size-5" width="size-20" />
          <Skeleton height="size-5" width="size-16" />
        </Block>
      </Block>
    </Col>
    <Col>
      <Separator width="size-full" my="md" />
    </Col>
    <Col>
      <Block gap="sm">
        <Block row justify="between">
          <Skeleton height="size-5" width="size-10" />
          <Skeleton height="size-5" width="size-10" />
        </Block>
        <Block row justify="between">
          <Skeleton height="size-5" width="size-10" />
          <Skeleton height="size-5" width="size-10" />
        </Block>
      </Block>
    </Col>
    <Col>
      <Skeleton height="size-12" width="size-full" rounded="xl" />
    </Col>
  </Grid>,
);
