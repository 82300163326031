import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import RedirectSpinCard from '@Components/common/RedirectSpinCard';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';
import { oauthLoginUrl, oauthRegisterUrl } from '@Libs/utils';

const Login = () => {
  const { t, i18n } = useTranslation();
  const { getRoute, route } = useLocaleRoute();

  const redirectUrl = route === getRoute('Login') ? oauthLoginUrl : oauthRegisterUrl;

  redirectUrl.searchParams.set('locale', i18n.language);
  setTimeout(() => window.location.replace(redirectUrl.toString()), 500);

  return <RedirectSpinCard title={t('redirect.redirecting-2')} description={t('redirect.redirecting-to-login')} />;
};

export default Login;
