import { useRef } from 'react';

import { SymbolStatisticsDataType } from '@bilira-org/react-utils/src/apis/price';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import { usePriceStore } from '@Store/priceStore';

type Props = {
  symbol: string;
};
const useStatisticsLive = ({ symbol }: Props) => {
  const priceRef = useRef<SymbolStatisticsDataType | null>(null);

  const liveData = usePriceStore((state) => {
    const currentPrice = state.statistics?.s === symbol ? state.statistics : null;
    const refPrice = priceRef.current?.s === symbol ? priceRef.current : null;

    return currentPrice ?? refPrice;
  });

  priceRef.current = liveData;

  const { data } = PriceQuery.useGetSymbolStatistics(symbol, { enabled: !liveData, suspense: false });

  return {
    liveData: liveData ?? data,
  };
};

export default useStatisticsLive;
