import { concatTicker, CryptoTimeScale, parseAssetPair } from '@bilira-org/react-utils';
import { useParams } from 'react-router-dom';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import { timeScaleConfiguration } from '@Modules/market/detail/components/priceChart/helpers/scaleConfiguration';

type Props = {
  timeScale: CryptoTimeScale;
};

const usePriceChartHook = ({ timeScale }: Props) => {
  const { assetPair } = useParams();

  const [base, quote] = parseAssetPair(assetPair);

  const scaleConf = timeScaleConfiguration[timeScale];
  const { data, isPending, isError } = PriceQuery.useGetLimitedOHLCS(
    {
      bar_count: scaleConf.point,
      symbol: concatTicker(base, quote),
      period: scaleConf.scale,
    },
    { suspense: false },
  );

  return {
    base,
    quote,
    data,
    isPending: isPending,
    hasError: isError,
  };
};

export default usePriceChartHook;
