import { BlogQuery } from '@bilira-org/react-utils';

import { environment } from '@Libs/constants/environment';

import getI18nLanguage from '../helpers/getI18nLanguage';

const client = new BlogQuery({
  baseUrl: environment.CONTENT_API_BASE_URL,
  getLocale: getI18nLanguage,
});

Object.freeze(client);

export default client;
