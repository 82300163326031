import React from 'react';

import { Avatar, Tooltip } from '@bilira-org/design';
import { NetworkAliasType } from '@bilira-org/react-utils';

import useGetNetworkDetailsByAlias from '@/libs/hooks/useGetNetworkDetailsByAlias';
import IconCoin from '@Components/icon/IconCoin';

type Props = {
  networkAlias?: NetworkAliasType;
  children: React.ReactNode;
};

const NetworkIndicator = ({ networkAlias, children }: Props) => {
  const { network } = useGetNetworkDetailsByAlias({ alias: networkAlias }, { suspense: false });

  const networkName = network?.name || networkAlias;

  return (
    <Avatar
      variant="padded"
      offset={{ top: 'xs', right: 'sm' }}
      iconColor="theme-wn"
      tr={
        <Tooltip message={networkName} placement="top">
          <IconCoin type={networkAlias} size="md" />
        </Tooltip>
      }
      avatar={children}
    />
  );
};

export default NetworkIndicator;
