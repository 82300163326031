import { useCallback, useMemo, useState } from 'react';

import { useSafeLocalStorage } from '@bilira-org/design';
import { BalanceType, concatTicker, filterExpression, filterLowBalances, useDeferredFilter } from '@bilira-org/react-utils';

import useGetSortedBalances from '@Libs/hooks/useGetSortedBalances';

const useMyAssetTableHook = () => {
  const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useSafeLocalStorage<boolean>('MYA-HSB', false);

  const { getPinnedBalance, isPending } = useGetSortedBalances({
    params: { quote_asset: 'TRYB' },
    options: {
      suspense: false,
    },
  });

  const filteredBalanceList = useMemo(
    () => (isEnabled ? filterLowBalances(getPinnedBalance('TRYB')) : getPinnedBalance('TRYB')),
    [isEnabled, getPinnedBalance],
  );

  const filter = useCallback(
    (item: BalanceType, search: string) => filterExpression<BalanceType>(['asset', 'name'])(item, search),
    [],
  );

  const { searchText, onSearch, filteredData } = useDeferredFilter({
    predicate: filter,
    data: filteredBalanceList,
  });

  const subscriptionList = useMemo(
    () => filteredData?.map((d) => concatTicker(d.asset, d.quote_asset)),
    [filteredData],
  );

  return {
    searchText,
    onSearch,
    data: filteredData,
    isPending,
    isConversionModalOpen,
    setIsConversionModalOpen,
    isEnabled,
    setIsEnabled,
    subscriptionList,
  };
};

export default useMyAssetTableHook;
