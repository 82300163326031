import Dark from '../../assets/dark.svg';
import Light from '../../assets/light.svg';
import { useDarkMode } from '../../hooks';
import { Swap } from '../swap';
import { BaseComponentType } from '../types';

type Props = BaseComponentType;

/**
 * Toggle for the theme of the application
 * @example
 * <ToggleDarkMode />
 */
function ToggleDarkMode({ testId }: Props): JSX.Element {
  const [isDark, setIsDark] = useDarkMode();

  return (
    <Swap
      rotate
      onChange={() => setIsDark(!isDark)}
      offElement={<Dark />}
      onElement={<Light />}
      testId={testId ?? 'toggle-dark-mode'}
    />
  );
}

export default ToggleDarkMode;
