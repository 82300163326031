import React from 'react';

import { flexRender, Row } from '@tanstack/react-table';
import classNames from 'classnames';

import { TableBodyStyle } from './types';
import { getColumnClasses, getCommonColumnStyles } from '../helpers';

interface TableRowProps<T> {
  row: Row<T>;
  highlightFn: (row: T, id: string) => boolean;
  highlightVariant?: 'bordered' | 'marker';
  onRowClick?: (data: T) => void;
  loading?: boolean;
  bodyStyle?: TableBodyStyle;
  isFetchingNextPage?: boolean;
}

function TableRow<T>({
  row,
  highlightFn,
  highlightVariant,
  onRowClick,
  loading,
  bodyStyle,
  isFetchingNextPage,
}: TableRowProps<T>) {
  const canExpand = row.getCanExpand();
  const isExpanded = row.getIsExpanded();

  const handleClick = (cell: any) => {
    if (cell.column.columnDef.disableClick) return;
    if (onRowClick) return onRowClick(row.original);
    if (canExpand) return row.toggleExpanded();
  };

  return (
    <>
      <tr
        className={classNames('table-row', {
          [`highlighted-${highlightVariant}`]: highlightFn(row.original, row.id),
          clickable: onRowClick || canExpand,
          expanded: isExpanded,
        })}
      >
        {row.getVisibleCells().map((cell) => {
          const { columnDef } = cell.column;
          const cellStyle = columnDef.style?.(cell.row.original);
          const renderCellContent = () => {
            if (loading || isFetchingNextPage) {
              return columnDef.skeleton ? flexRender(columnDef.skeleton, cell.getContext()) : undefined;
            }
            return flexRender(columnDef.cell, cell.getContext());
          };
          return (
            <td
              key={cell.id}
              onClick={() => handleClick(cell)}
              align={columnDef.align}
              className={classNames('row-cell', getColumnClasses(cell.column), {
                [`mt-${bodyStyle?.mt}`]: bodyStyle?.mt,
                [`py-${cellStyle?.py || bodyStyle?.py}`]: cellStyle?.py || bodyStyle?.py,
                [`px-${cellStyle?.px || bodyStyle?.px}`]: cellStyle?.px || bodyStyle?.px,
                'tw-align-middle': !cellStyle?.verticalAlign,
                [`tw-w-${cellStyle?.width}`]: cellStyle?.width,
                [`tw-align-${cellStyle?.verticalAlign}`]: cellStyle?.verticalAlign,
              })}
              style={{ ...getCommonColumnStyles(cell.column) }}
            >
              {renderCellContent()}
            </td>
          );
        })}
      </tr>
    </>
  );
}

export default TableRow;
