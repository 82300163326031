import React from 'react';

import { useTranslation } from 'react-i18next';

import { LayoutCommon } from '@Components/layouts';
import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';
import FiatDeposit from '@Modules/fiat/deposit/components/FiatDeposit';

const FiatDepositContainer = () => {
  const { t } = useTranslation();
  return (
    <>
      <LayoutCommon>
        <LayoutCommon.Content>
          <FiatDeposit />
        </LayoutCommon.Content>
        <LayoutCommon.Sidebar>
          <TransactionPanelCard
            title={t('common.recent-deposit-transactions')}
            limit={5}
            type="fiat"
            direction="deposit"
            showAllButton
          />
        </LayoutCommon.Sidebar>
      </LayoutCommon>
    </>
  );
};

export default FiatDepositContainer;
