import { Callback, Dict, Mixpanel, OverridedMixpanel, RequestOptions } from 'mixpanel-browser';

import { mixpanel } from './index';

type TrackerUserType = {
  name: string;
  surname: string;
  id: string;
  [key: string]: any;
};

class Tracker {
  _mixpanelTracker: Mixpanel;
  constructor(mixpanelTracker: OverridedMixpanel) {
    this._mixpanelTracker = mixpanelTracker;
  }

  identify = (id: string) => {
    this._mixpanelTracker.identify(id);
  };

  user = ({ name, surname, email, id, ...rest }: TrackerUserType) => {
    this._mixpanelTracker.people.set(id, {
      $first_name: name,
      $last_name: surname,
      $created: new Date().toISOString(),
      id,
      ...rest,
    });
  };

  logout = () => {
    this._mixpanelTracker.reset();
  };

  track = (
    eventName: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback,
  ) => {
    this._mixpanelTracker.track(eventName, { source: 'web', ...(properties || {}) }, optionsOrCallback, callback);
  };

  trackPageView = (page: string) => {
    this._mixpanelTracker.track_pageview({ page });
  };
}

const tracker = new Tracker(mixpanel);

export default tracker;
