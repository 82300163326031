import React, { useCallback, useEffect, useRef } from 'react';

import { Block, BottomSheet, Confetti, ConfettiRef, Display } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import useOfferTimeout from '@Components/trade/buySell/hooks/useOfferTimeout';
import useSwapStateHook from '@Components/trade/buySell/hooks/useSwapStateHook';
import OfferCompleted from '@Components/trade/buySell/offer/OfferCompleted';
import OfferFail from '@Components/trade/buySell/offer/OfferFail';
import OfferPreview from '@Components/trade/buySell/offer/OfferPreview';
import OfferProcessing from '@Components/trade/buySell/offer/OfferProcessing';
import { TradeWidgetType, useBuySellStore } from '@Components/trade/buySell/store';

export type Props = {
  refetchCallback: () => void;
  onOfferClose: (isOfferCompleted: boolean) => void;
  tradeWidgetType: TradeWidgetType;
};

/**
 * Represents a BuySellOffer component.
 */
const BuySellOffer = ({ refetchCallback, onOfferClose, tradeWidgetType }: Props) => {
  const particleRef = useRef<ConfettiRef>(null);
  const {
    isOfferRequested,
    changeSwapStatus,
    isOfferCompleted,
    isOfferExecuting,
    isOfferPendingFill,
    terminateRequest,
    isOfferFailed,
    isOfferOpen,
    buySellType,
  } = useSwapStateHook();

  const { t } = useTranslation();
  const { initializeTimeout } = useOfferTimeout({ changeSwapStatus });
  const { setBuySellOfferModal, buySellOfferModal } = useBuySellStore();

  useEffect(() => {
    return () => {
      // Offer preview screen should be disabled on component unmount.
      // E.g. on page navigate.
      setBuySellOfferModal({ [tradeWidgetType]: false });

      terminateRequest();
    };
  }, [setBuySellOfferModal, terminateRequest, tradeWidgetType]);

  useEffect(() => {
    initializeTimeout(isOfferRequested);
    if (isOfferCompleted) {
      particleRef.current?.boom?.();
    }
  }, [initializeTimeout, isOfferRequested, isOfferCompleted]);

  const onClose = useCallback(() => {
    if (!isOfferPendingFill) {
      terminateRequest();
      setBuySellOfferModal({ [tradeWidgetType]: false });
      onOfferClose(isOfferCompleted);
    }
  }, [terminateRequest, isOfferPendingFill, onOfferClose]);

  let modalTitle = t(`trade.offer.title.${buySellType}`);

  if (isOfferExecuting) {
    modalTitle = ' ';
  }

  let borderColor: 'green' | 'red' | null = buySellType === 'buy' ? 'green' : 'red';
  if (!isOfferOpen) {
    borderColor = null;
  }

  return (
    <BottomSheet
      title={modalTitle}
      borderColor={borderColor}
      onPreviousClick={onClose}
      open={!!buySellOfferModal?.[tradeWidgetType]}
      displayHeader={!isOfferExecuting && !isOfferFailed}
    >
      <Display show={buySellOfferModal?.[tradeWidgetType]}>
        <Block gap="md" p="2xl" height="size-full">
          <Display show={isOfferPendingFill}>
            <OfferProcessing />
          </Display>
          <Display show={isOfferFailed}>
            <OfferFail onClose={onClose} />
          </Display>
          <Display show={isOfferCompleted}>
            <OfferCompleted onClose={onClose} />
          </Display>
          <Display show={!isOfferExecuting && !isOfferFailed}>
            <OfferPreview onCancel={onClose} refetchCallback={refetchCallback} />
          </Display>
          <Confetti ref={particleRef} />
        </Block>
      </Display>
    </BottomSheet>
  );
};

export default BuySellOffer;
