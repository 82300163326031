import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { useDarkMode } from '../../hooks';
import { Block } from '../block';
import { IconCustom } from '../icons';
import { BaseComponentType, GapSizes, MarginSizes, PaddingSizes } from '../types';
import { Text } from '../typography/text';

export type EmptyProps = BaseComponentType & {
  /** Message to display */
  message: string;
  /** Margin top */
  mt?: PaddingSizes;
  /** Margin bottom */
  my?: MarginSizes;
  /** Extra content to display */
  extra?: ReactElement;
  /** Gap between items */
  gap?: GapSizes;
};

/**
 *  Component for displaying a message when there is no content to show.
 *
 * @example
 * <Empty message="No items found" mt="4" gap="2" testId="empty-component" />
 */
const Empty = ({ message, gap, mt, my, extra, testId }: EmptyProps) => {
  const [isDark] = useDarkMode();

  if (!message) {
    return null;
  }

  const classes = classNames('flex flex-col items-center mx-auto max-w-[26rem]', {
    [`gap-${gap}`]: gap,
    [`mt-${mt}`]: mt,
    [`my-${my}`]: my,
  });

  return (
    <div className={classes} data-testid={testId ?? 'empty'}>
      <Block gap="sm" items="center">
        <IconCustom type="empty-folder" theme={isDark ? 'dark' : 'light'} />
        <Text size="sm" color="neutral-600" align="center">
          {message}
        </Text>
      </Block>
      {extra && <div className="my-6">{extra}</div>}
    </div>
  );
};

export default Empty;
