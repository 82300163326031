import React, { ChangeEvent, forwardRef, ReactNode } from 'react';

import { LanguageUniversalType, useFormattedNumberInput } from '@bilira-org/react-utils';
import classNames from 'classnames';

import fixIosInputFocus from '../../helpers/fixIosInputFocus';
import { BackgroundColorType } from '../color';
import { BaseComponentType, BaseSize, FontWeight, PaddingSizes, TextSizes } from '../types';

interface Props {
  /** The value of the input. Can be a string or null. */
  value: string | null | undefined;
  /** Callback triggered on input change. */
  onChange?: (value: string) => void;
  /** Disables the input. */
  disabled?: boolean;
  /** Size of the input. */
  size?: BaseSize;
  /** Background color of the input. */
  color?: BackgroundColorType;
  /** Padding of the input. */
  padding?: PaddingSizes;
  /** Icon to be displayed with the input. */
  icon?: ReactNode;
  /** Text size of the input. */
  textSize?: TextSizes;
  /** Font weight of the input. */
  weight?: FontWeight;
  /** Icon displayed at the start of the input. */
  iconStart?: ReactNode;
  /** Icon displayed at the end of the input. */
  iconEnd?: ReactNode;
  /** Additional class name for the input. */
  className?: string;
  /** Indicates whether the input is in an invalid state. */
  invalid?: boolean;
  /** Number of decimal places to allow. */
  decimalPlaces?: number;
  /** Callback triggered on Enter key press. */
  onEnter?: () => void;
  /** Locale of the format */
  locale?: string;
}

export type FormattedInputNumberProps = Props &
  Omit<React.HTMLProps<HTMLInputElement>, keyof Props> &
  BaseComponentType;

/**
 * InputNumber component for numeric input.
 *
 * @example
 * <NumberInput
 *  decimalPlaces={4}
 *  onChange={handleChange}
 *  value={value}
 *  color="ghost"
 *  placeholder="Enter a number"
 * />
 */
const FormattedInputNumber = forwardRef<HTMLInputElement, FormattedInputNumberProps>((props, ref) => {
  const {
    value: valueFromProps,
    onChange,
    disabled,
    size,
    color,
    padding = 'lg',
    icon,
    textSize,
    weight,
    iconStart,
    iconEnd,
    className,
    invalid,
    decimalPlaces,
    testId,
    onEnter,
    onKeyDown,
    locale = 'en',
    onFocus,
    ...rest
  } = props;

  const { formattedValue: inputValue, handleChange } = useFormattedNumberInput({
    valueFromProps,
    locale,
    onChange,
    decimalPlaces,
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value);
  };

  const classes = classNames(
    'input w-full',
    {
      [`input-${color}`]: color,
      'has-icon-start': !!iconStart,
      'has-icon-end': !!iconEnd,
      'input-invalid': invalid,
    },
    className,
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };

  return (
    <label className="input-number-group" data-testid={testId}>
      {iconStart && <span className="input-icon-start">{iconStart}</span>}

      <input
        {...(testId ? { 'data-testid': `${testId}-inner` } : {})}
        ref={ref}
        className={classes}
        onChange={handleInputChange}
        value={inputValue}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        inputMode="decimal"
        onFocus={(e) => {
          fixIosInputFocus(e.currentTarget);
          onFocus?.(e);
        }}
        {...rest}
      />

      {iconEnd && <span className="input-icon-end">{iconEnd}</span>}
    </label>
  );
});

FormattedInputNumber.displayName = 'FormattedInputNumber';

export default FormattedInputNumber;
