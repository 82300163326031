import React, { useState } from 'react';

import { Layout, Panel } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import GeneralFilterTags from '@Modules/market/market/table/components/GeneralFilterTags';
import MarketTableContent from '@Modules/market/market/table/components/MarketTableContent';
import Header from '@Modules/market/ranking/Header';

const { Content } = Layout;

const Index = () => {
  const { t } = useTranslation();

  const [dataTag, setDataTag] = useState<string>();

  return (
    <>
      <HeadTags title={t('navigation.market')} titleSuffix="subpage" />
      <Header />
      <Content>
        <Panel border="neutral-400" size="2xl">
          <GeneralFilterTags tag={dataTag} callback={setDataTag} filterType="general" />
          <MarketTableContent dataTag={dataTag} quote="TRYB" />
        </Panel>
      </Content>
    </>
  );
};

export default Index;
