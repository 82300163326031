import { useEffect, useState } from 'react';

import { NetworkAliasType } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import debounce from 'lodash.debounce';

import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';

interface Props {
  amount?: string;
  network?: NetworkAliasType;
  asset?: string;
}

const useGetWithdrawalFee = ({ amount, network, asset }: Props, enabled = false) => {
  const [withdrawalFeeParams, setWithdrawalFeeParams] = useState<{
    amount?: string;
    network?: NetworkAliasType;
    asset?: string;
  }>({});

  const { data: minWithdrawalFeeData, isFetching: isMinWithdrawalFeeFetching } = CryptoWalletApi.useGetWithdrawalFee(
    { amount: '0', network, asset },
    {
      suspense: false,
    },
  );

  const { data: withdrawalFeeData, isFetching: isWithdrawalFeeFetching } = CryptoWalletApi.useGetWithdrawalFee(
    withdrawalFeeParams,
    {
      enabled:
        enabled &&
        !!(
          withdrawalFeeParams.amount &&
          withdrawalFeeParams.amount !== '0' &&
          withdrawalFeeParams.network &&
          withdrawalFeeParams.asset
        ),
      suspense: false,
    },
  );
  const [isWithdrawalFeeDebouncing, setIsWithdrawalFeeDebouncing] = useState(false);
  useEffect(() => {
    if (amount && network && asset) {
      setIsWithdrawalFeeDebouncing(true);
      debounceWithdrawalFeeParams({
        amount: amount,
        network: network,
        asset: asset,
      });
    }
  }, [asset, amount, network]);

  const debounceWithdrawalFeeParams = debounce((props: typeof withdrawalFeeParams) => {
    setWithdrawalFeeParams(props);
    setIsWithdrawalFeeDebouncing(false);
  }, 1000);

  const withdrawalAmount = BigNumber(amount || '0');
  let withdrawTotalAmount = undefined;
  let withdrawFee = undefined;

  // If there is no amount, we don't need to calculate the fee neither show the fee.
  if (withdrawalAmount.isGreaterThan(0)) {
    withdrawFee = withdrawalFeeData?.withdrawal_fee || '0';
    const resultAmountWithFee = withdrawalAmount.plus(withdrawFee);
    withdrawTotalAmount = BigNumber.max(resultAmountWithFee, 0).toString();
  }

  return {
    withdrawFee,
    withdrawFeeAsset: withdrawalFeeData?.asset,
    withdrawTotalAmount,
    isPending: (enabled && (isWithdrawalFeeDebouncing || isWithdrawalFeeFetching)) || isMinWithdrawalFeeFetching,
    minWithdrawFee: minWithdrawalFeeData?.withdrawal_fee,
    minWithdrawFeeAsset: minWithdrawalFeeData?.asset,
  };
};

export default useGetWithdrawalFee;
