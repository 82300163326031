import React, { useEffect } from 'react';

import { Block, withSuspense } from '@bilira-org/design';
import { PriceAssetTagType } from '@bilira-org/react-utils';
import { useLocation } from 'react-router-dom';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import { SWAP_COMPLETED_HASH } from '@Libs/constants';
import GeneralTags from '@Modules/market/market/table/components/components/GeneralTags';
import MarketTags from '@Modules/market/market/table/components/components/MarketTags';

type Props = {
  setDataTag: (tag?: string) => void;
  dataTag?: string;
};
const FilterTags = ({ dataTag, setDataTag }: Props) => {
  const { data } = PriceQuery.useGetAssetTags();
  const params = useLocation();

  const callbackTag = (tag?: PriceAssetTagType) => {
    setDataTag(tag?.tag);
  };

  useEffect(() => {
    if (params.hash && params.hash !== SWAP_COMPLETED_HASH) {
      const tag = params.hash.replace('#', '');
      setDataTag(tag);
    }
  }, [params]);

  const generalTags = data?.filter((d) => d.category === 'general');
  const marketTags = data?.filter((d) => d.category === 'market');

  return (
    <>
      <Block row justify="between" gap="md" items="center" mb="lg">
        <GeneralTags selectedItem={dataTag} items={generalTags} callback={callbackTag} />
        <MarketTags selectedItem={dataTag} items={marketTags} callback={callbackTag} />
      </Block>
    </>
  );
};

export default withSuspense(FilterTags);
