import React from 'react';

import { Progress, Skeleton, withSuspense } from '@bilira-org/design';
import { getAllowanceFields } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';

import allowancesQuery from '@Libs/clientInstances/allowancesQuery';
import useAuth from '@Libs/hooks/userAuth';

const AverageFiatAllowanceBar = () => {
  const { account } = useAuth();

  const { data: depositData } = allowancesQuery.useGetAllowances({
    account,
    domain: 'fiat',
    direction: 'deposit',
  });
  const { data: withdrawalData } = allowancesQuery.useGetAllowances({
    account,
    domain: 'fiat',
    direction: 'withdrawal',
  });

  const { monthlyPercentage: monthlyDepositPercentage } = getAllowanceFields(depositData);
  const { monthlyPercentage: monthlyWithdrawalPercentage } = getAllowanceFields(withdrawalData);

  const averagePercentage = BigNumber(monthlyDepositPercentage).plus(monthlyWithdrawalPercentage).dividedBy(2);

  return (
    <Progress
      progress={100 - averagePercentage.toNumber()}
      size="size-1"
      color="primary-500"
      background="neutral-300"
    />
  );
};

export default withSuspense(AverageFiatAllowanceBar, <Skeleton width="size-full" height="size-1" />);
