import React from 'react';

import { Button, Span, withHidden } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DisplayFeatureFlag from '@Components/common/DisplayFeatureFlag';
import { IconCustom } from '@Components/icon/CustomIcon';

/**
 * Navigation button for Reward page
 */
const RewardButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      style={{ height: '40px' }}
      variant="transparent"
      size="sm"
      onClick={() => navigate('/rewards')}
      testId="rewards-navigation-btn"
      startIcon={<IconCustom type="sparkles" style={{ width: '1.5rem', height: '1.5rem' }} />}
    >
      <Span color="gradient-1" weight="medium" size="base">
        {t('rewards.tracking-header')}
      </Span>
    </Button>
  );
};

export default withHidden(RewardButton);
