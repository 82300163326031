import React from 'react';

import { Breadcrumbs, CoinProps, Hidden, Meta, Text, TextProps } from '@bilira-org/design';
import Flex from '@bilira-org/design/src/components/flex/flex';
import { CoinSymbolType, NetworkAliasType } from '@bilira-org/react-utils';

import IconCoin from '@Components/icon/IconCoin';

type Props = {
  /** Icon to be displayed */
  icon?: CoinSymbolType | NetworkAliasType;
  /** Title to be displayed */
  title: string;
  /** Description to be displayed */
  description?: string;
  /** Size configuration for the BreadCrumb items */
  size?: { icon: CoinProps['size']; title?: TextProps['size']; description?: TextProps['size'] };
  /** data-testid for the component */
  testId?: string;
};

/**
 * Displays crypto symbol - name breadcrumbs with an optional icon
 *
 * <SymbolPairBreadcrumbs
 *   icon="BTC"
 *   title="BTC"
 *   description="Bitcoin"
 *   size={{ icon: '3xl', title: 'base', description: 'sm' }}
 * />
 */
const SymbolPairBreadcrumbs = ({
  size = { icon: '3xl', title: 'base', description: 'sm' },
  icon,
  title,
  description,
}: Props) => {
  return (
    <Meta
      space="sm"
      extra={<IconCoin size={size?.icon} type={icon} />}
      title={
        <>
          <Hidden show={{ xs: true, sm: true, md: true, lg: false, xl: false }}>
            <Flex>
              <Text size="sm" color="secondary-500" weight="semibold">
                {title}
              </Text>
              <Text size="sm" weight="regular">
                {description}
              </Text>
            </Flex>
          </Hidden>
          <Hidden show={{ xs: false, sm: false, md: false, lg: true, xl: true }}>
            <Breadcrumbs listType="disc">
              <Breadcrumbs.Item shrink="0">
                <Text size={size?.title} color="secondary-500" weight="semibold" overflow="x-hidden">
                  {title}
                </Text>
              </Breadcrumbs.Item>
              <Breadcrumbs.Item>
                <Text size={size?.description} weight="regular">
                  {description}
                </Text>
              </Breadcrumbs.Item>
            </Breadcrumbs>
          </Hidden>
        </>
      }
    />
  );
};

export default SymbolPairBreadcrumbs;
