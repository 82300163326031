import React from 'react';

import { Input } from '@bilira-org/design';
import { FormattedInputNumberProps } from '@bilira-org/design/src/components/input/formattedNumber';
import { useTranslation } from 'react-i18next';

type Props = FormattedInputNumberProps;

const FormattedInputNumber = (props: Props) => {
  const { i18n } = useTranslation();
  return <Input.FormattedNumber locale={i18n.language} {...props} />;
};

export default FormattedInputNumber;
