import React, { FC, memo, useCallback, useMemo } from 'react';

import {
  Block,
  ColumnDef,
  DataTable,
  DataTableProps,
  Skeleton,
  TableBodyStyle,
  Text,
  withSuspense,
} from '@bilira-org/design';
import { AssetPairType, CoinSymbolType, concatTicker, SwapPairType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import H24ChangeLive from '@Components/live/H24ChangeLive';
import LastPriceLive from '@Components/live/LastPriceLive';
import GeneralPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';

interface Props {
  setAsset: (asset: SwapPairType) => void;
  data?: SwapPairType[];
  quoteAsset?: CoinSymbolType;
  assetPair?: AssetPairType;
}

const bodyStyle: TableBodyStyle = { px: 'xl', py: 'sm' };
/**
 * Component that lists swap pairs and their balances.
 *
 * @example
 * <PairList data={pinnedItems} quoteAsset="TRYB" setAsset={setAsset} />
 */
const PairList: FC<Props> = ({ assetPair, quoteAsset, data, setAsset }) => {
  const { t } = useTranslation();

  const tokenList = useMemo<SwapPairType[]>(() => {
    if (data) {
      return quoteAsset ? data.filter((item) => item.quote_asset === quoteAsset) : data;
    }
    return [];
  }, [data, quoteAsset]);

  const subscriptionList = useMemo(
    () =>
      tokenList
        // Current selected asset pair is already subscribed.
        // Also, it should not be unsubscribed when the pair modal is closed.
        .filter((d) => `${d.base_asset}_${d.quote_asset}` !== assetPair)
        .map((d) => concatTicker(d.base_asset, d.quote_asset)),
    [tokenList],
  );

  const cols = useMemo<ColumnDef<SwapPairType>[]>(
    () => [
      {
        accessorKey: 'base_asset',
        header: () => (
          <Text size="xs" color="neutral-600" ml="xl">
            {t('trade.table.ticker')}
          </Text>
        ),
        cell(cell) {
          const item = cell.row.original;
          return (
            <>
              <Block row gap="xs" items="baseline">
                <Text color="neutral-900" weight="medium" size="xs">
                  {item.base_asset}
                </Text>
                <Text size="xs" weight="regular" color="neutral-700">
                  {` / `}
                </Text>
                <Text size="xs" weight="regular" color="neutral-700">
                  {item.quote_asset}
                </Text>
              </Block>
            </>
          );
        },
        skeleton: <Skeleton height="size-4" width="size-16" />,
      },
      {
        accessorKey: 'quote_asset',
        header: () => (
          <Text size="xs" color="neutral-600" align="right" pr="xl">
            {t('trade.table.ticker-price')}
          </Text>
        ),
        align: 'right',
        cell(cell) {
          const item = cell.row.original;
          return (
            <>
              <LastPriceLive
                suffixColor="neutral-700"
                size="xs"
                weight="regular"
                base={item.base_asset}
                quote={item.quote_asset}
                showSuffix={false}
                skeleton={<Skeleton height="size-4" width="size-14" />}
              />
            </>
          );
        },
        skeleton: <Skeleton height="size-4" width="size-14" />,
      },
      {
        accessorKey: 'base_asset',
        header: () => (
          <Text size="xs" color="neutral-600" align="right" pr="xl">
            {t('trade.table.change', { h: 24 })}
          </Text>
        ),
        align: 'right',
        cell(cell) {
          const item = cell.row.original;
          return (
            <>
              <H24ChangeLive
                size="xs"
                base={item.base_asset}
                justify="end"
                quote={item.quote_asset}
                skeleton={<Skeleton height="size-4" width="size-14" />}
              />
            </>
          );
        },
        skeleton: <Skeleton height="size-4" width="size-14" />,
      },
    ],
    [t],
  );

  const highlightedRowKey = useCallback<Exclude<DataTableProps<SwapPairType>['highlightedRowKey'], undefined | string>>(
    (entry) => {
      return `${entry.base_asset}_${entry.quote_asset}` === assetPair;
    },
    [assetPair],
  );

  return (
    <>
      <GeneralPriceSubscription
        channel="buy-sell-pair"
        baseQuoteList={subscriptionList}
        events="last_price,symbol_statistics"
      />
      <DataTable
        data={tokenList}
        columns={cols}
        bodyStyle={bodyStyle}
        highlightedRowKey={highlightedRowKey}
        highlightVariant="marker"
        onRowClick={setAsset}
        skeletonRowCount={3}
      />
    </>
  );
};

const PairListSkeleton = () => {
  const { t } = useTranslation();
  return (
    <DataTable
      data={[]}
      columns={[
        {
          id: '1',
          header: () => (
            <Text size="xs" color="neutral-600" ml="xl">
              {t('trade.table.ticker')}
            </Text>
          ),
          skeleton: <Skeleton height="size-4" width="size-16" />,
        },
        {
          id: '2',
          header: () => (
            <Text size="xs" color="neutral-600" align="right" pr="xl">
              {t('trade.table.ticker-price')}
            </Text>
          ),
          align: 'right',
          skeleton: <Skeleton height="size-4" width="size-14" />,
        },
        {
          id: '3',
          header: () => (
            <Text size="xs" color="neutral-600" align="right" pr="xl">
              {t('trade.table.change', { h: 24 })}
            </Text>
          ),
          align: 'right',
          skeleton: <Skeleton height="size-4" width="size-14" />,
        },
      ]}
      bodyStyle={{ px: 'xl', py: 'sm' }}
      skeletonRowCount={3}
      loading={true}
    />
  );
};

export default withSuspense(memo(PairList), <PairListSkeleton />);
