import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { Block } from '../block';
import { BackgroundColorType, BaseComponentType, BaseSize, SizeType } from '../types';

export type ProgressProps = BaseComponentType & {
  /** The progress value as a percentage (0-100). */
  progress?: number;
  /** The size of the progress bar. */
  size?: SizeType;
  /** The color of the progress bar. */
  color?: BackgroundColorType;
  /** The background color of the progress bar. */
  background?: BackgroundColorType;
  /** @deprecated Not used */
  children?: ReactNode;
};

/**
 * A component that represents a progress bar.
 * @example
 * <Progress progress={50} size="lg" color="primary-500" />
 */
const Progress = ({ progress = 0, color = 'primary-500', background, size, testId }: ProgressProps) => {
  // Clamp the progress value between 0 and 100
  const clampedProgress = Math.min(100, Math.max(0, progress));

  const classes = classNames('progress', { [`tw-h-${size}`]: size, [`tw-bg-${color}`]: color });

  return (
    <div
      className={classNames('progress-bar', { [`tw-h-${size}`]: size, [`tw-bg-${background}`]: background })}
      data-testid={testId ?? 'progress'}
    >
      <div className={classes} style={{ width: `${clampedProgress}%` }} />
    </div>
  );
};

export default Progress;
