import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../client';
import {
  GrantCode,
  GrantCodeMobileResult,
  GrantCodeResult,
  NotificationParamsType,
  NotificationPatchBody,
  NotificationType,
  PutPinCodeBodyType,
  QueryOptions,
  RecordsPaginationType,
  ResultType,
  TwoFACodeBodyType,
  TwoFACodeType,
  TwoFAMethodsType,
  TwoFAOptionsType,
  TwoFAVerifyCodeBodyType,
  TwoFAVerifyCodeType,
  VerifyPinCodeBodyType,
  VerifyPinCodeResultType,
} from '../../model';
import {
  DisclaimerRecordBodyType,
  DisclaimerRecordType,
  DisclaimParamsType,
  LegalDocumentConsentType,
  LegalDocumentParamsType,
  LegalDocumentType,
  PatchReferralCodeBody,
  PatchReferralCodeResponseType,
  PostReferralCodeBody,
  PostReferralCodeResponseType,
  PutLegalDocumentConsentBodyType,
  ReferralCodesResponseType,
  ReferralCodeTypeItem,
  UserType,
} from '../../model/User';
import { IUserApi, UserApi } from '../api';
import { RewardsQueryKey, UserQueryKey } from '../constants';

class UserQuery {
  _api: IUserApi;

  public constructor(params: HttpClientType) {
    this._api = new UserApi(params);
  }

  public useGetUsers = (options?: QueryOptions) =>
    useQuery<UserType, HttpErrorType>({
      queryKey: [UserQueryKey.USERS],
      queryFn: this._api.getUsers,
      suspense: false,
      ...options,
    });

  public useGetTwoFA = (type: TwoFAOptionsType, options?: QueryOptions) =>
    useQuery<TwoFAMethodsType, HttpErrorType>({
      queryKey: [UserQueryKey.TWO_FA, type],
      queryFn: () => this._api.getTwoFA(type),
      ...options,
    });

  public useGetTwoFACode = (options?: QueryOptions) => {
    return useMutation<TwoFACodeType, HttpErrorType, TwoFACodeBodyType>({
      mutationFn: this._api.getTwoFACode,
      ...options,
    });
  };

  public usePostVerifyTwoFACode = (options?: QueryOptions) => {
    return useMutation<TwoFAVerifyCodeType, HttpErrorType, TwoFAVerifyCodeBodyType>({
      mutationFn: this._api.postVerifyTwoFACode,
      ...options,
    });
  };

  public usePostVerifyTOTP = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation<any, HttpErrorType, any>({
      mutationFn: this._api.postVerifyTOTP,
      onSuccess: () => queryClient.invalidateQueries({ queryKey: [UserQueryKey.TWO_FA] }),
      ...options,
    });
  };
  public usePostActivateTOTP = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation<any, HttpErrorType, any>({
      mutationFn: this._api.postActivateTOTP,
      onSuccess: () => queryClient.invalidateQueries({ queryKey: [UserQueryKey.TWO_FA] }),
      ...options,
    });
  };

  public useGetNotifications = (params: NotificationParamsType, options?: QueryOptions) =>
    useQuery<RecordsPaginationType<NotificationType> & { unread_count: number }, HttpErrorType>({
      queryKey: [UserQueryKey.NOTIFICATIONS, params.limit, params.offset, params],
      queryFn: () => this._api.getNotifications(params),
      ...options,
    });

  public usePatchNotifications = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation<ResultType, HttpErrorType, NotificationPatchBody>({
      mutationFn: this._api.patchNotifications,
      onSuccess: () => queryClient.invalidateQueries({ queryKey: [UserQueryKey.NOTIFICATIONS] }),
      ...options,
    });
  };

  public usePutNotifications = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation<ResultType, HttpErrorType>({
      mutationFn: this._api.putNotifications,
      onSuccess: () => queryClient.invalidateQueries({ queryKey: [UserQueryKey.NOTIFICATIONS] }),
      ...options,
    });
  };

  public usePostGrantCode = (options?: QueryOptions) => {
    return useMutation<GrantCodeResult, HttpErrorType, GrantCode>({
      mutationFn: this._api.postGrantCode,
      ...options,
    });
  };

  public usePostGrantCodeMobile = (options?: QueryOptions) => {
    return useMutation<GrantCodeMobileResult, HttpErrorType, GrantCode>({
      mutationFn: this._api.postGrantCodeMobile,
      ...options,
    });
  };

  public useGetLegalDocuments = (params?: LegalDocumentParamsType, options?: QueryOptions) =>
    useQuery<LegalDocumentType[], HttpErrorType>({
      queryKey: [UserQueryKey.LEGAL_DOCUMENTS, params],
      queryFn: () => this._api.getLegalDocuments(params),
      ...options,
    });

  public useGetLegalDocumentConsents = (options?: QueryOptions) =>
    useQuery<LegalDocumentConsentType[], HttpErrorType>({
      queryKey: [UserQueryKey.LEGAL_DOCUMENT_CONSENTS],
      queryFn: () => this._api.getLegalDocumentConsents(),
      ...options,
    });

  public usePutLegalDocumentConsents = (options?: QueryOptions) => {
    return useMutation<ResultType, HttpErrorType, PutLegalDocumentConsentBodyType>({
      mutationFn: this._api.putLegalDocumentConsents,
      ...options,
    });
  };

  public useGetReferralCodes = (type?: string, options?: QueryOptions) =>
    useQuery<ReferralCodesResponseType, HttpErrorType>({
      queryKey: [UserQueryKey.REFERRAL_CODES, type],
      queryFn: () => this._api.getReferralCodes(type),
      ...options,
    });

  public usePostReferralCode = (options?: QueryOptions) => {
    return useMutation<PostReferralCodeResponseType, HttpErrorType, PostReferralCodeBody>({
      mutationFn: this._api.postReferralCode,
      ...options,
    });
  };

  public usePatchReferralCode = (options?: QueryOptions) => {
    return useMutation<PatchReferralCodeResponseType, HttpErrorType, PatchReferralCodeBody>({
      mutationFn: this._api.patchReferralCode,
      ...options,
    });
  };

  public useGetReferralCodeTypes = (options?: QueryOptions) =>
    useQuery<ReferralCodeTypeItem[], HttpErrorType>({
      queryKey: [UserQueryKey.REFERRAL_CODE_TYPES],
      queryFn: () => this._api.getReferralCodeTypes(),
      ...options,
    });

  public usePutPinCode = (options?: QueryOptions) => {
    return useMutation<ResultType, HttpErrorType, PutPinCodeBodyType>({
      mutationFn: this._api.putPinCode,
      ...options,
    });
  };

  public usePostVerifyPinCode = (options?: QueryOptions) => {
    return useMutation<VerifyPinCodeResultType, HttpErrorType, VerifyPinCodeBodyType>({
      mutationFn: this._api.postVerifyPinCode,
      ...options,
    });
  };

  public useDeletePinCode = (options?: QueryOptions) => {
    return useMutation<ResultType, HttpErrorType, string>({
      mutationFn: this._api.deletePinCode,
      ...options,
    });
  };

  public useGetDisclaimerRecords = (params: DisclaimParamsType, options?: QueryOptions) =>
    useQuery<DisclaimerRecordType[]>({
      queryKey: [UserQueryKey.DISCLAIMER_RECORDS, params],
      queryFn: () => this._api.getDisclaimerRecords(params),
      ...options,
    });

  public usePostDisclaimerRecords = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<DisclaimerRecordType | false, HttpErrorType, DisclaimerRecordBodyType>({
      mutationFn: this._api.postDisclaimerRecords,
      onSuccess: (data, variables) => {
        queryClient.setQueryData([UserQueryKey.DISCLAIMER_RECORDS, variables.type], (oldData) => {
          if (!Array.isArray(oldData) || data === false) {
            // If old data is empty or not an array (cached data is not valid)
            // or posted asset disclaim is already accepted (cached data is out of sync with db)
            // Do not update query data and invalidate it to refetch
            queryClient.invalidateQueries({ queryKey: [UserQueryKey.DISCLAIMER_RECORDS, variables.type] });
            return undefined;
          }

          if (variables.type === 'point_system_join_announcement') {
            queryClient.invalidateQueries({ queryKey: [RewardsQueryKey.REWARDS_USER_PARTICIPATION_PREREQUISITES_KEY] });
          }

          return [...oldData, data];
        });
      },
      ...options,
    });
  };
}

export default UserQuery;
