import React, { ComponentProps } from 'react';

import { Block, Number, Skeleton, withSuspense } from '@bilira-org/design';

import useGetTotalBalance from '@Components/balance/useGetTotalBalance';

import FormattedNumberByPrice from '../common/FormattedNumberByPrice';

interface Props {
  /** Text color of the total balance */
  color?: ComponentProps<typeof Number>['color'];
  /** Whether to show daily balance change percentage  */
  showChange24H?: boolean;
}

/**
 * Component to display total balance of the user
 *
 * Uses TRYB.free_balance property of the account balance
 *
 * @example
 * <TotalBalance color="primary-500"/>
 */
const TotalBalance = ({ color = 'secondary-500', showChange24H }: Props) => {
  const { error, maskBalance, balance } = useGetTotalBalance();

  if (error) {
    return <Skeleton height="size-4" width="size-52" mt="xl" />;
  }

  return (
    <Block row items="baseline" gap="sm">
      <FormattedNumberByPrice
        type="amount"
        price="1"
        testId="total-balance"
        animateChange={true}
        masked={maskBalance}
        heading
        size={{ xs: '3xl', sm: '3xl', md: '4xl' }}
        weight="bold"
        decimalSize={{ xs: 'xl', sm: 'xl', md: '2xl' }}
        decimalWeight="bold"
        suffix="TRYB"
        suffixSize={{ xs: 'base', sm: 'base', md: 'lg' }}
        suffixWeight="medium"
        suffixFont="primary"
        color={color}
        value={balance}
        wordBreak="all"
      />

      {/*{showChange24H && <PriceChange value={balances?.total_bala.change_percentage_24h} withTag size="sm" />}*/}
    </Block>
  );
};

export default withSuspense(TotalBalance, <Skeleton height="size-4" width="size-52" mt="xl" />);
