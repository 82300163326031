import React, { useEffect } from 'react';

import { withSuspense } from '@bilira-org/design';
import { ERROR_PAGE_STATE, getErrorMessage } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

import useUserPreferences from '@/libs/hooks/useUserPreferences';
import RedirectSpinCard from '@Components/common/RedirectSpinCard';

import NotEligibleForV2 from './components/NotEligibleForV2';
import UserDeclined from './components/UserDeclined';
import useAuthCallback from './hooks/useAuthCallback';

const CallbackContainer = () => {
  const { t } = useTranslation();

  const { isError, isSuccess, error, setIsNotEligible } = useAuthCallback();
  const { isSuccess: userPreferencesSuccess, setDefaultPreferences } = useUserPreferences({ enabled: isSuccess });

  const [searchParams] = useSearchParams();
  const searchParam = searchParams.get('error');

  useEffect(() => {
    if (userPreferencesSuccess) {
      setDefaultPreferences();

      setTimeout(() => {
        window.location.replace('/');
      }, 500);
    }
  }, [userPreferencesSuccess, setDefaultPreferences]);

  if (searchParam === 'user_declined') {
    return <UserDeclined />;
  }

  if (isError && getErrorMessage(error) === 'User is not eligible for v2') {
    return <NotEligibleForV2 setIsNotEligible={setIsNotEligible} />;
  }

  if (isError) {
    return <Navigate to="/error" state={{ errorState: ERROR_PAGE_STATE.LOGIN_ERROR }} />;
  }

  return <RedirectSpinCard title={t('redirect.logging-in')} description={t('redirecting.redirecting-please-wait')} />;
};

const CallbackContent = () => {
  const { t } = useTranslation();

  return <RedirectSpinCard title={t('redirect.please-wait')} description={t('redirect.redirecting')} />;
};
export default withSuspense(CallbackContainer, <CallbackContent />);
