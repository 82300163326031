import { formatPrice } from './currency';
import { SwapCommissionTiersItemType } from '../../model';

/**
 * Calculates the text representing commission tier boundaries based on the provided entry and locale.
 * @param {SwapCommissionTiersItemType} entry - The commission tier item.
 * @param {string} locale - The locale used for formatting the price.
 * @returns {string} The text representing the commission tier boundary.
 *
 * @example
 * getCommissionTierBoundaryText({ ..., upperBound: 50 }, 'tr')
 * // '< 50 TRYB'
 *
 * getCommissionTierBoundaryText({ ..., lowerBound: 100, upperBound: 200 }, 'tr')
 * // '≥ 100 TRYB'
 */

const getCommissionTierBoundaryText = (entry: SwapCommissionTiersItemType, locale: string) => {
  let text = '';
  if (!entry.lowerBound) {
    const formattedValue = formatPrice({ value: entry.upperBound?.toString(), locale: locale });
    text = `< ${formattedValue.shortValue || formattedValue.value} TRYB`;
  } else {
    const formattedValue = formatPrice({ value: entry.lowerBound?.toString(), locale: locale });

    text = `≥ ${formattedValue.shortValue || formattedValue.value} TRYB`;
  }

  return text;
};

export default getCommissionTierBoundaryText;
