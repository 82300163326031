import React from 'react';

import { Icon } from '@bilira-org/design';

import { useGeneralPersistedStore } from '@/store/generalPersistedStore';

/**
 * Displays an eye icon for hiding/showing balance on the app scale
 *
 * @example
 * <HiddenBalanceEyeIcon />
 */
function HiddenBalanceEyeIcon() {
  const { maskBalance, setMaskBalance } = useGeneralPersistedStore();

  return (
    <Icon
      testId="hide-balance-icon"
      size="sm"
      type={maskBalance ? 'o:eye-slash' : 'o:eye'}
      onClick={() => {
        setMaskBalance(!maskBalance);
      }}
    />
  );
}

export default HiddenBalanceEyeIcon;
