import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { DefaultToastOptions, toast as notify, resolveValue, Toaster, ToastIcon, ToastType } from 'react-hot-toast';

import { BackgroundColorType, TextColorType } from '../color';
import { Icon, IconType } from '../icons';
import { BaseComponentType } from '../types';

export type NotificationProps = Omit<DefaultToastOptions, 'duration'> &
  BaseComponentType & {
    /**  The duration in milliseconds for which the notification is visible. */
    duration?: number;
  };

/**
 * The `Notification` component is used to display toast notifications.
 *
 * @example
 * <Notification duration={3000} position="top-center" />
 */
const Notification = ({ duration = 3000, position = 'top-center', testId, ...props }: NotificationProps) => {
  return (
    <Toaster position={position} toastOptions={{ duration, ...props }} data-testid={testId ?? 'notification'}>
      {(t) => (
        <Transition
          appear
          as="div"
          show={t.visible}
          className="notification-area"
          enter="transition-all duration-150"
          enterFrom="opacity-0 -translate-y-4"
          enterTo="opacity-100 scale-100 translate-y-10"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <div className={classNames('notification', `notification-${t.type}`, {})}>
            <ToastIcon toast={t} />
            <p className="break-all">{resolveValue(t.message, { ...t })}</p>
          </div>
        </Transition>
      )}
    </Toaster>
  );
};

export type CustomNotificationProps = BaseComponentType & {
  /** The message content of the notification. */
  message: string;
  /** The text of the close button. */
  closeText: string;
  /** Callback fired when the close button is clicked. */
  onClose?: () => void;
  /** The icon props of the notification. */
  iconProps: { type: IconType; overlayColor?: BackgroundColorType; color?: TextColorType };
};

/**
 * Function to display a custom badge notification.
 *
 * @example
 * badgeNotify({
 *   message: 'Notification message',
 *   closeText: 'Close',
 *   onClose: () => {
 *     console.log('Notification closed');
 *   },
 *   iconProps: {
 *     type: 'o:info',
 *     overlayColor: 'primary',
 *     color: 'white',
 *   },
 * });
 */
const badgeNotify = ({ message, closeText, onClose, iconProps, testId }: CustomNotificationProps) => {
  notify.custom(
    (t) =>
      t.visible ? (
        <div className="notification notification-badge" data-testid={testId}>
          <div className="flex-1 w-0">
            <div className="flex items-center">
              <div className="flex-shrink-0 pt-0.5">
                <ToastIcon
                  toast={{
                    ...t,
                    icon: (
                      <Icon
                        overlaySize="3xl"
                        overlayColor={iconProps.overlayColor}
                        type={iconProps.type}
                        size="sm"
                        color={iconProps.color}
                      />
                    ),
                  }}
                />
              </div>
              <div className="mx-3 flex-1">
                <p className="text-base font-medium">{message}</p>
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                notification.dismiss(t.id);
                onClose?.();
              }}
              className="w-full border border-transparent rounded-none rounded-r-lg flex items-center justify-center text-sm font-medium tw-text-primary-500 hover:text-opacity-70"
            >
              {closeText}
            </button>
          </div>
        </div>
      ) : (
        <></>
      ),
    { position: 'top-right', style: { marginTop: '280px' } },
  );
};

const NotifyCustom = ({
  message,
  type,
  position = 'top-center',
  ...props
}: DefaultToastOptions & { message: string; type: ToastType }) => {
  return notify.custom(
    (t) => (
      <div className={classNames(`notification notification-${type}`)}>
        <div className="flex items-center">
          {type !== 'blank' && (
            <div className="flex-shrink-0 pt-0.5">
              <ToastIcon
                toast={{
                  ...t,
                  type: type,
                }}
              />
            </div>
          )}

          <div className="mx-3 flex-1">
            <p className="text-base font-medium">{message}</p>
          </div>
        </div>
      </div>
    ),
    {
      ...props,
    },
  );
};

type NotifyProps = {
  duration?: number;
};

const NotifySuccess = (message: string, props: NotifyProps = {}) => {
  return NotifyCustom({ message, type: 'success', ...props });
};

const NotifyError = (message: string, props: NotifyProps = {}) => {
  return NotifyCustom({ message, type: 'error', duration: 5000, ...props });
};

const NotifyLoading = (message: string, props: NotifyProps = {}) => {
  return NotifyCustom({ message, type: 'loading', ...props });
};

const NotifyInfo = (message: string, props: NotifyProps = {}) => {
  return NotifyCustom({ message, type: 'blank', ...props });
};

const notification = Object.assign(NotifyCustom, {
  info: NotifyInfo,
  success: NotifySuccess,
  error: NotifyError,
  loading: NotifyLoading,
  badge: badgeNotify,
  dismiss: notify.dismiss,
  promise: notify.promise,
  remove: notify.remove,
});

export { badgeNotify, notification };
export default Notification;
