import React from 'react';

import classNames from 'classnames';

import { BackgroundColorType, BaseComponentType, MarginSizes } from '../types';

interface ITag extends BaseComponentType {
  /** The content to be displayed */
  children: React.ReactNode;
  /** Background color of the Tag */
  color?: BackgroundColorType;
  /** Margin bottom of the Tag */
  mb?: MarginSizes;
  /** Margin top of the Tag */
  mt?: MarginSizes;
  /** Margin y-axis of the Tag */
  my?: MarginSizes;
  /** Margin x-axis of the Tag */
  mx?: MarginSizes;
}

/**
 * Customizable Tag component for displaying a small piece of information.
 *
 * @example
 * <Tag color="primary-500" mb="2" testId="customTestId">
 *   New
 * </Tag
 */
const Tag = ({ children, color = 'green-50', mb, mt, my, mx, testId }: ITag) => {
  return (
    <span
      className={classNames('inline-flex items-center justify-center py-xs px-sm text-xs font-medium rounded-lg', {
        [`tw-bg-${color}`]: color,
        [`mb-${mb}`]: mb,
        [`mt-${mt}`]: mt,
        [`my-${my}`]: my,
        [`mx-${mx}`]: mx,
      })}
      data-testid={testId ?? 'tag'}
    >
      {children}
    </span>
  );
};

export default Tag;
