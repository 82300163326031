import React, { useMemo } from 'react';

import {
  Block,
  ColumnDef,
  DataTable,
  GridView,
  Skeleton,
  TableBodyStyle,
  Text,
  withSuspense,
} from '@bilira-org/design';
import { AssetListType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import SymbolPair from '@Components/common/SymbolPair';
import H24ChangeLive from '@Components/live/H24ChangeLive';
import LastPriceLive from '@Components/live/LastPriceLive';
import MarketPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import CryptoListCardSkeleton from '@Components/skeletons/CryptoListCardSkeleton';
import useAssetCardsHook from '@Modules/market/market/table/components/hooks/useAssetCardsHook';

const bodyStyle: TableBodyStyle = { px: 'md', py: 'md' };

type Props = {
  tag: string;
  rowNumber?: boolean;
};

const CryptoListCard = ({ tag, rowNumber }: Props) => {
  const { t } = useTranslation();
  const { assetList, isPending, callback, subscriptionList } = useAssetCardsHook({ dataTag: tag });

  const columns = useMemo<ColumnDef<AssetListType>[]>(
    () => [
      {
        header: '#',
        id: 'row-number',
        cell({ row }) {
          return (
            <Text weight="regular" size="xs" color="neutral-600">
              {row.index + 1}
            </Text>
          );
        },
        show: !!rowNumber,
      },
      {
        header: '',
        accessorKey: 'base_asset',
        id: 'pair',
        cell({ row: { original: row } }) {
          return (
            <SymbolPair quote="TRYB" icon={row.base_asset} title={row.base_asset} description={row.base_asset_name} />
          );
        },
        skeleton: (
          <Block key="crypto" row gap="sm" items="center">
            <Skeleton rounded="full" height="size-8" width="size-8" />
            <Skeleton height="size-4" width="size-24" />
          </Block>
        ),
      },
      {
        header: '',
        accessorKey: 'base_asset',
        id: 'statistics',
        align: 'right',
        cell({ row: { original: item } }) {
          return (
            <>
              <LastPriceLive align="right" base={item.base_asset} quote="TRYB" />
              <H24ChangeLive base={item.base_asset} justify="end" size="sm" quote="TRYB" />
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <MarketPriceSubscription
        channel="crypto-cards"
        baseQuoteList={subscriptionList}
        events="last_price,symbol_statistics"
      />
      <DataTable
        rowId="base_asset"
        loading={isPending}
        displayHeader={false}
        rounded
        bodyStyle={bodyStyle}
        data={assetList?.slice(0, 3)}
        onRowClick={callback}
        columns={columns}
        emptyMessage={t('common.no-entries-found')}
      />
    </>
  );
};

export default withSuspense(CryptoListCard, <CryptoListCardSkeleton />);
