import AccountApi from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';
import { useGeneralPersistedStore } from '@Store/generalPersistedStore';

const useGetTotalBalance = () => {
  const { account } = useAuth();
  const maskBalance = useGeneralPersistedStore((state) => state.maskBalance);

  const { data, error } = AccountApi.useGetTotalBalance(
    account,
    { quote_asset: 'TRYB' },
    {
      enabled: !!account,
      suspense: false,
    },
  );

  return { error, balance: data?.balance || '0', freeBalance: data?.freeBalance || '0', maskBalance };
};

export default useGetTotalBalance;
