import React, { memo, useEffect, useMemo } from 'react';

import { Modal, Text, withSuspense } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { Networks } from '@Components/cryptoCards';
import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';
import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerCryptoWithdrawal } from '@Libs/tracker/helpers/addTrackerCryptoWithdrawal';

type Props = {
  /** An optional array of network aliases to filter available networks. */
  filter?: string[];
  /** Callback to be called when modal is closed */
  setActive: (value: boolean) => void;
  /** Whether the modal is active or not */
  active: boolean;
  activeAsset?: string;
};

/**
 * Network selection modal.
 * Uses `useWalletStore` to set selected network
 */
const NetworkModal = ({ active, setActive, filter, activeAsset }: Props) => {
  const { data, isPending } = CryptoWalletApi.useGetNetworks();
  const { setSelectedNetwork } = useWalletStore();
  const { t } = useTranslation();

  /** Filtered networks by `alias` based on the filter provided */
  const networks = useMemo(() => data?.filter((d) => (filter ? filter?.includes(d.alias) : true)), [data, filter]);

  useEffect(() => {
    if (networks?.length === 1) {
      setSelectedNetwork(networks[0]);
    } else {
      setSelectedNetwork(undefined);
    }
  }, [networks]);

  return (
    <>
      <Modal
        testId="networks-modal"
        title={t('crypto.select-network-modal.title')}
        open={active}
        onClose={() => setActive(false)}
      >
        <Text mb="2xl">{t('crypto.select-network-modal.desc')}</Text>

        <Networks
          networks={networks}
          isPending={isPending}
          filter={filter}
          onFinish={() => {
            setActive(false);
          }}
          setNetwork={(network) => {
            addTrackerCryptoWithdrawal(TRACKER_EVENTS.CRYPTO_WITHDRAWAL.SELECT_NETWORK, network);
            setSelectedNetwork(network);
          }}
          activeAsset={activeAsset}
        />
      </Modal>
    </>
  );
};

export default withSuspense(memo(NetworkModal));
