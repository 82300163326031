import React, { useState } from 'react';

import {
  Alert,
  Block,
  Flex,
  Icon,
  Label,
  Modal,
  Panel,
  PanelCopy,
  Separator,
  Span,
  StepProgress,
  Text,
} from '@bilira-org/design';
import { Trans, useTranslation } from 'react-i18next';

import Limits from '@/components/common/Limits';
import { Qr } from '@/components/common/Qr';
import TLIcon from '@Assets/icons/tl-symbol.svg?inline';
import Banks from '@Modules/fiat/deposit/components/Banks';
import { useActiveSystemBankPanel } from '@Modules/fiat/deposit/hooks/useActiveSystemBankPanel';

import { getQrCode } from './helpers';

const FiatDeposit = () => {
  const { setModalActive, selectedSystemBank, setSelectedSystemBank } = useActiveSystemBankPanel();
  const { t } = useTranslation();
  const [qrModalActive, setQrModalActive] = useState(false);

  const qrCode = getQrCode(selectedSystemBank?.iban);

  return (
    <>
      <Panel border="neutral-400" id="fiat-deposit">
        <Flex width={{ xs: 'full', sm: 'full', xl: '3/4' }}>
          <Text heading size="xl" weight="bold" mb="2xl">
            {t('fiat.tl-deposit')}
          </Text>

          <StepProgress
            hideStepIconOnSm
            vertical
            borderStyle="dashed"
            size="sm"
            alignItems="center"
            steps={[
              {
                title: (
                  <>
                    <Text size="lg" weight="medium" mb="2xl" color="secondary-500">
                      {t('fiat.bank-selection')}
                    </Text>
                    <Text size="sm" weight="regular" mb="xs">
                      {t('fiat.partner-banks')}
                    </Text>
                    <Banks
                      onFinish={(selectedBank) => {
                        setModalActive(false);
                        setSelectedSystemBank(selectedBank);
                      }}
                    />
                  </>
                ),
                active: true,
                complete: !!selectedSystemBank,
              },
              {
                title: (
                  <>
                    <Text size="lg" weight="medium" mb="2xl" color="secondary-500">
                      {t('fiat.retrieve-account-info')}
                    </Text>
                    {selectedSystemBank && (
                      <>
                        <Block gap="lg">
                          <div>
                            <Label>IBAN</Label>
                            <PanelCopy
                              testId="iban-panel"
                              extra={
                                qrCode ? (
                                  <Icon
                                    size="lg"
                                    color="neutral-600"
                                    type="o:qr-code"
                                    onClick={() => {
                                      setQrModalActive(true);
                                    }}
                                  />
                                ) : undefined
                              }
                              copiedText={t('common.copied')}
                            >
                              {selectedSystemBank?.iban ?? 'TR'}
                            </PanelCopy>
                          </div>
                          <div>
                            <Label> {t('fiat.account-name')}</Label>
                            <PanelCopy testId="account-name-panel" copiedText={t('common.copied')}>
                              BiLira Teknoloji A.S.
                            </PanelCopy>
                          </div>
                        </Block>
                        <Separator width="size-full" my="2xl" />
                        <Block mb="2xl">
                          <Limits domain="fiat" direction="deposit" />
                        </Block>
                      </>
                    )}

                    <Alert status="yellow">
                      <Text size="sm" weight="bold" color="secondary-500">
                        {t('fiat.deposit-warning.title')}
                      </Text>

                      <ul style={{ marginLeft: '18px' }}>
                        <li>
                          <Text color="secondary-500" size="sm">
                            {t('fiat.deposit-warning.desc-1')}
                          </Text>
                        </li>
                        <li>
                          <Text color="secondary-500" size="sm">
                            {t('fiat.deposit-warning.desc-2')}
                          </Text>
                        </li>
                        <li>
                          <Text color="secondary-500" size="sm">
                            <Trans
                              t={t}
                              i18nKey="fiat.deposit-warning.desc-3"
                              components={{
                                bold: <Span weight="semibold" />,
                              }}
                            />
                          </Text>
                        </li>
                        <li>
                          <Text color="secondary-500" size="sm">
                            <Trans
                              t={t}
                              i18nKey="fiat.deposit-warning.desc-4"
                              components={{
                                bold: <Span weight="semibold" />,
                              }}
                            />
                          </Text>
                        </li>
                        <li>
                          <Text color="secondary-500" size="sm">
                            {t('fiat.deposit-warning.desc-5')}
                          </Text>
                        </li>
                      </ul>
                    </Alert>
                  </>
                ),
                active: !!selectedSystemBank,
              },
            ]}
          />
        </Flex>
      </Panel>
      <Modal
        responsive={false}
        showOverlay={false}
        size="auto"
        closable={true}
        closeButton={<></>}
        onClose={setQrModalActive}
        open={qrModalActive}
      >
        {selectedSystemBank && (
          <Qr
            size={144}
            value={qrCode}
            level="H"
            imageSettings={{
              src: TLIcon,
              height: 28,
              width: 28,
              excavate: true,
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default FiatDeposit;
