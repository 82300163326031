import React from 'react';

import { Block, Icon, Meta, Number, Skeleton, StepProgress, Text } from '@bilira-org/design';
import { TransactionsRecordType } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import TransactionTitle from '@Components/transaction/components/TransactionTitle';
import { getStateConfig, TRANSACTION_STATUS } from '@Libs/helpers/getStateConfig';

import TransactionID from '../TransactionID';

type TransactionSummaryModalContentProps = {
  data?: TransactionsRecordType;
};

function Index({ data }: TransactionSummaryModalContentProps) {
  const { t, i18n } = useTranslation();

  if (!data) {
    return (
      <Block gap="md">
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-1/2" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-1/2" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-1/2" />
        <Skeleton height="size-4" width="size-full" />
      </Block>
    );
  }

  return (
    <StepProgress
      overflow="visible"
      color="neutral-300"
      vertical
      size="lg"
      steps={[
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-number')}</Text>}
              description={<TransactionID data={data} />}
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:hashtag" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-type')}</Text>}
              description={
                <Block row gap="md" items="center">
                  <TransactionTitle data={data} />
                </Block>
              }
            />
          ),
          icon: (
            <Icon
              color="secondary-500"
              size="lg"
              type={getStateConfig(data.status).icon(`${data.type}:${data.direction}`)}
            />
          ),
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-date')}</Text>}
              description={
                <Text weight="medium" color="secondary-500">
                  {dayjs(data.created_at, { locale: i18n.language }).format('DD MMM YYYY, HH:mm')}
                </Text>
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:clock" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t(`transactions.deposit-amount`)}</Text>}
              description={
                <FormattedNumberByPrice
                  size={{ xs: 'sm', sm: 'base' }}
                  suffixSize={{ xs: 'sm', sm: 'base' }}
                  suffix={data.asset}
                  weight="medium"
                  suffixWeight="medium"
                  color="primary-500"
                  value={data.amount}
                  base={data.asset}
                  type="amount"
                />
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:banknotes" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-status')}</Text>}
              description={
                <Text weight="medium" color={TRANSACTION_STATUS['completed'].text}>
                  {t('transactions.statuses.completed')}
                </Text>
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:document-check" />,
        },
      ]}
    />
  );
}

export default Index;
