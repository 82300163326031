import { AssetPairType, CoinSymbolType } from '../model';
import { SwapAssetPairTradeType, TradeType } from '../types';

const BILIRA_SYMBOL = 'TRYB';

/**
 * Constructs and returns an object containing the asset pair and swap pair strings based on the provided symbols and direction.
 *
 * The function takes in two coin symbols and a direction ('buy' or 'sell'). It then constructs the assetPair and swapPair strings
 * in the format "COIN1_COIN2", where COIN1 and COIN2 are the symbols of the coins. In case of a special scenario where both
 * symbols are equal to BILIRA_SYMBOL ('TRYB'), both assetPair and swapPair are set to "TRYB_TRY".
 *
 * @param {CoinSymbolType} base - The symbol of the coin to trade.
 * @param {CoinSymbolType} quote - The symbol of the base asset.
 * @param {'buy' | 'sell'} direction - The direction of the trade, either 'buy' or 'sell'.
 * @returns {{ assetPair: AssetPairType; swapPair: AssetPairType }} An object containing the assetPair and swapPair strings.
 *
 * @example
 * // Example when trading BTC with ETH in a 'buy' direction:
 * concatAssetPair('BTC', 'ETH', 'buy');
 * // Returns: { assetPair: 'BTC_ETH', swapPair: 'BTC_ETH' }
 *
 * @example
 * // Example when trading ETH with BTC in a 'sell' direction:
 * concatAssetPair('ETH', 'BTC', 'sell');
 * // Returns: { assetPair: 'ETH_BTC', swapPair: 'BTC_ETH' }
 *
 * @example
 * // Example when both symbols are BILIRA_SYMBOL ('TRYB'):
 * concatAssetPair('TRYB', 'TRYB', 'buy');
 * // Returns: { assetPair: 'TRYB_TRY', swapPair: 'TRYB_TRY' }
 */
const concatAssetPair = (
  base: CoinSymbolType,
  quote: CoinSymbolType,
  direction: TradeType,
): { assetPair: AssetPairType; swapPair: SwapAssetPairTradeType } => {
  if (base === BILIRA_SYMBOL && quote === BILIRA_SYMBOL) {
    return { assetPair: joinWithUnderscore(base, 'TRY'), swapPair: joinWithUnderscore(base, 'TRY', 'buy') };
  }

  return {
    assetPair: joinWithUnderscore(base, quote),
    swapPair: joinWithUnderscore(base, quote, direction),
  };
};

export const joinWithUnderscore = <T, M>(...args: T[]): M => args.join('_') as M;
export default concatAssetPair;
