import { Block, Dropdown, Flex, Link, notification, Skeleton, Text, withSuspense } from '@bilira-org/design';
import { Trans, useTranslation } from 'react-i18next';

import UserQuery from '@Libs/clientInstances/userQuery';

import { dropdownOptions } from '../constants';

const CEMPreference = () => {
  const { t } = useTranslation();
  const { data: legalDocumentConsents, refetch } = UserQuery.useGetLegalDocumentConsents();

  const { mutateAsync } = UserQuery.usePutLegalDocumentConsents();
  const cemDocument = legalDocumentConsents?.find((legalDocument) => legalDocument.document_type === 'cem');
  const selectedOptionIndex = cemDocument?.consents.find((consent) => consent.key === 'doc')?.consent ? 1 : 0;

  const updateConsent = async (item: (typeof dropdownOptions)[number]) => {
    const newValue = item.value ? ['doc'] : [];
    mutateAsync({ cem: newValue })
      .then(() => {
        notification.success(t('common.saved'));
        refetch();
      })
      .catch(({ response }) => {
        notification.error(response.data.message);
      });
  };

  return (
    <Flex
      direction={{ xs: 'col', sm: 'col', md: 'row' }}
      justify="between"
      gap={{ xs: 'lg', sm: 'lg', md: '8xl' }}
      items={{ xs: 'stretch', sm: 'stretch', md: 'center' }}
    >
      <Block flex items="start">
        <Text heading size="base" weight="semibold">
          {t('account.preferences.notifications.cem.title')}
        </Text>
        <Text mt="md">
          <Trans
            t={t}
            i18nKey="account.preferences.notifications.cem.sub"
            components={{
              anchor: (
                <Link display="inline" color="primary-500" anchor href={t('urls.data-privacy')} target="_blank" />
              ),
            }}
          />
        </Text>
      </Block>
      <Block minWidth="max">
        <Dropdown
          position="right"
          value={t(dropdownOptions[selectedOptionIndex].name)}
          size="md"
          textSize="sm"
          dataSource={dropdownOptions}
          onSelect={updateConsent}
          justify="between"
          renderItem={(item) => <Dropdown.Item key={item.name}>{t(item.name)}</Dropdown.Item>}
          listSize="full"
        />
      </Block>
    </Flex>
  );
};

export default withSuspense(
  CEMPreference,
  <Block row justify="between" gap="8xl" items="center">
    <Block flex items="start">
      <Skeleton height="size-6" width="size-40" />
      <Skeleton height="size-24" width="size-80" />
    </Block>
    <Block minWidth="max">
      <Skeleton />
    </Block>
  </Block>,
);
