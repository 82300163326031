import { HTMLAttributes, useMemo } from 'react';

import classNames from 'classnames';

import { BaseComponentType, MarginSizes, RadiusSizes, SizeType, SpaceSizes } from '../types';

export interface SkeletonProps extends HTMLAttributes<HTMLDivElement>, BaseComponentType {
  /** The number of columns for the skeleton. */
  columns?: number;
  /** The space between the skeleton items. */
  space?: SpaceSizes;
  /** The number of times to repeat the skeleton. */
  repeat?: number;
  /** The radius of the skeleton items. */
  rounded?: RadiusSizes;
  /** The width of the skeleton items. */
  width?: SizeType;
  /** The height of the skeleton items. */
  height?: SizeType;
  /** Additional class name for styling. */
  className?: string;
  /** Additional class name for skeleton item styling. */
  itemClassName?: string;
  /** The margin y-axis the skeleton items. */
  my?: MarginSizes;
  /** The margin top the skeleton items. */
  mt?: MarginSizes;
  /** The margin bottom the skeleton items. */
  mb?: MarginSizes;
  /** The light variant of the skeleton. */
  light?: boolean;
  /** Whether to animate the skeleton. */
  animate?: boolean;
}

function Skeleton({
  columns = 1,
  animate = true,
  space = 'sm',
  width,
  rounded = 'sm',
  className,
  itemClassName,
  height,
  my,
  mt,
  mb,
  repeat = 1,
  light,
  testId,
  ...rest
}: SkeletonProps) {
  const items = useMemo(
    () =>
      new Array(repeat).fill(0).map((item, index) => (
        <div
          {...rest}
          key={`list-skeleton-${index}`}
          className={classNames(
            light ? 'skeleton' : 'skeleton-dark',
            {
              'skeleton-animate': animate,
              [`tw-rounded-${rounded}`]: rounded,
              [`tw-h-${height}`]: height,
              [`tw-w-${width}`]: width,
            },
            itemClassName,
          )}
        >
          &nbsp;
        </div>
      )),
    [animate, light],
  );

  return (
    <div
      className={classNames(
        'opacity-30',
        {
          [`tw-w-${width}`]: width,
          [`tw-h-${height}`]: height,
          [`my-${my}`]: my,
          [`mt-${mt}`]: mt,
          [`mb-${mb}`]: mb,
        },
        className,
      )}
      data-testid={testId ?? 'skeleton'}
    >
      <div
        className={classNames({
          [`tw-w-${width}`]: width,
          [`tw-h-${height}`]: height,
          [`tw-columns-${columns}`]: columns,
          [`space-y-${space}`]: space,
        })}
      >
        {items}
      </div>
    </div>
  );
}

export default Skeleton;
