import React from 'react';

import { Text } from '@bilira-org/design';
import { CryptoWithdrawalFeesType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import useAuth from '@/libs/hooks/userAuth';
import allowancesQuery from '@Libs/clientInstances/allowancesQuery';

import FormattedNumberByPrice from '../common/FormattedNumberByPrice';

type Props = { network: string; activeAsset: string; fees?: CryptoWithdrawalFeesType[]; feesLoading: boolean };

const NetworkInfo = ({ network, activeAsset, fees, feesLoading }: Props) => {
  const { t } = useTranslation();

  const { account } = useAuth();
  const { data: allowance, isFetching } = allowancesQuery.useGetNextTransaction(
    {
      account,
      domain: 'crypto',
      direction: 'withdrawal',
      asset: activeAsset,
      network: network,
    },
    { suspense: false, enabled: !!account },
  );

  const fee = fees?.find((fee) => fee.asset === activeAsset && fee.network === network)?.withdrawal_fee;

  return (
    <>
      <Text color="neutral-600" size="xs" loading={feesLoading}>
        {t('crypto.select-network-modal.withdrawal-fee')}&nbsp;
        <FormattedNumberByPrice
          base={activeAsset}
          type="amount"
          as="span"
          suffix={activeAsset}
          value={fee}
          color="neutral-600"
          size="xs"
        />
      </Text>
      <Text color="neutral-600" size="xs" loading={isFetching}>
        {t('crypto.select-network-modal.min-withdraw')}&nbsp;
        <FormattedNumberByPrice
          base={activeAsset}
          type="amount"
          as="span"
          suffix={activeAsset}
          value={allowance?.min}
          color="neutral-600"
          size="xs"
        />
      </Text>
    </>
  );
};

export default NetworkInfo;
