import React, { useCallback, useEffect, useState } from 'react';

import { Block, Modal, notification, Skeleton } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { environment } from '@Libs/constants/environment';

type Props = {
  activeForm?: 'individual' | 'institutional';
  onClose: () => void;
};

const formIds = {
  individual: environment.INDIVIDUAL_OTC_APPLICATION_ID,
  institutional: environment.INSTITUTIONAL_OTC_APPLICATION_ID,
};

const OTCApplicationModal = ({ activeForm, onClose }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const onSuccess = useCallback((loadingToastId?: string) => {
    notification.remove(loadingToastId);
    notification.success(t('common.application-successfully-submitted'));
    onClose();
  }, []);

  useEffect(() => {
    if (activeForm !== undefined) {
      setLoading(true);
      let loadingToastId: string;

      (window as any).hbspt.forms.create({
        region: 'eu1',
        portalId: '139822810',
        formId: formIds[activeForm],
        target: '#hs-form',
        onFormSubmit: () => {
          loadingToastId = notification.loading(t('common.saving'));
        },
        onFormSubmitted: () => {
          onSuccess(loadingToastId);
        },
        onFormReady: () => {
          setLoading(false);
        },
      });
    }
  }, [activeForm, onSuccess]);

  return (
    <Modal open={activeForm !== undefined} onClose={onClose} color="white">
      {loading && (
        <Block gap="md">
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-1/2" />
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-1/2" />
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-1/2" />
          <Skeleton height="size-4" width="size-full" />
        </Block>
      )}
      <div id="hs-form" />
    </Modal>
  );
};

export default OTCApplicationModal;
