import React, { Fragment, memo, ReactNode, useEffect, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

import ButtonClose from '../button/buttonClose';
import { Icon } from '../icons';
import { BaseComponentType, PaddingSizes } from '../types';

interface IModal extends BaseComponentType {
  /** Children to render inside the modal */
  children?: ReactNode | React.ReactElement;
  /** Footer of the modal */
  footer?: ReactNode | React.ReactElement;
  /** Callback when the modal is closed */
  onClose?: (val: boolean) => void;
  /** Callback when the previous button is clicked */
  onPreviousClick?: (initial?: boolean) => void;
  /** Whether the modal has a previous button */
  hasPreviousButton?: boolean;
  /** Whether the modal is open */
  open: boolean;
  /** Title of the modal */
  title?: string | null;
  /** Whether the modal has a title separator */
  titleSeparator?: boolean;
  /** Whether the modal is closable */
  closable?: boolean;
  /** Whether the modal has an overlay */
  overlay?: boolean;
  /** Whether the modal is closable by clicking on the overlay */
  overlayClosable?: boolean;
  /** Whether the overlay has color */
  showOverlay?: boolean;
  /** Padding of the modal */
  padding?: PaddingSizes;
  /** Size of the modal */
  size?: 'md' | 'lg' | 'xl';
  /** Whether to enable keyboard shortcuts to close the modal*/
  enableShortcuts?: boolean;
  /** Custom close button */
  closeButton?: ReactNode;
  /** Whether the modal is responsive */
  responsive?: boolean;
  /** Whether the modal should shake on outside click */
  shakeOnOutsideClick?: boolean;
}

/**
 * Dialog modal styled to appear from the bottom of the screen.
 *
 * @example
 * <BottomDialog open={isSettingsModalOpen} closable={false} shakeOnOutsideClick>
 *   ...
 * </BottomDialog>
 */
const BottomDialog = memo(
  ({
    onClose,
    children,
    onPreviousClick,
    hasPreviousButton,
    open,
    title,
    closable = true,
    padding = '2xl',
    footer,
    size = 'lg',
    enableShortcuts = false,
    titleSeparator = true,
    closeButton,
    overlayClosable = true,
    showOverlay = true,
    responsive = true,
    shakeOnOutsideClick,
    testId,
  }: IModal) => {
    const [clickedOutsideCount, setClickedOutsideCount] = useState(0);
    function closeModal() {
      onClose?.(false);
      onPreviousClick?.(true);
    }

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (!enableShortcuts) {
          return;
        }

        if (
          (event.key === 'Escape' || event.key === 'Esc') &&
          !event.shiftKey &&
          !event.altKey &&
          !event.ctrlKey &&
          !event.metaKey
        ) {
          onClose?.(false);
        } else if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
          onClose?.(true);
        }
      };

      if (enableShortcuts) {
        document.addEventListener('keydown', handleKeyDown);
      }
      return () => {
        if (enableShortcuts) {
          document.removeEventListener('keydown', handleKeyDown);
        }
      };
    }, [enableShortcuts]);

    const classes = classNames('bottom-modal-container tw-drop-shadow-xl', {
      [`modal-size-${size}`]: size,
      'modal-responsive': responsive,
      'shake-bottom-dialog': clickedOutsideCount !== 0,
    });

    return (
      <>
        <Transition show={open} as={Fragment}>
          <Dialog
            as="div"
            open={open}
            className="dialog-bottom"
            onClose={() => {
              overlayClosable && closeModal();
              shakeOnOutsideClick && setClickedOutsideCount(clickedOutsideCount + 1);
            }}
            data-testid={testId ?? 'bottom-dialog'}
          >
            <div className="dialog-content">
              <Transition.Child
                as={Fragment}
                unmount={open}
                enter="ease-in duration-600"
                enterFrom="opacity-0"
                enterTo={showOverlay ? 'opacity-50' : 'opacity-0'}
                leave="ease-out duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className={`dialog-overlay ${!showOverlay && 'dialog-overlay-hidden'}`} />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="ease-in duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-out duration-50"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className={classes} key={clickedOutsideCount}>
                  {title && (
                    <div className={classNames('modal-title', { 'title-separator': titleSeparator })}>
                      {hasPreviousButton && (
                        <p className="cursor-pointer pr-6" onClick={() => onPreviousClick?.()}>
                          <Icon type="o:arrow-left" size="lg" color="secondary-500" />
                        </p>
                      )}
                      {title && (
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-secondary font-bold leading-6 tw-text-secondary-500"
                        >
                          {title}
                        </Dialog.Title>
                      )}
                      {closable &&
                        (closeButton ? (
                          <button tabIndex={-1} className="bg-transparent border-0 ring-0" onClick={closeModal}>
                            {closeButton}
                          </button>
                        ) : (
                          <Icon
                            color="secondary-500"
                            type="o:x-mark"
                            size="lg"
                            className="flex-none"
                            onClick={closeModal}
                          />
                        ))}
                    </div>
                  )}
                  {!title && closable && (
                    <div className="absolute right-5 z-30 top-5 flex gap-1">
                      {closeButton ? (
                        <button tabIndex={-1} className="bg-transparent border-0 ring-0" onClick={closeModal}>
                          {closeButton}
                        </button>
                      ) : (
                        <ButtonClose className="flex-none" onClick={closeModal} />
                      )}
                    </div>
                  )}
                  <div className={classNames('modal-content text-left', { [`p-${padding}`]: padding })}>{children}</div>
                  {footer && <div className={classNames('block', { [`p-${padding}`]: padding })}>{footer}</div>}
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  },
);

export default BottomDialog;
