import { TaskHistoryType } from '@bilira-org/react-utils';
import { TFunction } from 'i18next';

import { getTaskTitleByActionType } from './getTaskTitle';

export function getTaskHistoryTitle(task: TaskHistoryType, t: TFunction) {
  if (task.action === 'transaction_count') {
    return t(`rewards.task-titles.${task.action_type}`);
  } else if (task.action === 'transaction_volume') {
    return t('rewards.task-titles.volume', { type: t(`rewards.task-titles.${task.action_type}`) });
  }
  return getTaskTitleByActionType(task.action_type, t);
}
