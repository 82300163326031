import { useCallback, useEffect, useMemo, useState } from 'react';

import { notification } from '@bilira-org/design';
import { AssetPairType, ForceDecimal, formatPriceValue, getAskBidPrice, httpError } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import { usePriceFeed } from '@/libs/hooks/usePriceFeed';
import { calculatePrice } from '@Components/trade/buySell/helpers';
import getPairsByType from '@Components/trade/buySell/helpers/getPairsByType';
import { TradeWidgetType, useBuySellStore } from '@Components/trade/buySell/store';
import { BuySellDirection, SwapAssetType, SwapDirection } from '@Components/trade/types';
import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';
import SwapQuery from '@Libs/clientInstances/swapQuery';
import useGetBalance from '@Libs/hooks/useGetBalance';
import useAuth from '@Libs/hooks/userAuth';
import { handleErrorResponse } from '@Libs/utils';
import { useSwapStore } from '@Store/swapStore';

type Props = {
  type: BuySellDirection;
  assetInfo?: SwapAssetType;
  assetPair: AssetPairType;
  tradeWidgetType: TradeWidgetType;
};

/**
 * The useBuySellHook is a custom hook that provides various functionality related to buying and selling assets.
 * It accepts a set of props and returns an object with several properties and methods that can be used by the calling component.
 */
const useBuySellHook = ({ type, assetInfo, assetPair, tradeWidgetType }: Props) => {
  const { t, i18n } = useTranslation();
  const { account } = useAuth({ suspense: false });
  const [baseAsset, quoteAsset, base, quote] = getPairsByType(type, assetPair);
  const { freeBalance, compare } = useGetBalance({ symbol: baseAsset, suspense: false });

  const { mutateAsync, isPending, cancelRequest: cancelSwapQuoteRequest } = SwapQuery.usePostSwapQuoteRequest();
  const { data: assetPairPrice, isFetching: isPairPriceFetching } = CryptoWalletApi.useGetAssetPairPrice(
    { pair: `${base}_${quote}`, type: 'trade' },
    {
      enabled: true,
      suspense: false,
    },
  );
  const priceData = usePriceFeed(`${base}_${quote}`);
  const bidAskData = priceData || assetPairPrice;

  const [inputValue, setInputValue] = useState<Record<SwapDirection, string>>({ from: '', to: '0' });
  const assetPrice = getAskBidPrice(type, inputValue.from, bidAskData);

  const { setSwapOffer } = useSwapStore();
  const { setBuySellOfferModal, setBuySellType } = useBuySellStore();

  useEffect(() => {
    setInputValue((state) => {
      const calculatedPrice = calculatePrice(state.from, assetPrice || '0', type, i18n.language);
      return { ...state, to: calculatedPrice || '0' };
    });
  }, [i18n.language, setInputValue, assetPrice, type]);

  const validateBalance = useMemo(() => {
    if (!inputValue.from) {
      return {
        invalid: false,
        message: '',
      };
    }

    const balanceValidation = compare(inputValue.from) === 1;

    return {
      invalid: balanceValidation,
      message: balanceValidation ? t('common.insufficient-balance') : '',
    };
  }, [t, compare, inputValue.from]);

  const fetchOffer = useCallback(() => {
    if (!baseAsset || !quoteAsset || !inputValue.from) {
      return;
    }

    if (validateBalance.invalid) {
      notification.error(validateBalance.message);
      return;
    }

    const formattedFromAmount = formatPriceValue({
      value: inputValue.from,
      decimal: assetInfo?.scale,
      forceDecimal: ForceDecimal.ENFORCE,
      defaultValue: '0',
    });

    setBuySellType(type);

    mutateAsync({
      from_asset: baseAsset,
      to_asset: quoteAsset,
      from_amount: formattedFromAmount,
      account: account,
    })
      .then((data) => {
        setSwapOffer(data);
        setBuySellOfferModal({ [tradeWidgetType]: true });
      })
      .catch((error) => {
        const { is401, is429 } = httpError(error);
        if (is401) {
          notification.error(t('common.session-terminated'));
          onOfferClose();
        } else if (is429) {
          notification.error(t('market.swap.too-many-requests'));
        } else {
          handleErrorResponse(error, t('market.swap.could-not-create-offer'));
        }
      });
  }, [type, baseAsset, quoteAsset, assetInfo, inputValue, account, validateBalance]);

  const applyPercentageCallback = (percentage: number) => {
    const amount = BigNumber(freeBalance || '0').multipliedBy(percentage);
    const formattedValue = formatPriceValue({
      value: amount.toString(),
      decimal: assetInfo?.scale,
      forceDecimal: ForceDecimal.ENFORCE,
    });

    onFromInput(formattedValue);
  };

  const onFromInput = (value: string) => {
    setInputValue((state) => ({ ...state, from: value }));
    calculateQuote(value);
  };

  const calculateQuote = (value: string) => {
    const calculatedPrice = calculatePrice(value, assetPrice || '0', type, i18n.language);

    setInputValue((state) => ({ ...state, to: calculatedPrice || '0' }));
  };

  const onOfferClose = useCallback((isOfferCompleted = false) => {
    cancelSwapQuoteRequest();
    setBuySellOfferModal({ modal: false, card: false });

    if (isOfferCompleted) {
      setInputValue({ from: '', to: '0' });
    }
  }, []);

  const loginLink = account ? undefined : { href: '/login' };
  const disabled = !base || !quote || !inputValue.from || BigNumber(inputValue.from).isZero();

  return {
    disabled,
    loginLink,
    onFromInput,
    inputValue,
    applyPercentageCallback,
    baseAsset,
    quoteAsset,
    assetPrice,
    fetchOffer,
    isPairPriceFetching,
    base,
    quote,
    isPending,
    onOfferClose,
    t,
  };
};

export default useBuySellHook;
