import React from 'react';

import { Overflow } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';

import MarketPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import { useSubscribeToScroll } from '@Libs/hooks/useScrollEvents';
import useMarketAssetList from '@Modules/market/market/table/components/hooks/useMarketAssetList';
import MarketTable from '@Modules/market/market/table/components/MarketTable';

type Props = {
  dataTag?: string;
  exclude?: CoinSymbolType[];
  quote?: CoinSymbolType;
};
const MarketTableContent = ({ dataTag, exclude, quote }: Props) => {
  const { assetList, isPending, fetchNextPage, isFetchingNextPage, subscriptionList } = useMarketAssetList({
    dataTag,
    exclude,
    quote,
  });

  useSubscribeToScroll({ eventName: 'reached-content-scroll-bottom', callback: fetchNextPage });

  return (
    <>
      <Overflow overflow="x-auto">
        <MarketPriceSubscription
          channel="market-table"
          baseQuoteList={subscriptionList}
          events="last_price,symbol_statistics"
        />

        <MarketTable
          isFetchingNextPage={isFetchingNextPage}
          assetList={assetList}
          isPending={isPending}
          quoteAsset={quote}
        />
      </Overflow>
    </>
  );
};

export default MarketTableContent;
