import React, { HTMLAttributes, memo } from 'react';

import classNames from 'classnames';

import { BaseComponentType, SizeType } from '../types';

export interface ConnectProps extends HTMLAttributes<HTMLElement>, BaseComponentType {
  children: React.ReactNode[];
  width?: SizeType;
}

/**
 * Component to connect children with a line
 *
 * @example
 * <Connect>
 *   <div>1</div>
 *   <div>2</div>
 * </Connect>
 */
const Connect = memo(({ className, width = 'size-72', testId, ...props }: ConnectProps) => {
  const classes = classNames('connect-line', className);

  return (
    <div className="flex justify-center" data-testid={testId ?? 'connect'}>
      <div
        className={classNames('flex gap-5xl items-center justify-between relative z-10', {
          [`width-${width}`]: !!width,
        })}
      >
        {props.children}
        <div className={classes}></div>
      </div>
    </div>
  );
});

export default Connect;
