import React, { useState } from 'react';

import {
  CoinSymbolType,
  TransactionDirectionType,
  TransactionsRecordType,
  TransactionStatusType,
  TransactionsType,
  TransactionType,
} from '@bilira-org/react-utils';
import { useNavigate } from 'react-router-dom';

import { TransactionRecordInfo } from '@/libs/hooks/useGetTransactionDetail';
import useGetTransactions from '@/libs/hooks/useGetTransactions';
import AccountApi from '@Libs/clientInstances/accountQuery';
import { LIMIT } from '@Libs/config';
import useAuth from '@Libs/hooks/userAuth';

type Props = {
  type?: TransactionType;
  direction?: TransactionDirectionType;
  status?: TransactionStatusType;
  filterAsset?: CoinSymbolType;
  limit?: number;
  suspense?: boolean;
};

const useTransactionTable = ({ type, direction, limit, status, suspense, filterAsset }: Props) => {
  const navigate = useNavigate();
  const { account } = useAuth();
  const [modalActive, setModalActive] = useState(false);
  const [selectedTransactionRecordInfo, setSelectedTransactionRecordInfo] = useState<TransactionRecordInfo>();

  const transactionsQuery = useGetTransactions({
    type: type as any,
    params: { account, limit, offset: 0, direction, status, asset: filterAsset },
    queryOptions: { suspense },
  });

  const accountTransactionsQuery = AccountApi.useTransactions(
    {
      params: {
        limit: limit ?? LIMIT,
        offset: 0,
        type: type,
        direction: direction,
        status: status,
      },
      id: account,
    },
    { suspense: suspense, enabled: !transactionsQuery && !!account },
  );

  const {
    data: { records: data = [] as TransactionsRecordType[] } = {},
    isPending,
    refetch,
  } = transactionsQuery || accountTransactionsQuery;

  const tableCallback = React.useCallback((item: TransactionsRecordType) => {
    const customActions: Record<string, () => void> = {
      'other:payback': () => navigate('/referral'),
    };

    const defaultAction = () => {
      setSelectedTransactionRecordInfo({ id: item.id, type: item.type });
      setModalActive(true);
    };

    const actionKey = `${item.direction}:${item.type}`;
    const selectedAction = customActions[actionKey] || defaultAction;

    selectedAction();
  }, []);

  return {
    data: data as TransactionsRecordType[],
    modalActive,
    setModalActive,
    selectedTransactionRecordInfo,
    tableCallback,
    isPending,
    refetch,
  };
};

export default useTransactionTable;
