import React from 'react';

import { Drawer, Icon, List, Meta, Separator, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { withPrivateRoute } from '@/libs/hocs';
import { useGeneralStore } from '@/store/generalStore';
import DisplayFeatureFlag from '@Components/common/DisplayFeatureFlag';
import { IconCustom } from '@Components/icon/CustomIcon';
import UserKycCard from '@Components/layouts/components/components/components/UserKycCard';

import HelpContent from './components/HelpContent';

/**
 * Component for displaying a mobile menu drawer for authenticated users
 * containing user-related options and navigation links.
 */
const PrivateMobilMenuDrawer = () => {
  const { t } = useTranslation();
  const { privateDrawerOpen, setPrivateDrawerOpen } = useGeneralStore();
  const navigate = useNavigate();
  const redirect = (href: string) => {
    navigate(href);
    setPrivateDrawerOpen(false);
  };
  return (
    <Drawer open={privateDrawerOpen} onClose={() => setPrivateDrawerOpen(false)}>
      <UserKycCard />
      <Separator width="size-full" mt="md" mb="xs" />

      <List spaceY="xs">
        <List.Item px="md" py="md" hoverBgColor="neutral-300" onClick={() => redirect('/account')}>
          <Meta
            position="left-top"
            extra={<Icon size="lg" color="neutral-700" type="o:user-circle" />}
            title={
              <Text size="sm" color="secondary-500" weight="regular">
                {t('navigation.profile.account')}
              </Text>
            }
          />
        </List.Item>
        <List.Item px="md" py="md" hoverBgColor="neutral-300" onClick={() => redirect('/otc')}>
          <Meta
            position="left-top"
            extra={<Icon size="lg" color="neutral-700" type="o:arrows-right-left" />}
            title={
              <Text size="sm" color="secondary-500" weight="regular">
                {t('navigation.otc-portal')}
              </Text>
            }
          />
        </List.Item>
        <Separator width="size-full" my="xs" />
        <List.Item px="md" py="md" hoverBgColor="neutral-300" onClick={() => navigate('/rewards')}>
          <Meta
            space="md"
            extra={<IconCustom type="sparkles" width="size-6" />}
            title={
              <Text color="gradient-1" weight="medium" size="sm">
                {t('rewards.tracking-header')}
              </Text>
            }
          />
        </List.Item>
        <HelpContent closeCallback={() => setPrivateDrawerOpen(false)} />
        <Separator width="size-full" color="neutral-400" />
        <List.Item px="md" py="md" hoverBgColor="neutral-300" onClick={() => redirect('/logout')}>
          <Meta
            position="left-top"
            extra={<Icon size="lg" color="neutral-700" type="o:arrow-right-on-rectangle" />}
            title={
              <Text size="sm" color="secondary-500" weight="regular">
                {t('navigation.profile.logout')}
              </Text>
            }
          />
        </List.Item>
      </List>
    </Drawer>
  );
};

export default withPrivateRoute(PrivateMobilMenuDrawer);
