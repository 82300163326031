import React from 'react';

import { Panel } from '@bilira-org/design';
import { CoinSymbolType, TransactionDirectionType, TransactionStatusType, TransactionType } from '@bilira-org/react-utils';

import TransactionDetailModal from '@Components/transaction/modal/TransactionDetailModal';
import TransactionCard from '@Components/transaction/TransactionCard';
import TransactionCardHeader, {
  Props as TransactionCardHeaderProps,
} from '@Components/transaction/TransactionCardHeader';
import { withPrivateRoute } from '@Libs/hocs';

import useTransactionTable from './hooks/useTransactionTable';

interface TransactionPanelProps {
  /** Title of the panel */
  title: string;
  /** Type of transaction to filter */
  type?: TransactionType;
  /** Direction of transaction to filter*/
  direction?: TransactionDirectionType;
  /** Status of transaction to filter */
  filterStatus?: TransactionStatusType;
  /**List will be listed based the filter.*/
  filterAsset?: CoinSymbolType;
  /** Number of transactions to display per page */
  limit?: number;
  /** Whether to show sub amounts in the table */
  showSubAmount?: boolean;
  /** Whether to show "Show All" button */
  showAllButton?: boolean;
  /** Whether to hide panel if transaction data is empty */
  hideIfDataEmpty?: boolean;
  /** HTML id of the panel */
  id?: string;
  /** Test id of the panel */
  testId?: string;
}

type Props = TransactionPanelProps & Omit<TransactionCardHeaderProps, keyof TransactionPanelProps>;

/**
 * Component for rendering transaction records in a panel
 */
const TransactionPanelCard = ({
  title,
  description,
  type,
  direction,
  filterStatus,
  filterAsset,
  limit,
  showSubAmount,
  showAllButton,
  hideIfDataEmpty,
  id,
  testId,
}: Props) => {
  const { data, selectedTransactionRecordInfo, modalActive, setModalActive, tableCallback, isPending, refetch } =
    useTransactionTable({
      type,
      direction,
      limit,
      status: filterStatus,
      filterAsset: filterAsset,
      suspense: false,
    });

  if (!isPending && hideIfDataEmpty && data.length === 0) {
    return <></>;
  }

  return (
    <>
      <Panel border="neutral-400" size="md" id={id} testId={testId}>
        <TransactionCardHeader title={title} description={description} />
        <TransactionCard
          result={data}
          isPending={isPending}
          limit={limit}
          tableCallback={tableCallback}
          showSubAmount={showSubAmount}
          showAllButton={showAllButton}
          type={type}
          direction={direction}
          status={filterStatus}
        />
      </Panel>
      <TransactionDetailModal
        transactionRecordInfo={selectedTransactionRecordInfo}
        open={modalActive}
        onClose={setModalActive}
        refetch={refetch}
      />
    </>
  );
};

export default withPrivateRoute(TransactionPanelCard);
