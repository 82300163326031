import React, { useState } from 'react';

import { Overflow, Panel } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';
import { useSearchParams } from 'react-router-dom';

import FilterTags from '@Modules/market/market/table/components/FilterTags';
import MarketTable from '@Modules/market/market/table/components/MarketTableContent';

const MarketTableWidget = () => {
  const [dataTag, setDataTag] = useState<string>();

  const [searchParams] = useSearchParams();
  const excludedSymbols = searchParams.get('exclude')?.split(',');

  return (
    <>
      <Panel border="neutral-400" size="md">
        <FilterTags dataTag={dataTag} setDataTag={setDataTag} />
        <Overflow overflow="y-auto">
          <div style={{ height: 500 }}>
            <MarketTable dataTag={dataTag} quote="TRYB" exclude={(excludedSymbols || []) as CoinSymbolType[]} />
          </div>
        </Overflow>
      </Panel>
    </>
  );
};

export default MarketTableWidget;
