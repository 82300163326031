/**
 * Validates the provided string against the IBAN format.
 *
 * @param {string} str - The string to be validated as an IBAN.
 * @returns {boolean} - true if the string is a valid IBAN, false otherwise.
 *
 * validateIban("abc") // false
 * validateIban("TR480011100000000079118373") // true
 */
export const validateIban = (iban: string) => {
  // Remove spaces and trim the IBAN
  iban = iban.replace(/\s+/g, '').toUpperCase().trim();

  // Check if the length of IBAN is appropriate (26 with TR prefix)
  if (iban.length !== 26) {
    return false;
  }

  // Move the first four characters to the end of the string
  const rearrangedIBAN = iban.slice(4) + iban.slice(0, 4);

  // Replace each letter in the string with two digits
  const ibanAsDigits = rearrangedIBAN
    .split('')
    .map((char) => {
      if (char >= 'A' && char <= 'Z') {
        return (char.charCodeAt(0) - 'A'.charCodeAt(0) + 10).toString();
      } else {
        return char;
      }
    })
    .join('');

  // Convert the string to a big integer and perform the mod 97 operation
  const ibanNumber = BigInt(ibanAsDigits);
  return ibanNumber % 97n === 1n;
};
