import React, { FC } from 'react';

import {
  Badge,
  Block,
  Button,
  Col,
  Display,
  Dropdown,
  Empty,
  Flex,
  Grid,
  Icon,
  List,
  Meta,
  Skeleton,
  Span,
  Text,
  withSuspense,
} from '@bilira-org/design';
import { CryptoNetworkType, truncateMiddle, WalletModel } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import IconCoin from '@Components/icon/IconCoin';

import useWallet from './hooks/useWallet';
import WalletItemSkeleton from './WalletItemSkeleton';

interface WalletProps {
  onSelected?: (wallet: WalletModel) => void;
  callback: (wallet?: WalletModel) => void;
  activeCategory?: any;
  description?: string;
  filter?: CryptoNetworkType;
  isSelectionMode?: boolean;
}

const Wallets: FC<WalletProps> = ({ description, filter, callback, onSelected, isSelectionMode }) => {
  const { walletData, onDelete, onClickProp, isFetching, selectedWallet } = useWallet({ filter });
  const { t } = useTranslation();

  return (
    <Grid alignContent="between" minHeight="xs" gap="lg">
      <Col>
        {description && <Text>{description}</Text>}
        <List
          loading={isFetching}
          skeleton={<WalletItemSkeleton />}
          skeletonLine={2}
          empty={
            <Empty
              extra={
                <Button variant="filled" size="md" onClick={() => callback()}>
                  {t('wallet-address.modal.add-address')}
                </Button>
              }
              message={t('wallet-address.modal.empty-desc')}
            />
          }
          dataSource={walletData}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              size={{ xs: 'sm', sm: 'md' }}
              hoverBgColor="theme-n200d800"
              {...onClickProp(item, isSelectionMode, onSelected)}
              extra={
                <>
                  <Display show={isSelectionMode}>
                    <Flex direction="row" gap="lg" items="center">
                      <Flex.Item>
                        <Text size="xs" color="neutral-600" truncate>
                          {item.network}
                        </Text>
                      </Flex.Item>
                      <Flex.Item>
                        <Display show={onSelected}>
                          <Display show={item.id === selectedWallet?.id}>
                            <Icon
                              size="lg"
                              color={item.id === selectedWallet?.id ? 'primary-500' : 'neutral-600'}
                              type="s:check-circle"
                            />
                          </Display>
                          <Display show={item.id !== selectedWallet?.id}>
                            <Badge size="md" />
                          </Display>
                        </Display>
                      </Flex.Item>
                    </Flex>
                  </Display>

                  <Display show={!isSelectionMode}>
                    <Dropdown
                      divider
                      value={<Icon type="o:ellipsis-horizontal" size="lg" color="neutral-600" />}
                      position="right"
                      color="ghost"
                      appearance={false}
                    >
                      <Dropdown.Item onClick={() => callback(item)} key={`${item.alias}edit`}>
                        {t('common.edit')}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => onDelete(item.id)} key={`${item.alias}delete`}>
                        <Span color="red-500">{t('common.delete')}</Span>
                      </Dropdown.Item>
                    </Dropdown>
                  </Display>
                </>
              }
            >
              <Meta
                extra={<IconCoin size={{ xs: '2xl', sm: '3xl' }} type={item.network} />}
                space="sm"
                title={
                  <Text size={{ xs: 'sm', sm: 'base' }} weight="semibold" color="secondary-500">
                    {item.alias}
                  </Text>
                }
                description={<Text size={{ xs: 'sm', sm: 'base' }}>{truncateMiddle(item.address, 6, 4)}</Text>}
              />
            </List.Item>
          )}
        />
      </Col>
      <Col>
        <Display show={walletData?.length}>
          <Block pt={{ xs: 'none', sm: 'none', md: 'sm' }}>
            <Button
              variant="outline"
              size="lg"
              onClick={() => callback()}
              startIcon={<Icon type="o:plus-circle" />}
              stretch
            >
              {t('wallet-address.modal.add-wallet-address')}
            </Button>
          </Block>
        </Display>
      </Col>
    </Grid>
  );
};

export default withSuspense(
  Wallets,
  <Grid alignContent="between" minHeight="xs">
    <Col>
      <List loading={true} skeleton={<WalletItemSkeleton />} skeletonLine={2} />
    </Col>
    <Col>
      <Skeleton width="size-full" height="size-12" rounded="xl" />
    </Col>
  </Grid>,
);
