import React from 'react';

import { LayoutCommon } from '@Components/layouts';
import Header from '@Modules/crypto/withdraw/components/Header';
import CryptoWithdrawal from '@Modules/crypto/withdraw/CryptoWithdrawal';
import LastCryptoWithdrawals from '@Modules/crypto/withdraw/LastCryptoWithdrawals';

const CryptoWithdrawalContainer = () => {
  return (
    <>
      <Header />
      <LayoutCommon>
        <LayoutCommon.Content>
          <CryptoWithdrawal />
        </LayoutCommon.Content>
        <LayoutCommon.Sidebar>
          <LastCryptoWithdrawals />
        </LayoutCommon.Sidebar>
      </LayoutCommon>
    </>
  );
};

export default CryptoWithdrawalContainer;
