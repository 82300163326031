import React, { FC } from 'react';

import { Block, Icon, Input, List, Skeleton, Tab } from '@bilira-org/design';
import { AssetPairType, SwapPairAndBalanceType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import PairList from '@Components/trade/buySell/components/PairList';

import useBuySellPairs from './hooks/useBuySellPairs';

interface Props {
  assetPair?: AssetPairType;
  setAsset: (asset: SwapPairAndBalanceType) => void;
}

/**
 * The BuySellPairs component displays a list of swap pairs for buying and selling assets.
 */
const BuySellPairs: FC<Props> = ({ setAsset, assetPair }: Props) => {
  const { t } = useTranslation();

  const { isPending, onSearch, filteredSwapPairs, filteredWatchListPairs, searchText, displayWatchList } =
    useBuySellPairs();

  if (isPending) {
    return (
      <>
        <Block mb="md">
          <Skeleton width="size-full" height="size-12" />
        </Block>
        <List
          color="neutral-300"
          space="xs"
          loading={true}
          skeleton={
            <Block row justify="between" items="center" p="md" gap="lg">
              <Block row items="center" gap="md">
                <Skeleton width="size-9" height="size-9" rounded="full" />

                <Skeleton height="size-3" width="size-20" />
              </Block>

              <Skeleton height="size-3" width="size-12" />
            </Block>
          }
          skeletonLine={9}
        />
      </>
    );
  }

  return (
    <>
      <Block height="size-full">
        <Block mx="sm" p="lg" gap="md">
          <Input.Search
            variant="filled"
            iconStart={<Icon size="md" type="o:magnifying-glass" color="neutral-600" />}
            placeholder={t('common.search-asset')}
            value={searchText}
            onChange={(val) => onSearch(val.target.value)}
          />
        </Block>
        <Tab
          scrollable
          variant="boxed"
          headerStretch={false}
          headerStyle={{
            rowPx: 'xl',
            size: 'sm',
          }}
          panelsMt="lg"
          headerTextStyle={{ size: 'xs', weight: 'medium', color: 'neutral-900' }}
          defaultIndex={displayWatchList ? 1 : 0}
          headers={[
            ...(displayWatchList
              ? [
                  {
                    name: <Icon type="o:star" size="sm" />,
                    key: 'tab-watchlist-pairs',
                  },
                ]
              : []),
            {
              name: 'TRYB',
              key: 'tab-tryb-pairs',
            },
            {
              name: 'USDC',
              key: 'tab-usdc-pairs',
            },
            {
              name: 'USDT',
              key: 'tab-usdt-pairs',
            },
          ]}
        >
          {displayWatchList && (
            <Tab.Panel>
              <PairList assetPair={assetPair} data={filteredWatchListPairs} setAsset={setAsset} />
            </Tab.Panel>
          )}
          <Tab.Panel>
            <PairList assetPair={assetPair} data={filteredSwapPairs} quoteAsset="TRYB" setAsset={setAsset} />
          </Tab.Panel>
          <Tab.Panel>
            <PairList assetPair={assetPair} data={filteredSwapPairs} quoteAsset="USDC" setAsset={setAsset} />
          </Tab.Panel>
          <Tab.Panel>
            <PairList assetPair={assetPair} data={filteredSwapPairs} quoteAsset="USDT" setAsset={setAsset} />
          </Tab.Panel>
        </Tab>
      </Block>
    </>
  );
};

export default BuySellPairs;
