import FormattedFancyInputNumber from './formattedFancyNumber';
import { default as FormattedInputNumber } from './formattedNumber';
import { default as InputText } from './input';
import { default as InputSearch } from './inputSearch';
import { default as LegacyInputNumber } from './legacyNumber';
import { default as InputNumber } from './number';
import { default as InputPassword } from './password';

export { default as Textarea } from './textarea';

export { default as InputCode } from './inputCode';
export type { InputCodeProps, InputCodeRef } from './inputCode';

const Input = Object.assign(InputText, {
  Password: InputPassword,
  Number: InputNumber,
  FormattedNumber: FormattedInputNumber,
  FormattedFancyNumber: FormattedFancyInputNumber,
  LegacyNumber: LegacyInputNumber,
  Search: InputSearch,
});

export { Input };
