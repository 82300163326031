import React from 'react';

import { Button, Flex, Icon, Skeleton, Tooltip, withSuspense } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TotalBalanceCard from '@/components/balance/TotalBalanceCard';
import { withPrivateRoute } from '@/libs/hocs';

const PrivateWelcomePanel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Flex
        wrap={true}
        minWidth="min"
        items={{ md: 'center' }}
        gap="2xl"
        justify={{ sm: 'start', md: 'end' }}
        direction={{ xs: 'col', md: 'row' }}
      >
        <Flex.Item id="wallet-overview" flexType="auto">
          <TotalBalanceCard
            color="white"
            label={t('overview.total-asset-value')}
            extra={
              <Tooltip placement="right" message={t('overview.total-available-assets')}>
                <Icon type="o:exclaimation-circle" color="white" size="sm" />
              </Tooltip>
            }
          />
        </Flex.Item>
        <Flex.Item
          id="deposit-buttons"
          flex
          wrap={{ xs: 'wrap', sm: 'nowrap' }}
          gap="md"
          direction="row"
          items="center"
          justify={{ sm: 'start', lg: 'end' }}
        >
          <Button
            testId="fiat-deposit-btn"
            flexType="1"
            startIcon={<Icon type="o:banknotes" />}
            variant="outline-filled"
            size="lg"
            onClick={() => navigate('/fiat/deposit')}
          >
            {t('common.fiat-deposit')}
          </Button>
          <Button
            testId="crypto-deposit-btn"
            flexType="1"
            startIcon={<Icon type="o:qr-code" />}
            variant="outline-filled"
            size="lg"
            onClick={() => navigate('/crypto/deposit')}
          >
            {t('common.crypto-deposit')}
          </Button>
        </Flex.Item>
      </Flex>
    </>
  );
};

export default withPrivateRoute(
  withSuspense(PrivateWelcomePanel, <Skeleton width="size-36" height="size-3" my="3xl" className="inline-block" />),
);
