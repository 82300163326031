import React, { Suspense } from 'react';

import { Block, Button, Icon, Panel, Skeleton, Typography } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import AnnouncementSlider, {
  AnnouncementSliderProps,
} from '@Modules/overview/components/announcements/components/AnnouncementSlider';

const Announcement = () => {
  const { t } = useTranslation();
  const sliderRef = React.useRef<AnnouncementSliderProps>(null);

  return (
    <Panel border="neutral-400" id="announcement" testId="announcement-panel">
      <Block flex>
        <Block row justify="between">
          <Typography.Text heading size="xl" weight="semibold">
            {t('overview.announcements')}
          </Typography.Text>
          <Block row gap="sm">
            <Button
              size="md"
              variant="transparent"
              onClick={() => sliderRef.current?.prev()}
              startIcon={<Icon color="secondary-500" type="o:arrow-left" />}
            />
            <Button
              size="md"
              variant="transparent"
              onClick={() => sliderRef.current?.next()}
              startIcon={<Icon color="secondary-500" type="o:arrow-right" />}
            />
          </Block>
        </Block>

        <Suspense
          fallback={
            <Block row justify="between" mt="lg">
              <Block gap="sm" justify="center">
                <Skeleton width="size-44" height="size-3" />

                <Skeleton width="size-20" height="size-3" />

                <Skeleton width="size-32" mt="4xl" />
              </Block>

              <Skeleton rounded="full" width="size-20" height="size-20" mt="sm" />
            </Block>
          }
        >
          <Block>
            <AnnouncementSlider ref={sliderRef} />
          </Block>
        </Suspense>
      </Block>
    </Panel>
  );
};

export default Announcement;
