import React from 'react';

import classNames from 'classnames';

import AkbankIconDark from '../../assets/banks/akbank-icon-dark.svg';
import AkbankIconLight from '../../assets/banks/akbank-icon-light.svg';
import AkbankLogoDark from '../../assets/banks/akbank-logo-dark.svg';
import AkbankLogoLight from '../../assets/banks/akbank-logo.svg';
import AktifbankIconDark from '../../assets/banks/aktifbank-icon-dark.svg';
import AktifbankIconLight from '../../assets/banks/aktifbank-icon-light.svg';
import AktifbankLogoDark from '../../assets/banks/aktifbank-logo-dark.svg';
import AktifbankLogoLight from '../../assets/banks/aktifbank-logo.svg';
import AlbarakaIconDark from '../../assets/banks/albaraka-icon-dark.svg';
import AlbarakaIconLight from '../../assets/banks/albaraka-icon-light.svg';
import AlbarakaLogoDark from '../../assets/banks/albaraka-logo-dark.svg';
import AlbarakaLogoLight from '../../assets/banks/albaraka-logo.svg';
import AlternatifbankIconDark from '../../assets/banks/alternatifbank-icon-dark.svg';
import AlternatifbankIconLight from '../../assets/banks/alternatifbank-icon-light.svg';
import AlternatifbankLogoDark from '../../assets/banks/alternatifbank-logo-dark.svg';
import AlternatifbankLogoLight from '../../assets/banks/alternatifbank-logo.svg';
import AnadolubankIconDark from '../../assets/banks/anadolubank-icon-dark.svg';
import AnadolubankIconLight from '../../assets/banks/anadolubank-icon-light.svg';
import AnadolubankLogoDark from '../../assets/banks/anadolubank-logo-dark.svg';
import AnadolubankLogoLight from '../../assets/banks/anadolubank-logo.svg';
import CitibankIconDark from '../../assets/banks/citibank-icon-dark.svg';
import CitibankIconLight from '../../assets/banks/citibank-icon-light.svg';
import CitibankLogoDark from '../../assets/banks/citibank-logo-dark.svg';
import CitibankLogoLight from '../../assets/banks/citibank-logo.svg';
import DenizbankIconDark from '../../assets/banks/denizbank-icon-dark.svg';
import DenizbankIconLight from '../../assets/banks/denizbank-icon-light.svg';
import DenizbankLogoDark from '../../assets/banks/denizbank-logo-dark.svg';
import DenizbankLogoLight from '../../assets/banks/denizbank-logo.svg';
import FibabankaIconDark from '../../assets/banks/fibabanka-icon-dark.png';
import FibabankaIconLight from '../../assets/banks/fibabanka-icon-light.png';
import FibabankaLogoDark from '../../assets/banks/fibabanka-logo-dark.png';
import FibabankaLogoLight from '../../assets/banks/fibabanka-logo.png';
import GarantibbvaIconDark from '../../assets/banks/garantibbva-icon-dark.svg';
import GarantibbvaIconLight from '../../assets/banks/garantibbva-icon-light.svg';
import GarantibbvaLogoDark from '../../assets/banks/garantibbva-logo-dark.svg';
import GarantibbvaLogoLight from '../../assets/banks/garantibbva-logo.svg';
import HalkbankIconDark from '../../assets/banks/halkbank-icon-dark.svg';
import HalkbankIconLight from '../../assets/banks/halkbank-icon-light.svg';
import HalkbankLogoDark from '../../assets/banks/halkbank-logo-dark.svg';
import HalkbankLogoLight from '../../assets/banks/halkbank-logo.svg';
import HsbcIconDark from '../../assets/banks/hsbc-icon-dark.svg';
import HsbcIconLight from '../../assets/banks/hsbc-icon-light.svg';
import HsbcLogoDark from '../../assets/banks/hsbc-logo-dark.svg';
import HsbcLogoLight from '../../assets/banks/hsbc-logo.svg';
import IcbcIconDark from '../../assets/banks/icbc-icon-dark.svg';
import IcbcIconLight from '../../assets/banks/icbc-icon-light.svg';
import IcbcLogoDark from '../../assets/banks/icbc-logo-dark.svg';
import IcbcLogoLight from '../../assets/banks/icbc-logo.svg';
import IngIconDark from '../../assets/banks/ing-icon-dark.svg';
import IngIconLight from '../../assets/banks/ing-icon-light.svg';
import IngLogoDark from '../../assets/banks/ing-logo-dark.svg';
import IngLogoLight from '../../assets/banks/ing-logo.svg';
import IsbankasiIconDark from '../../assets/banks/isbankasi-icon-dark.svg';
import IsbankasiIconLight from '../../assets/banks/isbankasi-icon-light.svg';
import IsbankasiLogoDark from '../../assets/banks/isbankasi-logo-dark.svg';
import IsbankasiLogoLight from '../../assets/banks/isbankasi-logo.svg';
import KuveytturkIconDark from '../../assets/banks/kuveytturk-icon-dark.svg';
import KuveytturkIconLight from '../../assets/banks/kuveytturk-icon-light.svg';
import KuveytturkLogoDark from '../../assets/banks/kuveytturk-logo-dark.svg';
import KuveytturkLogoLight from '../../assets/banks/kuveytturk-logo.svg';
import OdeabankIconDark from '../../assets/banks/odeabank-icon-dark.svg';
import OdeabankIconLight from '../../assets/banks/odeabank-icon-light.svg';
import OdeabankLogoDark from '../../assets/banks/odeabank-logo-dark.svg';
import OdeabankLogoLight from '../../assets/banks/odeabank-logo.svg';
import QnbIconDark from '../../assets/banks/qnb-icon-dark.svg';
import QnbIconLight from '../../assets/banks/qnb-icon-light.svg';
import QnbLogoDark from '../../assets/banks/qnb-logo-dark.svg';
import QnbLogoLight from '../../assets/banks/qnb-logo.svg';
import SekerbankIconDark from '../../assets/banks/sekerbank-icon-dark.svg';
import SekerbankIconLight from '../../assets/banks/sekerbank-icon-light.svg';
import SekerbankLogoDark from '../../assets/banks/sekerbank-logo-dark.svg';
import SekerbankLogoLight from '../../assets/banks/sekerbank-logo.svg';
import TebIconDark from '../../assets/banks/teb-icon-dark.svg';
import TebIconLight from '../../assets/banks/teb-icon-light.svg';
import TebLogoDark from '../../assets/banks/teb-logo-dark.svg';
import TebLogoLight from '../../assets/banks/teb-logo.svg';
import TurkishbankIconDark from '../../assets/banks/turkishbank-icon-dark.svg';
import TurkishbankIconLight from '../../assets/banks/turkishbank-icon-light.svg';
import TurkishbankLogoDark from '../../assets/banks/turkishbank-logo-dark.svg';
import TurkishbankLogoLight from '../../assets/banks/turkishbank-logo.svg';
import TurkiyefinansIconDark from '../../assets/banks/turkiyefinans-icon-dark.svg';
import TurkiyefinansIconLight from '../../assets/banks/turkiyefinans-icon-light.svg';
import TurkiyefinansLogoDark from '../../assets/banks/turkiyefinans-logo-dark.svg';
import TurkiyefinansLogoLight from '../../assets/banks/turkiyefinans-logo.svg';
import VakifbankIconDark from '../../assets/banks/vakifbank-icon-dark.svg';
import VakifbankIconLight from '../../assets/banks/vakifbank-icon-light.svg';
import VakifbankLogoDark from '../../assets/banks/vakifbank-logo-dark.svg';
import VakifbankLogoLight from '../../assets/banks/vakifbank-logo.svg';
import YapikrediIconDark from '../../assets/banks/yapikredi-icon-dark.svg';
import YapikrediIconLight from '../../assets/banks/yapikredi-icon-light.svg';
import YapikrediLogoDark from '../../assets/banks/yapikredi-logo-dark.svg';
import YapikrediLogoLight from '../../assets/banks/yapikredi-logo.svg';
import ZiraatIconDark from '../../assets/banks/ziraat-icon-dark.svg';
import ZiraatIconLight from '../../assets/banks/ziraat-icon-light.svg';
import ZiraatLogoDark from '../../assets/banks/ziraat-logo-dark.svg';
import ZiraatLogoLight from '../../assets/banks/ziraat-logo.svg';
import { BaseComponentType, BaseSize, TextColorType } from '../types';

export type BankNames =
  | 'akbank-icon-dark'
  | '00046-icon-dark'
  | 'akbank-icon-light'
  | '00046-icon-light'
  | 'akbank-logo-light'
  | '00046-logo-light'
  | 'akbank-logo-dark'
  | '00046-logo-dark'
  | 'aktifbank-icon-dark'
  | '00143-icon-dark'
  | 'aktifbank-icon-light'
  | '00143-icon-light'
  | 'aktifbank-logo-light'
  | '00143-logo-light'
  | 'aktifbank-logo-dark'
  | '00143-logo-dark'
  | 'albaraka-icon-dark'
  | '00203-icon-dark'
  | 'albaraka-icon-light'
  | '00203-icon-light'
  | 'albaraka-logo-light'
  | '00203-logo-light'
  | 'albaraka-logo-dark'
  | '00203-logo-dark'
  | 'alternatifbank-icon-dark'
  | '00124-icon-dark'
  | 'alternatifbank-icon-light'
  | '00124-icon-light'
  | 'alternatifbank-logo-light'
  | '00124-logo-light'
  | 'alternatifbank-logo-dark'
  | '00124-logo-dark'
  | 'anadolubank-icon-dark'
  | '00135-icon-dark'
  | 'anadolubank-icon-light'
  | '00135-icon-light'
  | 'anadolubank-logo-light'
  | '00135-logo-light'
  | 'anadolubank-logo-dark'
  | '00135-logo-dark'
  | 'citibank-icon-dark'
  | '00092-icon-dark'
  | 'citibank-icon-light'
  | '00092-icon-light'
  | 'citibank-logo-light'
  | '00092-logo-light'
  | 'citibank-logo-dark'
  | '00092-logo-dark'
  | 'denizbank-icon-dark'
  | '00134-icon-dark'
  | 'denizbank-icon-light'
  | '00134-icon-light'
  | 'denizbank-logo-light'
  | '00134-logo-light'
  | 'denizbank-logo-dark'
  | '00134-logo-dark'
  | 'fibabanka-icon-dark'
  | '00103-icon-dark'
  | 'fibabanka-icon-light'
  | '00103-icon-light'
  | 'fibabanka-logo-light'
  | '00103-logo-light'
  | 'fibabanka-logo-dark'
  | '00103-logo-dark'
  | 'garantibbva-icon-dark'
  | '00062-icon-dark'
  | 'garantibbva-icon-light'
  | '00062-icon-light'
  | 'garantibbva-logo-light'
  | '00062-logo-light'
  | 'garantibbva-logo-dark'
  | '00062-logo-dark'
  | 'halkbank-icon-dark'
  | '00012-icon-dark'
  | 'halkbank-icon-light'
  | '00012-icon-light'
  | 'halkbank-logo-light'
  | '00012-logo-light'
  | 'halkbank-logo-dark'
  | '00012-logo-dark'
  | 'hsbc-icon-dark'
  | '00123-icon-dark'
  | 'hsbc-icon-light'
  | '00123-icon-light'
  | 'hsbc-logo-light'
  | '00123-logo-light'
  | 'hsbc-logo-dark'
  | '00123-logo-dark'
  | 'icbc-icon-dark'
  | '00109-icon-dark'
  | 'icbc-icon-light'
  | '00109-icon-light'
  | 'icbc-logo-light'
  | '00109-logo-light'
  | 'icbc-logo-dark'
  | '00109-logo-dark'
  | 'ing-icon-dark'
  | '00099-icon-dark'
  | 'ing-icon-light'
  | '00099-icon-light'
  | 'ing-logo-light'
  | '00099-logo-light'
  | 'ing-logo-dark'
  | '00099-logo-dark'
  | 'isbankasi-icon-dark'
  | '00064-icon-dark'
  | 'isbankasi-icon-light'
  | '00064-icon-light'
  | 'isbankasi-logo-light'
  | '00064-logo-light'
  | 'isbankasi-logo-dark'
  | '00064-logo-dark'
  | 'kuveytturk-icon-dark'
  | '00205-icon-dark'
  | 'kuveytturk-icon-light'
  | '00205-icon-light'
  | 'kuveytturk-logo-light'
  | '00205-logo-light'
  | 'kuveytturk-logo-dark'
  | '00205-logo-dark'
  | 'odeabank-icon-dark'
  | '00146-icon-dark'
  | 'odeabank-icon-light'
  | '00146-icon-light'
  | 'odeabank-logo-light'
  | '00146-logo-light'
  | 'odeabank-logo-dark'
  | '00146-logo-dark'
  | 'qnb-icon-dark'
  | '00111-icon-dark'
  | 'qnb-icon-light'
  | '00111-icon-light'
  | 'qnb-logo-light'
  | '00111-logo-light'
  | 'qnb-logo-dark'
  | '00111-logo-dark'
  | 'sekerbank-icon-dark'
  | '00059-icon-dark'
  | 'sekerbank-icon-light'
  | '00059-icon-light'
  | 'sekerbank-logo-light'
  | '00059-logo-light'
  | 'sekerbank-logo-dark'
  | '00059-logo-dark'
  | 'teb-icon-dark'
  | '00032-icon-dark'
  | 'teb-icon-light'
  | '00032-icon-light'
  | 'teb-logo-light'
  | '00032-logo-light'
  | 'teb-logo-dark'
  | '00032-logo-dark'
  | 'turkishbank-icon-dark'
  | '00096-icon-dark'
  | 'turkishbank-icon-light'
  | '00096-icon-light'
  | 'turkishbank-logo-light'
  | '00096-logo-light'
  | 'turkishbank-logo-dark'
  | '00096-logo-dark'
  | 'turkiyefinans-icon-dark'
  | '00206-icon-dark'
  | 'turkiyefinans-icon-light'
  | '00206-icon-light'
  | 'turkiyefinans-logo-light'
  | '00206-logo-light'
  | 'turkiyefinans-logo-dark'
  | '00206-logo-dark'
  | 'vakifbank-icon-dark'
  | '00015-icon-dark'
  | 'vakifbank-icon-light'
  | '00015-icon-light'
  | 'vakifbank-logo-light'
  | '00015-logo-light'
  | 'vakifbank-logo-dark'
  | '00015-logo-dark'
  | 'yapikredi-icon-dark'
  | '00067-icon-dark'
  | 'yapikredi-icon-light'
  | '00067-icon-light'
  | 'yapikredi-logo-light'
  | '00067-logo-light'
  | 'yapikredi-logo-dark'
  | '00067-logo-dark'
  | 'ziraat-icon-dark'
  | '00010-icon-dark'
  | 'ziraat-icon-light'
  | '00010-icon-light'
  | 'ziraat-logo-light'
  | '00010-logo-light'
  | 'ziraat-logo-dark'
  | '00010-logo-dark';

export type BankIconProps = Omit<React.ComponentProps<'svg'> & React.ComponentProps<'img'>, ''> &
  BaseComponentType & {
    /** Color of the icon */
    color?: TextColorType;
    /** Fill color of the icon */
    fill?: TextColorType;
    /** Size of the icon */
    size?: BaseSize;
    /** Width of the icon */
    width?: BaseSize;
    /** Height of the icon */
    height?: BaseSize;
    /** Type of the bank icon */
    type: BankNames;
  };

/**
 * Bank icon component that renders different bank icons based on the specified type.
 *
 * @example
 * // Example usage of the IconBank component
 * <IconBank type="akbank-icon-dark" size="lg" />
 */
export function IconBank({ color, fill, size, width, height, type, testId, ...rest }: BankIconProps) {
  const classes = classNames({
    [`tw-h-${size ?? height}`]: size ?? height,
    [`tw-w-${size ?? width}`]: size ?? width,
    [`tw-text-${color}`]: color,
    [`tw-fill-${fill}`]: fill,
  });

  const props = { ...rest, ['data-testid']: testId ?? 'icon-bank' };

  switch (type) {
    case 'akbank-icon-dark':
    case '00046-icon-dark':
      return <AkbankIconDark className={classes} {...props} />;

    case 'akbank-icon-light':
    case '00046-icon-light':
      return <AkbankIconLight className={classes} {...props} />;

    case 'akbank-logo-light':
    case '00046-logo-light':
      return <AkbankLogoLight className={classes} {...props} />;

    case 'akbank-logo-dark':
    case '00046-logo-dark':
      return <AkbankLogoDark className={classes} {...props} />;

    case 'aktifbank-icon-dark':
    case '00143-icon-dark':
      return <AktifbankIconDark className={classes} {...props} />;

    case 'aktifbank-icon-light':
    case '00143-icon-light':
      return <AktifbankIconLight className={classes} {...props} />;

    case 'aktifbank-logo-light':
    case '00143-logo-light':
      return <AktifbankLogoLight className={classes} {...props} />;

    case 'aktifbank-logo-dark':
    case '00143-logo-dark':
      return <AktifbankLogoDark className={classes} {...props} />;

    case 'albaraka-icon-dark':
    case '00203-icon-dark':
      return <AlbarakaIconDark className={classes} {...props} />;

    case 'albaraka-icon-light':
    case '00203-icon-light':
      return <AlbarakaIconLight className={classes} {...props} />;

    case 'albaraka-logo-light':
    case '00203-logo-light':
      return <AlbarakaLogoLight className={classes} {...props} />;

    case 'albaraka-logo-dark':
    case '00203-logo-dark':
      return <AlbarakaLogoDark className={classes} {...props} />;

    case 'alternatifbank-icon-dark':
    case '00124-icon-dark':
      return <AlternatifbankIconDark className={classes} {...props} />;

    case '00124-icon-light':
    case 'alternatifbank-icon-light':
      return <AlternatifbankIconLight className={classes} {...props} />;

    case 'alternatifbank-logo-light':
    case '00124-logo-light':
      return <AlternatifbankLogoLight className={classes} {...props} />;

    case 'alternatifbank-logo-dark':
    case '00124-logo-dark':
      return <AlternatifbankLogoDark className={classes} {...props} />;

    case 'anadolubank-icon-dark':
    case '00135-icon-dark':
      return <AnadolubankIconDark className={classes} {...props} />;

    case 'anadolubank-icon-light':
    case '00135-icon-light':
      return <AnadolubankIconLight className={classes} {...props} />;

    case 'anadolubank-logo-light':
    case '00135-logo-light':
      return <AnadolubankLogoLight className={classes} {...props} />;

    case 'anadolubank-logo-dark':
    case '00135-logo-dark':
      return <AnadolubankLogoDark className={classes} {...props} />;

    case 'citibank-icon-dark':
    case '00092-icon-dark':
      return <CitibankIconDark className={classes} {...props} />;

    case 'citibank-icon-light':
    case '00092-icon-light':
      return <CitibankIconLight className={classes} {...props} />;

    case 'citibank-logo-light':
    case '00092-logo-light':
      return <CitibankLogoLight className={classes} {...props} />;

    case 'citibank-logo-dark':
    case '00092-logo-dark':
      return <CitibankLogoDark className={classes} {...props} />;

    case 'denizbank-icon-dark':
    case '00134-icon-dark':
      return <DenizbankIconDark className={classes} {...props} />;

    case 'denizbank-icon-light':
    case '00134-icon-light':
      return <DenizbankIconLight className={classes} {...props} />;

    case 'denizbank-logo-light':
    case '00134-logo-light':
      return <DenizbankLogoLight className={classes} {...props} />;

    case 'denizbank-logo-dark':
    case '00134-logo-dark':
      return <DenizbankLogoDark className={classes} {...props} />;

    case 'fibabanka-icon-dark':
    case '00103-icon-dark':
      return <img width={32} height={32} src={FibabankaIconDark} className={classes} {...props} />;

    case 'fibabanka-icon-light':
    case '00103-icon-light':
      return <img width={32} height={32} src={FibabankaIconLight} className={classes} {...props} />;

    case 'fibabanka-logo-light':
    case '00103-logo-light':
      return <img width={122.5} height={32} src={FibabankaLogoLight} className={classes} {...props} />;

    case 'fibabanka-logo-dark':
    case '00103-logo-dark':
      return <img width={122.5} height={32} src={FibabankaLogoDark} className={classes} {...props} />;

    case 'garantibbva-icon-dark':
    case '00062-icon-dark':
      return <GarantibbvaIconDark className={classes} {...props} />;

    case 'garantibbva-icon-light':
    case '00062-icon-light':
      return <GarantibbvaIconLight className={classes} {...props} />;

    case 'garantibbva-logo-light':
    case '00062-logo-light':
      return <GarantibbvaLogoLight className={classes} {...props} />;

    case 'garantibbva-logo-dark':
    case '00062-logo-dark':
      return <GarantibbvaLogoDark className={classes} {...props} />;

    case 'halkbank-icon-dark':
    case '00012-icon-dark':
      return <HalkbankIconDark className={classes} {...props} />;

    case 'halkbank-icon-light':
    case '00012-icon-light':
      return <HalkbankIconLight className={classes} {...props} />;

    case 'halkbank-logo-light':
    case '00012-logo-light':
      return <HalkbankLogoLight className={classes} {...props} />;

    case 'halkbank-logo-dark':
    case '00012-logo-dark':
      return <HalkbankLogoDark className={classes} {...props} />;

    case 'hsbc-icon-dark':
    case '00123-icon-dark':
      return <HsbcIconDark className={classes} {...props} />;

    case 'hsbc-icon-light':
    case '00123-icon-light':
      return <HsbcIconLight className={classes} {...props} />;

    case 'hsbc-logo-light':
    case '00123-logo-light':
      return <HsbcLogoLight className={classes} {...props} />;

    case 'hsbc-logo-dark':
    case '00123-logo-dark':
      return <HsbcLogoDark className={classes} {...props} />;

    case 'icbc-icon-dark':
    case '00109-icon-dark':
      return <IcbcIconDark className={classes} {...props} />;

    case 'icbc-icon-light':
    case '00109-icon-light':
      return <IcbcIconLight className={classes} {...props} />;

    case 'icbc-logo-light':
    case '00109-logo-light':
      return <IcbcLogoLight className={classes} {...props} />;

    case 'icbc-logo-dark':
    case '00109-logo-dark':
      return <IcbcLogoDark className={classes} {...props} />;

    case 'ing-icon-dark':
    case '00099-icon-dark':
      return <IngIconDark className={classes} {...props} />;

    case 'ing-icon-light':
    case '00099-icon-light':
      return <IngIconLight className={classes} {...props} />;

    case 'ing-logo-light':
    case '00099-logo-light':
      return <IngLogoLight className={classes} {...props} />;

    case 'ing-logo-dark':
    case '00099-logo-dark':
      return <IngLogoDark className={classes} {...props} />;

    case 'isbankasi-icon-dark':
    case '00064-icon-dark':
      return <IsbankasiIconDark className={classes} {...props} />;

    case 'isbankasi-icon-light':
    case '00064-icon-light':
      return <IsbankasiIconLight className={classes} {...props} />;

    case 'isbankasi-logo-light':
    case '00064-logo-light':
      return <IsbankasiLogoLight className={classes} {...props} />;

    case 'isbankasi-logo-dark':
    case '00064-logo-dark':
      return <IsbankasiLogoDark className={classes} {...props} />;

    case 'kuveytturk-icon-dark':
    case '00205-icon-dark':
      return <KuveytturkIconDark className={classes} {...props} />;

    case 'kuveytturk-icon-light':
    case '00205-icon-light':
      return <KuveytturkIconLight className={classes} {...props} />;

    case 'kuveytturk-logo-light':
    case '00205-logo-light':
      return <KuveytturkLogoLight className={classes} {...props} />;

    case 'kuveytturk-logo-dark':
    case '00205-logo-dark':
      return <KuveytturkLogoDark className={classes} {...props} />;

    case 'odeabank-icon-dark':
    case '00146-icon-dark':
      return <OdeabankIconDark className={classes} {...props} />;

    case 'odeabank-icon-light':
    case '00146-icon-light':
      return <OdeabankIconLight className={classes} {...props} />;

    case 'odeabank-logo-light':
    case '00146-logo-light':
      return <OdeabankLogoLight className={classes} {...props} />;

    case 'odeabank-logo-dark':
    case '00146-logo-dark':
      return <OdeabankLogoDark className={classes} {...props} />;

    case 'qnb-icon-dark':
    case '00111-icon-dark':
      return <QnbIconDark className={classes} {...props} />;

    case 'qnb-icon-light':
    case '00111-icon-light':
      return <QnbIconLight className={classes} {...props} />;

    case 'qnb-logo-light':
    case '00111-logo-light':
      return <QnbLogoLight className={classes} {...props} />;

    case 'qnb-logo-dark':
    case '00111-logo-dark':
      return <QnbLogoDark className={classes} {...props} />;

    case 'sekerbank-icon-dark':
    case '00059-icon-dark':
      return <SekerbankIconDark className={classes} {...props} />;

    case 'sekerbank-icon-light':
    case '00059-icon-light':
      return <SekerbankIconLight className={classes} {...props} />;

    case 'sekerbank-logo-light':
    case '00059-logo-light':
      return <SekerbankLogoLight className={classes} {...props} />;

    case 'sekerbank-logo-dark':
    case '00059-logo-dark':
      return <SekerbankLogoDark className={classes} {...props} />;

    case 'teb-icon-dark':
    case '00032-icon-dark':
      return <TebIconDark className={classes} {...props} />;

    case 'teb-icon-light':
    case '00032-icon-light':
      return <TebIconLight className={classes} {...props} />;

    case 'teb-logo-light':
    case '00032-logo-light':
      return <TebLogoLight className={classes} {...props} />;

    case 'teb-logo-dark':
    case '00032-logo-dark':
      return <TebLogoDark className={classes} {...props} />;

    case 'turkishbank-icon-dark':
    case '00096-icon-dark':
      return <TurkishbankIconDark className={classes} {...props} />;

    case 'turkishbank-icon-light':
    case '00096-icon-light':
      return <TurkishbankIconLight className={classes} {...props} />;

    case 'turkishbank-logo-light':
    case '00096-logo-light':
      return <TurkishbankLogoLight className={classes} {...props} />;

    case 'turkishbank-logo-dark':
    case '00096-logo-dark':
      return <TurkishbankLogoDark className={classes} {...props} />;

    case 'turkiyefinans-icon-dark':
    case '00206-icon-dark':
      return <TurkiyefinansIconDark className={classes} {...props} />;

    case 'turkiyefinans-icon-light':
    case '00206-icon-light':
      return <TurkiyefinansIconLight className={classes} {...props} />;

    case 'turkiyefinans-logo-light':
    case '00206-logo-light':
      return <TurkiyefinansLogoLight className={classes} {...props} />;

    case 'turkiyefinans-logo-dark':
    case '00206-logo-dark':
      return <TurkiyefinansLogoDark className={classes} {...props} />;

    case 'vakifbank-icon-dark':
    case '00015-icon-dark':
      return <VakifbankIconDark className={classes} {...props} />;

    case 'vakifbank-icon-light':
    case '00015-icon-light':
      return <VakifbankIconLight className={classes} {...props} />;

    case 'vakifbank-logo-light':
    case '00015-logo-light':
      return <VakifbankLogoLight className={classes} {...props} />;

    case 'vakifbank-logo-dark':
    case '00015-logo-dark':
      return <VakifbankLogoDark className={classes} {...props} />;

    case 'yapikredi-icon-dark':
    case '00067-icon-dark':
      return <YapikrediIconDark className={classes} {...props} />;

    case 'yapikredi-icon-light':
    case '00067-icon-light':
      return <YapikrediIconLight className={classes} {...props} />;

    case 'yapikredi-logo-light':
    case '00067-logo-light':
      return <YapikrediLogoLight className={classes} {...props} />;

    case 'yapikredi-logo-dark':
    case '00067-logo-dark':
      return <YapikrediLogoDark className={classes} {...props} />;

    case 'ziraat-icon-dark':
    case '00010-icon-dark':
      return <ZiraatIconDark className={classes} {...props} />;
    case 'ziraat-icon-light':
    case '00010-icon-light':
      return <ZiraatIconLight className={classes} {...props} />;

    case 'ziraat-logo-light':
    case '00010-logo-light':
      return <ZiraatLogoLight className={classes} {...props} />;
    case 'ziraat-logo-dark':
    case '00010-logo-dark':
      return <ZiraatLogoDark className={classes} {...props} />;
  }

  return null;
}
