import AcademicCapOutline from '../../../assets/icon/outline/academic-cap.svg';
import AdjustmentsHorizontalOutline from '../../../assets/icon/outline/adjustments-horizontal.svg';
import ArchiveBoxXMarkOutline from '../../../assets/icon/outline/archive-box-x-mark.svg';
import ArrowDownLeftOutline from '../../../assets/icon/outline/arrow-down-left.svg';
import ArrowDownRightOutline from '../../../assets/icon/outline/arrow-down-right.svg';
import ArrowDownTrayOutline from '../../../assets/icon/outline/arrow-down-tray.svg';
import ArrowDownOutline from '../../../assets/icon/outline/arrow-down.svg';
import ArrowLeftOutline from '../../../assets/icon/outline/arrow-left.svg';
import ArrowPathOutline from '../../../assets/icon/outline/arrow-path.svg';
import ArrowRightOnRectangleOutline from '../../../assets/icon/outline/arrow-right-on-rectangle.svg';
import ArrowRightOutline from '../../../assets/icon/outline/arrow-right.svg';
import ArrowTopRightOnSquareOutline from '../../../assets/icon/outline/arrow-top-right-on-square.svg';
import ArrowUTurnLeftOutline from '../../../assets/icon/outline/arrow-u-turn-left.svg';
import ArrowUpOnSquareOutline from '../../../assets/icon/outline/arrow-up-on-square.svg';
import ArrowUpRightOutline from '../../../assets/icon/outline/arrow-up-right.svg';
import ArrowUpTrayOutline from '../../../assets/icon/outline/arrow-up-tray.svg';
import ArrowUpOutline from '../../../assets/icon/outline/arrow-up.svg';
import ArrowsRightLeftOutline from '../../../assets/icon/outline/arrows-right-left.svg';
import ArrowsUpDownOutline from '../../../assets/icon/outline/arrows-up-down.svg';
import BanknotesOutline from '../../../assets/icon/outline/banknotes.svg';
import Bars3Outline from '../../../assets/icon/outline/bars-3.svg';
import Battery50Outline from '../../../assets/icon/outline/battery-50.svg';
import BeakerOutline from '../../../assets/icon/outline/beaker.svg';
import BellSnoozeOutline from '../../../assets/icon/outline/bell-snooze.svg';
import BellOutline from '../../../assets/icon/outline/bell.svg';
import BookOpenOutline from '../../../assets/icon/outline/book-open.svg';
import BuildingLibraryInsideOutline from '../../../assets/icon/outline/building-library-inside.svg';
import BuildingLibraryOutline from '../../../assets/icon/outline/building-library.svg';
import BuildingOffice2Outline from '../../../assets/icon/outline/building-office-2.svg';
import CalendarOutline from '../../../assets/icon/outline/calendar.svg';
import CameraOutline from '../../../assets/icon/outline/camera.svg';
import ChartPieOutline from '../../../assets/icon/outline/chart-pie.svg';
import CheckBadgeOutline from '../../../assets/icon/outline/check-badge.svg';
import CheckCircleOutline from '../../../assets/icon/outline/check-circle.svg';
import CheckOutline from '../../../assets/icon/outline/check.svg';
import CircleStackSlashOutline from '../../../assets/icon/outline/circle-stack-slash.svg';
import CircleStackOutline from '../../../assets/icon/outline/circle-stack.svg';
import ClockOutline from '../../../assets/icon/outline/clock.svg';
import Cog6ToothOutline from '../../../assets/icon/outline/cog-6-tooth.svg';
import ConvertOutline from '../../../assets/icon/outline/convert.svg';
import CursorArrowRaysOutline from '../../../assets/icon/outline/cursor-arrow-rays.svg';
import DevicePhoneMobileOutline from '../../../assets/icon/outline/device-phone-mobile.svg';
import DocumentArrowDownOutline from '../../../assets/icon/outline/document-arrow-down.svg';
import DocumentCheckOutline from '../../../assets/icon/outline/document-check.svg';
import DocumentDuplicateOutline from '../../../assets/icon/outline/document-duplicate.svg';
import DocumentTextOutline from '../../../assets/icon/outline/document-text.svg';
import EllipsisHorizontalOutline from '../../../assets/icon/outline/ellipsis-horizontal.svg';
import EllipsisVerticalOutline from '../../../assets/icon/outline/ellipsis-vertical.svg';
import EnvelopeOutline from '../../../assets/icon/outline/envelope.svg';
import ExclamationCircleOutline from '../../../assets/icon/outline/exclaimation-circle.svg';
import EyeSlashOutline from '../../../assets/icon/outline/eye-slash.svg';
import EyeOutline from '../../../assets/icon/outline/eye.svg';
import FacebookOutline from '../../../assets/icon/outline/facebook.svg';
import FlagOutline from '../../../assets/icon/outline/flag.svg';
import GiftTopOutline from '../../../assets/icon/outline/gift-top.svg';
import GiftOutline from '../../../assets/icon/outline/gift.svg';
import GithubOutlineOutline from '../../../assets/icon/outline/github-outline.svg';
import GlobeAltOutline from '../../../assets/icon/outline/globe-alt.svg';
import GlobeAmericasOutline from '../../../assets/icon/outline/globe-americas.svg';
import HandCoinsOutline from '../../../assets/icon/outline/hand-coins.svg';
import HandThumbUpOutline from '../../../assets/icon/outline/hand-thumb-up.svg';
import HashtagOutline from '../../../assets/icon/outline/hashtag.svg';
import HistoryOutline from '../../../assets/icon/outline/history.svg';
import HomeOutline from '../../../assets/icon/outline/home.svg';
import IdentificationOutline from '../../../assets/icon/outline/identification.svg';
import InformationCircleOutline from '../../../assets/icon/outline/information-circle.svg';
import KeyOutline from '../../../assets/icon/outline/key.svg';
import LightBulbOutline from '../../../assets/icon/outline/light-bulb.svg';
import LightningBoltOutline from '../../../assets/icon/outline/lightning-bolt.svg';
import LinkOutline from '../../../assets/icon/outline/link.svg';
import LinkedinOutline from '../../../assets/icon/outline/linkedin.svg';
import LockClosedOutline from '../../../assets/icon/outline/lock-closed.svg';
import MagnifyingGlassMinusOutline from '../../../assets/icon/outline/magnifying-glass-minus.svg';
import MagnifyingGlassPlusOutline from '../../../assets/icon/outline/magnifying-glass-plus.svg';
import MagnifyingGlassOutline from '../../../assets/icon/outline/magnifying-glass.svg';
import MapPin1Outline from '../../../assets/icon/outline/map-pin-1.svg';
import MapPinOutline from '../../../assets/icon/outline/map-pin.svg';
import MoonOutline from '../../../assets/icon/outline/moon.svg';
import PaperAirplaneOutline from '../../../assets/icon/outline/paper-airplane.svg';
import PassportOutline from '../../../assets/icon/outline/passport.svg';
import PencilSquareOutline from '../../../assets/icon/outline/pencil-square.svg';
import PhotoOutline from '../../../assets/icon/outline/photo.svg';
import PlusCircleOutline from '../../../assets/icon/outline/plus-circle.svg';
import PlusSmallOutline from '../../../assets/icon/outline/plus-small.svg';
import QrCodeOutline from '../../../assets/icon/outline/qr-code.svg';
import QuestionMarkCircleOutline from '../../../assets/icon/outline/question-mark-circle.svg';
import ReceiptPercentOutline from '../../../assets/icon/outline/receipt-percent.svg';
import RocketLaunchOutline from '../../../assets/icon/outline/rocket-launch.svg';
import ShieldCheckOutline from '../../../assets/icon/outline/shield-check.svg';
import SparklesOutline from '../../../assets/icon/outline/sparkles.svg';
import Square2StackOutline from '../../../assets/icon/outline/square-2-stack.svg';
import StarOutline from '../../../assets/icon/outline/star.svg';
import StopCircleOutline from '../../../assets/icon/outline/stop-circle.svg';
import SunOutline from '../../../assets/icon/outline/sun.svg';
import SupportOutline from '../../../assets/icon/outline/support.svg';
import TelegramOutline from '../../../assets/icon/outline/telegram.svg';
import TokenArrowDownOutline from '../../../assets/icon/outline/token-arrow-down.svg';
import TokenArrowUpOutline from '../../../assets/icon/outline/token-arrow-up.svg';
import TrashOutline from '../../../assets/icon/outline/trash.svg';
import TrendingUpOutline from '../../../assets/icon/outline/trending-up.svg';
import UserCircleOutline from '../../../assets/icon/outline/user-circle.svg';
import UserMinusOutline from '../../../assets/icon/outline/user-minus.svg';
import UserPlusOutline from '../../../assets/icon/outline/user-plus.svg';
import UserOutline from '../../../assets/icon/outline/user.svg';
import UsersOutline from '../../../assets/icon/outline/users.svg';
import WalletOutline from '../../../assets/icon/outline/wallet.svg';
import WindowOutline from '../../../assets/icon/outline/window.svg';
import XMarkOutline from '../../../assets/icon/outline/x-mark.svg';
//SOLID
import AppStoreSolid from '../../../assets/icon/solid/app-store.svg';
import BanknotesSolid from '../../../assets/icon/solid/banknotes.svg';
import BeakerSolid from '../../../assets/icon/solid/beaker.svg';
import BellSolid from '../../../assets/icon/solid/bell.svg';
import BuildingLibrarySolid from '../../../assets/icon/solid/building-library.svg';
import CameraSolid from '../../../assets/icon/solid/camera.svg';
import ChartPieSolid from '../../../assets/icon/solid/chart-pie.svg';
import CheckBadgeSolid from '../../../assets/icon/solid/check-badge.svg';
import CheckCircleSolid from '../../../assets/icon/solid/check-circle.svg';
import CheckSolid from '../../../assets/icon/solid/check.svg';
import ChevronDownUpSolid from '../../../assets/icon/solid/chevron-down-up.svg';
import ChevronDownSolid from '../../../assets/icon/solid/chevron-down.svg';
import ChevronLeftSolid from '../../../assets/icon/solid/chevron-left.svg';
import ChevronRightSolid from '../../../assets/icon/solid/chevron-right.svg';
import ChevronUpDownSolid from '../../../assets/icon/solid/chevron-up-down.svg';
import ChevronUpSolid from '../../../assets/icon/solid/chevron-up.svg';
import CommandSolid from '../../../assets/icon/solid/command.svg';
import ConvertSolid from '../../../assets/icon/solid/convert.svg';
import DocumentDuplicateSolid from '../../../assets/icon/solid/document-duplicate.svg';
import DocumentTextSolid from '../../../assets/icon/solid/document-text.svg';
import ExclamationCircleSolid from '../../../assets/icon/solid/exclaimation-circle.svg';
import FacebookSolid from '../../../assets/icon/solid/facebook.svg';
import GlobeAltSolid from '../../../assets/icon/solid/globe-alt.svg';
import HandThumbUpSolid from '../../../assets/icon/solid/hand-thumb-up.svg';
import HomeSolid from '../../../assets/icon/solid/home.svg';
import InformationCircleSolid from '../../../assets/icon/solid/information-circle.svg';
import LightningBoltSolid from '../../../assets/icon/solid/lightning-bolt.svg';
import LinkSolid from '../../../assets/icon/solid/link.svg';
import LinkedinSolid from '../../../assets/icon/solid/linkedin.svg';
import LoadingSolid from '../../../assets/icon/solid/loading.svg';
import LockClosedSolid from '../../../assets/icon/solid/lock-closed.svg';
import MagnifyingGlassMinusSolid from '../../../assets/icon/solid/magnifying-glass-minus.svg';
import MagnifyingGlassPlusSolid from '../../../assets/icon/solid/magnifying-glass-plus.svg';
import MapPinSolid from '../../../assets/icon/solid/map-pin.svg';
import MoonSolid from '../../../assets/icon/solid/moon.svg';
import PaperAirplaneSolid from '../../../assets/icon/solid/paper-airplane.svg';
import PhotoSolid from '../../../assets/icon/solid/photo.svg';
import PlayStoreSolid from '../../../assets/icon/solid/play-store.svg';
import QrCodeSolid from '../../../assets/icon/solid/qr-code.svg';
import QuestionMarkCircleSolid from '../../../assets/icon/solid/question-mark-circle.svg';
import SearchSolid from '../../../assets/icon/solid/search.svg';
import Square2StackSolid from '../../../assets/icon/solid/square-2-stack.svg';
import StarSolid from '../../../assets/icon/solid/star.svg';
import SunSolid from '../../../assets/icon/solid/sun.svg';
import SupportSolid from '../../../assets/icon/solid/support.svg';
import TrashSolid from '../../../assets/icon/solid/trash.svg';
import TrendingUpSolid from '../../../assets/icon/solid/trending-up.svg';
import TwitterSolid from '../../../assets/icon/solid/twitter.svg';
import UserPlusSolid from '../../../assets/icon/solid/user-plus.svg';
import UserSolid from '../../../assets/icon/solid/user.svg';
import WalletSolid from '../../../assets/icon/solid/Wallet.svg';
import XCircleSolid from '../../../assets/icon/solid/x-circle.svg';

export const iconComponents: Record<IconType, any> = {
  'o:academic-cap': AcademicCapOutline,
  'o:adjustments-horizontal': AdjustmentsHorizontalOutline,
  'o:archive-box-x-mark': ArchiveBoxXMarkOutline,
  'o:arrow-down': ArrowDownOutline,
  'o:arrow-down-left': ArrowDownLeftOutline,
  'o:arrow-down-right': ArrowDownRightOutline,
  'o:arrow-down-tray': ArrowDownTrayOutline,
  'o:arrow-left': ArrowLeftOutline,
  'o:arrow-path': ArrowPathOutline,
  'o:arrow-right': ArrowRightOutline,
  'o:arrow-right-on-rectangle': ArrowRightOnRectangleOutline,
  'o:arrow-top-right-on-square': ArrowTopRightOnSquareOutline,
  'o:arrow-up': ArrowUpOutline,
  'o:arrow-up-on-square': ArrowUpOnSquareOutline,
  'o:arrow-up-right': ArrowUpRightOutline,
  'o:arrow-up-tray': ArrowUpTrayOutline,
  'o:arrow-u-turn-left': ArrowUTurnLeftOutline,
  'o:arrows-right-left': ArrowsRightLeftOutline,
  'o:arrows-up-down': ArrowsUpDownOutline,
  'o:banknotes': BanknotesOutline,
  'o:battery-50': Battery50Outline,
  'o:beaker': BeakerOutline,
  'o:bell': BellOutline,
  'o:bell-snooze': BellSnoozeOutline,
  'o:book-open': BookOpenOutline,
  'o:building-library': BuildingLibraryOutline,
  'o:building-library-inside': BuildingLibraryInsideOutline,
  'o:calendar': CalendarOutline,
  'o:camera': CameraOutline,
  'o:chart-pie': ChartPieOutline,
  'o:check-badge': CheckBadgeOutline,
  'o:check-circle': CheckCircleOutline,
  'o:circle-stack': CircleStackOutline,
  'o:circle-stack-slash': CircleStackSlashOutline,
  'o:clock': ClockOutline,
  'o:cog-6-tooth': Cog6ToothOutline,
  'o:convert': ConvertOutline,
  'o:cursor-arrow-rays': CursorArrowRaysOutline,
  'o:device-phone-mobile': DevicePhoneMobileOutline,
  'o:document-arrow-down': DocumentArrowDownOutline,
  'o:document-check': DocumentCheckOutline,
  'o:document-duplicate': DocumentDuplicateOutline,
  'o:document-text': DocumentTextOutline,
  'o:ellipsis-horizontal': EllipsisHorizontalOutline,
  'o:ellipsis-vertical': EllipsisVerticalOutline,
  'o:envelope': EnvelopeOutline,
  'o:exclaimation-circle': ExclamationCircleOutline,
  'o:eye': EyeOutline,
  'o:eye-slash': EyeSlashOutline,
  'o:facebook': FacebookOutline,
  'o:flag': FlagOutline,
  'o:gift': GiftOutline,
  'o:gift-top': GiftTopOutline,
  'o:github-outline': GithubOutlineOutline,
  'o:globe-alt': GlobeAltOutline,
  'o:globe-americas': GlobeAmericasOutline,
  'o:hand-coins': HandCoinsOutline,
  'o:hand-thumb-up': HandThumbUpOutline,
  'o:home': HomeOutline,
  'o:identification': IdentificationOutline,
  'o:information-circle': InformationCircleOutline,
  'o:key': KeyOutline,
  'o:light-bulb': LightBulbOutline,
  'o:lightning-bolt': LightningBoltOutline,
  'o:link': LinkOutline,
  'o:linkedin': LinkedinOutline,
  'o:lock-closed': LockClosedOutline,
  'o:magnifying-glass': MagnifyingGlassOutline,
  'o:magnifying-glass-minus': MagnifyingGlassMinusOutline,
  'o:magnifying-glass-plus': MagnifyingGlassPlusOutline,
  'o:map-pin': MapPinOutline,
  'o:map-pin-1': MapPin1Outline,
  'o:moon': MoonOutline,
  'o:paper-airplane': PaperAirplaneOutline,
  'o:passport': PassportOutline,
  'o:pencil-square': PencilSquareOutline,
  'o:photo': PhotoOutline,
  'o:plus-circle': PlusCircleOutline,
  'o:plus-small': PlusSmallOutline,
  'o:qr-code': QrCodeOutline,
  'o:question-mark-circle': QuestionMarkCircleOutline,
  'o:receipt-percent': ReceiptPercentOutline,
  'o:rocket-launch': RocketLaunchOutline,
  'o:shield-check': ShieldCheckOutline,
  'o:square-2-stack': Square2StackOutline,
  'o:sparkles': SparklesOutline,
  'o:sun': SunOutline,
  'o:support': SupportOutline,
  'o:telegram': TelegramOutline,
  'o:token-arrow-down': TokenArrowDownOutline,
  'o:token-arrow-up': TokenArrowUpOutline,
  'o:trash': TrashOutline,
  'o:trending-up': TrendingUpOutline,
  'o:user': UserOutline,
  'o:user-circle': UserCircleOutline,
  'o:user-minus': UserMinusOutline,
  'o:user-plus': UserPlusOutline,
  'o:wallet': WalletOutline,
  'o:window': WindowOutline,
  'o:x-mark': XMarkOutline,
  'o:users': UsersOutline,
  'o:bars-3': Bars3Outline,
  'o:building-office-2': BuildingOffice2Outline,
  'o:check': CheckOutline,
  'o:stop-circle': StopCircleOutline,
  'o:history': HistoryOutline,
  'o:hashtag': HashtagOutline,
  'o:star': StarOutline,
  's:app-store': AppStoreSolid,
  's:banknotes': BanknotesSolid,
  's:beaker': BeakerSolid,
  's:bell': BellSolid,
  's:building-library': BuildingLibrarySolid,
  's:camera': CameraSolid,
  's:chart-pie': ChartPieSolid,
  's:check': CheckSolid,
  's:check-badge': CheckBadgeSolid,
  's:check-circle': CheckCircleSolid,
  's:chevron-down': ChevronDownSolid,
  's:chevron-down-up': ChevronDownUpSolid,
  's:chevron-left': ChevronLeftSolid,
  's:chevron-right': ChevronRightSolid,
  's:chevron-up': ChevronUpSolid,
  's:chevron-up-down': ChevronUpDownSolid,
  's:command': CommandSolid,
  's:convert': ConvertSolid,
  's:document-duplicate': DocumentDuplicateSolid,
  's:document-text': DocumentTextSolid,
  's:exclamation-circle': ExclamationCircleSolid,
  's:facebook': FacebookSolid,
  's:globe-alt': GlobeAltSolid,
  's:hand-thumb-up': HandThumbUpSolid,
  's:home': HomeSolid,
  's:information-circle': InformationCircleSolid,
  's:lightning-bolt': LightningBoltSolid,
  's:link': LinkSolid,
  's:linkedin': LinkedinSolid,
  's:loading': LoadingSolid,
  's:lock-closed': LockClosedSolid,
  's:magnifying-glass-minus': MagnifyingGlassMinusSolid,
  's:magnifying-glass-plus': MagnifyingGlassPlusSolid,
  's:map-pin': MapPinSolid,
  's:moon': MoonSolid,
  's:paper-airplane': PaperAirplaneSolid,
  's:photo': PhotoSolid,
  's:play-store': PlayStoreSolid,
  's:qr-code': QrCodeSolid,
  's:question-mark-circle': QuestionMarkCircleSolid,
  's:search': SearchSolid,
  's:square-2-stack': Square2StackSolid,
  's:sun': SunSolid,
  's:support': SupportSolid,
  's:trash': TrashSolid,
  's:trending-up': TrendingUpSolid,
  's:twitter': TwitterSolid,
  's:user': UserSolid,
  's:user-plus': UserPlusSolid,
  's:x-circle': XCircleSolid,
  's:wallet': WalletSolid,
  's:star': StarSolid,
};

export type IconType =
  | 'o:academic-cap'
  | 'o:adjustments-horizontal'
  | 'o:archive-box-x-mark'
  | 'o:arrow-down'
  | 'o:arrow-down-left'
  | 'o:arrow-path'
  | 'o:arrow-down-right'
  | 'o:arrow-down-tray'
  | 'o:arrow-left'
  | 'o:arrow-right'
  | 'o:arrow-right-on-rectangle'
  | 'o:arrow-top-right-on-square'
  | 'o:arrow-up'
  | 'o:arrow-up-on-square'
  | 'o:arrow-up-right'
  | 'o:arrow-up-tray'
  | 'o:arrow-u-turn-left'
  | 'o:arrows-right-left'
  | 'o:arrows-up-down'
  | 'o:banknotes'
  | 'o:battery-50'
  | 'o:beaker'
  | 'o:bell'
  | 'o:bell-snooze'
  | 'o:book-open'
  | 'o:building-library'
  | 'o:building-library-inside'
  | 'o:calendar'
  | 'o:camera'
  | 'o:chart-pie'
  | 'o:check-badge'
  | 'o:check-circle'
  | 'o:circle-stack'
  | 'o:circle-stack-slash'
  | 'o:clock'
  | 'o:cog-6-tooth'
  | 'o:convert'
  | 'o:cursor-arrow-rays'
  | 'o:device-phone-mobile'
  | 'o:document-arrow-down'
  | 'o:document-check'
  | 'o:document-duplicate'
  | 'o:document-text'
  | 'o:ellipsis-horizontal'
  | 'o:ellipsis-vertical'
  | 'o:envelope'
  | 'o:exclaimation-circle'
  | 'o:eye'
  | 'o:eye-slash'
  | 'o:facebook'
  | 'o:flag'
  | 'o:gift'
  | 'o:gift-top'
  | 'o:github-outline'
  | 'o:globe-alt'
  | 'o:globe-americas'
  | 'o:hand-coins'
  | 'o:hand-thumb-up'
  | 'o:home'
  | 'o:identification'
  | 'o:information-circle'
  | 'o:key'
  | 'o:light-bulb'
  | 'o:lightning-bolt'
  | 'o:link'
  | 'o:linkedin'
  | 'o:lock-closed'
  | 'o:magnifying-glass'
  | 'o:magnifying-glass-minus'
  | 'o:magnifying-glass-plus'
  | 'o:map-pin'
  | 'o:map-pin-1'
  | 'o:moon'
  | 'o:paper-airplane'
  | 'o:passport'
  | 'o:pencil-square'
  | 'o:photo'
  | 'o:plus-circle'
  | 'o:plus-small'
  | 'o:qr-code'
  | 'o:question-mark-circle'
  | 'o:receipt-percent'
  | 'o:rocket-launch'
  | 'o:shield-check'
  | 'o:square-2-stack'
  | 'o:sparkles'
  | 'o:sun'
  | 'o:support'
  | 'o:telegram'
  | 'o:token-arrow-down'
  | 'o:token-arrow-up'
  | 'o:trash'
  | 'o:trending-up'
  | 'o:user'
  | 'o:user-circle'
  | 'o:user-minus'
  | 'o:user-plus'
  | 'o:wallet'
  | 'o:window'
  | 'o:x-mark'
  | 'o:users'
  | 'o:bars-3'
  | 'o:building-office-2'
  | 'o:check'
  | 'o:stop-circle'
  | 'o:history'
  | 'o:hashtag'
  | 'o:star'
  | 's:app-store'
  | 's:banknotes'
  | 's:beaker'
  | 's:bell'
  | 's:building-library'
  | 's:camera'
  | 's:chart-pie'
  | 's:check'
  | 's:check-badge'
  | 's:check-circle'
  | 's:chevron-down'
  | 's:chevron-down-up'
  | 's:chevron-left'
  | 's:chevron-right'
  | 's:chevron-up'
  | 's:chevron-up-down'
  | 's:command'
  | 's:convert'
  | 's:document-duplicate'
  | 's:document-text'
  | 's:exclamation-circle'
  | 's:facebook'
  | 's:globe-alt'
  | 's:hand-thumb-up'
  | 's:home'
  | 's:information-circle'
  | 's:lightning-bolt'
  | 's:link'
  | 's:linkedin'
  | 's:loading'
  | 's:lock-closed'
  | 's:magnifying-glass-minus'
  | 's:magnifying-glass-plus'
  | 's:map-pin'
  | 's:moon'
  | 's:paper-airplane'
  | 's:photo'
  | 's:play-store'
  | 's:qr-code'
  | 's:question-mark-circle'
  | 's:search'
  | 's:square-2-stack'
  | 's:sun'
  | 's:support'
  | 's:trash'
  | 's:trending-up'
  | 's:twitter'
  | 's:user'
  | 's:user-plus'
  | 's:x-circle'
  | 's:wallet'
  | 's:star';
