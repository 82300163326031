import { Block, Display, Icon, Panel, Skeleton, Span, withSuspense } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import SymbolPair from '@Components/common/SymbolPair';
import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';

type Props = {
  onClick: () => void;
  value: string;
  disabled?: boolean;
};

const SelectNetwork = ({ onClick, value, disabled }: Props) => {
  const { data } = CryptoWalletApi.useGetNetworks();

  const network = data?.find((d) => d.alias === value);

  const { t } = useTranslation();
  return (
    <Panel
      mb="sm"
      size="md"
      color={disabled ? 'neutral-200' : undefined}
      border="neutral-400"
      onClick={!disabled ? onClick : undefined}
    >
      <Block row gap="md" items="center" justify="between">
        <Block row gap="md" items="center" justify="between">
          {/* Show if inner network is not selected*/}
          <Display show={!network}>
            <Span color="neutral-500" size="sm">
              {t('wallet-address.modal.network-placeholder')}
            </Span>
          </Display>
          {/* Show if inner network is selected*/}
          <Display show={network}>
            <SymbolPair icon={network?.alias} title={network?.alias || ''} description={network?.name || ''} />
          </Display>
        </Block>
        <Icon size="lg" color={disabled ? 'neutral-500' : 'neutral-600'} type="s:chevron-down" />
      </Block>
    </Panel>
  );
};

export default withSuspense(SelectNetwork, <Skeleton height="size-12" width="size-full" mb="xl" />);
