import React from 'react';

import { Block, NumberTextProps, Skeleton, Text, TextProps, withSuspense } from '@bilira-org/design';
import { JustifyType } from '@bilira-org/design/types';
import { CoinSymbolType, FormatPriceType } from '@bilira-org/react-utils';

import { withPrivateRoute } from '@Libs/hocs';
import useGetBalance from '@Libs/hooks/useGetBalance';

import FormattedNumberByPrice from './FormattedNumberByPrice';

type Props = {
  /** Whether to show symbol suffix */
  showSuffix?: boolean;
  /** Symbol of the coin */
  symbol?: CoinSymbolType;
  /** Label to be displayed */
  label: string;
  /** CSS justify-content property */
  justify?: JustifyType;
  /** Additional props for formatting the price */
  formatPriceProps?: Partial<FormatPriceType>;
  labelTextProps?: Partial<TextProps>;
  numberTextProps?: Partial<NumberTextProps>;
};

/**
 * Displays free balance of the specified crypto on users account
 *
 * @example
 * <FreeBalanceLabel label="ETH Free balance" symbol="ETH" />
 */
const FreeBalanceLabel = ({
  label,
  symbol,
  justify = 'end',
  showSuffix = true,
  formatPriceProps,
  labelTextProps,
  numberTextProps,
}: Props) => {
  const { freeBalance } = useGetBalance({ symbol: symbol });

  const priceProps = symbol ? { price: undefined, base: symbol } : { price: '1' };
  return (
    <Block row justify={justify} items="center">
      <Text {...labelTextProps}>{`${label}:`}</Text>
      &nbsp;
      <FormattedNumberByPrice
        {...priceProps}
        type="amount"
        font="primary"
        suffix={showSuffix ? symbol : ''}
        value={freeBalance}
        formatPriceProps={formatPriceProps}
        {...numberTextProps}
      />
    </Block>
  );
};

export default withPrivateRoute(withSuspense(FreeBalanceLabel, <Skeleton height="size-3" width="size-28" />));
