import { CryptoAssetsType, DisclaimerRecordType } from '@bilira-org/react-utils';

import useAuth from '@/libs/hooks/userAuth';
import CryptoQuery from '@Libs/clientInstances/cryptoQuery';
import UserQuery from '@Libs/clientInstances/userQuery';

const useTradeOnlyPopup = (symbol?: string) => {
  const { user } = useAuth();

  const { data: assets } = CryptoQuery.useGetAssets({ suspense: false, enabled: !!user });
  const { data: closedTradePopups } = UserQuery.useGetDisclaimerRecords(
    { type: 'only_trade_asset' },
    {
      enabled: !!user,
      suspense: false,
    },
  );
  const { mutateAsync, isPending } = UserQuery.usePostDisclaimerRecords();

  let asset: CryptoAssetsType | undefined;
  let isTradePopupClosed: DisclaimerRecordType | undefined;

  if (user) {
    asset = assets?.find((asset) => asset.symbol === symbol);
    isTradePopupClosed = closedTradePopups?.find((closedTradePopup) => closedTradePopup.asset === symbol);
  }

  const isPopupActive = Boolean(asset?.only_for_trade) && !isTradePopupClosed;

  const handleClose = async () => {
    if (symbol) {
      await mutateAsync({ user, asset: symbol, type: 'only_trade_asset' });
    }
  };

  return { open: isPopupActive, symbol: symbol, handleClose, isPending };
};

export default useTradeOnlyPopup;
