import React from 'react';

import { Button, withHidden } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useGeneralStore } from '@/store/generalStore';

interface SwapButtonProps {
  /** Whether the button should stretch to fill its container */
  stretch?: boolean;
}

/**
 * Button for opening swap modal
 */
const SwapButton = ({ stretch }: SwapButtonProps) => {
  const { t } = useTranslation();
  const { setSwapModal } = useGeneralStore();

  return (
    <>
      <Button
        id="navigation-swap"
        testId="navigation-swap"
        style={{ height: '40px' }}
        variant="filled"
        size="md"
        onClick={() => setSwapModal(true)}
        stretch={stretch}
      >
        {t('navigation.buy-sell')}
      </Button>
    </>
  );
};

export default withHidden(SwapButton);
export { SwapButton as RawSwapButton };
