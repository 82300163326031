import { useCallback } from 'react';

import { CoinSymbolType, pinItems } from '@bilira-org/react-utils';

import SwapQuery from '@Libs/clientInstances/swapQuery';

const useGetSwapPairs = () => {
  const { data: swapPairs, isPending } = SwapQuery.useGetSwapPairs({ suspense: false });

  const getPinnedSwapPairsWithBalance = useCallback(
    (pinnedAsset: CoinSymbolType) => {
      if (!swapPairs) {
        return [];
      }
      return pinItems({
        list: swapPairs,
        pinnedField: 'base_asset',
        pinnedValue: pinnedAsset,
      });
    },
    [swapPairs],
  );

  return { getPinnedSwapPairsWithBalance, swapPairsList: swapPairs, isPending };
};

export default useGetSwapPairs;
