import { useRef, useState } from 'react';

import { notification } from '@bilira-org/design';
import { TwoFAOptionsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import UserQuery from '@Libs/clientInstances/userQuery';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerTwoFa } from '@Libs/tracker/helpers/addTrackerAccount';

/**
 * Hook for handling Two-Factor Authentication (2FA).
 *
 * @template T - The type of payload used in the Two-Factor Authentication process.
 * @param {TwoFAOptionsType} type - The operation type of Two-Factor Authentication (fiatWithdrawal, cryptoWithdrawal, addCryptoWallet, etc.).
 * @returns An object containing the Two-Factor Authentication data and handling functions.
 */
export default function useTwoFA<T>(type?: TwoFAOptionsType) {
  const { data: twoFAData } = UserQuery.useGetTwoFA(type as TwoFAOptionsType, { enabled: !!type });
  const { t } = useTranslation();
  const payloadRef = useRef<T>();
  const isTwoFAEnabled = twoFAData?.sms || twoFAData?.email || twoFAData?.totp;

  const [activeTwoFAModal, setActiveTwoFAModal] = useState(false);

  /**
   * Function to be called when twoFA required form saved.
   * Opens twoFAModal if twoFA is enabled otherwise executes callback.
   * @param {Function} callback - The callback function to execute if twoFa is not enabled.
   * @param {string} payload - The payload to be saved.
   */
  const handleSave = (callback: (payload: T) => void, payload: T) => {
    if (isTwoFAEnabled) {
      payloadRef.current = payload;
      setActiveTwoFAModal(true);
    } else {
      callback(payload);
    }
  };

  /**
   * Handle the verification of Two-Factor Authentication.
   *
   * @param {Function} callback - The callback function to execute on verification.
   * @param {string} values - The verification token.
   */
  const handleVerifyTwoFA = (callback: (payload: T, token: string) => void, values: string) => {
    if (!payloadRef.current) {
      notification.error(t('error.unexpected-error'));
      return;
    }

    addTrackerTwoFa(TRACKER_EVENTS.TWO_FA.COMPLETE_TWO_FA, {
      type,
      data: values,
    });

    callback(payloadRef.current, values);
    setActiveTwoFAModal(false);
  };

  return {
    twoFAData,
    activeTwoFAModal,
    setActiveTwoFAModal,
    payloadRef,
    handleVerifyTwoFA,
    handleSave,
    isTwoFAEnabled,
  };
}
