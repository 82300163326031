import React from 'react';

import { Block, Panel, Tab, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import BuySell from './components/BuySell';
import DepositWithdraw from './components/DepositWithdraw';

const Index = () => {
  const { t } = useTranslation();
  return (
    <Block gap="2xl">
      <Panel border="neutral-400">
        <Text heading size="xl" weight="bold">
          {t('commission.commission-and-fee')}
        </Text>
        <Tab
          variant="bordered"
          headers={[
            {
              name: t('commission.buy-sell'),
              key: 'buy-selling',
            },
            {
              name: t('commission.deposit-withdrawal'),
              key: 'deposit-withdrawal',
            },
          ]}
          panelsMt="lg"
        >
          <Tab.Panel>
            <BuySell />
          </Tab.Panel>
          <Tab.Panel>
            <DepositWithdraw />
          </Tab.Panel>
        </Tab>
      </Panel>
    </Block>
  );
};
export default Index;
