import React from 'react';

import classNames from 'classnames';

import { BaseComponentType, BaseSize } from '../types';

export type DisplayProps = BaseComponentType & {
  /** Children to render */
  children: React.ReactNode;
  /** Blur effect amount */
  blur?: BaseSize | 'none';
};

/**
 * Component to blur the children
 *
 * @example
 * <Blur blur="sm">
 *   Hello world
 * </Blur>
 *
 * <Blur blur="none">
 *   No blur
 * </Blur>
 */
const Blur = ({ children, blur }: DisplayProps) => {
  return <div className={classNames({ [`tw-blur-${blur}`]: blur })}>{children}</div>;
};

export default Blur;
