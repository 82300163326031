import React, { forwardRef, ReactNode, useEffect } from 'react';

import getMiniDecimal, { DecimalClass, num2str, toFixed, validateNumber, ValueType } from '@rc-component/mini-decimal';
import classNames from 'classnames';

import { composeRef } from '../ref';
import { BackgroundColorType, BaseComponentType, BaseSize, FontWeight, PaddingSizes, TextSizes } from '../types';

export interface InputNumberProps<T extends ValueType = ValueType>
  extends Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'value' | 'defaultValue' | 'onInput' | 'onChange' | 'size' | 'color'
    >,
    BaseComponentType {
  type?: 'number' | 'text';
  min?: T;
  max?: T;
  defaultValue?: T;
  value?: T | null;
  controls?: boolean;

  /** Syntactic sugar of `formatter`. Config decimal separator of display. */
  decimalSeparator?: string;
  onInput?: (text: string) => void;
  onChange?: (value: T | null) => void;
  parser?: (displayValue: string | undefined) => T;
  /** value will show as string */
  stringMode?: boolean;

  size?: BaseSize;
  color?: BackgroundColorType;
  padding?: PaddingSizes;
  icon?: ReactNode;
  textSize?: TextSizes;
  weight?: FontWeight;

  iconStart?: ReactNode;
  iconEnd?: ReactNode;
}

/**
 * @deprecated Use `InputNumber` instead
 */
const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>((props, ref): JSX.Element => {
  const {
    type = 'number',
    defaultValue,
    decimalSeparator,
    value,
    className,
    controls = false,
    min = 0,
    max,
    disabled,
    readOnly,
    onChange,
    onInput,
    parser,
    stringMode = false,
    size,
    textSize = 'base',
    icon,
    padding = 'lg',
    weight,
    iconStart,
    iconEnd,
    color,
    testId,
    ...inputProps
  } = props;
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const compositionRef = React.useRef(false);
  const userTypingRef = React.useRef(false);
  // const [focus, setFocus] = React.useState(false);

  const [decimalValue, setDecimalValue] = React.useState<DecimalClass>(() =>
    getMiniDecimal(value ?? (defaultValue as ValueType)),
  );

  // Input by value
  useEffect(() => {
    const newValue = getMiniDecimal(value ?? '');
    setDecimalValue(newValue);

    const currentParsedValue = getMiniDecimal(mergedParser(inputValue ?? ''));

    // When user typing from `1.2` to `1.`, we should not convert to `1` immediately.
    // But let it go if user set `formatter`
    if (!newValue.equals(currentParsedValue) || !userTypingRef.current) {
      // Update value as effect
      setInputValue(newValue, userTypingRef.current);
    }
  }, [value]);

  // >>> Max & Min limit
  const maxDecimal = React.useMemo(() => getDecimalIfValidate(max), [max]);
  const minDecimal = React.useMemo(() => getDecimalIfValidate(min), [min]);

  function setInputValue(newValue: DecimalClass, userTyping: boolean) {
    setInternalInputValue(
      mergedFormatter(
        // Invalidate number is sometime passed by external control, we should let it go
        // Otherwise is controlled by internal interactive logic which check by userTyping
        // You can ref 'show limited value when input is not focused' test for more info.
        newValue.isInvalidate() ? newValue.toString(false) : newValue.toString(!userTyping),
        userTyping,
      ),
    );
  }

  function setUncontrolledDecimalValue(newDecimal: DecimalClass) {
    if (value === undefined) {
      setDecimalValue(newDecimal);
    }
  }

  const mergedFormatter = React.useCallback(
    (number: string | number, userTyping: boolean) => {
      let str = typeof number === 'number' ? num2str(number) : number;

      // User typing will not auto format with precision directly
      if (!userTyping) {
        // const mergedPrecision = getPrecision(str, userTyping);

        if (validateNumber(str) && decimalSeparator) {
          // Separator
          const separatorStr = decimalSeparator || ',';

          str = toFixed(str, separatorStr);
        }
      }

      return str;
    },
    [decimalSeparator],
  );

  const [inputValue, setInternalInputValue] = React.useState<string | number | undefined>(() => {
    const initValue = defaultValue ?? value;

    if (decimalValue.isInvalidate() && ['string', 'number'].includes(typeof initValue)) {
      return Number.isNaN(initValue) ? '' : initValue?.toString();
    }

    return mergedFormatter(decimalValue.toString(), false);
  });

  const getRangeValue = (target: DecimalClass) => {
    // target > max
    if (maxDecimal && !target.lessEquals(maxDecimal)) {
      return maxDecimal;
    }

    // target < min
    if (minDecimal && !minDecimal.lessEquals(target)) {
      return minDecimal;
    }

    return null;
  };
  const isInRange = (target: DecimalClass) => !getRangeValue(target);

  const triggerValueUpdate = (newValue: DecimalClass, userTyping: boolean): DecimalClass => {
    let updateValue = newValue;

    let isRangeValidate = isInRange(updateValue) || updateValue.isEmpty();
    // Skip align value when trigger value is empty.
    // We just trigger onChange(null)
    // This should not block user typing
    if (!updateValue.isEmpty() && !userTyping) {
      // Revert value in range if needed
      updateValue = getRangeValue(updateValue) || updateValue;
      isRangeValidate = true;
    }

    if (!readOnly && !disabled && isRangeValidate) {
      // const numStr = updateValue.toString();
      // const mergedPrecision = getPrecision(numStr, userTyping);
      //
      // if (mergedPrecision && mergedPrecision >= 0) {
      //   updateValue = getMiniDecimal(toFixed(numStr, '.', mergedPrecision));
      //
      //   // When fixed. The value may out of min & max range.
      //   // 4 in [0, 3.8] => 3.8 => 4 (toFixed)
      //   if (!isInRange(updateValue)) {
      //     updateValue = getMiniDecimal(toFixed(numStr, '.', mergedPrecision, true));
      //   }
      // }

      // Trigger event
      if (!updateValue.equals(decimalValue)) {
        setUncontrolledDecimalValue(updateValue);
        onChange?.(updateValue.isEmpty() ? null : getDecimalValue(stringMode, updateValue));

        // Reformat input if value is not controlled
        if (value === undefined) {
          setInputValue(updateValue, userTyping);
        }
      }

      return updateValue;
    }

    return decimalValue;
  };

  // >>> Parser
  const mergedParser = React.useCallback(
    (num: string | number) => {
      const numStr = String(num);

      if (parser) {
        return parser(numStr);
      }

      let parsedStr = numStr;
      if (decimalSeparator) {
        parsedStr = parsedStr.replace(decimalSeparator, '.');
      }

      // [Legacy] We still support auto convert `$ 123,456` to `123456`
      return parsedStr.replace(/[^\w.-]+/g, '');
    },
    [parser, decimalSeparator],
  );

  const flushInputValue = (userTyping: boolean) => {
    const parsedValue = getMiniDecimal(mergedParser(inputValue || ''));
    let formatValue: DecimalClass = parsedValue;

    if (!parsedValue.isNaN()) {
      // Only validate value or empty value can be re-fill to inputValue
      // Reassign the formatValue within ranged of trigger control
      formatValue = triggerValueUpdate(parsedValue, userTyping);
    } else {
      formatValue = decimalValue;
    }

    if (value !== undefined) {
      // Reset back with controlled value first
      setInputValue(decimalValue, false);
    } else if (!formatValue.isNaN()) {
      // Reset input back since no validate value
      setInputValue(formatValue, false);
    }
  };

  const onBlur = () => {
    flushInputValue(false);
    userTypingRef.current = false;
  };

  // >>> Collect input value
  const collectInputValue = (inputStr: string) => {
    // recordCursor();
    // Update inputValue in case input can not parse as number
    setInternalInputValue(inputStr);

    // Parse number
    if (!compositionRef.current) {
      // const finalValue = mergedParser(inputStr);
      const finalDecimal = getMiniDecimal(inputStr);

      if (!finalDecimal.isNaN()) {
        triggerValueUpdate(finalDecimal, true);
      }
    }

    // Trigger onInput later to let user customize value if they want to do handle something after onChange
    onInput?.(inputStr);
  };

  // >>> Composition
  const onCompositionStart = () => {
    compositionRef.current = true;
  };

  const onCompositionEnd = () => {
    compositionRef.current = false;

    collectInputValue(inputRef.current?.value || '');
  };

  // >>> Input
  // const onInternalInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
  //   collectInputValue(e.target.value);
  // };

  // >>> Input
  const onInternalInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    collectInputValue(e.target.value);
  };
  const onTest: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const reg = new RegExp('^[0-9]+$');

    if (
      !(
        reg.test(e.key) ||
        e.key === ',' ||
        e.key === '.' ||
        e.key === 'Backspace' ||
        e.key === 'Delete' ||
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight'
      )
    ) {
      e.preventDefault();
    }
  };

  const classes = classNames(
    'input w-full',
    {
      [`input-${color}`]: color,
      'has-icon-start': !!iconStart,
      'has-icon-end': !!iconEnd,
    },
    className,
  );

  return (
    <label className="input-group" data-testid={testId ?? 'legacy-input-number'}>
      <span className="sr-only">Number</span>
      {iconStart && <span className="input-icon-start">{iconStart}</span>}
      <input
        onWheel={() => false}
        className={classes}
        min={min}
        onBlur={onBlur}
        onCompositionEnd={onCompositionEnd}
        onCompositionStart={onCompositionStart}
        autoComplete="off"
        role="spinbutton"
        aria-valuemin={min as any}
        aria-valuemax={max as any}
        aria-valuenow={decimalValue.isInvalidate() ? null : (decimalValue.toString() as any)}
        {...inputProps}
        ref={composeRef(inputRef, ref)}
        value={inputValue}
        onChange={onInternalInput}
        onKeyDown={onTest}
        disabled={disabled}
        readOnly={readOnly}
        type={type}
      />
      {iconEnd && <span className="input-icon-end">{iconEnd}</span>}
    </label>
  );
});

InputNumber.displayName = 'Number';

export default InputNumber;

const getDecimalValue = (stringMode: boolean, decimalValue: DecimalClass) => {
  if (stringMode || decimalValue.isEmpty()) {
    return decimalValue.toString();
  }

  return decimalValue.toNumber();
};

const getDecimalIfValidate = (value?: ValueType) => {
  if (!value) return null;

  const decimal = getMiniDecimal(value);
  return decimal.isInvalidate() ? null : decimal;
};
