import React from 'react';

import { Block } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import PriceChange from '@/components/common/PriceChange';
import TotalBalanceCard from '@Components/balance/TotalBalanceCard';

const WalletOverview = () => {
  const { t } = useTranslation();

  return (
    <Block p="2xl">
      <TotalBalanceCard label={t('assets.account-balance')} showChange24H />
    </Block>
  );
};

export default WalletOverview;
