import React, { useCallback, useEffect, useRef } from 'react';

import classNames from 'classnames';

import { ResponsiveReturnType } from './types';

/**
 * Function to transform string into TitleCase
 * @param {string} str  - String to be transformed
 * @returns {string} - Transformed string
 *
 * @example
 * toTitleCase("transaction history details") // "Transaction History Details"
 */
export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

/**
 * Function to check if node is an React Fragment
 *
 * @param {React.ReactNode} node - Node to be checked
 * @returns {boolean} - Whether the node is React Fragment
 *
 * @example
 * isReactFragment(<></>) // true
 * isReactFragment(<div>Hello World</div>) // false
 */
export const isReactFragment = (node: React.ReactNode) => {
  if (!node) return false;

  if ((node as React.ReactElement)?.type) {
    return (node as React.ReactElement)?.type === React.Fragment;
  }

  return false; // "TODO: check here.
  // return node === React.Fragment;
};

/**
 * Function to pass props to node with invalid element & fragment handling.
 *
 * If an invalid element or fragment is passed in as the node, wrap it with the wrapper and add props
 * If a valid element is passed, add the props
 * @returns - React element with props added
 *
 * @example
 * wrapWithElementIfInvalid({
 *   node: renderItem(item, index),
 *   wrapper: <div></div>,
 *   props: { onClick: () => onSelect?.(item) },
 * })
 */
export const wrapWithElementIfInvalid = ({
  node,
  wrapper,
  props = {},
}: {
  node: React.ReactNode;
  wrapper: React.ReactElement;
  props?: any;
}) => {
  if (!node) {
    return React.cloneElement(wrapper, props);
  } else if (!React.isValidElement(node)) {
    return React.cloneElement(wrapper, props, node);
  } else if (isReactFragment(node)) {
    return React.cloneElement(
      wrapper,
      { ...props, className: classNames(node.props?.className, props?.className) },
      node.props.children,
    );
  } else {
    return React.cloneElement(node, {
      ...props,
      className: classNames(node.props?.className, props?.className),
    });
  }
};

// const optional = Symbol();

/**
 * Custom hook to synchronize multiple refs.
 *
 * @template TType - The type of the node that the refs are referencing.
 * @param {...(React.MutableRefObject<TType | null> | ((instance: TType) => void) | null)} refs - The refs to be synchronized.
 * @returns {undefined | ((value: TType) => void)} - Sync refs function or undefined if all refs are truthy.
 *
 * @example
 * const syncRefs = useSyncRefs(ref1,ref2,ref3)
 * if(syncRefs)
 *   syncRefs(refValue)
 */
export function useSyncRefs<TType>(
  ...refs: (React.MutableRefObject<TType | null> | ((instance: TType) => void) | null)[]
) {
  const cache = useRef(refs);

  useEffect(() => {
    cache.current = refs;
  }, [refs]);

  const syncRefs = useCallback(
    (value: TType) => {
      for (const ref of cache.current) {
        if (ref == null) continue;
        if (typeof ref === 'function') ref(value);
        else ref.current = value;
      }
    },
    [cache],
  );

  return refs.every((ref) => ref) ? undefined : syncRefs;
}

/**
 * Custom hook to get the previous value of a variable.
 *
 * @template T - The type of the variable.
 * @param {T} value - The current value.
 * @returns {T | undefined} - The previous value.
 *
 * @example
 * let amount = "3.43"
 * const previousAmount = usePrevious(amount)
 * amount="13.43"
 * previousAmount // "3.43"
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/**
 * Function to build responsive class names based on breakpoints.
 *
 * @template T - The type of the prop.
 * @param {T} prop - The prop value.
 * @param {string} classTemplate - The class name template.
 * @returns {Object} - Responsive class names object.
 *
 * @example
 * buildResponsiveClass({xs:"primary-500", sm:"neutral-400"}, "tw-bg-")
 */
export function buildResponsiveClass<T>(prop: T, classTemplate: string) {
  let classes: ResponsiveReturnType;

  if (typeof prop === 'object') {
    const { xs, sm, md, lg, xl } = prop as { [key: string]: string | number | boolean };

    classes = {
      [`xs:${classTemplate}${xs}`]: !!xs,
      [`sm:${classTemplate}${sm}`]: !!sm,
      [`md:${classTemplate}${md}`]: !!md,
      [`lg:${classTemplate}${lg}`]: !!lg,
      [`xl:${classTemplate}${xl}`]: !!xl,
    };
  } else {
    classes = { [`${classTemplate}${prop}`]: !!prop };
  }

  return classes;
}

/**
 * Function to transform value to string
 * Transforms null/undefined values to ""
 *
 * @template T - The type of the value.
 * @param {T} value - The input value.
 * @returns {string} - The transformed string
 *
 * @example
 * fixControlledValue(undefined) // ""
 * fixControlledValue(null) // ""
 * fixControlledValue(50) // "50"
 * fixControlledValue("example") // "example"
 */
export function fixControlledValue<T>(value: T) {
  if (typeof value === 'undefined' || value === null) {
    return '';
  }
  return String(value);
}
