import React from 'react';

import { Block, Icon, List, Meta, Modal, Separator, Spinner, Text } from '@bilira-org/design';
import { IdType, LanguageUniversalType } from '@bilira-org/react-utils';
import { KeyValuePairType } from '@bilira-org/react-utils/src/model/Preferences';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LANGUAGES } from '@/libs/constants';
import getNewLocaleRoute from '@Libs/route/getNewLocaleRoute';

type Props = {
  callback?: (data: KeyValuePairType) => Promise<IdType>;
  modalActive: boolean;
  setModalActive: (value: boolean) => void;
  isMutating?: boolean;
};

/**
 * Modal component for managing language preferences.
 * It allows users to choose between different languages.
 */
function LanguageModal({ callback, modalActive, setModalActive, isMutating }: Props) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [language, setLanguage] = React.useState(i18n.language === 'tr' ? 'Türkçe' : 'English');

  const updateI18nAndCloseModal = (data: KeyValuePairType) => {
    i18next.changeLanguage(data.key).then(() => {
      setModalActive(false);
    });
  };

  const handleSystemLanguageChange = async (data: KeyValuePairType) => {
    const previousLanguage = language;
    try {
      setLanguage(data.value);
      await callback?.({
        key: 'language',
        value: data.key,
      });

      const newRoute = getNewLocaleRoute(i18n.language as LanguageUniversalType, data.key as LanguageUniversalType);

      if (newRoute) {
        navigate(newRoute);
      }

      updateI18nAndCloseModal(data);
    } catch (error) {
      setLanguage(previousLanguage);
    }
  };

  return (
    <>
      <Modal open={modalActive} onClose={setModalActive}>
        <Text heading size="xl" weight="bold">
          {t('account.preferences.language-preference')}
        </Text>
        <Separator width="size-full" my="2xl" />
        <List spaceY="xs">
          {LANGUAGES.map((item) => (
            <List.Item
              key={item.key}
              onClick={isMutating ? undefined : () => handleSystemLanguageChange(item)}
              px="md"
              py="md"
              hoverBgColor="neutral-300"
              extra={
                <Block row gap="lg" items="center">
                  {item.value === language && !isMutating && (
                    <Icon size="lg" color="primary-500" type="s:check-circle" />
                  )}
                  {item.value === language && isMutating && <Spinner size="sm" color="primary" />}
                </Block>
              }
            >
              <Meta
                title={
                  <Text color="secondary-500" size="base" weight="semibold">
                    {item.value}
                  </Text>
                }
                description={<Text size="sm">{t(`languages.${item.key}`)}</Text>}
              />
            </List.Item>
          ))}
        </List>
      </Modal>
    </>
  );
}

export default LanguageModal;
