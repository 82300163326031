import React from 'react';

import { Block, Separator } from '@bilira-org/design';
import { checkIsOfferValid, OTCType } from '@bilira-org/react-utils';

import OTCRowActive from './components/OTCRowActive';
import OTCRowPassive from './components/OTCRowPassive';

type Props = {
  data: OTCType[];
  onDetailClick: (id: string) => void;
  acceptRequest: (entry: OTCType) => () => void;
  cancelRequest: (entry: OTCType) => () => void;
  expireTimeout: () => void;
};

const OTCTableResponsive = ({ data, onDetailClick, acceptRequest, cancelRequest, expireTimeout }: Props) => {
  return (
    <Block>
      {data.map((entry) => {
        if (checkIsOfferValid(entry)) {
          return (
            <React.Fragment key={entry.id}>
              <Separator width="size-full" color="neutral-400" />
              <OTCRowActive
                key={entry.id}
                entry={entry}
                acceptRequest={acceptRequest}
                cancelRequest={cancelRequest}
                expireTimeout={expireTimeout}
              />
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={entry.id}>
            <Separator width="size-full" color="neutral-400" />
            <OTCRowPassive key={entry.id} entry={entry} onDetailClick={onDetailClick} />
          </React.Fragment>
        );
      })}
    </Block>
  );
};

export default OTCTableResponsive;
