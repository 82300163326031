import React, { FC } from 'react';

import {
  Badge,
  Block,
  Button,
  Dropdown,
  Empty,
  Icon,
  List,
  Meta,
  Skeleton,
  Span,
  Typography,
  withSuspense,
} from '@bilira-org/design';
import { BankAccountType } from '@bilira-org/react-utils/src/model/BankAccount';
import { useTranslation } from 'react-i18next';

import { BankIcon } from '@Components/icon/BankIcon';
import { truncateMiddle } from '@Libs/utils';

import useBankAccounts from './hooks/useBankAccount';

const { Text } = Typography;

interface IBankAccounts {
  /** Callback to be called when user selected a bank */
  callback: (data?: BankAccountType) => void;
  /** If true list is in selection mode, otherwise edit & delete buttons will be visible*/
  isModeSelection?: boolean;
}

/**
 * Lists bank accounts of the user
 * Can be used for select or edit & delete features for bank accounts
 *
 * @example
 * <BankAccount
 *   isModeSelection // in selection mode
 *   callback={(selectedBank) => console.log(selectedBank)}
 * />
 * <BankAccount /> // in edit mode
 */
const BankAccounts: FC<IBankAccounts> = ({ callback, isModeSelection }) => {
  const { t } = useTranslation();
  const { bankAccounts, bankDelete, isPending, switchMainBankAccount } = useBankAccounts();

  const getExtra = (item: BankAccountType) => {
    if (isModeSelection) {
      if (!item.is_default) {
        return <Badge size="xl" variant="circle" color="neutral-400" />;
      }
      return (
        <Icon
          size="lg"
          onClick={() => {
            switchMainBankAccount(item.id, item.is_default).then(() => callback(item));
          }}
          color="primary-500"
          type="s:check-circle"
        />
      );
    }

    return (
      <>
        <Dropdown
          value={<Icon type="o:ellipsis-horizontal" size="lg" color="neutral-600" />}
          position="right"
          color="ghost"
          appearance={false}
          divider
        >
          <Dropdown.Item onClick={() => callback(item)} key={`menu-edit-${item.id}`}>
            {t('common.edit')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => bankDelete(item.id)} key={`menu-del-${item.id}`}>
            <Span color="red-500">{t('common.delete')}</Span>
          </Dropdown.Item>
        </Dropdown>
      </>
    );
  };

  return (
    <>
      <Text mb="6xl">{t('bank-account.saved-accounts-desc')}</Text>
      <List<BankAccountType>
        space="sm"
        color="neutral-300"
        empty={<Empty message={t('common.empty-message')} />}
        loading={isPending}
        dataSource={bankAccounts}
        skeleton={
          <Block row justify="between" items="center" p="md" gap="lg">
            <Block row gap="sm" items="center">
              <Skeleton height="size-8" width="size-8" rounded="full" />

              <Block gap="xs">
                <Skeleton height="size-6" width="size-20" />

                <Skeleton height="size-5" width="size-44" />
              </Block>
            </Block>

            <Skeleton height="size-2" width="size-6" />
          </Block>
        }
        skeletonLine={3}
        paginationSize={3}
        renderItem={(item) => (
          <List.Item
            hoverBgColor="theme-n200d800"
            size="md"
            key={item.id}
            {...(isModeSelection
              ? { onClick: () => switchMainBankAccount(item.id, item.is_default).then(() => callback(item)) }
              : {})}
            extra={getExtra(item)}
          >
            <Meta
              extra={<BankIcon name={item.bank_code} type="icon" />}
              space="sm"
              title={
                <Text weight="semibold" color="secondary-500">
                  {item.alias}
                </Text>
              }
              description={<Text size="sm">{truncateMiddle(item.iban, 8, 4, '**************')}</Text>}
            />
          </List.Item>
        )}
      />
      <Button
        mt="8xl"
        onClick={() => callback()}
        startIcon={<Icon type="o:plus-circle" />}
        variant="outline"
        size="lg"
        stretch
        justify="center"
      >
        {t('bank-account.add-new-account')}
      </Button>
    </>
  );
};

export default withSuspense(
  BankAccounts,
  <>
    <Skeleton mb="6xl" height="size-4" width="size-full" />
    <List
      space="sm"
      color="neutral-300"
      loading={true}
      skeleton={
        <Block row justify="between" items="center" p="md" gap="lg">
          <Block row gap="sm" items="center">
            <Skeleton height="size-8" width="size-8" rounded="full" />

            <Block gap="xs">
              <Skeleton height="size-6" width="size-20" />

              <Skeleton height="size-5" width="size-44" />
            </Block>
          </Block>

          <Skeleton height="size-2" width="size-6" />
        </Block>
      }
      skeletonLine={3}
    />
    <Skeleton mt="8xl" height="size-12" width="size-full" rounded="xl" />
  </>,
);
