import React from 'react';

import { Block, Button, Display, Icon, Skeleton, Text, withSuspense } from '@bilira-org/design';
import { parseAssetPair } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import BlogQuery from '@Libs/clientInstances/blogQuery';
import { navigate } from '@Libs/helpers/navigation';

type Props = {
  assetPair?: string;
};
const AssetInformation = ({ assetPair }: Props) => {
  const { i18n, t } = useTranslation();
  const [symbol] = parseAssetPair(assetPair);
  const { data } = BlogQuery.useGetCoinInfo({ symbol: symbol, locale: i18n.language });

  return (
    <section>
      <Text heading mb="lg">
        {t('market.about-crypto', { crypto: data?.name, symbol: symbol })}
      </Text>

      <Text colorizeHtml size="sm" dangerouslySetInnerHTML={{ __html: data?.content || '' }} />
      <Block row gap="sm" wrap mt="4xl">
        <Display show={data?.links?.website}>
          <Button
            size="md"
            variant="outline"
            onClick={navigate(data?.links?.website, '_blank')}
            startIcon={<Icon type="o:cursor-arrow-rays" />}
          >
            {t('market.coin-info.official-website')}
          </Button>
        </Display>
        <Display show={data?.links?.whitepaper}>
          <Button
            size="md"
            variant="outline"
            onClick={navigate(data?.links?.whitepaper, '_blank')}
            startIcon={<Icon type="o:book-open" />}
          >
            {t('market.coin-info.whitepaper')}
          </Button>
        </Display>
        <Display show={data?.links?.github}>
          <Button
            size="md"
            variant="outline"
            onClick={navigate(data?.links?.github, '_blank')}
            startIcon={<Icon type="o:github-outline" />}
          >
            Github
          </Button>
        </Display>
        <Display show={data?.links?.explorer}>
          <Button
            size="md"
            variant="outline"
            onClick={navigate(data?.links?.explorer, '_blank')}
            startIcon={<Icon type="o:globe-alt" />}
          >
            {t('market.coin-info.block-explorer')}
          </Button>
        </Display>
      </Block>
    </section>
  );
};

export default withSuspense(
  AssetInformation,
  <section>
    <Skeleton width="size-32" height="size-7" mb="lg" />

    <Skeleton width="size-96" height="size-3" mb="sm" />
    <Skeleton width="size-52" height="size-3" mb="sm" />
    <Skeleton width="size-32" height="size-3" />

    <Block row gap="sm" wrap mt="4xl">
      <Skeleton height="size-10" width="size-52" />
      <Skeleton height="size-10" width="size-48" />
      <Skeleton height="size-10" width="size-48" />
      <Skeleton height="size-10" width="size-48" />
    </Block>
  </section>,
);
