import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface GeneralPersistedStoreType {
  maskBalance: boolean;
  setMaskBalance: (value: boolean) => void;

  isNotEligible: boolean;
  setIsNotEligible: (value: boolean) => void;

  acceptedCookies: { values?: string[]; sent: boolean };
  setAcceptedCookies: ({ values, sent }: { values?: string[]; sent: boolean }) => void;

  isNewFeatureViewed?: Record<string, boolean>;
  setIsNewFeatureViewed: (value: Record<string, boolean>) => void;

  closedAnnouncementPopupId?: string;
  setClosedAnnouncementPopupId: (value?: string) => void;
}

export const useGeneralPersistedStore = create<GeneralPersistedStoreType>()(
  persist(
    (set) => ({
      maskBalance: false,
      setMaskBalance: (value: boolean) => set({ maskBalance: value }),

      isNotEligible: false,
      setIsNotEligible: (value: boolean) => set({ isNotEligible: value }),

      acceptedCookies: { values: undefined, sent: false },
      setAcceptedCookies: (props: { values?: string[]; sent: boolean }) =>
        set((state) => ({ acceptedCookies: { ...state.acceptedCookies, ...props } })),

      isNewFeatureViewed: {},
      setIsNewFeatureViewed: (value: Record<string, boolean>) =>
        set((state) => ({ isNewFeatureViewed: { ...state.isNewFeatureViewed, ...value } })),

      closedAnnouncementPopupId: undefined,
      setClosedAnnouncementPopupId: (value?: string) => set({ closedAnnouncementPopupId: value }),
    }),
    {
      name: 'general-persisted-store', // name of the item in the storage (must be unique)
    },
  ),
);
