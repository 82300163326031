import React from 'react';

import { Layout } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@Components/common/HeadTags';
import useAuth from '@Libs/hooks/userAuth';
import DefaultContent from '@Modules/otc/components/DefaultContent';
import Header from '@Modules/otc/components/Header';
import OTCContent from '@Modules/otc/otcTrade/OTCContent';

const { Content } = Layout;

const Index = () => {
  const { t } = useTranslation();
  const { otcUser } = useAuth();

  return (
    <>
      <HeadTags title={t('navigation.otc-portal')} titleSuffix="subpage" />
      <Header />
      <Content>{otcUser ? <OTCContent /> : <DefaultContent />}</Content>
    </>
  );
};
export default Index;
