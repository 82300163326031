import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface StorageValues {
  MARKET_TAB: number;
}

interface GeneralLocalStorage {
  localStore: StorageValues;
  setLocalStore: (data: StorageValues) => void;
}

export const useLocalStorage = create<GeneralLocalStorage>()(
  persist(
    (set) => ({
      localStore: {} as StorageValues,
      setLocalStore: (data) => set((state) => ({ localStore: { ...state.localStore, ...data } })),
    }),
    {
      name: 'general-local-storage',
    },
  ),
);
