import React from 'react';

import { Block, Button, Flex, Icon, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { AnimatedIcon } from '@/components/icon/AnimatedIcon';

import FAQ from './FAQ';
import Prerequisites from './Prerequisites';

const RewardsRegistration = () => {
  const { t } = useTranslation();
  return (
    <>
      <Panel color="radial-primary" size="8xl">
        <Flex direction={{ xs: 'col', sm: 'col', md: 'row' }} gap="4xl">
          <Flex
            direction={{ xs: 'col-reverse', sm: 'col-reverse', md: 'col' }}
            width={{ xs: 'full', sm: 'full', md: '2/4' }}
            grow="1"
            gap="lg"
          >
            <Flex>
              <Text heading size="2xl">
                {t('rewards.overview.title')}
              </Text>
              <Text mt="sm">{t('rewards.overview.description')}</Text>
            </Flex>
            <Flex direction="row" justify={{ xs: 'center', sm: 'center', md: 'start' }}>
              <AnimatedIcon type="gift-box" style={{ height: '150px', width: '150px' }} />
            </Flex>
          </Flex>
          <Flex direction="col" gap="md" items="start" grow="1">
            <Prerequisites />
          </Flex>
        </Flex>
      </Panel>
      <Block mt="8xl">
        <Text heading size="xl" mb="2xl">
          {t('rewards.faq-title')}
        </Text>
        <FAQ />
      </Block>
    </>
  );
};

export default RewardsRegistration;
