import { concatTicker, formatNumberByPrice, FormatNumberByPriceProps, LanguageUniversalType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import useLastLivePrice from './useLastLivePrice';

interface UseFormatByPriceProps {
  /** Base asset symbol */
  base: string;
  /** Quote asset symbol */
  quote?: string;
}

type Props = UseFormatByPriceProps & Partial<Omit<FormatNumberByPriceProps, keyof UseFormatByPriceProps | 'price'>>;

/**
 * Custom hook to format a price based on live price data and locale.
 * @returns {Function} - A function that formats a price using the live exchange rate and locale.
 *
 * @example
 * const formatter = useFormatByLivePrice({base:"ETH"})
 * ...
 * formatter({value,type,...})
 */
const useFormatNumberByLivePrice = ({ base, quote = 'TRYB', ...rest }: Props) => {
  const { i18n } = useTranslation();
  const { liveData } = useLastLivePrice({ symbol: concatTicker(base, quote) });

  const price = liveData?.p || 1;

  return (props: Omit<FormatNumberByPriceProps, 'price' | 'locale'>) =>
    formatNumberByPrice({ price, locale: i18n.language as LanguageUniversalType, ...rest, ...props });
};

export default useFormatNumberByLivePrice;
