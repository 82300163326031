import React from 'react';

import { Flex, Icon, Meta, Skeleton, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import idpQuery from '@Libs/clientInstances/idpQuery';

function PhoneNumber() {
  const { t } = useTranslation();
  const { data } = idpQuery.useGetUserInfo({ suspense: false });

  return (
    <Flex py="4xl" direction={{ xs: 'col', sm: 'row' }} gap="xl" justify="between" items={{ sm: 'center' }}>
      <Meta
        extra={<Icon size="lg" type="o:device-phone-mobile" color="primary-500" />}
        position="left-top"
        title={t('account.security-settings.phone-number')}
        description={t('account.security-settings.phone-number-info')}
      />
      {data?.phone_number ? (
        <Text weight="semibold" color="secondary-500">
          {data?.phone_number}
        </Text>
      ) : (
        <Skeleton width="size-16" />
      )}
    </Flex>
  );
}

export default PhoneNumber;
