import React from 'react';

import { Layout } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import HeadTags from '@/components/common/HeadTags';
import DepositInfoBar from '@Modules/crypto/components/DepositInfoBar';
import WithdrawInfoBar from '@Modules/crypto/components/WithdrawInfoBar';
import CryptoDepositContainer from '@Modules/crypto/deposit/CryptoDepositContainer';
import CryptoWithdrawalContainer from '@Modules/crypto/withdraw/CryptoWithdrawalContainer';

const { Content } = Layout;

const CryptoHome = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const defaultRoute = pathname === '/crypto/withdraw';

  return (
    <>
      <HeadTags title={t('navigation.crypto')} titleSuffix="subpage" />
      {defaultRoute ? <WithdrawInfoBar /> : <DepositInfoBar />}
      <Content>{defaultRoute ? <CryptoWithdrawalContainer /> : <CryptoDepositContainer />}</Content>
    </>
  );
};

export default CryptoHome;
