import React, { forwardRef, ReactNode, useRef } from 'react';

import classNames from 'classnames';

import fixIosInputFocus from '../../helpers/fixIosInputFocus';
import { composeRef } from '../ref';
import {
  BaseComponentType,
  BreakpointType,
  CustomWidthType,
  FontType,
  PaddingSizes,
  ResponsiveReturnType,
  TextAlign,
  TextColorType,
} from '../types';
import { buildResponsiveClass } from '../utils';

export type IconProps = {
  custom?: boolean;
  space?: 'xs' | 'sm' | 'md';
};

export type InputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size' | 'color' | 'type' | 'placeholder' | 'padding' | 'icon' | 'textSize'
> &
  BaseComponentType & {
    /** Additional class name for the input */
    className?: string;
    /** Input type */
    type?: string;
    /** Placeholder text for the input */
    placeholder?: string;
    /** Icon to be placed at the start of the input */
    iconStart?: ReactNode;
    /** Customization props for the icon at the start of the input */
    iconStartProps?: IconProps;
    /** Icon displayed at the end of the input. */
    iconEnd?: ReactNode;
    /** Customization props for the icon at the end of the input */
    iconEndProps?: IconProps;
    /** Custom width of the input */
    customWidth?: CustomWidthType | BreakpointType<CustomWidthType>;
    /** Removes borders from the input */
    noBorders?: boolean;
    /** Text size of the input */
    size?: 'sm';
    /** Font weight of the input */
    weight?: 'semibold';
    /** Text color of the input */
    color?: TextColorType;
    /** Font type of the input */
    font?: FontType;
    /** Text alignment of the input */
    align?: TextAlign;
    /** Callback function triggered on pressing Enter key */
    onEnter?: () => void;
    /** Padding of the input */
    p?: PaddingSizes;
  };

/**
 * Customizable styled Input component.
 *
 * @note
 * @see `InputNumber` for numeric inputs .
 * @see `InputPassword` for password inputs.
 *
 * @example
 * <Input
 *   iconStart={<Icon type="o:magnifying-glass" />}
 *   placeholder="Search something"
 *   value={searchText}
 *   onChange={(val) => onSearch(val.target.value)}
 * />
 */
const Input = forwardRef<HTMLInputElement, InputProps>((props, ref): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    placeholder,
    iconEnd,
    iconEndProps,
    iconStart,
    iconStartProps,
    noBorders,
    type = 'text',
    className,
    customWidth = 'full',
    size = 'sm',
    color = 'secondary-500',
    weight = 'semibold',
    align,
    font,
    testId,
    onEnter,
    onKeyDown,
    p = 'md',
    onFocus,
    ...defaultProps
  } = props;
  let customWidthClasses: ResponsiveReturnType = {};

  if (customWidth) {
    customWidthClasses = buildResponsiveClass(customWidth, 'tw-custom-width-');
  }
  const classes = classNames(
    'input',
    {
      'has-icon-start': !!iconStart,
      'has-icon-end': !!iconEnd,
      [`input-font-${weight}`]: weight,
      [`input-text-${size}`]: size,
      [`tw-text-${align}`]: align,
      [`tw-text-${color}`]: color,
      [`font-${font}`]: font,
      [`p-${p}`]: p,
    },
    className,
  );

  const getIconClasses = (iconProps: IconProps | undefined, position: string) => {
    return classNames(`input-icon-${position}`, {
      [`input-icon-custom`]: iconProps?.custom,
      [`input-icon-space-${iconProps?.space || 'md'}`]: iconProps,
    });
  };

  const iconStartClasses = getIconClasses(iconStartProps, 'start');
  const iconEndClasses = getIconClasses(iconEndProps, 'end');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };

  return (
    <label
      className={classNames('input-group', {
        'input-no-borders': noBorders,
      })}
      data-testid={testId ?? 'input'}
    >
      <span className="sr-only">Input</span>
      <span
        className={classNames('input-wrapper', className, customWidthClasses)}
        aria-disabled={defaultProps.disabled}
      >
        {iconStart && <span className={iconStartClasses}>{iconStart}</span>}
        <input
          {...defaultProps}
          className={classes}
          ref={composeRef(inputRef, ref)}
          type={type}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onFocus={(e) => {
            fixIosInputFocus(e.currentTarget);
            onFocus?.(e);
          }}
        />
        {iconEnd && <span className={iconEndClasses}>{iconEnd}</span>}
      </span>
    </label>
  );
});

Input.displayName = 'Input';

export default Input;
