import React, { ComponentProps } from 'react';

import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

import { Spinner } from '../spinner';
import { BackgroundColorType, BaseComponentType } from '../types';

type IRadioGroup<T extends { id: string; label: string }, E extends React.ElementType> = {
  /** The array of data sources for the radio options. */
  dataSource?: T[];
  /** Background color of the radio options. */
  color?: BackgroundColorType;
  /** The selected radio option. */
  selected?: T;
  /** Callback function to triggered when a radio option is selected. */
  setSelected?: (value: T) => void;
  /** Whether the radio options arranged vertical or horizontal. */
  vertical?: boolean;
  /** Whether the radio options is controlled. */
  controlled?: boolean;
  /** The name of the radio group. */
  name?: string;
  as?: E;
  loading?: T;
} & ComponentProps<E> &
  BaseComponentType;

/**
 * A component that renders a radio group
 *
 * @example
 * <Radio
 *   dataSource={[
 *     { id: '1', label: 'Option 1' },
 *     { id: '2', label: 'Option 2' },
 *   ]}
 *   selected={selected}
 *   setSelected={setSelected}
 * />
 */
function Radio<T extends { id: string; label: string }, E extends React.ElementType = 'div'>(props: IRadioGroup<T, E>) {
  const { dataSource, selected, setSelected, testId, vertical, controlled, name, as, loading, ...rest } = props;

  return (
    <>
      <RadioGroup
        as={as}
        {...(controlled ? { value: selected } : { defaultValue: selected })}
        onChange={setSelected}
        name={name}
        className={as ? undefined : classNames('flex', { 'flex-row gap-6xl': !vertical, 'flex-col gap-lg': vertical })}
        data-testid={testId ?? 'radio'}
        {...rest}
      >
        <RadioGroup.Label className="sr-only">Options</RadioGroup.Label>
        {dataSource?.map((item: T) => (
          <RadioGroup.Option
            key={item.id}
            value={item}
            className={classNames({ 'cursor-pointer': !loading })}
            disabled={!!loading}
          >
            <div className="flex items-center gap-2">
              {loading?.id === item.id && <Spinner size="xs" color="primary" />}
              {(!loading || loading?.id !== item.id) && (
                <div
                  className={classNames('w-3 h-3 outline outline-1 outline-offset-2 rounded-full m-[2px]', {
                    'tw-outline-primary-400 tw-bg-primary-400': item.id === selected?.id,
                    'tw-outline-neutral-500 tw-bg-neutral-200': item.id !== selected?.id,
                  })}
                />
              )}
              <div className="flex items-center">
                <RadioGroup.Label as="p" className="font-regular text-sm tw-text-secondary-500">
                  {item.label}
                </RadioGroup.Label>
              </div>
            </div>
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </>
  );
}

export default Radio;
