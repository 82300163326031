import React, { FC, ImgHTMLAttributes, useState } from 'react';

import classNames from 'classnames';

import { Skeleton } from '../skeleton';
import { BaseComponentType, MarginSizes, RadiusSizes, SizeType } from '../types';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement>, BaseComponentType {
  /** Source of the image */
  src?: string;
  /** Width of the image */
  width?: SizeType;
  /** Height of the image */
  height?: SizeType;
  /** Alt text for the image */
  alt: string;
  /** CSS object-fit property of the image */
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  /** Border radius of the image */
  rounded?: RadiusSizes;
  /** Margin y-axis of the image */
  my?: MarginSizes;
  /** Aspect ratio of the skeleton */
  skeletonAspectRatio?: number;
}

/**
 * Image component for rendering images with optional skeleton loading effect.
 */
const Image: FC<ImageProps> = ({
  my,
  src,
  width,
  height,
  alt,
  objectFit,
  rounded,
  className,
  style,
  skeletonAspectRatio,
  testId,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <img
        {...rest}
        src={src}
        alt={alt}
        className={classNames(className, {
          [`tw-object-${objectFit}`]: objectFit,
          [`tw-rounded-${rounded}`]: rounded,
          [`tw-w-${width}`]: width,
          [`tw-h-${height}`]: height,
          [`my-${my}`]: my,
          'cursor-pointer': !!rest.onClick,
          hidden: loading,
        })}
        style={style}
        onLoad={() => setLoading(false)}
        data-testid={testId ?? 'image'}
      />
      {loading && (
        <Skeleton
          className={classNames('flex-shrink-0', {
            [`tw-object-${objectFit}`]: objectFit,
            [`my-${my}`]: my,
          })}
          itemClassName={className}
          rounded={rounded}
          width={width}
          height={height}
          style={{ ...style, aspectRatio: skeletonAspectRatio || style?.aspectRatio }}
        />
      )}
    </>
  );
};

export default Image;
