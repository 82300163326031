import { useQuery } from '@tanstack/react-query';

import { aggregateAssetWithPrice, getAssetBy } from './helpers';
import { HttpClientType, HttpErrorType } from '../../../client';
import { AssetsWithPriceType, CoinSymbolType } from '../../../model';
import { CryptoApi, ICryptoApi } from '../../../network/api';
import { IPriceApi } from '../../price/api/interface';
import PriceApi from '../../price/api/PriceApi';
import { GeneratorQueryKey } from '../constants';

class AssetGenerator {
  private readonly cryptoApi: ICryptoApi;
  private readonly priceQuery: IPriceApi;

  public constructor(cryptoClient: HttpClientType, priceClient: HttpClientType) {
    this.cryptoApi = new CryptoApi(cryptoClient);
    this.priceQuery = new PriceApi(priceClient);
  }

  private aggregate = async (quote: CoinSymbolType): Promise<AssetsWithPriceType[]> => {
    const assetData = await this.cryptoApi.getAssets();
    const priceData = await this.priceQuery.getAllPrices();

    return aggregateAssetWithPrice(assetData, priceData, quote);
  };
  private findBy = async (base: CoinSymbolType, quote: CoinSymbolType): Promise<AssetsWithPriceType> => {
    const assetData = await this.cryptoApi.getAssets();
    const priceData = await this.priceQuery.getAllPrices();

    return getAssetBy(assetData, priceData, base, quote) as AssetsWithPriceType;
  };

  public useGetAssets = (quote: CoinSymbolType) =>
    useQuery<AssetsWithPriceType[], HttpErrorType>({
      queryKey: [GeneratorQueryKey.ASSETS_GENERATOR, quote],
      queryFn: () => this.aggregate(quote),
    });

  public useGetAsset = (base: CoinSymbolType, quote: CoinSymbolType) =>
    useQuery<AssetsWithPriceType, HttpErrorType>({
      queryKey: [GeneratorQueryKey.ASSET_GENERATOR, base, quote],
      queryFn: () => this.findBy(base, quote),
    });
}

export default AssetGenerator;
