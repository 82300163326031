import React, { ReactElement } from 'react';

import { Motion } from '../framer';
import { BaseComponentType } from '../types';

export type DisplayProps<T> = BaseComponentType & {
  /* Content to display. */
  children: React.ReactNode;
  /* Whether to display the content. */
  show: T;
  /* Whether to use motion transitions. */
  motion?: boolean;
};

/**
 * Display component for conditionally rendering content based on the 'show' prop.
 * Optionally supports motion transitions using the 'motion' prop.
 *
 * @example
 * // Example usage of Display component without motion
 * <Display show={isVisible} testId="display">
 *   <div>Content to display</div>
 * </Display>
 *
 * @example
 * // Example usage of Display component with motion
 * <Display show={isVisible} motion testId="display">
 *   <div>Content with motion</div>
 * </Display>
 */

const Display = <T,>({ children, show, motion, testId }: DisplayProps<T>): null | ReactElement<any, any> => {
  if (!show) {
    return null;
  }

  if (motion) {
    return (
      <Motion.Section show={!!show} data-testid={testId ?? 'display'}>
        {children}
      </Motion.Section>
    );
  }

  return <>{children}</>;
};

export default Display;
