import React from 'react';

import { CoinSymbolType, NetworkAliasType } from '@bilira-org/react-utils';
import classNames from 'classnames';

import { getIconURLByType } from './helper/getIconURLByType';
import { Image } from '../image';
import { BaseComponentType, BreakpointType, FixSizeType, ResponsiveReturnType, TextColorType } from '../types';
import { buildResponsiveClass } from '../utils';

export type CoinProps = BaseComponentType & {
  /** Type of the coin icon */
  type?: CoinSymbolType | NetworkAliasType;
  /** Color of the icon */
  color?: TextColorType;
  /** Fill color of the icon */
  fill?: TextColorType;
  /** Size of the icon */
  size?: FixSizeType | BreakpointType<FixSizeType>;
  /** Base URL of the CDN */
  cdnBaseURL: string;
};

/**
 * Coin icon component that renders different coin icons based on the type prop.
 *
 * @example
 * // Example usage of the IconBank component
 * <IconCoin type="ETH" size="md" />
 */
export function IconCoin({ color, fill, size = 'lg', type, testId, cdnBaseURL, ...props }: CoinProps) {
  let sizeClass: ResponsiveReturnType = {};

  if (size) {
    sizeClass = buildResponsiveClass(size, 'fix-size-');
  }

  const classes = classNames('rounded-full', sizeClass, {
    [`tw-text-${color}`]: color,
    [`tw-fill-${fill}`]: fill,
  });
  if (!type) return null;

  const source = getIconURLByType(cdnBaseURL, type);

  if (!source) {
    return null;
  }

  return (
    <Image src={source} rounded="full" {...props} className={classes} alt={type} data-testid={testId ?? 'icon-coin'} />
  );
}
