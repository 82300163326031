import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import {
  KeyValuePairType,
  PreferencesPutBodyType,
  PreferencesPutType,
  PreferencesType,
  UserPreferenceType,
} from '../../model/Preferences';
import { IdType } from '../../model/ResultType';
import { IPreferencesApi } from './interface';

class PreferencesApi extends HttpClient implements IPreferencesApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getPreferences = (accountId: string) =>
    this.instance.get<string, PreferencesType[]>(`/accounts/${accountId}/account-preferences?type=notification`);

  public getPreference = (id: string) =>
    this.instance.get<string, PreferencesType[]>(`/accounts/${id}/account-preferences`);

  public putAccountPreference = (payload: PreferencesPutType) =>
    this.instance.put<HttpErrorType, PreferencesType, PreferencesPutBodyType>(
      `/accounts/${payload.account}/account-preferences`,
      payload.body,
    );

  public putUserPreference = (body: KeyValuePairType) =>
    this.instance.put<KeyValuePairType, IdType>(`/users/preferences`, body);

  public getUserPreference = (params?: { key: string }) =>
    this.instance.get<string, UserPreferenceType[]>(`/users/preferences`, { params });
}

export default PreferencesApi;
