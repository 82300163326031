import React from 'react';

import { Text } from '@bilira-org/design';
import type { InternalTransferType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { TransactionItemSizeType } from '../../TransactionTable';
import { transactionTextSize } from '../constants';

type TransactionTitleProps = {
  data: InternalTransferType;
  size: TransactionItemSizeType;
};

const TransactionTitle = ({ data, size }: TransactionTitleProps) => {
  const { t } = useTranslation();
  return (
    <Text weight="medium" size={transactionTextSize[size]} wordBreak="none" color="secondary-500">
      {t('transactions.referral-earning')}
    </Text>
  );
};

export default TransactionTitle;
