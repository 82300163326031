import React, { useMemo } from 'react';

import { getCoreRowModel, Table, useReactTable } from '@tanstack/react-table';

import { DataTableColumnProps } from './dataTable';
import { getRowId } from '../helpers';

interface Props<T>
  extends Pick<
    DataTableColumnProps<T>,
    'loading' | 'skeletonRowCount' | 'columns' | 'rowId' | 'data' | 'pinnedColumns' | 'getRowCanExpand'
  > {
  children: (table: Table<T>) => React.ReactNode;
}

function DataTableHOC<T>(props: Props<T>) {
  const {
    loading,
    skeletonRowCount,
    columns,
    rowId,
    data: dataFromProps = [],
    pinnedColumns,
    getRowCanExpand,
    children,
  } = props;

  const data = useMemo(() => {
    if (loading) {
      return new Array(skeletonRowCount).fill(null) as T[];
    }
    return dataFromProps;
  }, [dataFromProps, loading, skeletonRowCount]);

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: getRowId({ rowId, loading }),
    state: {
      columnPinning: { left: pinnedColumns },
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    getRowCanExpand,
  });

  return children(table);
}
export default DataTableHOC;
