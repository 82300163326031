import classNames from 'classnames';

import { BaseComponentType, BaseSize } from '../types';

export type SpinnerProps = BaseComponentType & {
  /** Size of the spinner. */
  size?: BaseSize;
  /** Color variant of the spinner */
  color?: 'primary' | 'primary-100' | 'default' | 'orange';
  /** Additional class name for styling */
  className?: string;
  /** Border size of the spinner */
  borderSize?: BaseSize;
  /** Animation variant */
  variant?: 'bezier' | 'linear' | 'alternate';
  position?: 'relative' | 'absolute';
};

/**
 * Component that represents a loading spinner.
 *
 * @example
 * <Spinner size="lg" variant="linear" color="primary" />
 */
const Spinner = ({
  size = 'md',
  variant = 'linear',
  borderSize = 'xs',
  color = 'default',
  className = '',
  testId,
  position = 'relative',
}: SpinnerProps) => {
  return (
    <div
      className={classNames(`spinner ${position}`, className, { [`spinner-${size}`]: size })}
      data-testid={testId ?? 'spinner'}
    >
      <div className="spinner-content">
        <div
          className={classNames('inner-spinner', {
            [`spinner-${color}`]: color,
            [`spinner-${variant}`]: variant,
            [`spinner-border-${borderSize}`]: borderSize,
          })}
        >
          &nbsp;
        </div>
      </div>
      <span className="sr-only">Loading</span>
    </div>
  );
};

export default Spinner;
