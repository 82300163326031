import React, { memo, ReactNode } from 'react';

import classNames from 'classnames';

import ButtonClose from '../../button/buttonClose';
import Display from '../../display/display';
import { Icon } from '../../icons';
import { BaseComponentType } from '../../types';
import { Text } from '../../typography';

interface BottomSheetProps extends BaseComponentType {
  /** Children to render inside the modal */
  children?: ReactNode | React.ReactElement;
  /** Callback when the modal is closed */
  onClose?: () => void;
  /** Whether the modal is open */
  open: boolean;
  /** Title of the modal */
  title?: string | null;
  /**Border top color*/
  borderColor?: 'red' | 'green' | null;
  /** Callback function to be called when the previous button is clicked. */
  onPreviousClick?: () => void;
  /**Display bottom sheet header*/
  displayHeader?: boolean;
}

/**
 * Dialog modal styled to appear from the bottom of the screen.
 *
 * @example
 * <BottomDialog open={isSettingsModalOpen} closable={false} shakeOnOutsideClick>
 *   ...
 * </BottomDialog>
 */
const BottomSheet = memo(
  ({
    borderColor,
    onClose,
    children,
    onPreviousClick,
    open,
    title,
    testId,
    displayHeader = true,
  }: BottomSheetProps) => {
    function closeModal() {
      onClose?.();
    }

    const classes = classNames(`bottom-sheet`, {
      'translate-y-full ': !open,
      '-translate-y-full': open,
      [`bottom-sheet-${borderColor}`]: borderColor,
    });

    return (
      <div className={classes} data-testid={testId}>
        <Display show={displayHeader}>
          <div className={classNames('bottom-sheet-header')}>
            <Display show={onPreviousClick}>
              <p className="cursor-pointer" onClick={onPreviousClick}>
                <Icon type="o:arrow-left" size="lg" color="secondary-500" />
              </p>
            </Display>
            <Text heading size="lg" weight="bold">
              {title}
            </Text>
            <Display show={onPreviousClick}>
              <div />
            </Display>
            <Display show={!onPreviousClick}>
              <ButtonClose onClick={closeModal} />
            </Display>
          </div>
        </Display>
        <div
          className={classNames('bottom-sheet-content', {
            'h-[calc(100%-50px)]': displayHeader,
            'h-full': !displayHeader,
          })}
        >
          <Display show={open}>{children}</Display>
        </div>
      </div>
    );
  },
);

type Props = {
  children: ReactNode;
};

const BottomSheetOverlay = ({ children }: Props) => {
  return <div className="bottom-sheet-overlay">{children}</div>;
};

export default Object.assign(BottomSheet, { Overlay: BottomSheetOverlay });
