import mixpanel from 'mixpanel-browser';

import { environment } from '@Libs/constants/environment';

mixpanel.init(environment.MIXPANEL_TOKEN, {
  debug: false,
  track_pageview: true,
  persistence: 'localStorage',
  ignore_dnt: true,
  api_host: 'https://api-eu.mixpanel.com',
  verbose: false,
});

export { mixpanel };
