import { useMemo } from 'react';

import { Skeleton, Text, withSuspense } from '@bilira-org/design';
import { CoinSymbolType, NetworkAliasType } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import useGetMaxCryptoWithdraw from '@/libs/hooks/useGetCryptoMaxWithdraw';
import AccountApi from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';

type WithdrawAllButtonProps = {
  /** Symbol of the crypto */
  symbol?: CoinSymbolType;
  /** Callback function to be triggered when button clicked */
  onClick: (amount: string) => void;
  /** Whether the button is disabled or not */
  disabled?: boolean;
  /** Number of decimal places to show */
  decimalPlaces?: number;
  /** Network of the crypto */
  network?: NetworkAliasType;
};

/**
 * WithdrawAllButton component displays a button to withdraw the maximum available balance for a specific crypto.
 *
 * @example
 * <WithdrawAllButton
 *   symbol="ETH"
 *   onClick={(amount) => console.log(`Withdraw amount: ${amount}`)}
 *   decimalPlaces={8}
 * />
 */
const WithdrawAllButton = ({ symbol, onClick, disabled, decimalPlaces, network }: WithdrawAllButtonProps) => {
  const { account } = useAuth();
  const { t } = useTranslation();
  const { data: balances } = AccountApi.useGetBalances(account);
  const cryptoBalance = useMemo(() => balances?.find((d) => d.asset === symbol), [symbol, balances]);
  const freeBalance = BigNumber(cryptoBalance?.free_balance || '0');

  const { maxWithdraw: cryptoMaxWithdraw, isPending } = useGetMaxCryptoWithdraw({
    balance: cryptoBalance?.free_balance,
    asset: symbol,
    network: network,
  });

  const formatAmount = (number: BigNumber, decimalPlaces?: number) => {
    let amount = number.toString();
    if (decimalPlaces !== undefined) {
      amount = number.decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN).toString();
    }
    return amount;
  };

  const handleClick = () => {
    const maxWithdrawAmount = network ? cryptoMaxWithdraw : freeBalance;
    const formattedAmount = formatAmount(maxWithdrawAmount, decimalPlaces);
    onClick(formattedAmount);
  };

  if (!symbol || isPending) {
    return <Skeleton rounded="sm" height="size-4" width="size-10" />;
  }

  return (
    <Text onClick={disabled ? undefined : handleClick} color={disabled ? 'neutral-500' : 'primary-500'}>
      {t('common.all-2')}
    </Text>
  );
};

export default withSuspense(WithdrawAllButton, <Skeleton rounded="sm" height="size-4" width="size-10" />);
