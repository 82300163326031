import React from 'react';

import { Badge, Flex, Icon, Layout, Link, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import BuySellCard from '@Components/trade/buySell/BuySellCard';

import { navItems } from './constants';

const { Content, Hero } = Layout;

function DepositWithdraw() {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('navigation.mobile.deposit-withdraw')} titleSuffix="subpage" />
      <Hero view="strip">
        <div>
          <Text heading size="xl" color="white" weight="bold" my="md">
            {t('navigation.mobile.deposit-withdraw')}
          </Text>
        </div>
      </Hero>
      <Content>
        <Flex direction="col" gap="xs">
          {navItems.map((item) => (
            <Link to={item.href} key={item.title}>
              <Panel color="theme-wn" px="sm" py="md" hover="background">
                <Flex direction="row" gap="lg" justify="between" items="center">
                  <Flex direction="row" gap="sm">
                    <Badge size="6xl" variant="circle" color="neutral-300">
                      <Icon size="md" type={item.icon} color="secondary-500" />
                    </Badge>
                    <Flex>
                      <Text weight="medium" size="sm" color="secondary-500">
                        {t(item.title)}
                      </Text>
                      <Text size="xs" color="neutral-800">
                        {t(item.desc)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Icon size="lg" type="s:chevron-right" color="neutral-600" />
                </Flex>
              </Panel>
            </Link>
          ))}
        </Flex>
      </Content>
    </>
  );
}

export default DepositWithdraw;
