import React, { forwardRef, ReactNode } from 'react';

import { Tab as HeadlessTab } from '@headlessui/react';
import classNames from 'classnames';

import { Flex } from '../flex';
import { useTabRouter } from '../hooks';
import { Overflow } from '../overflow';
import { BaseComponentType, BaseSize, MarginSizes, PaddingSizes } from '../types';
import { Text, TextProps } from '../typography';

type HeaderProps<T extends string> = {
  /** The name of the tab header. */
  name: ReactNode;
  /** The unique key for the tab header. */
  key: T;
  /** Whether the tab header is hidden. */
  hidden: boolean;
  /** Whether the tab header is disabled. */
  disabled: boolean;
};

type HeaderStyle = {
  rowPy: PaddingSizes;
  rowPx: PaddingSizes;
  size: BaseSize;
};

export type TabProps<T extends string> = BaseComponentType & {
  /** An array of tab headers. Each header should have a name and a unique key. */
  headers: Partial<HeaderProps<T>>[];
  /** The content of the tab, either as a ReactNode or a function that receives the headers. */
  children: ReactNode | React.ReactElement | ((val: Partial<HeaderProps<T>>[]) => ReactNode | React.ReactElement);
  /** The variant of the tab */
  variant?: 'bordered' | 'boxed';
  /** Callback function triggered when the selected tab changes. */
  onChange?: ((index: number, header: Partial<HeaderProps<T>>) => void) | undefined;
  /** The default index of the selected tab. */
  defaultIndex?: number;
  /** Whether the tab header should stretch to fill the available space. Defaults to `true`. */
  headerStretch?: boolean;
  /** Whether tab content should be scrollable */
  scrollable?: boolean;
  /** Margin top of the panel contents */
  panelsMt?: MarginSizes;
  headerStyle?: Partial<HeaderStyle>;
  headerTextStyle?: Partial<TextProps>;
  /** Key of the selected tab */
  selectedKey?: T;
  /** Extra node to be rendered on headers */
  extra?: ReactNode;
};

/**
 * Tab component for managing and displaying content in tabs.
 *
 * @example
 * // Example usage of the Tab component
 * <Tab
 *   headers={[
 *     { name: 'Tab 1', key: 'tab1' },
 *     { name: 'Tab 2', key: 'tab2' },
 *     { name: 'Tab 3', key: 'tab3' },
 *   ]}
 *   onChange={handleTabChange}
 *   defaultIndex={0}
 *   headerStretch
 *   testId="customTestId"
 * >
 *   {(headers) => (
 *     <>
 *       {headers.map((header, index) => (
 *         <Tab.Panel key={header.key} center={index === 1}>
 *           <p>{`Content for ${header.name}`}</p>
 *         </Tab.Panel>
 *       ))}
 *     </>
 *   )}
 * </Tab>
 */
const TabComponent = <T extends string>(
  {
    headerStretch = true,
    variant = 'boxed',
    defaultIndex,
    headers,
    onChange,
    children,
    testId,
    scrollable,
    panelsMt = '4xl',
    headerStyle,
    headerTextStyle,
    selectedKey,
    extra,
  }: TabProps<T>,
  ref: React.Ref<HTMLDivElement>,
) => {
  return (
    <div ref={ref} className={classNames('tab-page', { scrollable: scrollable })} data-testid={testId ?? 'tab'}>
      <HeadlessTab.Group
        defaultIndex={defaultIndex}
        selectedIndex={selectedKey ? headers.findIndex((header) => header.key === selectedKey) : undefined}
        onChange={(index) => onChange?.(index, headers[index])}
      >
        <Flex
          width="full"
          direction="row"
          justify="between"
          className={classNames('tab-list-container', {
            [`px-${headerStyle?.rowPx}`]: headerStyle?.rowPx,
            [`py-${headerStyle?.rowPy}`]: headerStyle?.rowPy,
          })}
        >
          <Overflow overflow="x-scroll" className="w-full">
            <HeadlessTab.List
              className={classNames('group tab-list', {
                [`tab-${variant}-list`]: variant,
                [`header-stretch`]: headerStretch,
              })}
            >
              {headers.map((item) => {
                if (item.hidden) {
                  return null;
                }

                return (
                  <HeadlessTab
                    disabled={item.disabled}
                    key={item.key}
                    className={({ selected }) =>
                      classNames('tab-header-item', {
                        [`header-stretch`]: headerStretch,
                        [`tab-${variant}`]: variant,
                        [`tab-${variant}-selected`]: selected,
                        [`tab-${variant}-${headerStyle?.size}`]: headerStyle?.size,
                        [`tab-disabled`]: item.disabled,
                      })
                    }
                  >
                    {headerTextStyle ? <Text {...headerTextStyle}>{item.name}</Text> : item.name}
                  </HeadlessTab>
                );
              })}
            </HeadlessTab.List>
          </Overflow>
          {extra && (
            <Flex direction="row" grow="1" justify="end" className={`extra tab-${variant}-list`}>
              {extra}
            </Flex>
          )}
        </Flex>
        <HeadlessTab.Panels className={classNames({ [`mt-${panelsMt}`]: panelsMt }, 'tab-panels')}>
          {typeof children === 'function' ? children(headers) : children}
        </HeadlessTab.Panels>
      </HeadlessTab.Group>
    </div>
  );
};

const Tab = forwardRef(TabComponent) as <T extends string>(
  props: TabProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => ReturnType<typeof TabComponent>;

export type PanelProps = BaseComponentType & {
  /** The content of the panel. */
  children: ReactNode | React.ReactElement;
  /** Whether to center the content within the panel. */
  center?: boolean;
};

/**
 * Tab panels to be used within `Tab` component
 *
 * @example
 * <Panel center>
 *   Content
 * </Panel>
 */
function Panel({ center, children, testId }: PanelProps) {
  return (
    <HeadlessTab.Panel
      className={classNames('tab-panel', { 'text-center': center })}
      data-testid={testId ?? 'tab-panel'}
    >
      {children}
    </HeadlessTab.Panel>
  );
}

export default Object.assign(Tab, { Panel, useTabRouter });
