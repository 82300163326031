import React, { memo } from 'react';

import { Block, Modal, Text } from '@bilira-org/design';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import WarningTemplate from '@Components/template/WarningTemplate';

type Props = {
  amount?: string;
  asset?: string;
  active: boolean;
  onClose: (active: boolean) => void;
  campaign?: string;
};

const PromoCodeModal = ({ amount, asset, active, campaign, onClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal open={active} onClose={onClose}>
      <WarningTemplate
        icon="completed-big"
        onAccept={() => navigate('/transaction-history')}
        confirmText={t('otc.success-modal.confirm')}
      >
        <Block gap="md" items="center" maxWidth="sm" align="center">
          <Text heading size="xl" weight="bold" align="center">
            {t('promo-code.congrats')}
          </Text>

          <Text>
            <Trans
              t={t}
              i18nKey="promo-code.reward-has-been-credited"
              values={{ campaign: campaign }}
              components={{
                semibold: <Text as="span" weight="semibold" />,
                reward: (
                  <FormattedNumberByPrice
                    as="span"
                    weight="semibold"
                    value={amount}
                    suffix={asset}
                    {...(asset ? { base: asset } : { price: '1' })}
                    type="amount"
                  />
                ),
              }}
            />
          </Text>
        </Block>
      </WarningTemplate>
    </Modal>
  );
};
export default memo(PromoCodeModal);
