import React, { memo, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { createRoundedPath, createSparkline, getPathData } from './helpers';
import { BaseComponentType, BaseSize, TextColorType } from '../types';

export type PriceChartProps = BaseComponentType & {
  /** Array of prices to display in the sparkline */
  prices: number[];
  /** Color of the sparkline */
  color?: TextColorType;
  /** Size of the sparkline */
  size?: BaseSize;
  /** @deprecated */
  name?: string;
  /** @deprecated */
  children?: ReactNode;
  /** @deprecated */
  margin?: number;
};

/**
 * Sparkline component to display a simple line chart representing the price trend over time.
 *
 * @example
 * // Example usage of Sparkline component
 * <Sparkline prices={[50, 60, 70, 65, 80]} color="primary" size="small" />
 */
const Sparkline: React.FC<PriceChartProps> = ({ prices, color = 'neutral', size, testId }) => {
  const width = 64;
  const height = 30;

  const data = useMemo(() => createSparkline({ data: prices, width, height }), [prices]);
  const pathData: string = useMemo(() => createRoundedPath(data, 2, false), [data]);

  // const gradientId = `area-gradient`;

  return (
    <div
      className={classNames('sparkline', { [`sparkline-${color}`]: color, [`sparkline-${size}`]: size })}
      data-testid={testId ?? 'sparkline'}
    >
      <svg viewBox={`0 0 ${width} ${height}`} className="w-full h-full">
        {/*<defs>*/}
        {/*  <linearGradient id={gradientId} x1="0%" y1="0%" x2="50%" y2="70%">*/}
        {/*    <stop offset="0%" className={'start-color'} />*/}
        {/*    <stop offset="0%" className={'stop-color'} />*/}
        {/*  </linearGradient> fill={`url(#${gradientId})`}*/}
        {/*</defs>*/}
        <path strokeLinecap="round" className="line" d={pathData} fill={'none'} />
      </svg>
    </div>
  );
};

export default memo(Sparkline);
