import React from 'react';

import { Layout } from '@bilira-org/design';

import CallbackContainer from '@Modules/auth/callback/CallbackContainer';

const { Content } = Layout;

const Index = () => {
  return (
    <Content style={{ height: '100%' }}>
      <CallbackContainer />
    </Content>
  );
};

export default Index;
