import { useEffect, useState } from 'react';

import { Block, Overflow, Panel, Separator, Skeleton, Tab, withSuspense } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import TransactionDetailModal from '@Components/transaction/modal/TransactionDetailModal';
import { TRANSACTION_HISTORY_LIMIT } from '@Libs/config';
import AllTransactionsCard from '@Modules/transactios/components/components/AllTransactionsCard';

import AllTransactionTable from './AllTransactionTable';
import Filters from './components/Filters';
import useTransactionTable from './hooks/useTransactionTable';
import TransactionExportModal from './TransactionExport';

const AllTransactionsCardContainer = () => {
  const { t } = useTranslation();
  const {
    setFilters,
    setType,
    modalActive,
    setModalActive,
    tableCallback,
    selectedTransactionRecordInfo,
    data,
    metadata,
    isPending,
    refetch,
    type,
    filters,
  } = useTransactionTable();
  const [variant, setVariant] = useState<'deposit-withdraw' | 'swap'>('deposit-withdraw');
  const [filterLabels, setFilterLabels] = useState<Record<string, string | undefined>>({ date: 'common.all' });

  const resetFilters = () => {
    setFilters({ offset: 0, status: 'completed' });
  };

  const { state } = useLocation();
  useEffect(() => {
    if (!state) return;

    const { type, direction, status } = state;

    const initialFilters = {
      ...(direction && { direction }),
      ...(status && { status }),
    };

    if (type) {
      if (['otc', 'swap'].includes(type)) {
        setVariant('swap');
      }
      setType(type);
    }

    setFilters(initialFilters);
  }, [setFilters, setType, state]);

  return (
    <>
      <Tab
        variant="bordered"
        headers={[
          { name: t('transactions.deposit-withdraw'), key: 'deposit-withdraw' },
          { name: t('transactions.buy-sell'), key: 'swap' },
        ]}
        selectedKey={variant}
        headerStretch
        testId="customTestId"
        onChange={(index, header) => {
          setVariant(header.key as typeof variant);
          setType(header.key === 'deposit-withdraw' ? 'fiat' : 'swap');
          resetFilters();
        }}
      >
        {(headers) => (
          <>
            {headers.map((header, index) => (
              <Tab.Panel key={header.key}>
                <Overflow overflow="x-auto">
                  <Block row gap="lg" mb="lg">
                    <Filters
                      variant={header.key as any}
                      filters={filters}
                      setFilters={(value) => setFilters({ ...value, offset: 0 })}
                      type={type}
                      setType={setType}
                      filterLabels={filterLabels}
                      setFilterLabels={setFilterLabels}
                      onReset={resetFilters}
                    />
                  </Block>
                </Overflow>

                <AllTransactionsCard
                  onPageChange={(page) =>
                    setFilters({ ...filters, offset: page * TRANSACTION_HISTORY_LIMIT - TRANSACTION_HISTORY_LIMIT })
                  }
                  tableCallback={tableCallback}
                  data={data}
                  metadata={metadata}
                  isPending={isPending}
                  type={type}
                />
              </Tab.Panel>
            ))}
          </>
        )}
      </Tab>

      <TransactionDetailModal
        transactionRecordInfo={selectedTransactionRecordInfo}
        open={modalActive}
        onClose={setModalActive}
        refetch={refetch}
      />

      <TransactionExportModal
        defaultFilters={filters}
        defaultType={type}
        defaultVariant={variant}
        defaultFilterLabels={filterLabels}
      />
    </>
  );
};

export default withSuspense(
  AllTransactionsCardContainer,
  <>
    <Block row gap="lg" mb="lg">
      <Block>
        <Skeleton height="size-5" width="size-24" mb="xs" />
        <Skeleton height="size-14" width="size-24" />
      </Block>
      <Block>
        <Skeleton height="size-5" width="size-24" mb="xs" />
        <Skeleton height="size-14" width="size-24" />
      </Block>
      <Block>
        <Skeleton height="size-5" width="size-24" mb="xs" />
        <Skeleton height="size-14" width="size-24" />
      </Block>
    </Block>

    <Panel size="md" border="neutral-400">
      <div style={{ minHeight: '25vh' }}>
        <AllTransactionTable type="fiat" loading={true} limit={5} data={[]} />
      </div>
      <Separator my="sm" width="size-full" />
      <Block justify="end" row={true}>
        <Skeleton height="size-7" width="size-24" />
      </Block>
    </Panel>
  </>,
);
