import React from 'react';

import { Icon } from '@bilira-org/design';
import { buildTwitterShareUrl } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
type TwitterShareButtonProps = {
  name?: string;
  symbol?: string;
  quoteAsset: string;
};
const TwitterShareButton = ({ name, symbol, quoteAsset }: TwitterShareButtonProps) => {
  const { t } = useTranslation();

  const share = () => {
    window.open(
      buildTwitterShareUrl({
        url: window.location.href,
        text: symbol ? t('market.pair-chart', { pair: `${symbol}/${quoteAsset}` }) : '',
        hashtags: `BiLira,${symbol},${quoteAsset},${name}`,
      }),
      '_blank',
    );
  };

  return (
    <>
      <Icon
        color="white"
        type="o:arrow-up-on-square"
        size="lg"
        overlayColor="white"
        overlaySize="4xl"
        onClick={share}
      />
    </>
  );
};

export default TwitterShareButton;
