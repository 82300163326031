import React from 'react';

import IdpQuery from '@Libs/clientInstances/idpQuery';
import { Display, Flex, Icon, Meta, Separator, withSuspense } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { LEGAL_STATUS } from '@Libs/constants';

function DocumentFooter() {
  const { t } = useTranslation();
  const { data: kycData } = IdpQuery.useGetUserKyc();

  return (
    <Flex items={{ md: 'center' }} direction={{ md: 'row' }} gap={{ xs: 'xs', sm: 'sm', md: 'lg' }}>
      <Flex.Item>
        <Meta
          space="sm"
          extra={<Icon size="lg" type="o:identification" color="neutral-700" />}
          description={t('account.id-verification.id-photo')}
        />
      </Flex.Item>
      <Flex.Item flex items={{ xs: 'start', sm: 'start', md: 'center' }}>
        <Separator height="size-4" mx="md" />
      </Flex.Item>
      <Flex.Item>
        <Meta
          space="sm"
          extra={<Icon size="lg" type="o:user-circle" color="neutral-700" />}
          description={t('account.id-verification.selfie-photo')}
        />
      </Flex.Item>
      <Display show={kycData?.legal_status !== LEGAL_STATUS.NATIVE_TC_CITIZEN}>
        <Flex.Item flex items={{ xs: 'start', sm: 'start', md: 'center' }}>
          <Separator height="size-4" mx="md" />
        </Flex.Item>
        <Flex.Item>
          <Meta
            space="sm"
            extra={<Icon size="lg" type="o:map-pin" color="neutral-700" />}
            description={t('account.id-verification.residence-permit-or-domicile-certificate')}
          />
        </Flex.Item>
      </Display>
    </Flex>
  );
}

export default withSuspense(DocumentFooter, <></>);
