import React from 'react';

import { Block, Button, Hidden, Separator } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import Help from '@Components/layouts/components/components/Help';
import PublicMobilMenu from '@Components/layouts/components/components/PublicMobilMenu';
import RewardButton from '@Components/layouts/components/components/RewardButton';
import { withPublicRoute } from '@Libs/hocs';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

import Preferences from './Preferences';

/**
 * Layout component for displaying header buttons for anonymous users
 */
const PublicHeaderButtons = () => {
  const { t } = useTranslation();
  const { getRoute } = useLocaleRoute();

  return (
    <>
      <Hidden show={{ xs: false, sm: false, md: true }}>
        <Block row gap="lg" height="size-full" items="center">
          <RewardButton show={{ md: true }} />

          <Button
            route={{ to: getRoute('Login') }}
            style={{ height: '40px' }}
            variant="outline"
            size="md"
            testId="login-btn"
          >
            {t('navigation.login')}
          </Button>
          <Button route={{ to: getRoute('Register') }} style={{ height: '40px' }} variant="filled" size="md">
            {t('navigation.create-free-account')}
          </Button>
          <Separator height="size-5" color="neutral-500" />
          <Help show={{ sm: true }} />
          <Preferences show={{ sm: true }} />
        </Block>
      </Hidden>

      <PublicMobilMenu show={{ xs: true, sm: true, md: false }} />
    </>
  );
};

export default withPublicRoute(PublicHeaderButtons);
