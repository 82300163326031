import { Flex, Icon, Layout, Span, Tab, TabProps } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import RewardsQuery from '@Libs/clientInstances/rewardsQuery';
import Header from '@Modules/rewards/private/components/Header';

import RewardsRegistration from '../components/RewardsRegistration';

const { Content } = Layout;

const DefaultContent = () => {
  const { t } = useTranslation();

  const { data: seasons } = RewardsQuery.useGetSeasons({ suspense: false });

  const headers: TabProps<string>['headers'] = [
    {
      name: t('rewards.tabs.entry'),
      key: 'entry',
    },
    {
      name: (
        <Flex direction="row" items="center" gap="xs">
          {t('rewards.tabs.season', { number: seasons ? seasons?.past_seasons.length + 1 : '-' })}
          <Icon type="o:lock-closed" size="sm" />
        </Flex>
      ),
      key: 'rewards.tabs.currentSeason',
      disabled: true,
    },
    {
      name: (
        <Flex direction="row" items="center">
          {t('rewards.tabs.season', { number: seasons ? seasons?.past_seasons.length + 2 : '-' })} &nbsp;
          <Span size="xs" weight="medium">
            {t('rewards.tabs.coming-soon')}
          </Span>
        </Flex>
      ),
      key: 'rewards.tabs.nextSeason',
      disabled: true,
    },
  ];

  return (
    <>
      <Header isRegisterPage={true} />
      <Content>
        <Tab defaultIndex={0} variant="bordered" headers={headers}>
          <Tab.Panel>
            <RewardsRegistration />
          </Tab.Panel>
        </Tab>
      </Content>
    </>
  );
};

export default DefaultContent;
