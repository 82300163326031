import React, { memo } from 'react';

import { Block } from '@bilira-org/design';

import RewardsQuery from '@Libs/clientInstances/rewardsQuery';

import { sortTasks } from './helpers';
import RewardTask from '../../components/RewardTask';

const TaskList = () => {
  const { data } = RewardsQuery.useGetUserParticipation();

  if (!data) {
    return null;
  }

  return (
    <>
      <Block gap="lg" mb="lg">
        {sortTasks(data.tasks).map((task) => (
          <RewardTask task={task} key={task.id} color="theme-n200d800" />
        ))}
      </Block>
    </>
  );
};

export default memo(TaskList);
