import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import HeadTags from '@Components/common/HeadTags';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';
import { withPrivateRoute } from '@Libs/hocs';

import DefaultContent from './DefaultContent';
import ParticipatedContent from './ParticipatedContent';

const Index = () => {
  const { t } = useTranslation();

  const { data, isPending } = RewardsQuery.useGetUserParticipationPrerequisites({ suspense: false });

  if (isPending) {
    return <HeadTags title={t('navigation.profile.reward')} titleSuffix="subpage" />;
  }

  return (
    <>
      <HeadTags title={t('navigation.profile.reward')} titleSuffix="subpage" />
      {data?.participated_at ? <ParticipatedContent /> : <DefaultContent />}
    </>
  );
};

export default memo(withPrivateRoute(Index));
