import { useEffect, useRef, useState } from 'react';

import { BottomDialog, Button, Flex, Link, Text } from '@bilira-org/design';
import { Trans, useTranslation } from 'react-i18next';

import { useGeneralPersistedStore } from '@/store/generalPersistedStore';

import LegalDocumentsModal from './LegalDocumentsModal';
import { ActiveConsentType } from '../hooks/useLegalDocuments';

type Props = {
  /** Data for active consent */
  data?: ActiveConsentType;
  /**  Function to handle cookie accept */
  onAccept: (value: string[]) => Promise<void>;
  /** Whether the modal is open */
  open?: boolean;
  /** Whether the user is authenticated */
  authenticated?: boolean;
  /** Whether the request is pending */
  isPending?: boolean;
};

/**
 * Modal for asking user for cookie consent
 */
const CookieModal = ({ data, onAccept, open = false, authenticated = false, isPending }: Props) => {
  const { t } = useTranslation();
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const { acceptedCookies, setAcceptedCookies } = useGeneralPersistedStore();
  const acceptingRef = useRef(false);

  const acceptAndUpdateStore = (value: string[]) => {
    onAccept(value).then(() => setAcceptedCookies({ values: value, sent: authenticated }));
  };

  const onAcceptAll = () => {
    const acceptedValues = Object.keys(data?.option_contents || {}).map((option) => option) || [];
    if (!acceptedValues.find((value) => value === 'doc')) {
      acceptedValues.push('doc');
    }
    acceptAndUpdateStore(acceptedValues);
  };

  useEffect(() => {
    // Case: User accepted cookies without login then logged in
    if (open && !acceptedCookies.sent && authenticated && acceptedCookies.values !== undefined) {
      if (!acceptingRef.current) {
        acceptingRef.current = true;
        onAccept(acceptedCookies.values)
          .then(() => setAcceptedCookies({ sent: true }))
          .finally(() => (acceptingRef.current = false));
      }
    }
  }, [open, acceptedCookies, acceptingRef]);

  return (
    <>
      <BottomDialog open={open && !isSettingsModalOpen} closable={false} testId="cookie-dialog" shakeOnOutsideClick>
        <Flex direction={{ xs: 'col', sm: 'col', md: 'row' }} gap="4xl">
          <Flex.Item>
            <Text color="secondary-500" size="sm" weight="bold">
              {t('cookie-modal.title')}
            </Text>
            <Text size="xs">
              <Trans
                t={t}
                i18nKey="cookie-modal.content"
                components={{
                  br: <br />,
                  anchor: <Link color="primary-500" anchor href={t('urls.cookie-policy')} target="_blank" />,
                }}
              />
            </Text>
          </Flex.Item>
          <Flex.Item flex direction="col" gap="lg" justify="center">
            <Button
              variant="filled"
              size="sm"
              stretch
              onClick={() => onAcceptAll()}
              testId="accept-all-cookies-btn"
              loading={isPending}
            >
              {t('cookie-modal.accept')}
            </Button>
            <Button
              variant="outline"
              size="sm"
              stretch
              onClick={() => setIsSettingsModalOpen(true)}
              loading={isPending}
            >
              {t('cookie-modal.manage')}
            </Button>
          </Flex.Item>
        </Flex>
      </BottomDialog>

      <LegalDocumentsModal
        open={isSettingsModalOpen}
        data={data}
        onAccept={(value) => {
          setIsSettingsModalOpen(false);
          acceptAndUpdateStore(value);
        }}
        acceptButtonText={t('cookie-modal.save-preferences')}
        isPending={isPending}
      />
    </>
  );
};

export default CookieModal;
