import { Dispatch, SetStateAction, useState } from 'react';

import { Block, Button, DateRangePicker, Display, Dropdown, Label } from '@bilira-org/design';
import { CustomWidthType } from '@bilira-org/design/types';
import { LanguageUniversalType, TransactionsParamsType, TransactionTypes } from '@bilira-org/react-utils';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { subtractMonths } from '@Libs/utils';

import AssetFilter from './AssetFilter';
import {
  swapDirectionTypeData,
  swapTransactionTypeData,
  transactionDateData,
  transactionStatusData,
  withdrawDepositDirectionTypeData,
  withdrawDepositTransactionTypeData,
} from '../data';

type FiltersProps = {
  variant: 'deposit-withdraw' | 'swap';
  filters: Omit<TransactionsParamsType, 'account'>;
  setFilters: (filters: Omit<TransactionsParamsType, 'account'>) => void;
  type: TransactionTypes;
  setType: (type: TransactionTypes) => void;
  displayVariant?: 'table' | 'export';
  filterLabels: Record<string, string | undefined>;
  setFilterLabels: Dispatch<SetStateAction<Record<string, string | undefined>>>;
  onReset?: () => void;
};

const Filters = ({
  variant,
  displayVariant,
  filters,
  type,
  setFilters,
  setType,
  filterLabels,
  setFilterLabels,
  onReset,
}: FiltersProps) => {
  const { t, i18n } = useTranslation();
  const [customDate, setCustomDate] = useState<[Dayjs, Dayjs]>();

  const updateFilterKey = (key: string, value?: string) => {
    setFilterLabels((filters) => ({ ...filters, [key]: value }));
  };

  const getDropdownSize = (width: CustomWidthType): CustomWidthType => {
    if (displayVariant === 'export') {
      return 'full';
    }
    return width;
  };

  return (
    <>
      <div>
        <Label>{t('transactions.type')}</Label>
        <Dropdown
          width={getDropdownSize('140')}
          listSize={getDropdownSize('140')}
          justify="between"
          position="left"
          value={t(`transactions.${type}`)}
          size="lg"
          onSelect={(item) => {
            setType(item.data);
            if (variant === 'deposit-withdraw') {
              setFilters({ ...filters, asset: undefined });
            }
          }}
          dataSource={variant === 'deposit-withdraw' ? withdrawDepositTransactionTypeData : swapTransactionTypeData}
          renderItem={(item) => (
            <Dropdown.Item selected={item.data === type} key={item.name}>
              {t(item.name)}
            </Dropdown.Item>
          )}
        />
      </div>
      <Display show={variant === 'deposit-withdraw'}>
        <div>
          <Label>{t('transactions.transfer')}</Label>
          <Dropdown
            width={getDropdownSize('140')}
            listSize={getDropdownSize('140')}
            justify="between"
            position="left"
            value={filters.direction ? t(`transactions.${filters.direction}`) : t('common.all')}
            size="lg"
            onSelect={(item) => {
              setFilters({ ...filters, direction: item.data as any });
            }}
            dataSource={withdrawDepositDirectionTypeData}
            renderItem={(item) => (
              <Dropdown.Item key={item.name} selected={item.data === filters.direction}>
                {t(item.name)}
              </Dropdown.Item>
            )}
          />
        </div>
      </Display>
      <Display show={variant === 'swap'}>
        <div>
          <Label>{t('transactions.transaction')}</Label>
          <Dropdown
            width={getDropdownSize('140')}
            listSize={getDropdownSize('140')}
            justify="between"
            position="left"
            value={filters.side ? t(`transactions.${filters.side}`) : t('common.all')}
            size="lg"
            onSelect={(item) => {
              setFilters({ ...filters, side: item.data as any });
            }}
            dataSource={swapDirectionTypeData}
            renderItem={(item) => (
              <Dropdown.Item key={item.name} selected={item.data === filters.side}>
                {t(item.name)}
              </Dropdown.Item>
            )}
          />
        </div>
      </Display>
      <div>
        <Label>{t('transactions.date')}</Label>
        <DateRangePicker
          placeholder={t('common.all')}
          locale={i18n.language as LanguageUniversalType}
          value={customDate}
          onChange={(dates) => {
            setCustomDate(dates);
            setFilters({ ...filters, startDate: dates[0]?.toISOString(), endDate: dates[1]?.toISOString() });
            updateFilterKey('date', undefined);
          }}
          label={filterLabels.date ? t(filterLabels.date) : undefined}
          datePickerLabel={t('common.custom-date-range')}
          width={getDropdownSize('140')}
          limit={[90, 'days']}
        >
          {transactionDateData.map((item) => (
            <Dropdown.Item
              key={item.name}
              onClick={() => {
                const date = item.key === 0 ? undefined : subtractMonths(new Date(), item.key).toISOString();
                setFilters({ ...filters, startDate: date, endDate: undefined });
                setCustomDate(undefined);
                updateFilterKey('date', item.name);
              }}
              selected={item.name === filterLabels.date}
            >
              {t(item.name)}
            </Dropdown.Item>
          ))}
        </DateRangePicker>
      </div>
      <div>
        <Label>{t('transactions.crypto')}</Label>
        <AssetFilter
          disabled={type === 'fiat'}
          value={filters.asset}
          onSelect={(asset) => setFilters({ ...filters, asset: asset.symbol })}
          fluid={displayVariant === 'export'}
          excludeTRYB={variant === 'swap'}
        />
      </div>
      <Display show={variant === 'deposit-withdraw'}>
        <Display show={displayVariant !== 'export'}>
          <div>
            <Label>{t('transactions.status')}</Label>
            <Dropdown
              width={getDropdownSize('140')}
              listSize={getDropdownSize('140')}
              justify="between"
              position="right"
              value={
                (filters.status && t(`transactions.statuses.${filters.status}`)) ||
                t('transactions.transaction-status-title')
              }
              size="lg"
              dataSource={transactionStatusData}
              onSelect={(item) => {
                setFilters({ ...filters, status: item.key } as Omit<TransactionsParamsType, 'account'>);
              }}
              renderItem={(item) => (
                <Dropdown.Item key={item.name} selected={item.key === filters.status}>
                  {' '}
                  {t(`transactions.statuses.${item.name}`)}{' '}
                </Dropdown.Item>
              )}
            />
          </div>
        </Display>
      </Display>
      <Display show={displayVariant !== 'export'}>
        <Block>
          <Label>&nbsp;</Label>
          <Block row items="center" height="size-full">
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                onReset?.();
                setFilterLabels({});
                setCustomDate(undefined);
                updateFilterKey('date', 'common.all');
              }}
              style={{ margin: '1px' }}
            >
              {t('common.reset')}
            </Button>
          </Block>
        </Block>
      </Display>
    </>
  );
};

export default Filters;
