import { useQuery } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../client';
import { QueryOptions, RecordsPaginationType } from '../../model';
import {
  BlogAnnouncementType,
  BlogCategoriesType,
  BlogLocaleType,
  BlogParamsType,
  BlogPostType,
  CoinInfoParamsType,
  CoinInfoType,
  FeaturedPostType,
  PostDetailParamsType,
  RelatedPostType,
} from '../../model/BlogTypes';
import { BlogApi, IBlogApi } from '../api';
import { BlogQueryKey } from '../constants';

class BlogQuery {
  _api: IBlogApi;

  public constructor(params: HttpClientType) {
    this._api = new BlogApi(params);
  }

  public useGetBlogAnnouncements = (params: BlogLocaleType, options?: QueryOptions) =>
    useQuery<BlogAnnouncementType[], HttpErrorType>({
      queryKey: [BlogQueryKey.BLOG_ANNOUNCEMENTS, params],
      queryFn: () => this._api.getBlogAnnouncements(params),
      ...options,
    });

  public useGetFeaturedPosts = (params: BlogLocaleType, options?: QueryOptions) =>
    useQuery<FeaturedPostType[], HttpErrorType>({
      queryKey: [BlogQueryKey.FEATURED_POSTS, params],
      queryFn: () => this._api.getFeaturedPosts(params),
      gcTime: 50000,
      staleTime: 50000,
      ...options,
    });

  public useGetCategories = (params: BlogLocaleType, options?: QueryOptions) =>
    useQuery<BlogCategoriesType[], HttpErrorType>({
      queryKey: [BlogQueryKey.BLOG_CATEGORIES, params],
      queryFn: () => this._api.getCategories(params),
      gcTime: 50000,
      staleTime: 50000,
      ...options,
    });

  public useGetCoinInfo = (params: CoinInfoParamsType, options?: QueryOptions) =>
    useQuery<CoinInfoType, HttpErrorType>({
      queryKey: [BlogQueryKey.COIN_INFO, params],
      queryFn: () => this._api.getCoinInfo(params),
      gcTime: 50000,
      staleTime: 50000,
      ...options,
    });

  public useGetBlogPosts = ({ category, tag, offset, locale, limit }: BlogParamsType, options?: QueryOptions) => {
    return useQuery<RecordsPaginationType<BlogPostType>, HttpErrorType>({
      queryKey: [BlogQueryKey.BLOG_POSTS, category, tag, offset, locale, limit],
      queryFn: () => this._api.getBlogPosts({ category, tag, offset, locale, limit }),
      gcTime: 50000,
      staleTime: 50000,
      ...options,
    });
  };

  public useGetBlogPostsDetail = ({ slug, locale }: PostDetailParamsType, options?: QueryOptions) => {
    return useQuery<BlogPostType, HttpErrorType>({
      queryKey: [BlogQueryKey.BLOG_POST_DETAIL, slug, locale],
      queryFn: () => this._api.getBlogPostsDetail({ slug, locale }),
      gcTime: 50000,
      staleTime: 50000,
      ...options,
    });
  };
  public useGetRelatedPosts = (params: BlogParamsType, options?: QueryOptions) => {
    return useQuery<RecordsPaginationType<RelatedPostType>, HttpErrorType>({
      queryKey: [BlogQueryKey.BLOG_LIST, params],
      queryFn: () => this._api.getRelatedPosts(params),
      gcTime: 50000,
      staleTime: 50000,
      ...options,
    });
  };
}

export default BlogQuery;
