import React from 'react';

import { Block, Layout } from '@bilira-org/design';
import { AssetPairType, parseAssetPair } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import { LayoutCommon } from '@Components/layouts';
import BuySellCard from '@Components/trade/buySell/BuySellCard';
import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';
import Header from '@Modules/market/detail/Header';
import AssetPairContainer from '@Modules/market/detail/PriceChartContainer';

const { Content } = Layout;

const Index = () => {
  const { t } = useTranslation();
  const { assetPair } = useParams<{ assetPair: AssetPairType | undefined }>();
  const [base, quote] = parseAssetPair(assetPair);

  if (!assetPair || !base || !quote) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header />
      <Content>
        <LayoutCommon>
          <LayoutCommon.Content>
            <AssetPairContainer assetPair={assetPair} />
          </LayoutCommon.Content>
          <LayoutCommon.Sidebar>
            <Block gap="4xl">
              <BuySellCard
                defaultAssetPair={assetPair}
                navigateOnSelection={true}
                hiddenProps={{ show: { xs: false, sm: true } }}
              />
              <TransactionPanelCard
                title={t('common.recent-swap-transactions')}
                limit={5}
                type="swap"
                filterAsset={base}
                filterStatus="completed"
                showSubAmount={false}
                showAllButton
              />
            </Block>
          </LayoutCommon.Sidebar>
        </LayoutCommon>
      </Content>
    </>
  );
};

export default Index;
