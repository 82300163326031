import React, { memo } from 'react';

import { Block } from '@bilira-org/design';

import Help from '@Components/layouts/components/components/Help';
import NotificationBadge from '@Components/layouts/components/components/NotificationBadge';
import OTCButton from '@Components/layouts/components/components/OTCButton';
import PrivateMobilMenu from '@Components/layouts/components/components/PrivateMobilMenu';
import RewardButton from '@Components/layouts/components/components/RewardButton';
import SearchButton from '@Components/layouts/components/components/SearchButton';
import SwapButton from '@Components/layouts/components/components/SwapButton';
import WalletBadge from '@Components/layouts/components/components/walletBadge/WalletBadge';

/**
 * Layout component for displaying header buttons
 */
const HeaderButtons = () => {
  return (
    <>
      <Block row gap="sm" items="center">
        <RewardButton show={{ md: false, lg: true }} />
        <OTCButton show={{ md: false, lg: true }} />
        <SwapButton show={{ md: false, lg: true }} />
        <WalletBadge show={{ md: false, lg: true }} />
        <SearchButton show={{ xs: true, sm: true, lg: false }} />
        <NotificationBadge />
        <Help show={{ md: false, lg: true }} />
        <PrivateMobilMenu show={{ xs: true, sm: true, lg: false }} />
      </Block>
    </>
  );
};

export default memo(HeaderButtons);
