import React from 'react';

import { isPatternMatching } from '@bilira-org/react-utils';
import classNames from 'classnames';

import { BaseComponentType } from '../types';

export type PasswordRuleSchemaProps = {
  /** The text of the rule. */
  rule: string;
  /** The pattern or length to match. */
  pattern: RegExp | number;
};

interface IPasswordMeter extends BaseComponentType {
  /** The password string to be evaluated. */
  password?: string;
  /** The schema of password rules, including the rule text and pattern to match. */
  schema: PasswordRuleSchemaProps[];
}

/**
 * The `PasswordMeter` component is used to display a list of password rules and visually indicate whether the provided password meets each rule.
 *
 * @example
 * <PasswordMeter password="password" schema={[
 *   { rule: 'At least 8 characters', pattern: 8 },
 *   { rule: 'At least 1 number', pattern: /\d/ }]}
 * />
 */
const PasswordMeter: React.FC<IPasswordMeter> = ({ password = '', schema, testId }) => {
  return (
    <ul
      className="list-none list-inside text-xs font-normal flex flex-col gap-2"
      data-testid={testId ?? 'password-meter'}
    >
      {schema.map((value) => (
        <Li key={value.rule} selected={isPatternMatching(password, value.pattern)} text={value.rule} />
      ))}
    </ul>
  );
};

interface PasswordMeterLiProps extends BaseComponentType {
  /** Indicates whether the rule is passed or not. */
  selected: boolean;
  /** The text of the rule. */
  text: string;
}

/**
 * The `Li` component is used to render an individual password rule item within the `PasswordMeter` component.
 * It indicates whether the rule is passed or not.
 *
 * @example
 * <Li selected={true} text="At least 8 characters" />
 */
const Li = ({ selected, text, testId }: PasswordMeterLiProps) => {
  const dot = (selected: boolean) => (
    <div
      className={classNames('h-2 w-2  mr-2 rounded-full border border-neutral-500 inline-block', {
        'bg-primary-500': selected,
        'border-primary': selected,
      })}
    />
  );

  return (
    <li
      className={classNames({ 'text-primary-500': selected, 'text-neutral-800': !selected })}
      data-testid={testId ?? 'password-meter-li'}
    >
      {dot(selected)} {text}
    </li>
  );
};

export default PasswordMeter;
