import React, { memo, useMemo } from 'react';

import { withSuspense } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';

import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';

type Props = {
  symbol?: CoinSymbolType;
};
const AssetName = ({ symbol }: Props) => {
  const { data: assets } = CryptoWalletApi.useGetAssets();

  const assetName = useMemo(() => {
    return assets?.find((asset) => asset.symbol === symbol);
  }, [assets, symbol]);

  if (!symbol) {
    return null;
  }

  return <>{assetName?.name}</>;
};

export default memo(withSuspense(AssetName));
