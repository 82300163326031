import React from 'react';

import classNames from 'classnames';

import { BaseComponentType } from '../../types';
import FormFieldErrors from './errors';
import FormFieldLabel from './label';

export type FormFieldTemplateProps = {
  name: string;
  label?: React.ReactNode;
  errors?: string[];
  children: React.ReactNode;
  hidden?: boolean;
  hideErrorList?: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  BaseComponentType;

/**
 * A template component for a form field. It renders a label and an error message for the input.
 */
const FormFieldTemplate = ({
  name,
  label,
  children,
  errors,
  hidden,
  hideErrorList,
  testId,
  ...rest
}: FormFieldTemplateProps) => {
  return (
    <div
      className={classNames('form-field', { 'form-field-hidden': hidden })}
      data-testid={testId ?? 'form-field-template'}
      {...rest}
    >
      {label && <FormFieldLabel htmlFor={name}>{label}</FormFieldLabel>}

      {children}

      {!hideErrorList && <FormFieldErrors errors={errors} />}
    </div>
  );
};

export default FormFieldTemplate;
