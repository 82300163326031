import { memo, useEffect } from 'react';

import useUserPreferences from '@/libs/hooks/useUserPreferences';
import { withPrivateRoute } from '@Libs/hocs';

const PreferencesInitializer = () => {
  const { isSuccess, setDefaultPreferences } = useUserPreferences({ enabled: true, suspense: false });

  useEffect(() => {
    if (isSuccess) {
      setDefaultPreferences();
    }
  }, [isSuccess, setDefaultPreferences]);

  return null;
};

export default memo(withPrivateRoute(PreferencesInitializer));
