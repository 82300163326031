import React from 'react';

import { Flex, Hidden, Logo, Separator, Space } from '@bilira-org/design';
import { Link } from 'react-router-dom';

import LanguagePreferenceModal from '@Components/layouts/components/components/modals/LanguagePreferenceModal';
import PrivateHeaderButtons from '@Components/layouts/components/components/PrivateHeaderButtons';
import Projects from '@Components/layouts/components/components/Projects';
import PublicHeaderButtons from '@Components/layouts/components/components/PublicHeaderButtons';
import Search from '@Components/layouts/components/components/Search';

const Header = () => {
  return (
    <Flex direction="row" justify="between">
      <Space space="md">
        <Hidden show={{ sm: true }}>
          <Link to="/">
            <Logo variant="kripto-text-icon" />
          </Link>
        </Hidden>
        <Hidden show={{ xs: true }}>
          <Link to="/">
            <Logo variant="kripto-app-icon" />
          </Link>
        </Hidden>
        <Separator height="size-full" />
        <Projects />
        <Hidden show={{ lg: true }}>
          <Search />
        </Hidden>
      </Space>
      <PrivateHeaderButtons />
      <PublicHeaderButtons />
    </Flex>
  );
};

export default Header;
