import { useMemo } from 'react';

import { CoinSymbolType, getMax, NetworkAliasType } from '@bilira-org/react-utils';

import allowancesQuery from '@Libs/clientInstances/allowancesQuery';

interface Props {
  asset?: CoinSymbolType;
  networkAlias?: NetworkAliasType;
  suspense?: boolean;
}
const useGetMinCryptoDepositLimit = ({ asset, networkAlias, suspense }: Props = {}) => {
  const { data: data, isPending } = allowancesQuery.useGetCryptoDepositLimits({}, { suspense: suspense });

  const getMinDepositLimit = (asset: CoinSymbolType, networkAlias: NetworkAliasType) => {
    const allowance = data?.find((item) => item.network === networkAlias && item.asset === asset);
    return getMax(allowance?.minimum, '0').toString();
  };

  const minDepositLimit = useMemo(
    () => (asset && networkAlias ? getMinDepositLimit(asset, networkAlias) : undefined),
    [asset, networkAlias],
  );
  return { data, isPending, minDepositLimit, getMinDepositLimit };
};

export default useGetMinCryptoDepositLimit;
