import { useMemo } from 'react';

import {
  Badge,
  Block,
  Breadcrumbs,
  Button,
  CircleTimer,
  ColumnDef,
  DataTable,
  Flex,
  Icon,
  Loop,
  Meta,
  Skeleton,
  TableBodyStyle,
  TableHeaderStyle,
  Text,
  withSuspense,
} from '@bilira-org/design';
import {
  AssetPairPriceType,
  checkIsOfferValid,
  getOfferStatus,
  getRemainingTime,
  OTCStatusType,
  OTCType,
} from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import IconCoin from '@Components/icon/IconCoin';
import AssetUnitPriceInfo from '@Components/trade/components/AssetUnitPriceInfo';
import { getOTCStatusConfig } from '@Libs/helpers/getStateConfig';
import { getShortId } from '@Libs/utils';

import { HidableOTCCommissionPanel } from '../components/OTCCommissionPanel';
import OTCInfo from '../components/OTCInfo';

const headerStyle: TableHeaderStyle = { showBackground: true, px: '2xl', py: 'sm' };
const bodyStyle: TableBodyStyle = { px: '2xl', py: 'lg' };

type Props = {
  data: OTCType[];
  acceptRequest: (entry: OTCType) => () => void;
  cancelRequest: (entry: OTCType) => () => void;
  onDetailClick: (id: string) => void;
  filter?: OTCStatusType;
  expireTimeout: () => void;
};
const OTCTable = ({ data, acceptRequest, cancelRequest, onDetailClick, expireTimeout }: Props) => {
  const { t, i18n } = useTranslation();

  const cols = useMemo<ColumnDef<OTCType>[]>(
    () => [
      {
        accessorKey: 'execution_time',
        header: t('otc.table.offer-date'),
        style: (entry) => {
          return checkIsOfferValid(entry) ? { verticalAlign: 'top' } : { verticalAlign: 'middle' };
        },
        cell: (cell) => {
          const entry = cell.row.original;
          if (checkIsOfferValid(entry)) {
            const remaining = getRemainingTime(entry, 'millisecond');

            return (
              <Meta
                space="xs"
                position={{ xs: 'right' }}
                extra={
                  <CircleTimer
                    startAt={remaining}
                    showLabel={false}
                    size="xs"
                    updateInterval={1000}
                    durationType="millisecond"
                    duration={entry.offer_timeout_in_minutes * 1000 * 60}
                    onComplete={expireTimeout}
                  />
                }
                title={
                  <Text size="sm" wordBreak="none">
                    {t('otc.offer-expire-date')}
                  </Text>
                }
                description={
                  <Text size="sm" color="primary-500">
                    <CircleTimer
                      digitalDisplay={true}
                      startAt={remaining}
                      variant="countdown"
                      updateInterval={1000}
                      durationType="millisecond"
                      duration={entry.offer_timeout_in_minutes * 1000 * 60}
                    />
                  </Text>
                }
              />
            );
          }

          return (
            <Flex direction={{ xs: 'row', sm: 'col' }}>
              <Text size="sm">{dayjs(entry.created_at, { locale: i18n.language }).format('DD MMM YYYY,')}</Text>
              <Text size="sm">{dayjs(entry.created_at, { locale: i18n.language }).format('HH:mm')}</Text>
            </Flex>
          );
        },
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },

      {
        accessorKey: 'settlement_time',
        header: t('otc.table.offer'),
        cell: (cell) => {
          const entry = cell.row.original;
          if (checkIsOfferValid(entry)) {
            return (
              <Flex items={{ xs: 'center', md: 'center', lg: 'start' }} width="full">
                <Text size="sm" mb="sm" weight="regular" transform="uppercase">
                  #{getShortId(entry.id)}
                </Text>
                <Badge size="md" variant="square" color="green" transparent>
                  <Block>
                    <Text size="xs" color="neutral-800" style={{ marginBottom: '2px' }}>
                      {t('otc.price-with-commission')}{' '}
                    </Text>
                    <AssetUnitPriceInfo
                      disableTooltip
                      color="green-500"
                      iconColor="green-500"
                      weight="medium"
                      amount={entry.from_amount}
                      assetPairPrice={
                        {
                          price: entry.to_amount,
                          quote_asset: entry.to_asset,
                          base_asset: entry.from_asset,
                        } as AssetPairPriceType
                      }
                      equalitySymbol="="
                    />
                  </Block>
                </Badge>
                <OTCInfo entry={entry} />
                <div>
                  <Block gap="sm">
                    <Badge size="md" variant="square" color="neutral-300" transparent width="size-fit">
                      <Icon type="o:question-mark-circle" size="sm" />
                      <Text ml="xs" as="span" size="xs" weight="medium" color="neutral-700">
                        {t('otc.settlement-day', {
                          date: dayjs(entry.settlement_day, { locale: i18n.language }).format('DD MMM YYYY'),
                        })}
                      </Text>
                    </Badge>
                    <HidableOTCCommissionPanel entry={entry} style={{ minWidth: '330px' }} />
                  </Block>
                </div>
              </Flex>
            );
          }
          return (
            <Block gap="xs">
              <Breadcrumbs listType="disc">
                <Breadcrumbs.Item>
                  <Text size="sm" color="secondary-500" weight="regular">
                    #{getShortId(entry.id)}
                  </Text>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Text size="sm" weight="semibold">
                    {entry.pair?.replace('_', '/')}
                  </Text>
                </Breadcrumbs.Item>
              </Breadcrumbs>
              {entry.network && (
                <Badge size="md" variant="square" color="neutral-200" bordered width="size-fit">
                  <IconCoin type={entry.network} />
                  <Text color="neutral-700" weight="medium" size="sm" ml="xs">
                    {entry.network}
                  </Text>
                </Badge>
              )}
            </Block>
          );
        },
      },

      {
        accessorKey: 'status',
        header: '',
        style: (entry) => {
          return checkIsOfferValid(entry) ? { verticalAlign: 'top' } : { verticalAlign: 'middle' };
        },
        cell: (cell) => {
          const entry = cell.row.original;
          const status = getOfferStatus(entry);
          return (
            <>
              <Badge size="md" variant="square" color={getOTCStatusConfig(status).badgeBg} transparent bordered>
                <Badge size="xs" color={getOTCStatusConfig(status).badgeBg} />
                <Text ml="xs" as="span" size="xs" weight="medium" color="secondary-500">
                  {t(`otc.status.${status}`)}
                </Text>
              </Badge>
            </>
          );
        },
      },

      {
        accessorKey: 'id',
        header: '',
        style: (entry) => {
          return checkIsOfferValid(entry) ? { verticalAlign: 'top' } : { verticalAlign: 'middle' };
        },
        cell: (cell) => {
          const entry = cell.row.original;
          if (checkIsOfferValid(entry)) {
            return (
              <>
                <Flex
                  width="full"
                  direction={{ xs: 'col-reverse', sm: 'row' }}
                  gap="sm"
                  justify={{ xs: 'between', sm: 'between', lg: 'end' }}
                >
                  <Button
                    flexType={{ xs: '1', sm: '1', md: '1', lg: 'none' }}
                    variant="outline"
                    size="sm"
                    onClick={cancelRequest(entry)}
                  >
                    {t(`otc.reject-offer`)}
                  </Button>
                  <Button
                    flexType={{ xs: '1', sm: '1', md: '1', lg: 'none' }}
                    variant="filled"
                    size="sm"
                    onClick={acceptRequest(entry)}
                  >
                    {t(`otc.confirm-offer`)}
                  </Button>
                </Flex>
              </>
            );
          }

          return (
            <Block row gap="sm" justify="end">
              <Button variant="outline" size="sm" onClick={() => onDetailClick(entry.id)}>
                {t(`otc.detail`)}
              </Button>
            </Block>
          );
        },
      },
    ],
    [t, i18n.language],
  );

  return (
    <>
      <DataTable
        data={data}
        columns={cols}
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
        divider
        emptyMessage={t('otc.table.empty-info')}
        rowId="id"
      />
    </>
  );
};

export default withSuspense(
  OTCTable,
  <Block gap="4xl" mt="lg">
    <Loop<number> data={[1, 2, 3]}>
      {(data) => (
        <Block key={`skeleton-${data}`} row justify="between" mx="md">
          <Skeleton height="size-3" width="size-20" />
          <Skeleton height="size-3" width="size-20" />
          <Skeleton height="size-3" width="size-20" />
          <Skeleton height="size-3" width="size-20" />
        </Block>
      )}
    </Loop>
  </Block>,
);
