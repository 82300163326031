import React from 'react';

import { Dropdown, Skeleton, withSuspense } from '@bilira-org/design';
import { CoinSymbolType, CryptoAssetsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import CryptoQuery from '@Libs/clientInstances/cryptoQuery';

type Props = {
  /** Selected value */
  value?: string;
  /** Function to be called when a value is selected */
  onSelect: (value: CryptoAssetsType) => void;
  /** Whether the filter is disabled */
  disabled?: boolean;
  fluid?: boolean;
  excludeTRYB?: boolean;
};

/**
 * Dropdown  component for filtering crypto assets.
 */
const AssetFilter = ({ value, onSelect, disabled, fluid, excludeTRYB }: Props) => {
  const { t } = useTranslation();
  const { data = [] as CryptoAssetsType[] } = CryptoQuery.useGetAssets();
  const filteredData = excludeTRYB ? data.filter((token) => token.symbol !== 'TRYB') : data;

  return (
    <Dropdown
      disabled={disabled}
      textColor={disabled ? 'neutral-500' : undefined}
      color={disabled ? 'neutral-200' : undefined}
      width={fluid ? 'full' : '140'}
      listSize={fluid ? 'full' : '140'}
      justify="between"
      position="right"
      value={value || t('common.all')}
      size="lg"
      dataSource={[{ symbol: undefined }, ...filteredData] as CryptoAssetsType[]}
      onSelect={onSelect}
      filterItems={(item, search) => (item.symbol || t('common.all')).toLowerCase().includes(search.toLowerCase())}
      renderItem={(item) => (
        <Dropdown.Item key={item.symbol || 'all'} selected={item.symbol === value}>
          {item.symbol || t('common.all')}
        </Dropdown.Item>
      )}
    />
  );
};

export default withSuspense(AssetFilter, <Skeleton height="size-14" width="size-36" />);
