import React, { memo } from 'react';

import { Layout } from '@bilira-org/design';
import { Outlet } from 'react-router-dom';

import Header from '@Components/layouts/components/Header';
import Sidebar from '@Components/layouts/components/Sidebar';

import ErrorBoundary from '../errorBoundary/ErrorBoundary';

const { Head, Main, Container } = Layout;

function LayoutError() {
  return (
    <ErrorBoundary>
      <Layout>
        <Head>
          <Header />
        </Head>
        <Container>
          <Sidebar />
          <Main>
            {/* Replacing your content */}
            <Outlet />
            {/* /End replace */}
          </Main>
        </Container>
      </Layout>
    </ErrorBoundary>
  );
}

export default memo(LayoutError);
