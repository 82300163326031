import React, { useEffect, useState } from 'react';

import { Block, Flex, Icon, Label, Modal, Panel, PanelCopy, Skeleton, Text, withSuspense } from '@bilira-org/design';
import { CryptoAssetsType } from '@bilira-org/react-utils';
import type { CryptoNetworkType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { Qr } from '@/components/common/Qr';
import { getIconURLByType } from '@/libs/utils';
import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';
import useAuth from '@Libs/hooks/userAuth';

type Props = {
  network?: CryptoNetworkType;
  asset?: CryptoAssetsType;
};
const DepositWalletAddress = ({ network, asset }: Props) => {
  const { account } = useAuth();
  const [qrModalActive, setQrModalActive] = useState(false);
  const { t } = useTranslation();

  const { data, refetch } = CryptoWalletApi.useGetDepositWallet(
    {
      account: account,
      asset: asset?.symbol,
      network: network?.alias,
    },
    { enabled: false, suspense: false },
  );

  useEffect(() => {
    if (network && asset) {
      refetch().then();
    }
  }, [network, asset]);

  if (!network?.alias) {
    return null;
  }

  return (
    <>
      <Label>{t('crypto.selected-network-address')}</Label>
      {data?.address ? (
        <PanelCopy
          testId="address-panel"
          extra={
            <Icon
              testId="address-qr-btn"
              size="lg"
              color="neutral-600"
              type="o:qr-code"
              onClick={() => {
                setQrModalActive(true);
              }}
            />
          }
          copiedText={t('common.copied')}
        >
          {data?.address}
        </PanelCopy>
      ) : (
        <Panel py="lg" px="md" border="neutral-400" radius="lg">
          <Block row justify="between">
            <Skeleton width="size-56" />
            <Skeleton width="size-9" />
          </Block>
        </Panel>
      )}
      <Modal
        responsive={false}
        showOverlay={false}
        size="md"
        color="theme-wb"
        closable={true}
        closeButton={<></>}
        onClose={setQrModalActive}
        open={qrModalActive}
        testId="address-qr-modal"
      >
        {data && (
          <Flex items="center">
            <Qr
              size={144}
              value={data.address}
              mb="lg"
              level="H"
              imageSettings={{
                src: getIconURLByType(network.alias) || '',
                height: 28,
                width: 28,
                excavate: true,
                rounded: true,
              }}
            />
          </Flex>
        )}

        <Flex direction="row" gap="xs" items="start">
          <Flex.Item flexType="none">
            <Icon type="o:arrow-right" size="xs" />
          </Flex.Item>
          <Flex.Item>
            <Text size="xs" weight="medium">
              {t('crypto.deposit-only-this-asset', { name: asset?.name, symbol: asset?.symbol })}
            </Text>
          </Flex.Item>
        </Flex>

        <Flex direction="row" gap="xs" items="start">
          <Flex.Item flexType="none">
            <Icon type="o:arrow-right" size="xs" />
          </Flex.Item>
          <Flex.Item>
            <Text size="xs" weight="medium">
              {t('crypto.verify-your-network', { network: network?.name })}
            </Text>
          </Flex.Item>
        </Flex>
      </Modal>
    </>
  );
};

export default DepositWalletAddress;
