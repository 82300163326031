import React from 'react';

import { Button, Display, Flex, Image, Link, Modal, Text } from '@bilira-org/design';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { withPrivateRoute } from '@/libs/hocs';
import { useGeneralPersistedStore } from '@/store';
import CommonQuery from '@Libs/clientInstances/commonQuery';

const AnnouncementPopupModal = () => {
  const { t } = useTranslation();
  const { data } = CommonQuery.useGetAnnouncementPopup();
  const [closedAnnouncementPopupId, setClosedAnnouncementPopupId] = useGeneralPersistedStore((state) => [
    state.closedAnnouncementPopupId,
    state.setClosedAnnouncementPopupId,
  ]);

  if (!data) {
    return;
  }

  const isPopupOpen = !dayjs().isAfter(data.expires_at) && data.id !== closedAnnouncementPopupId;

  return (
    <Modal open={isPopupOpen} variant="popup" compound onClose={() => setClosedAnnouncementPopupId(data.id)}>
      <Display show={!!data.image}>
        <Image alt="" width="size-full" src={data.image} skeletonAspectRatio={512 / 160} />
      </Display>

      <Flex p={{ xs: '2xl', sm: '4xl' }} mb={{ xs: 'sm', sm: 'lg' }} gap="2xl">
        <Flex gap="md">
          <Display show={!!data.title}>
            <Text weight="bold" color="neutral-900" size={{ xs: 'sm', sm: 'base' }}>
              {data.title}
            </Text>
          </Display>
          <Display show={!!data.description}>
            <Text
              color="neutral-900"
              colorizeHtml
              dangerouslySetInnerHTML={{ __html: data.description || '' }}
              size={{ xs: 'sm', sm: 'base' }}
            />
          </Display>
        </Flex>
        <Display show={!!data.link}>
          <Link href={data.link} anchor target="__blank">
            <Button variant="filled" size="md">
              {/* TODO: Remove fallback value when BE development is done */}
              {data.button_text || t('common.show-details')}
            </Button>
          </Link>
        </Display>
      </Flex>
    </Modal>
  );
};

export default withPrivateRoute(AnnouncementPopupModal);
