import React, { useRef, useState } from 'react';

import { Alert, Block, Modal, notification, Stepper, withSuspense } from '@bilira-org/design';
import { getErrorMessage } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import TwoFA from '@Components/TwoFA/components/TwoFA';
import UserQuery from '@Libs/clientInstances/userQuery';
import StepQR from '@Modules/account/security/twoFA/authenticator/components/StepQR';
import TwoFAAuthenticator from '@Modules/account/security/twoFA/authenticator/components/TwoFAAuthenticator';

interface Props {
  modalActive: boolean;
  setModalActive: (active: boolean) => void;
}

function AuthenticatorModal({ modalActive, setModalActive }: Props) {
  const { t } = useTranslation();
  const { mutateAsync, data, failureReason } = UserQuery.usePostVerifyTOTP();
  const twoFATokenRef = useRef<string>('');
  const [currentStep, setCurrentStep] = useState(0);

  const callback = (token: string) => {
    twoFATokenRef.current = token;
    mutateAsync({ two_fa_token: token, enable: true }).then(() => {
      changeStep();
    });
  };

  const changeStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const completed = () => {
    notification.success(t('two-fa.two-fa-enabled'));
    setModalActive(false);
  };

  const onModalClose = () => {
    setCurrentStep(0);
    setModalActive(false);
  };

  const failureMessage = getErrorMessage(failureReason);

  return (
    <>
      <Modal
        title={currentStep === 1 ? t('two-fa.enable-authenticator-app') : t('two-fa.security-verification')}
        titleSeparator={currentStep !== 1}
        onClose={onModalClose}
        open={modalActive}
      >
        <Stepper step={currentStep}>
          <Stepper.Item key="authenticator-step-1">
            {failureMessage && (
              <Alert status="red" mb="md">
                {failureMessage}
              </Alert>
            )}
            <Block mt="md">
              <TwoFA type="totpSettings" twoFAData={{ email: true, totp: false, sms: false }} callback={callback} />
            </Block>
          </Stepper.Item>
          <Stepper.Item key="authenticator-step-2">
            <StepQR data={data} callback={changeStep} />
          </Stepper.Item>
          <Stepper.Item key="authenticator-step-3">
            <Block mt="md">
              <TwoFAAuthenticator callback={completed} />
            </Block>
          </Stepper.Item>
        </Stepper>
      </Modal>
    </>
  );
}

export default withSuspense(AuthenticatorModal);
