import { CoinSymbolType } from '../../model';

/**
 * Concatenates the base and quote assets to create a ticker string.
 *
 * @param {string} base - The base asset.
 * @param {string} quote - The quote asset.
 * @returns {string} The concatenated ticker string.
 */
export const concatTicker = (base?: string, quote?: string): string => {
  if (!base || !quote) {
    return '';
  }

  const tickerQuote = adjustQuoteAsset(base, quote);
  return `${base}${tickerQuote}`;
};

/**
 * Adjusts the quote asset based on the given base and defaultQuoteAsset.
 *
 * @param {string} base - The base asset.
 * @param {string} defaultQuoteAsset - The default quote asset.
 * @returns {string} - The adjusted quote asset.
 */
export const adjustQuoteAsset = (base: string, defaultQuoteAsset: string): string => {
  if (base === 'TRYB' && defaultQuoteAsset === 'TRYB') {
    return 'TRY';
  }

  return defaultQuoteAsset;
};

// Since swap token selection depends on base asset, base asset must be a valid asset which included among pairs.
// Since we don't have TRY among pairs, we should do this check.
// NOTE: Yet, this TRY is necessary for chart detail. And chart detail does not use this function.
const filterBaseAsset = (asset: string) => {
  if (asset === 'TRY') {
    return 'TRYB';
  }

  return asset;
};

/**
 * Parses a given asset pair string and returns an array of coin symbols.
 *
 * The function expects a string in the format "COIN1_COIN2", where COIN1 and COIN2 are the symbols of the coins.
 * It splits this string by the underscore and returns an array containing these two coin symbols.
 * If the input is not in the correct format or is absent, the function returns an empty array.
 *
 * @param {string} [assetPair] - The asset pair string to be parsed.
 * @returns {[CoinSymbolType, CoinSymbolType] | []} An array of two coin symbols if the input is valid, otherwise an empty array.
 *
 * @example
 * // Example when the asset pair is correctly formatted:
 * parseAssetPair('BTC_ETH');
 * // Returns: ['BTC', 'ETH']
 *
 * @example
 * // Example when the asset pair is incorrectly formatted:
 * parseAssetPair('BTCETH');
 * // Returns: []
 *
 * @example
 * // Example when the asset pair is not provided:
 * parseAssetPair();
 * // Returns: []
 */
const parseAssetPair = (assetPair?: string) => {
  if (!assetPair) {
    return [];
  }

  const result = parsePairs<CoinSymbolType[]>(assetPair);

  if (result.length > 1) {
    return result;
  }

  return [];
};

export const parsePairs = <T>(pairs: string, splitBy: string = '_') => {
  const result = pairs.split(splitBy);
  return result.map((pair) => filterBaseAsset(pair)) as T;
};
export default parseAssetPair;
