import React from 'react';

import { LayoutCommon } from '@Components/layouts';
import Header from '@Modules/crypto/deposit/components/Header';
import CryptoDeposit from '@Modules/crypto/deposit/CryptoDeposit';
import LastCryptoDeposits from '@Modules/crypto/deposit/LastCryptoDeposits';

const CryptoDepositContainer = () => {
  return (
    <>
      <Header />
      <LayoutCommon>
        <LayoutCommon.Content>
          <CryptoDeposit />
        </LayoutCommon.Content>
        <LayoutCommon.Sidebar>
          <LastCryptoDeposits />
        </LayoutCommon.Sidebar>
      </LayoutCommon>
    </>
  );
};

export default CryptoDepositContainer;
