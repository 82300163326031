import { getRewardTaskState } from './getRewardTaskState';
import { UserParticipationType } from '../../model';

/**
 * Determines the sorting precedence for a reward task based on its current state.
 *
 * Tasks are assigned precedence as follows:
 * - 'recurringActive': 3
 * - 'recurringCoolDown': 2
 * - 'completed': 1
 * - 'recurringToDo' or 'toDo': 0
 *
 * This allows tasks to be sorted with 'recurringActive' tasks having the highest priority
 * and 'toDo' or 'recurringToDo' having the lowest.
 *
 * @param {UserParticipationType} task - The task object representing user participation.
 * @returns {number} - A numeric value representing the sorting precedence of the task, where higher numbers indicate higher precedence.
 */
export function getRewardTaskSortingPrecedence(task: UserParticipationType) {
  const state = getRewardTaskState(task);

  switch (state) {
    case 'recurringActive':
      return 3;
    case 'recurringCoolDown':
      return 2;
    case 'completed':
      return 1;
    case 'recurringToDo':
    case 'toDo':
      return 0;
  }
}
