import { useCallback, useMemo } from 'react';

import { useDarkMode } from '@bilira-org/design';
import { getI18n } from 'react-i18next';

import { useGeneralSessionStore } from '@/store/generalSessionStore';
import PreferencesApi from '@Libs/clientInstances/preferencesQuery';
import loadPreferences from '@Libs/hooks/helpers/loadPreferences';

type Props = {
  /** Whether the query hook should be enabled. */
  enabled?: boolean;
  /** Whether to use suspense mode while fetching data. */
  suspense?: boolean;
};

/**
 * Hook for managing user preferences, such as language, theme, and completed tours.
 *
 * @param {Props} [props] - Additional properties for customizing query behavior.
 * @returns - An object containing user preferences, a function to initializing default preferences, and query states.
 */
const useUserPreferences = (props: Props = {}) => {
  const [, setEnableDarkMode] = useDarkMode();
  const setIsNotificationViewed = useGeneralSessionStore((state) => state.setIsNotificationViewed);

  const { data, ...rest } = PreferencesApi.useGetUserPreferences({
    enabled: props.enabled,
    suspense: props.suspense,
  });

  const preferences = useMemo(() => loadPreferences(data), [data]);

  const setDefaultPreferences = useCallback(() => {
    if (preferences) {
      setEnableDarkMode(preferences.isDarkModeEnabled);
      getI18n().changeLanguage(preferences.language).then();
      setIsNotificationViewed(false);
    }
  }, [preferences, setEnableDarkMode]);

  return {
    preferences: preferences,
    setDefaultPreferences,
    ...rest,
  } as const;
};

export default useUserPreferences;
