import React, { HTMLAttributes, memo } from 'react';

import classNames from 'classnames';

import {
  BaseComponentType,
  BreakpointType,
  FlexItemsType,
  GapSizes,
  JustifyType,
  MarginSizes,
  MaxWidthType,
  MinWidthType,
  PaddingSizes,
  PositionType,
  ResponsiveReturnType,
  SizeType,
  TextAlign,
} from '../types';
import { buildResponsiveClass } from '../utils';

export interface BlockProps extends HTMLAttributes<HTMLElement>, BaseComponentType {
  /** Children to render inside the Block */
  children: React.ReactNode | React.ReactElement[];
  /** Whether to wrap the flex items*/
  wrap?: boolean;
  /** Gap size between flex items*/
  gap?: GapSizes;
  /** Whether to add divider between children */
  divide?: 'x' | 'y';
  /** Justify content */
  justify?: JustifyType;
  /** Align items */
  items?: FlexItemsType;
  /** Align text */
  align?: TextAlign;
  /** Whether to use flexbox */
  flex?: boolean;
  /** Whether to use flexbox row */
  row?: boolean;
  /** Min height size */
  minHeight?: MinWidthType;
  /** Min width size */
  minWidth?: MinWidthType;
  /** Max width size */
  maxWidth?: MaxWidthType;
  /** Width size */
  width?: SizeType;
  /** Height size */
  height?: SizeType;
  /** Padding size */
  p?: PaddingSizes | BreakpointType<PaddingSizes>;
  /** Padding top size */
  pt?: PaddingSizes | BreakpointType<PaddingSizes>;
  /** Padding x-axis size */
  px?: PaddingSizes;
  /** Padding y-axis size */
  py?: PaddingSizes;
  /** Margin bottom size */
  mb?: MarginSizes;
  /** Margin top size */
  mt?: MarginSizes;
  /** Margin y-axis size */
  my?: MarginSizes;
  /** Margin x-axis size */
  mx?: MarginSizes;
  /** Position type */
  position?: PositionType;
}

/**
 * Block component with flexbox properties
 *
 * @example
 *  <Block row gap="lg" items="center">
 *       <Badge color="primary" size="md">Apple</Badge>
 *       <Badge color="secondary" size="md">Orange</Badge>
 *       <Badge color="primary" size="md">Banana</Badge>
 *  </Block>
 */
const Block = memo(
  ({
    flex = true,
    row,
    minHeight,
    minWidth,
    maxWidth,
    width,
    height,
    justify,
    items,
    wrap,
    align,
    divide,
    mb,
    mt,
    my,
    mx,
    p,
    pt,
    px,
    py,
    className,
    gap,
    position,
    testId,
    onClick,
    ...props
  }: BlockProps) => {
    let paddingTopClass: ResponsiveReturnType = {};

    if (pt) {
      paddingTopClass = buildResponsiveClass(pt, 'pt-');
    }

    let paddingClass: ResponsiveReturnType = {};

    if (p) {
      paddingClass = buildResponsiveClass(p, 'p-');
    }

    const classes = classNames(
      {
        'flex tw-flex-col': flex,
        'tw-flex-row': row && flex,
        [`gap-${gap}`]: gap,
        [`tw-min-h-${minHeight}`]: minHeight,
        [`tw-min-w-${minWidth}`]: minWidth,
        [`tw-max-w-${maxWidth}`]: maxWidth,
        [`tw-justify-${justify}`]: justify,
        [`tw-text-${align}`]: align,
        [`tw-items-${items}`]: items,
        [`tw-divide-${divide}`]: divide,
        [`tw-w-${width}`]: width,
        [`tw-h-${height}`]: height,
        [`tw-position-${position}`]: position,
        'flex-wrap': wrap,
        [`px-${px}`]: px,
        [`py-${py}`]: py,
        [`mb-${mb}`]: mb,
        [`mt-${mt}`]: mt,
        [`my-${my}`]: my,
        [`mx-${mx}`]: mx,
        [`cursor-pointer`]: !!onClick,
      },
      paddingTopClass,
      paddingClass,
      className,
    );

    return (
      <div className={classes} data-testid={testId ?? 'block'} onClick={onClick} {...props}>
        {props.children}
      </div>
    );
  },
);

Block.displayName = 'Block';
export default Block;
