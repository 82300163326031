import React from 'react';

import { Block, Hidden, Icon, Layout, Link, Meta, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import Menubar from '@Components/common/Menubar';

const { Hero } = Layout;

const DepositInfoBar = () => {
  const { t } = useTranslation();

  const headers = [
    {
      text: t('crypto.crypto-deposit-tab'),
      defaultPath: true,
      path: '/crypto/deposit',
      icon: <Icon type="o:arrow-down-tray" size="lg" />,
    },
    {
      text: t('crypto.crypto-withdraw-tab'),
      path: '/crypto/withdraw',
      icon: <Icon type="o:arrow-up-tray" size="lg" />,
    },
  ];

  return (
    <Hero size="none">
      <div>
        <Block row justify="between" items="center">
          <Block row pt={{ xs: 'lg', sm: '2xl', lg: '4xl' }} items="end">
            <Menubar data={headers} />
          </Block>

          <Hidden show={{ md: true }}>
            <Link anchor href={t('urls.crypto-deposit-not-showing')} target="_blank" rel="noreferrer">
              <Meta
                extra={<Icon type="o:exclaimation-circle" color="white" size="md" />}
                space="xs"
                title={
                  <Text weight="medium" color="white">
                    {t('crypto.why-deposit-not-arrived')}
                  </Text>
                }
              />
            </Link>
          </Hidden>
        </Block>
      </div>
    </Hero>
  );
};

export default DepositInfoBar;
