import React from 'react';

import { Button } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { withPrivateRoute, withPublicRoute } from '@/libs/hocs';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';

import RewardTask from './RewardTask';

const PrivatePrerequisites = withPrivateRoute(() => {
  const { t } = useTranslation();
  const { data } = RewardsQuery.useGetUserParticipationPrerequisites({ suspense: false });
  const { mutateAsync, isPending } = RewardsQuery.usePatchUserParticipation({ suspense: false });

  return (
    <>
      {data?.tasks.map((task) => <RewardTask key={task.id} task={task} color="theme-wn" />)}
      {data?.can_participate && !data.participated_at && (
        <Button mt="xs" variant="filled" size="md" stretch onClick={() => mutateAsync()} loading={isPending}>
          {t('rewards.join-point-system')}
        </Button>
      )}
    </>
  );
});

const PublicPrerequisites = withPublicRoute(() => {
  const { data } = RewardsQuery.useGetParticipationPrerequisites({ suspense: false });
  return <>{data?.tasks.map((task) => <RewardTask key={task.id} task={task} color="theme-wn" />)}</>;
});

const Prerequisites = () => {
  return (
    <>
      <PublicPrerequisites />
      <PrivatePrerequisites />
    </>
  );
};
export default Prerequisites;
