import { useMemo, useState } from 'react';

import {
  Block,
  Button,
  ColumnDef,
  DataTable,
  Flex,
  Icon,
  Modal,
  Number,
  Pagination,
  Separator,
  Skeleton,
  TableBodyStyle,
  TableHeaderStyle,
  Text,
  Tooltip,
} from '@bilira-org/design';
import { TransactionsRecordType } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import AccountApi from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';

import ReferralTotalEarning from './ReferralTotalEarning';

const headerStyle: TableHeaderStyle = { showBackground: true, px: 'md', py: 'sm' };
const bodyStyle: TableBodyStyle = { px: 'md', py: 'md' };

type Props = {
  open: boolean;
  onClose: () => void;
};

const LIMIT = 4;
const ReferralHistoryModal = ({ open, onClose }: Props) => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const { account } = useAuth();

  const { data: { records: data = [], _metadata: metadata } = {}, isPending } = AccountApi.useTransactions(
    {
      params: {
        limit: LIMIT,
        offset: page * LIMIT - LIMIT,
        type: 'other',
        direction: 'payback',
      },
      id: account,
    },
    { suspense: false },
  );

  const columns = useMemo<ColumnDef<TransactionsRecordType>[]>(
    () => [
      {
        header: () => <Text size="xs">{t('common.date')}</Text>,
        accessorKey: 'created_at',
        cell: ({ row: { original: entry } }) => (
          <Text size="xs">{dayjs(entry.created_at).locale(i18n.language).format('DD MMMM YYYY')}</Text>
        ),
        skeleton: <Skeleton height="size-4" width="size-14" />,
      },
      {
        header: () => (
          <Text align="right" size="xs">
            {t('referral.earned-commission')}
          </Text>
        ),
        accessorKey: 'amount',
        cell: ({ row: { original: entry } }) => (
          <Number value={entry.amount} suffix={entry.asset} color="neutral-800" size="sm" align="right" />
        ),
        skeleton: (
          <Flex direction="row" justify="end">
            <Skeleton height="size-4" width="size-14" />
          </Flex>
        ),
      },
    ],
    [i18n.language, t],
  );

  return (
    <Modal size="lg" onClose={onClose} open={open} title={t('referral.referral-earning-history')}>
      <Text mb="2xl" size="sm">
        {t('referral.history-description')}
      </Text>
      <Block
        style={{
          minHeight: '214px', // min height to prevent layout shifts with data
        }}
      >
        <DataTable.Responsive
          loading={isPending}
          skeletonRowCount={LIMIT}
          headerStyle={headerStyle}
          bodyStyle={bodyStyle}
          divider
          data={data}
          emptyMessage={t('common.empty-message')}
          columns={columns}
        />
      </Block>
      <Separator mt="md" mb="lg" width="size-full" />
      <Pagination
        onPageChange={setPage}
        pagination={{
          page: metadata?.page,
          pageCount: metadata?.page_count,
          perPage: metadata?.per_page,
          totalCount: metadata?.total_count,
        }}
      />
      <Flex direction="row" mt="auto" pt="2xl" gap="xl" wrap="wrap-reverse">
        <Flex flexType="auto">
          <Button stretch variant="filled" disabled size="xl">
            <Icon type="o:document-arrow-down" color="neutral-600" size="lg" />
            <Text color="neutral-600" ml="md">
              {t('referral.export')}
            </Text>
          </Button>
        </Flex>
        <Flex shrink="0" ml="auto">
          <Block row gap="xs" items="center">
            <Text color="neutral-700" size="xs">
              {t('referral.total-earning')}
            </Text>
            <Tooltip width="size-56" placement="top" message={t('referral.tryb-equivalent-of-earned-commissions')}>
              <Icon color="neutral-600" type="o:information-circle" size="sm" />
            </Tooltip>
          </Block>
          <ReferralTotalEarning />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ReferralHistoryModal;
