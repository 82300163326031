import { Icon, Tooltip } from '@bilira-org/design';
import { RewardTaskState } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

interface TaskEndIconProps {
  taskState: RewardTaskState;
}
function RewardTaskEndIcon({ taskState }: TaskEndIconProps) {
  const { t } = useTranslation();

  if (taskState === 'recurringActive') {
    return (
      <Tooltip message={t('rewards.recurring-task-description')} width="size-64">
        <Icon type="s:check-circle" size="xl" color="primary-500" spinner={{ size: 'md', color: 'primary-100' }} />
      </Tooltip>
    );
  }

  return (
    <>
      {taskState !== 'toDo' && taskState !== 'recurringToDo' ? (
        <Icon type="s:check-circle" size="xl" color="primary-500" />
      ) : (
        <Icon size="md" overlaySize="3xl" type="s:chevron-right" overlayColor="theme-n300d700" color="neutral-900" />
      )}
    </>
  );
}

export default RewardTaskEndIcon;
