import React from 'react';

import { Flex, Layout, Panel, Separator } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';
import Header from '@Modules/assets/components/Header';
import MyAssetContainer from '@Modules/assets/components/MyAssetContainer';
import MyWalletOverview from '@Modules/assets/components/MyWalletOverview';

const { Content } = Layout;

const Index = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeadTags title={t('navigation.assets')} titleSuffix="subpage" />
      <Header />
      <Content>
        <Flex gap="4xl">
          <Panel border="neutral-400" size="none">
            <MyWalletOverview />
            <Separator width="size-full" />
            <MyAssetContainer />
          </Panel>
          <TransactionPanelCard
            id="assets"
            title={t('assets.recent-transactions')}
            description={t('assets.last-n-transactions', { n: 5 })}
            limit={5}
            showAllButton
          />
        </Flex>
      </Content>
    </>
  );
};

export default Index;
