import React from 'react';

import { Layout } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import HeadTags from '@/components/common/HeadTags';
import DepositInfoBar from '@Modules/fiat/components/DepositInfoBar';
import WithdrawInfoBar from '@Modules/fiat/components/WithdrawInfoBar';
import FiatDepositContainer from '@Modules/fiat/deposit/FiatDepositContainer';
import WithdrawFiatContainer from '@Modules/fiat/withdraw/WithdrawFiatContainer';

const { Content } = Layout;

const FiatBoard = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const defaultRoute = pathname === '/fiat/withdraw';

  return (
    <>
      <HeadTags title={t('navigation.crypto')} titleSuffix="subpage" />
      {defaultRoute ? <WithdrawInfoBar /> : <DepositInfoBar />}
      <Content>{defaultRoute ? <WithdrawFiatContainer /> : <FiatDepositContainer />}</Content>
    </>
  );
};

export default FiatBoard;
