import { CSSProperties } from 'react';

import { Column } from '@tanstack/react-table';
import classNames from 'classnames';

import { DataTableProps } from './dataTable/dataTable';
import { BreakpointType, ResponsiveReturnType } from '../types';

/**
 * Generates the CSS styles for columns based on their states.
 *
 * @template T - The type of data used in the table.
 * @param {Column<T>} column - The column object for which to generate styles.
 * @returns {CSSProperties} - The CSS styles for the column.
 */
export function getCommonColumnStyles<T>(column: Column<T>): CSSProperties {
  const isPinned = column.getIsPinned();
  if (isPinned) {
    return {
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      width: column.getSize(),
    };
  } else {
    return {
      width: column.columnDef.size !== Number.MAX_SAFE_INTEGER ? column.getSize() : undefined,
    };
  }
}

/**
 * Generates the CSS classes for a column based on its pinned and visibility states.
 *
 * @template T - The type of data used in the table.
 * @param {Column<T>} column - The column object for which to generate classes.
 * @returns {string} - The generated CSS classes for the column.
 */
export function getColumnClasses<T>(column: Column<T>) {
  return classNames(getPinnedColumnClasses(column), getHiddenClass(column.columnDef.show));
}

/**
 * Generates the CSS classes for pinned columns.
 *
 * @template T - The type of data used in the table.
 * @param {Column<T>} column - The column object for which to generate pinned classes.
 * @returns {string} - The CSS class for the pinned column.
 */
function getPinnedColumnClasses<T>(column: Column<T>): string {
  const isPinned = column.getIsPinned();
  if (isPinned) {
    return classNames('pinned-cell');
  } else return '';
}

/**
 * Generates the CSS classes for hiding or showing a column based on the breakpoints.
 *
 * @param {boolean | BreakpointType<boolean>} [show=true] - The visibility state or breakpoint configuration for the column.
 * @param {'table-cell' | 'flex'} [displayClass='table-cell'] - The CSS class for displaying the column.
 * @returns {string} - The generated CSS classes for visibility based on the breakpoints.
 */
export function getHiddenClass(
  show: boolean | BreakpointType<boolean> = true,
  displayClass: 'table-cell' | 'flex' = 'table-cell',
) {
  let hiddenClass: ResponsiveReturnType;

  if (typeof show === 'object') {
    hiddenClass = {
      'tw-hidden': true,
      [show.xs ? `xs:${displayClass}` : `xs:hidden`]: show.xs !== undefined,
      [show.sm ? `sm:${displayClass}` : `sm:hidden`]: show.sm !== undefined,
      [show.md ? `md:${displayClass}` : `md:hidden`]: show.md !== undefined,
      [show.lg ? `lg:${displayClass}` : `lg:hidden`]: show.lg !== undefined,
      [show.xl ? `xl:${displayClass}` : `xl:hidden`]: show.xl !== undefined,
    };
  } else {
    hiddenClass = { 'tw-hidden': !show };
  }

  return classNames(hiddenClass);
}

/**
 * Generates CSS classes for stretching elements based on the breakpoints.
 *
 * @param {boolean | BreakpointType<boolean>} stretch - The stretch configuration or boolean to apply full width.
 * @returns {object} - The generated CSS classes for stretch based on the breakpoints.
 */
export function generateStretchClasses(stretch: boolean | BreakpointType<boolean>) {
  if (typeof stretch === 'object') {
    return {
      [stretch['xs'] ? `xs:w-full` : `xs:w-auto xs:border-separate xs:border-spacing-x-12`]: true,
      [stretch['sm'] ? `sm:w-full` : `sm:w-auto sm:border-separate sm:border-spacing-x-12`]: true,
      [stretch['md'] ? `md:w-full` : `md:w-auto md:border-separate md:border-spacing-x-12`]: true,
      [stretch['lg'] ? `lg:w-full` : `lg:w-auto lg:border-separate lg:border-spacing-x-12`]: true,
    };
  } else {
    return { [`w-full`]: !!stretch };
  }
}

/**
 * Retrieves a function or key for generating row IDs.
 *
 * @template T - The type of data used in the table.
 * @param {Pick<DataTableProps<T>, 'rowId' | 'loading'>} params - An object containing the rowId and loading state.
 * @returns {(row: T) => string | undefined} - A function for generating row IDs or undefined if the rowId is not provided.
 */
export const getRowId = <T>({ rowId, loading }: Pick<DataTableProps<T>, 'rowId' | 'loading'>) => {
  if (!rowId || loading) {
    return undefined; // rowId is same as index
  } else if (typeof rowId === 'function') {
    return rowId;
  } else {
    return (row: T) => String(row[rowId as keyof T]);
  }
};

/**
 * Determines if a row should be highlighted based on the highlightedRowKey.
 *
 * @template T - The type of data used in the table.
 * @param {object} params - An object containing the row, rowId, and highlightedRowKey.
 * @param {T} params.row - The row data.
 * @param {string} params.rowId - The ID of the row.
 * @param {DataTableProps<T>['highlightedRowKey']} params.highlightedRowKey - The key or function to determine if the row should be highlighted.
 * @returns {boolean} - Whether the row should be highlighted.
 */
export function highlightFn<T>({
  row,
  rowId,
  highlightedRowKey,
}: {
  row: T;
  rowId: string;
  highlightedRowKey: DataTableProps<T>['highlightedRowKey'];
}): boolean {
  try {
    if (!row) {
      return false;
    }

    if (typeof highlightedRowKey === 'string') {
      return highlightedRowKey === rowId.toLowerCase();
    }

    if (typeof highlightedRowKey === 'function') {
      return highlightedRowKey(row);
    }
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 * Inverts the boolean values of a BreakpointType object.
 *
 * @param {BreakpointType<boolean>} breakpoints - The breakpoints object to invert.
 * @returns {BreakpointType<boolean>} - The inverted breakpoints object.
 */
export function invertBreakpointType(breakpoints: BreakpointType<boolean>): BreakpointType<boolean> {
  const inverted: BreakpointType<boolean> = {};

  const breakpointKeys: (keyof BreakpointType<boolean>)[] = ['xs', 'sm', 'md', 'lg', 'xl'];

  breakpointKeys.forEach((key) => {
    const value = breakpoints[key];
    inverted[key] = value === undefined ? true : !value;
  });

  return inverted;
}
