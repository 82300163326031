import React, { useMemo } from 'react';

import {
  Block,
  ColumnDef,
  DataTable,
  Flex,
  Meta,
  Overflow,
  TableBodyStyle,
  TableHeaderStyle,
  Text,
} from '@bilira-org/design';
import { CoinSymbolType, NetworkAliasType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { useGeneralSessionStore } from '@/store';
import IconCoin from '@Components/icon/IconCoin';
import cryptoQuery from '@Libs/clientInstances/cryptoQuery';
import MinDepositLimit from '@Modules/settings/commission/fee/components/MinDepositLimit';

import EmptyText from './EmptyText';
import Fee from './Fee';
import MinTransaction from './MinTransaction';

const headerStyle: TableHeaderStyle = { showBackground: true, px: '2xl', py: 'sm' };
const bodyStyle: TableBodyStyle = { px: '2xl', py: 'lg' };

const data: {
  id: string;
  coin: CoinSymbolType;
  network?: { alias: NetworkAliasType; name: string }[];
}[] = [
  {
    id: '1',
    coin: 'TRY',
  },
  {
    id: '2',
    coin: 'TRYB',
    network: [
      { name: 'Ethereum (ERC20)', alias: 'ETH' },
      { name: 'BNB Smart Chain (BEP20)', alias: 'BSC' },
      { name: 'Solana', alias: 'SOL' },
      { name: 'Polygon', alias: 'MATIC' },
      { name: 'AVAX C-Chain', alias: 'AVAXC' },
    ],
  },
  {
    id: '3',
    coin: 'AVAX',
    network: [{ name: 'AVAX C-Chain', alias: 'AVAXC' }],
  },
  {
    id: '4',
    coin: 'BNB',
    network: [{ name: 'BNB Smart Chain (BEP20)', alias: 'BSC' }],
  },
  {
    id: '5',
    coin: 'BTC',
    network: [{ name: 'Bitcoin', alias: 'BTC' }],
  },
  {
    id: '6',
    coin: 'ETH',
    network: [{ name: 'Ethereum (ERC20)', alias: 'ETH' }],
  },
  {
    id: '7',
    coin: 'MATIC',
    network: [{ name: 'Polygon', alias: 'MATIC' }],
  },
  {
    id: '8',
    coin: 'SOL',
    network: [{ name: 'Solana', alias: 'SOL' }],
  },
  {
    id: '9',
    coin: 'TRX',
    network: [{ name: 'Tron [Shasta]', alias: 'TRON' }],
  },
  {
    id: '10',
    coin: 'USDC',
    network: [{ name: 'Ethereum (ERC20)', alias: 'ETH' }],
  },
  {
    id: '11',
    coin: 'USDT',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '12',
    coin: 'BMMF',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '13',
    coin: 'GALA',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '14',
    coin: 'SAND',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '15',
    coin: 'AXS',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '16',
    coin: 'CHZ',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '17',
    coin: 'USDY',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '18',
    coin: 'PEPE',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '19',
    coin: 'RENDER',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '20',
    coin: 'FLOKI',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '21',
    coin: 'UNI',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '22',
    coin: 'SHIB',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '23',
    coin: 'DAI',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '24',
    coin: 'OM',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
  {
    id: '25',
    coin: 'ZRO',
    network: [
      {
        name: 'Ethereum (ERC20)',
        alias: 'ETH',
      },
    ],
  },
];

const DepositWithdraw = () => {
  const { t } = useTranslation();
  const isLoggedIn = useGeneralSessionStore((state) => state.isLoggedIn);

  const { data: withdrawalFees, isLoading: withdrawalFeesLoading } = cryptoQuery.useGetWithdrawalFees({
    suspense: false,
  });

  const columns = useMemo<ColumnDef<(typeof data)[number]>[]>(
    () => [
      {
        header: 'Coin/Token',
        accessorKey: 'coin',
        cell: (entry) => {
          return (
            <Meta
              space="sm"
              extra={<IconCoin size="2xl" type={entry.row.original.coin} />}
              title={
                <Text color="secondary-500" size="sm" weight="medium">
                  {entry.row.original.coin === 'TRY' ? t('commission.bank-transfer') : entry.row.original.coin}
                </Text>
              }
            />
          );
        },
      },
      {
        header: t('commission.network'),
        accessorKey: 'network',
        cell: ({ row: { original: entry } }) => {
          if (!entry.network) {
            return <EmptyText />;
          }

          return (
            <Flex gap="xs" items={{ xs: 'end' }}>
              {entry.network.map((item) => (
                <Text key={item.alias} size="sm" weight="regular">
                  {item.name}
                </Text>
              ))}
            </Flex>
          );
        },
      },
      {
        header: t('commission.min-deposit'),
        accessorKey: 'network',
        id: 'min-deposit',
        show: isLoggedIn,
        cell: ({ row: { original: entry } }) => {
          if (entry.coin === 'TRY' || !entry.network) {
            return <EmptyText />;
          }

          return (
            <Flex gap="xs" items={{ xs: 'end' }}>
              <MinDepositLimit asset={entry.coin} networks={entry.network} />
            </Flex>
          );
        },
      },
      {
        header: t('commission.deposit-fee'),
        id: 'deposit-fee',
        cell: () => {
          return <Text size="sm">{t('common.free')}</Text>;
        },
      },
      {
        header: t('commission.min-withdraw'),
        accessorKey: 'network',
        id: 'min-withdraw',
        show: isLoggedIn,
        cell: ({ row: { original: entry } }) => {
          return (
            <Flex gap="xs" items={{ xs: 'end' }}>
              {entry.coin !== 'TRY' ? (
                entry.network?.map((item) => (
                  <MinTransaction
                    domain="crypto"
                    key={item.alias}
                    asset={entry.coin}
                    network={item.alias}
                    direction="withdrawal"
                  />
                ))
              ) : (
                <MinTransaction domain="fiat" direction="withdrawal" />
              )}
            </Flex>
          );
        },
      },
      {
        header: t('commission.withdraw-fee'),
        accessorKey: 'network',
        id: 'withdraw-fee',
        cell: ({ row: { original: entry } }) => {
          return (
            <Flex gap="xs" items={{ xs: 'end' }}>
              {entry.network ? (
                entry.network.map((item) => (
                  <Fee
                    key={item.alias}
                    symbol={entry.coin}
                    network={item.alias}
                    fees={withdrawalFees}
                    loading={withdrawalFeesLoading}
                  />
                ))
              ) : entry.coin === 'TRY' ? (
                <Fee symbol="TRY" />
              ) : (
                <EmptyText />
              )}
            </Flex>
          );
        },
      },
    ],
    [isLoggedIn, t, withdrawalFees, withdrawalFeesLoading],
  );

  return (
    <>
      <Block my="sm">
        <Text size="xs" color="neutral-700">
          *{t('commission.warnings.no-fee-for-deposit-transactions')}
        </Text>
        <Text size="xs" color="neutral-700">
          *{t('commission.warnings.fixed-fee-for-crypto-withdrawals')}
        </Text>
        <Text size="xs" color="neutral-700">
          *{t('commission.warnings.withdraw-fee-based-on-network')}
        </Text>
        <Text size="xs" color="neutral-700">
          *{t('commission.warnings.withdraw-fees-can-dynamically-change')}
        </Text>
      </Block>
      <Overflow overflow="x-auto">
        <DataTable.Responsive
          headerStyle={headerStyle}
          bodyStyle={bodyStyle}
          emptyMessage={t('otc.table.empty-info')}
          divider
          data={data}
          columns={columns}
        />
      </Overflow>
    </>
  );
};

export default DepositWithdraw;
