import React from 'react';

import { type CoinProps, IconCoin as DesignIconCoin } from '@bilira-org/design';

import { environment } from '@/libs/constants/environment';

type Props = Omit<CoinProps, 'cdnBaseURL'>;

const IconCoin = (props: Props) => {
  return <DesignIconCoin cdnBaseURL={environment.CDN_BASE_URL} {...props} />;
};

export default IconCoin;
