import React from 'react';

import {
  Block,
  Breadcrumbs,
  Empty,
  Flex,
  Image,
  Link,
  List,
  Panel,
  Skeleton,
  Text,
  withSuspense,
} from '@bilira-org/design';
import { parseAssetPair } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BlogQuery from '@Libs/clientInstances/blogQuery';

require('dayjs/locale/tr');

type Props = {
  assetPair?: string;
};

const RelatedPosts = ({ assetPair }: Props) => {
  const { i18n, t } = useTranslation();
  const [symbol] = parseAssetPair(assetPair);
  const navigate = useNavigate();

  const { data: { records: data = [] } = {} } = BlogQuery.useGetRelatedPosts({
    locale: i18n.language,
    tag: symbol,
    limit: 3,
    offset: 0,
  });

  const { data: coinInfo } = BlogQuery.useGetCoinInfo({ symbol: symbol, locale: i18n.language });

  const goto = (path: string) => () => navigate(path);

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Panel border="neutral-400">
      <Block gap="sm">
        <Text heading>{t('for-you.related-post-more-info', { name: coinInfo?.name, symbol: symbol })}</Text>

        <List
          empty={<Empty message={t('common.empty-message')} />}
          dataSource={data}
          space="none"
          divider
          renderItem={(item) => (
            <List.Item key={item.slug} px="none" py="2xl">
              <Link width="size-full" anchor href={`${t('urls.blog-prefix')}${item.slug}`} target="_blank">
                <Flex
                  direction={{ xs: 'col', sm: 'row' }}
                  gap="md"
                  items={{ xs: 'start', sm: 'center' }}
                  justify="between"
                  width="full"
                >
                  <div>
                    <Text weight="semibold" color="secondary-500" mb="sm">
                      {item.name}
                    </Text>
                    <Text mb="lg">{item.sub_content}</Text>
                    <Breadcrumbs listType="disc">
                      <Breadcrumbs.Item>
                        <Text size="sm">{item.category_name}</Text>
                      </Breadcrumbs.Item>
                      <Breadcrumbs.Item>
                        <Text size="sm">{dayjs(item.create_date, { locale: i18n.language }).format('DD MMM')}</Text>
                      </Breadcrumbs.Item>
                      <Breadcrumbs.Item>
                        <Text size="sm" color="secondary-500">
                          {item.tag_name}
                        </Text>
                      </Breadcrumbs.Item>
                    </Breadcrumbs>
                  </div>
                  <Image
                    style={{ width: '240px' }}
                    src={item.image}
                    alt={item.name}
                    objectFit="fill"
                    rounded="lg"
                    skeletonAspectRatio={2}
                  />
                </Flex>
              </Link>
            </List.Item>
          )}
        />
      </Block>
    </Panel>
  );
};

export default withSuspense(
  RelatedPosts,
  <Panel border="neutral-400">
    <Block gap="sm">
      <Skeleton width="size-64" />

      <List
        dataSource={['r-skeleton-1', 'r-skeleton-2', 'r-skeleton-3']}
        space="none"
        divider
        renderItem={(item) => (
          <List.Item key={item} px="none" py="2xl">
            <Flex
              width="full"
              direction={{ xs: 'col', sm: 'row' }}
              gap="md"
              justify="between"
              items={{ xs: 'start', sm: 'center' }}
            >
              <div>
                <Skeleton width="size-40" mb="sm" />
                <Skeleton width="size-20" mb="sm" />
                <Skeleton width="size-10" mb="lg" />

                <Breadcrumbs listType="disc">
                  <Breadcrumbs.Item>
                    <Skeleton width="size-10" />
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Skeleton width="size-10" />
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Skeleton width="size-10" />
                  </Breadcrumbs.Item>
                </Breadcrumbs>
              </div>
              <Skeleton style={{ width: '240px', height: '120px' }} rounded="lg" />
            </Flex>
          </List.Item>
        )}
      />
    </Block>
  </Panel>,
);
