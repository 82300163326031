import { RefObject, useEffect } from 'react';

import { useMeasure } from '@bilira-org/design';

import { useGeneralStore } from '@/store/generalStore';

/**
 * A custom React hook for tracking the height of a specific element and managing it in the global state.
 *
 * @template E - The type of the element, defaults to Element.
 * @param {string} key - The key associated with the tracked header element.
 * @returns {{ ref: React.RefObject<E>, reset: () => void }} - A ref object for the element and a function to reset the tracked height.
 *
 * @note The tracked header height is managed in the global state using the `useGeneralStore` hook.
 * Use `useHeaderHeight` hook to get the sum of header height.
 * This hook is particularly useful for header components where the height of an
 * element dynamically changes based on endpoint response (eg. AnnouncementBar).
 */
const useTrackHeaderHeight = <E extends Element = Element>(key: string) => {
  const { setHeaderHeights, headerHeights } = useGeneralStore();
  const [ref, { height }] = useMeasure<E>();

  useEffect(() => {
    setHeaderHeights({ [key]: height });
    return () => {
      setHeaderHeights({ [key]: 0 });
    };
  }, [height, key, setHeaderHeights]);

  const reset = () => {
    if (headerHeights[key] !== 0) {
      setHeaderHeights({ [key]: 0 });
    }
  };

  return { ref, reset };
};

export default useTrackHeaderHeight;
