import React from 'react';

import classNames from 'classnames';

import { Icon } from '../icons';
import { BaseComponentType, PaddingSizes } from '../types';

export type IButtonClose = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'> &
  BaseComponentType & {
    background?: 'neutral-200' | 'transparent' | 'theme-wn' | 'theme-wb' | 'white';
    p?: PaddingSizes;
  };

const ButtonClose = ({ testId, className, background = 'neutral-200', p, ...rest }: IButtonClose) => {
  return (
    <button
      {...rest}
      type="button"
      className={classNames(
        'outline-none rounded-full text-sm',
        {
          'bg-transparent hover:bg-transparent': background === 'transparent',
          [`tw-bg-${background} hover:tw-${background}`]: background !== 'transparent',
          [`p-${p}`]: p,
        },
        className,
      )}
      data-testid={testId ?? 'close-button'}
    >
      <Icon color="secondary-500" type="o:x-mark" size="lg" />
    </button>
  );
};

export default ButtonClose;
