import React, { forwardRef, HTMLAttributes, ReactNode, useImperativeHandle, useRef } from 'react';

import { useIsomorphicEffect } from '@bilira-org/react-utils';
import classNames from 'classnames';

import Gallery from './Gallery';
import { BaseComponentType } from '../types';

export interface SliderProps<T> extends HTMLAttributes<HTMLElement>, BaseComponentType {
  /** Function to render each item in the slider. */
  renderItem?: (item: T, index: number) => ReactNode;
  /** Data source for the slider. */
  dataSource?: T[];
  /** Whether the slider should auto play. */
  autoPlay?: boolean;
}

export interface SliderButtonHandler {
  /** Go to the next slide. */
  next: () => void;
  /** Go to the previous slide. */
  prev: () => void;
  /** Go to a specific slide. */
  goto: (index: number) => void;
}

/**
 * Slider component that displays gallery of items
 *
 * Use forwarded ref object to control the slider.
 *
 * @example
 * <Slider
 *   dataSource={data}
 *   renderItem={(item, index) => <Slider.Item>{item.title} <img src={item.image} /> </Slider.Item>}
 *   autoPlay={true}
 * />
 *
 */
function Slider<T>(
  { dataSource, renderItem, autoPlay, testId, ...rest }: SliderProps<T>,
  ref?: React.ForwardedRef<SliderButtonHandler>,
) {
  const sliderRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<Gallery>();
  const placeHolderRef = useRef<HTMLDivElement>(null);

  useIsomorphicEffect(() => {
    if (!sliderRef.current || !placeHolderRef.current) {
      return;
    }
    // Example usage:
    const rootEl = sliderRef.current;
    const platform = rootEl.querySelector('.platform') as HTMLDivElement;
    const frames = platform.querySelectorAll('.each-frame') as NodeListOf<HTMLDivElement>;
    const contentArea = rootEl.querySelector('.content-area') as HTMLDivElement;
    const placeHolder = placeHolderRef.current;

    const gallery = new Gallery(rootEl, platform, frames, contentArea, placeHolder);
    galleryRef.current = gallery;
    let interval: any;
    if (autoPlay) {
      interval = setInterval(nextSlider, 10000);
    }
    return () => clearInterval(interval);
  }, [dataSource]);

  /**
   * Function to go to the next slide.
   *
   * Goes to the first slide if the current slide is the last slide.
   */
  const nextSlider = () => {
    if (galleryRef.current === undefined) return;

    if (galleryRef.current.index !== galleryRef.current.limit.end) {
      galleryRef.current.next();
    } else {
      galleryRef.current.goto(0);
    }
  };

  /**
   * Function to go to the previous slide.
   *
   * Stays as first slide if the current slide is the first slide.
   */
  const prevSlider = () => {
    if (galleryRef.current === undefined) return;

    if (galleryRef.current.index !== galleryRef.current.limit.start) {
      galleryRef.current.prev();
    } else {
      galleryRef.current.goto(0);
    }
  };

  const gotoSlider = (goto: number | string) => {
    if (galleryRef.current === undefined) return;

    const goToIndex = goto === 'end' ? galleryRef.current.limit.end : Number(goto);
    galleryRef.current.goto(goToIndex);
  };

  useImperativeHandle(
    ref,
    () => ({
      goto: gotoSlider,
      next: nextSlider,
      prev: prevSlider,
    }),
    [],
  );

  const renderInnerItem = (item: T, index: number) => {
    if (!renderItem) return null;

    return renderItem(item, index);
  };

  const items = dataSource?.map((item: T, index: number) => renderInnerItem(item, index));

  return (
    <div
      ref={sliderRef}
      className="slider rounded mx-auto w-full min-h-max relative"
      data-testid={testId ?? 'slider'}
      {...rest}
    >
      <div ref={placeHolderRef} />
      <div className="content-area w-full overflow-hidden absolute top-0 left-0">
        <div className="platform h-full flex">{items}</div>
      </div>
    </div>
  );
}

interface SliderItemProps {
  children?: ReactNode;
  onClick?: () => void;
}

/**
 * Component for individual items within the Slider.
 *
 * @example
 * <SliderItem onClick={handleClick}>
 *   <img src="..." />
 * </SliderItem>
 */
const SliderItem = ({ children, onClick }: SliderItemProps) => {
  return (
    <div
      className={classNames('each-frame border-box flex-none h-full', { 'cursor-pointer': !!onClick })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

type SliderToExportType<T> = React.ForwardRefExoticComponent<
  SliderProps<T> & React.RefAttributes<SliderButtonHandler>
> & {
  Item: typeof SliderItem;
};

const SliderToExport: SliderToExportType<any> = forwardRef(Slider) as SliderToExportType<any>;

SliderToExport.Item = SliderItem;

export default SliderToExport;
