import { AxiosRequestConfig } from 'axios';

import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import {
  AccountTransactionParamsType,
  BalanceType,
  IdType,
  RecordsPaginationType,
  ResultType,
  TransactionsRecordType,
  WalletBodyModel,
  WalletModel,
  WalletPatchModel,
  WatchlistParamType,
  WatchListSymbolsType,
} from '../../model';
import {
  AccountInternalTransferParamsType,
  AccountTransactionsParamsType,
  AccountType,
  BalanceParamsType,
  DeleteWatchListRequestType,
  FiatWithdrawalRequestType,
  InternalTransferType,
  PutWatchListRequestType,
  TransactionHistoryExportAllowanceType,
  TransactionHistoryExportBodyType,
  TransactionsType,
  WatchListType,
} from '../../model/Account';
import { BankAccountEntityType, BankAccountPatchType, BankAccountType } from '../../model/BankAccount';
import { IAccountApi } from '../api/interface';

/**
 * Account
 *
 *
 * GET
 * /accounts/{id}
 * User gets their account with specified id
 *
 *
 * GET
 * /accounts/{id}/account-preferences
 * User wants to get preferences for one of their accounts.
 *
 *
 * PUT
 * /accounts/{id}/account-preferences
 * User wants to create or update account preference for their accounts.
 *
 *
 * GET
 * /accounts/{id}/bank-accounts
 * User gets list of saved bank accounts for an account to withdraw fiat.
 *
 *
 * POST
 * /accounts/{id}/bank-accounts
 * User requests to add a saved bank account. The owner should be the user.
 *
 *
 * GET
 * /accounts/bank-accounts/{id}
 * User gets record of the bank account.
 *
 *
 * PATCH
 * /accounts/bank-accounts/{id}
 * User requests to rename or change is_default bank account. Alias & is_default is updated.
 *
 *
 * DELETE
 * /accounts/bank-accounts/{id}
 * User requests to delete a saved bank account. (Soft delete).
 *
 *
 * GET
 * /accounts/{id}/balances/{asset}
 * User gets balance of an asset
 *
 *
 * GET
 * /accounts/{id}/balances
 * User gets account balances
 *
 *
 * GET
 * /accounts/{id}/transactions
 * User gets a list of fiat and crypto transactions.
 *
 *
 * GET
 * /accounts/{id}/crypto-wallets
 * User wants to retrieve all saved crypto wallets of one of their accounts.
 *
 *
 * POST
 * /accounts/{id}/crypto-wallets
 * User creates crypto wallet definitions to withdraw and deposit tryb and native tokens.
 *
 *
 * GET
 * /accounts/{id}/crypto-wallets/categories
 * Returns a summary consists of how many records the account have for each category.
 *
 *
 * GET
 * /accounts/crypto-wallets/{id}
 * User wants to retrieve a saved crypto wallet of one of their accounts.
 *
 *
 * DELETE
 * /accounts/crypto-wallets/{id}
 * User deletes an existing crypto wallet record.
 *
 *
 * PATCH
 * /accounts/crypto-wallets/{id}
 * User updates an existing crypto wallet definition.
 */

class AccountApi extends HttpClient implements IAccountApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getBalances = (id: string, params: BalanceParamsType = {}, config?: AxiosRequestConfig) =>
    this.instance.get<string, BalanceType[]>(`/accounts/${id}/balances`, { params, ...config });

  // "balances": [
  //   {
  //     "timestamp": "string",
  //     "year": 0,
  //     "month": 0,
  //     "day": 0,
  //     "hour": 0,
  //     "week": 0,
  //     "balances": [
  //       {
  //         "asset": "TRYB",
  //         "amount": "string"
  //       }
  //     ]
  //   }
  // ]
  public getBalanceHistory = (id: string, config?: AxiosRequestConfig) =>
    this.instance.get<string, BalanceType[]>(`/accounts/${id}/balances/history`, config);

  public getAccounts = (id: string) => this.instance.get<string, AccountType>(`/accounts/${id}`);

  // public getCryptoWallets = (id: string) =>
  //   this.instance.get<HttpErrorType, CryptoWalletType, string>(`/accounts/${id}/crypto-wallets`);

  public postFiatWithdrawal = (body: FiatWithdrawalRequestType) => {
    return this.instance.post<FiatWithdrawalRequestType, IdType>(`/fiat/withdrawals`, body);
  };

  public getTransactions = (params: AccountTransactionsParamsType) =>
    this.instance.get<AccountTransactionsParamsType, TransactionsType>(`/accounts/${params.id}/transactions`, {
      params: params.params,
    });

  public getTransaction = (params: AccountTransactionParamsType) =>
    this.instance.get<AccountTransactionParamsType, TransactionsRecordType>(
      `/accounts/${params.id}/transactions/${params.transactionId}?type=${params.type}`,
    );

  public getInternalTransfer = (params: AccountInternalTransferParamsType) =>
    this.instance.get<AccountInternalTransferParamsType, InternalTransferType>(
      `/accounts/${params.id}/internal-transfers/${params.transactionId}`,
    );

  public postTransactionExportRequest = (accountId: string, body: TransactionHistoryExportBodyType) =>
    this.instance.post<TransactionHistoryExportBodyType, null>(
      `/accounts/${accountId}/transaction-history-export`,
      body,
    );

  public getTransactionExportAllowance = (accountId: string) =>
    this.instance.get<string, TransactionHistoryExportAllowanceType>(
      `/accounts/${accountId}/transaction-history-export`,
    );

  ////
  public getCryptoWallets = (accountId: string) => {
    return this.instance.get<string, WalletModel[]>(`/accounts/${accountId}/crypto-wallets`);
  };

  public getCryptoWallet = (id: string) => {
    return this.instance.get<string, WalletModel>(`/accounts/crypto-wallets/${id}`);
  };

  public postCryptoWallet = ({ id, body }: { id: string; body: Partial<WalletBodyModel> }) => {
    return this.instance.post<WalletBodyModel, ResultType>(`/accounts/${id}/crypto-wallets`, body);
  };

  public patchCryptoWallet = ({ id, body }: { id: string; body: WalletPatchModel }) => {
    return this.instance.patch<WalletPatchModel, ResultType>(`/accounts/crypto-wallets/${id}`, body);
  };

  public deleteCryptoWallet = (id: string) => {
    return this.instance.delete<string, ResultType>(`/accounts/crypto-wallets/${id}`);
  };

  ////
  // BANK ACCOUNT

  public getBankAccounts = (id: string, isDefault: boolean) =>
    this.instance.get<string, BankAccountType[]>(`/accounts/${id}/bank-accounts`, { params: { default: isDefault } });

  public getBankAccount = (id: string) => {
    return this.instance.get<string, BankAccountType>(`/accounts/bank-accounts/${id}`);
  };

  public postBankAccount = ({ id, body }: { id: string; body: BankAccountEntityType }) => {
    return this.instance.post<HttpErrorType, IdType>(`/accounts/${id}/bank-accounts`, body);
  };

  public patchBankAccount = ({ id, body }: { id: string; body: BankAccountPatchType }) => {
    return this.instance.patch<BankAccountPatchType, ResultType>(`/accounts/bank-accounts/${id}`, body);
  };

  public deleteBankAccount = (id: string) => {
    return this.instance.delete<string, ResultType>(`/accounts/bank-accounts/${id}`);
  };

  public patchDefaultBankAccount = (id: string) => {
    return this.instance.patch<WalletPatchModel, ResultType>(`/accounts/bank-accounts/${id}/set-default`);
  };

  public getWatchList = (category: string) => {
    return this.instance.get<HttpErrorType, WatchListSymbolsType[]>(`/accounts/watchlist`, {
      params: { category: category },
    });
  };

  public getWatchListData = (params: Partial<WatchlistParamType>) => {
    return this.instance.get<HttpErrorType, RecordsPaginationType<WatchListType>>(`/accounts/watchlist/v2`, { params });
  };

  public putWatchList = (body: PutWatchListRequestType) => {
    return this.instance.put<HttpErrorType, boolean>(`/accounts/watchlist`, body);
  };

  public deleteWatchList = (body: DeleteWatchListRequestType) => {
    return this.instance.delete<HttpErrorType, boolean>(`/accounts/watchlist`, { data: body });
  };
}

export default AccountApi;
