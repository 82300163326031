import { useState } from 'react';

import { isEmpty } from '@bilira-org/react-utils';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

import { CheckboxDataType } from './type';
import { Block } from '../block';
import { Icon } from '../icons';
import { BaseComponentType } from '../types';
import { Text } from '../typography';

export type CheckboxMultipleProps<T extends CheckboxDataType> = BaseComponentType & {
  /** Data source for the checkboxes. */
  dataSource: T[];
  /** Callback function triggered when a checkbox state changes. */
  onChecked: (checked: T[]) => void;
  /** Checked items. */
  checked: T[];
};

/**
 * Checkbox component for handling multiple checkboxes in a group.
 *
 * @example
 * // Example usage of Checkbox component as a group of checkboxes
 * <Checkbox
 *   dataSource={[
 *     { id: 'option1', label: 'Option 1', checked: false },
 *     { id: 'option2', label: 'Option 2', checked: true },
 *   ]}
 *   onChecked={(checkedItems) => console.log('Checked items:', checkedItems)}
 *   checked={[
 *     { id: 'option2', label: 'Option 2', checked: true },
 *   ]}
 * />
 */
function Checkbox<T extends CheckboxDataType>({ dataSource, onChecked, checked, testId }: CheckboxMultipleProps<T>) {
  const classes = classNames('checkbox');

  const getChecked = (id: string) => {
    return checked?.find((d) => d.id === id) || ({} as T);
  };

  return (
    <Block gap="sm" testId={testId ?? 'checkbox'}>
      {dataSource?.map((item) => (
        <Switch.Group key={item.id} as="div" className="flex gap-sm items-center">
          <Switch
            checked={item.checked}
            onChange={(val) => {
              let data: T[];

              const exists = getChecked(item.id);

              if (isEmpty(exists)) {
                data = [...(checked || []), { ...item, checked: val }];
              } else {
                data = checked.filter((d) => d.id !== item.id);
              }

              onChecked(data);
            }}
            className={classes}
          >
            <span className="sr-only">Checkbox</span>
            <span
              aria-hidden="true"
              className={classNames('check-field', {
                checked: getChecked(item.id).checked,
              })}
            >
              {getChecked(item.id).checked && <Icon type="s:check" />}
            </span>
          </Switch>
          <Switch.Label>
            <Text size="sm" color="secondary-500" weight="regular" className="select-none cursor-pointer">
              {item.label}
            </Text>
          </Switch.Label>
        </Switch.Group>
      ))}
    </Block>
  );
}

export default Checkbox;
