import { notification } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import AccountQuery from '@Libs/clientInstances/accountQuery';

type Props = {
  baseAsset?: CoinSymbolType;
  quoteAsset?: CoinSymbolType;
};

const useManageWatchlist = ({ baseAsset, quoteAsset }: Props) => {
  const { t } = useTranslation();
  const { data } = AccountQuery.useGetWatchList('market');
  const postWatchlist = AccountQuery.usePutWatchList();
  const deleteWatchlist = AccountQuery.useDeleteWatchList();

  const isInWatchlist = data?.some((d) => d === (quoteAsset ? `${baseAsset}/${quoteAsset}` : baseAsset));

  const toggleWatchlist = () => {
    if (!(baseAsset && quoteAsset)) {
      return null;
    }

    const mutate = isInWatchlist ? deleteWatchlist.mutateAsync : postWatchlist.mutateAsync;

    mutate({ category: 'market', asset: `${baseAsset}/${quoteAsset}` }).then(() => {
      notification.info(isInWatchlist ? t('watchlist.save.removed') : t('watchlist.save.added'));
    });
  };

  return { toggleWatchlist, isInWatchlist, isPending: postWatchlist.isPending || deleteWatchlist.isPending };
};

export default useManageWatchlist;
