import React, { memo, useLayoutEffect, useState } from 'react';

import { Flex, Panel, StepProgress, Text } from '@bilira-org/design';
import { CryptoAssetsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import useGetDisabledNetworks from '@/libs/hooks/useGetDisabledNetworks';
import { useGeneralSessionStore } from '@/store/generalSessionStore';
import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';
import WarningClosable from '@Modules/crypto/withdraw/components/WarningClosable';
import WithdrawCryptoForm from '@Modules/crypto/withdraw/components/WithdrawCryptoForm';
import SelectAddressStep from '@Modules/crypto/withdraw/steps/SelectAddressStep';
import SelectCryptoStep from '@Modules/crypto/withdraw/steps/SelectCryptoStep';
import SelectNetworkStep from '@Modules/crypto/withdraw/steps/SelectNetworkStep';

import useWithdrawalValidation from './components/hooks/useWithdrawalValidation';
import WithdrawNotAllowed from './components/WithdrawNotAllowed';

const CryptoWithdrawal = () => {
  const [activeAsset, setActiveAsset] = useState<CryptoAssetsType>();
  const { selectedWallet, setSelectedWallet, setSelectedNetwork, selectedNetwork } = useWalletStore();

  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (activeAsset) {
      setSelectedWallet(undefined);
      setSelectedNetwork(undefined);
    }
  }, [activeAsset]);

  useLayoutEffect(() => {
    if (selectedNetwork) {
      setSelectedWallet(undefined);
    }
  }, [selectedNetwork]);

  const { disabled } = useGetDisabledNetworks({
    asset: activeAsset?.symbol,
    network: selectedNetwork?.alias,
    operation: 'withdrawal',
  });

  const withdrawErrorKey = useWithdrawalValidation();

  const handleSuccess = () => {
    setActiveAsset(undefined);
    setSelectedWallet(undefined);
    setSelectedNetwork(undefined);
    useGeneralSessionStore.getState().setIsNotificationViewed(false);
  };

  return (
    <>
      <Panel border="neutral-400">
        <Text heading size="xl" weight="bold" mb="md">
          {t('crypto.crypto-withdraw-title')}
        </Text>
        <WarningClosable />
        <WithdrawNotAllowed errorKey={withdrawErrorKey} />

        <Flex width={{ xs: 'full', sm: 'full', xl: '3/4' }} mt="2xl">
          <StepProgress
            hideStepIconOnSm
            size="sm"
            vertical
            borderStyle="dashed"
            steps={[
              {
                title: (
                  <SelectCryptoStep
                    disabled={!!withdrawErrorKey}
                    activeAsset={activeAsset}
                    setActiveAsset={setActiveAsset}
                  />
                ),
                active: true,
                complete: !!activeAsset,
              },
              {
                title: (
                  <>
                    <Text size="lg" weight="medium" mb="2xl" color="secondary-500">
                      {t('crypto.withdraw-address-step')}
                    </Text>
                    <SelectNetworkStep
                      filter={activeAsset?.available_on}
                      activeAsset={activeAsset}
                      isNetworkDisabled={disabled}
                      disabled={!!withdrawErrorKey}
                    />
                    {!disabled && !!selectedNetwork && (
                      <SelectAddressStep filter={selectedNetwork} disabled={disabled} />
                    )}
                  </>
                ),
                active: true,
                complete: !!selectedWallet && !!selectedNetwork,
                hidden: !activeAsset,
              },
              {
                title: (
                  <>
                    <Text size="lg" weight="medium" mb="2xl" color="secondary-500">
                      {t('common.withdraw-amount')}
                    </Text>
                    <WithdrawCryptoForm asset={activeAsset} onSuccess={handleSuccess} />
                  </>
                ),
                active: true,
                hidden: !selectedWallet || !selectedNetwork,
              },
            ]}
          />
        </Flex>
      </Panel>
    </>
  );
};

export default memo(CryptoWithdrawal);
