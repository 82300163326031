import { Fragment, ReactNode } from 'react';

import { Row } from '@tanstack/react-table';

import { DataTableProps } from './dataTable';
import ExpandedRow from './expandedRow';
import TableRow from './row';
import { TableBodyStyle } from './types';

interface TableBodyProps<T> {
  rows: Row<T>[];
  bodyStyle?: TableBodyStyle;
  highlightFn: (row: T, id: string) => boolean;
  highlightVariant?: DataTableProps<T>['highlightVariant'];
  onRowClick?: (data: T) => void;
  loading?: boolean;
  renderSubComponent?: (row: T) => ReactNode;
  isFetchingNextPage?: boolean;
  skeletonRowCount?: number;
}

export default function TableBody<T>({
  rows,
  bodyStyle,
  highlightFn,
  highlightVariant,
  onRowClick,
  loading,
  renderSubComponent,
  isFetchingNextPage,
  skeletonRowCount,
}: TableBodyProps<T>) {
  return (
    <tbody>
      {rows.map((row) => (
        <Fragment key={row.id}>
          <TableRow
            key={row.id}
            row={row}
            highlightFn={highlightFn}
            highlightVariant={highlightVariant}
            onRowClick={onRowClick}
            loading={loading}
            bodyStyle={bodyStyle}
          />

          <ExpandedRow row={row} bodyStyle={bodyStyle} renderSubComponent={renderSubComponent} />
        </Fragment>
      ))}

      {isFetchingNextPage && (
        <>
          {new Array(skeletonRowCount).fill(null).map((empty, entryIndex) => (
            <TableRow key={entryIndex} row={rows[0]} highlightFn={() => false} loading={true} bodyStyle={bodyStyle} />
          ))}
        </>
      )}
    </tbody>
  );
}
