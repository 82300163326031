import React, { memo, ReactNode } from 'react';

type Props<T> = {
  /** Data to be looped over.
   * If a number is provided, it will be used as the number of times to loop.
   */
  data: T[] | number;
  /** The children function or JSX element to be rendered in each iteration of the loop. */
  children: ((param: T) => ReactNode) | ReactNode;
};

/**
 * The `Loop` component is used to iterate over a given data array or a specified number of times and render the provided children.
 *
 * @example
 * const data = ["apple", "orange", "banana"];
 *
 * <Loop data={data}>
 *   {(item) => <div key={item}>{item}</div>}
 * </Loop>
 *
 * @example
 * <Loop data={5}>
 *   <div>Item</div>
 * </Loop>
 */
const Loop = <T,>({ data, children }: Props<T>) => {
  if (typeof data === 'number') {
    return <>{new Array(data).fill(0).map(() => children)}</>;
  }

  return <>{data.map((item) => (typeof children === 'function' ? children(item) : children))}</>;
};

export default memo(Loop) as typeof Loop;
