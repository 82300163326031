import { ReactNode } from 'react';

import classNames from 'classnames';

import ButtonClose from '../button/buttonClose';
import { BaseComponentType } from '../types';

export type DrawerProps = BaseComponentType & {
  /** Additional class name to apply to the drawer. */
  className?: string;
  /** The content to display in the drawer. */
  children: ReactNode;
  /** Callback function triggered when drawer is closed. */
  onClose: (value: boolean) => void;
  /** Whether the drawer is open. */
  open?: boolean;
};

/**
 * Drawer component for displaying content in a side drawer.
 *
 * @example
 * <Drawer open={isDrawerOpen} onClose={(isOpen) => setIsDrawerOpen(isOpen)}>
 *   <div>Drawer content goes here</div>
 * </Drawer>
 */
const Drawer = ({ children, open, className, onClose, testId }: DrawerProps) => {
  return (
    <>
      <div
        className={classNames('drawer-overlay', { active: open })}
        data-testid={`${testId ?? 'drawer'}-overlay`}
        onClick={() => onClose(false)}
      />
      <div className={classNames('drawer relative', className, { active: open })} data-testid={testId ?? 'drawer'}>
        <div className="drawer-header">
          <ButtonClose onClick={() => onClose(false)} />
        </div>
        <div className="drawer-content">{children}</div>
      </div>
    </>
  );
};

export default Drawer;
