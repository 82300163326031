import React, { ReactNode } from 'react';

import classNames from 'classnames';
import {
  FieldPath,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

import FormField from './field/formField';
import { FormContext } from './formContext';
import { BaseComponentType } from '../types';

type BaseProps<T extends FieldValues> = {
  children: ReactNode;
  /**
   * An optional instance of the useForm hook to be used to control form from outside component. */
  form?: UseFormReturn<T>;
  /**
   * Options parameter for the form inside component
   */
  initialValues?: UseFormProps<T>['defaultValues'];
  /**
   * Options parameter for the form inside component
   */
  options?: Omit<UseFormProps<T>, 'defaultValues'>;
  /**
   * Optional handler to be called when the form is submitted successfully.
   *
   */
  onFinish?: SubmitHandler<T>;
  /**
   * Optional handler to be called when the form submission fails. */
  onError?: SubmitErrorHandler<T>;
  preserveErrorPlaceholder?: boolean;
};

type FormProps<T extends FieldValues> = BaseProps<T> &
  Omit<React.HTMLAttributes<HTMLFormElement>, keyof BaseProps<T>> &
  BaseComponentType;

/**
 * A form element with child components that utilize the react-hook-form library
 * @example
 *<Form onFinish={onFinish}>
 *  <FormField label="Name" name="name" >
 *    <Input />
 *  </FormField>
 *  <FormField label="Email" name="email" >
 *    <Input />
 *  </FormField>
 *  <button type="submit">Submit</button>
 *</Form>
 */
const Form = <T extends FieldValues>({
  children,
  form: formFromProps,
  onFinish,
  onError,
  initialValues,
  options,
  testId,
  preserveErrorPlaceholder = true,
  ...rest
}: FormProps<T>) => {
  const form = useForm({
    defaultValues: initialValues,
    ...options,
  });
  const formMethods = formFromProps || form;
  const { handleSubmit } = formMethods;
  const classes = classNames({ 'form-preserve-error-placeholder': preserveErrorPlaceholder }, rest.className);

  return (
    <FormContext.Provider value={{ form: formMethods }}>
      <form
        className={classes}
        onSubmit={onFinish ? handleSubmit(onFinish, onError) : undefined}
        {...rest}
        data-testid={testId ?? 'form'}
      >
        {children}
      </form>
    </FormContext.Provider>
  );
};

const triggerFormErrors = <T extends FieldValues>(form: UseFormReturn<T>) => {
  Object.keys(form.formState.errors).forEach((field) => {
    form.trigger(field as FieldPath<T>);
  });
};

export default Object.assign(Form, { Field: FormField, useForm, triggerFormErrors });
