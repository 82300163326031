import { ReactNode, useState } from 'react';

import { Switch } from '@headlessui/react';
import classNames from 'classnames';

import { Block } from '../block';
import { Icon } from '../icons';
import { BaseComponentType } from '../types';
import { Text } from '../typography';

export type CheckboxSingleProps = BaseComponentType & {
  /** Label or the content of the checkbox */
  label: ReactNode;
  /** Callback function triggered when a checkbox state changes. */
  onChecked: (checked: boolean) => void;
  /** Whether the checkbox is checked or not. */
  checked?: boolean;
};

/**
 * Single Checkbox component for handling a single checkbox.
 *
 *
 * @example
 * // Example usage of CheckboxSingle component
 * <CheckboxSingle label="Single Checkbox" onChecked={(isChecked) => console.log('Is Checked:', isChecked)} checked={true} />
 *
 */
function CheckboxSingle({ label, onChecked, checked = false, testId }: CheckboxSingleProps) {
  const [enabled, setEnabled] = useState(checked);
  const classes = classNames('checkbox');

  return (
    <Block row gap="sm" items="center" testId={testId ?? 'checkbox'}>
      <Switch.Group>
        <Switch
          checked={enabled}
          onChange={(val) => {
            onChecked(val);
            setEnabled(val);
          }}
          className={classes}
        >
          <span className="sr-only">Selection</span>
          <span
            aria-hidden="true"
            className={classNames('check-field', {
              checked: enabled,
            })}
          >
            {enabled && <Icon type="s:check" />}
          </span>
        </Switch>
        <Switch.Label>
          {typeof label === 'string' ? (
            <Text size="sm" color="secondary-500" className="select-none">
              {label}
            </Text>
          ) : (
            <>{label}</>
          )}
        </Switch.Label>
      </Switch.Group>
    </Block>
  );
}

export default CheckboxSingle;
