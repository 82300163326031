import React, { HTMLAttributes } from 'react';

import classNames from 'classnames';

import { BaseComponentType, BreakpointType, ResponsiveReturnType } from '../types';

export type HiddenProps = Omit<HTMLAttributes<HTMLElement>, 'hidden'> &
  BaseComponentType & {
    /** Content to be displayed */
    children: React.ReactNode;
    /** Additional class name for styling */
    className?: string;
    /** Show content based on breakpoint or boolean value */
    show: boolean | BreakpointType<boolean>;
  };

/**
 * Component for conditionally display content based on the props
 *
 * @example
 * // Hide content based on breakpoint
 * <Hidden show={{ xs: true , md:false}}>
 *   Content
 * </Hidden>
 *
 * // Hide content based on boolean state
 * <Hidden show={isVisible}>
 *   Content
 * </Hidden>
 */
const Hidden = ({ children, show, className, testId, ...props }: HiddenProps) => {
  let hiddenClass: ResponsiveReturnType;

  if (typeof show === 'object') {
    hiddenClass = {
      'tw-hidden': true,
      [show.xs ? `xs:block` : `xs:hidden`]: show.xs !== undefined,
      [show.sm ? `sm:block` : `sm:hidden`]: show.sm !== undefined,
      [show.md ? `md:block` : `md:hidden`]: show.md !== undefined,
      [show.lg ? `lg:block` : `lg:hidden`]: show.lg !== undefined,
      [show.xl ? `xl:block` : `xl:hidden`]: show.xl !== undefined,
    };
  } else {
    hiddenClass = { 'tw-hidden': !show, block: show };
  }

  const classes = classNames(className, hiddenClass);

  return (
    <div className={classes} data-testid={testId ?? 'hidden'} {...props}>
      {children}
    </div>
  );
};

export default Hidden;
