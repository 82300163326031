import React, { memo } from 'react';

import { Layout, Tab } from '@bilira-org/design';
import { useLocation } from 'react-router-dom';

import InfoBar from '@Modules/account/components/InfoBar';

import Account from './account';
import KYC from './kyc';
import Preferences from './preference';
import Security from './security';

const { Content } = Layout;

function Home() {
  const { pathname } = useLocation();

  let child;

  switch (pathname) {
    case '/account/security-settings':
      child = <Security />;
      break;
    case '/account/id-verification':
      child = <KYC />;
      break;
    case '/account/preferences':
      child = <Preferences />;
      break;
    default:
      child = <Account />;
      break;
  }

  return (
    <>
      <InfoBar />
      <Content>{child}</Content>
    </>
  );
}

export default memo(Home);
