import { IdpQueryKey, UserInfo } from '@bilira-org/react-utils';
import { Query, QueryKey } from '@tanstack/react-query';

import { useGeneralSessionStore } from '@/store';
import { addTrackerUser } from '@Libs/tracker/helpers/addTrackerUser';

const handleQuerySuccess = (data: unknown, query: Query<unknown, unknown, unknown, QueryKey>) => {
  if (query.queryKey.includes(IdpQueryKey.USER_INFO)) {
    useGeneralSessionStore.getState().setUserInfo(data as UserInfo);
    addTrackerUser({ userInfo: data as UserInfo });
  }
};

export default handleQuerySuccess;
