import React from 'react';

import { Block, Button, Flex, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CryptoListCard from '@Components/cryptoCards/CryptoListCard';

const HypeCards = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Flex
        minWidth="min"
        items="stretch"
        gap="4xl"
        justify={{ sm: 'start', md: 'end' }}
        direction={{ xs: 'col', sm: 'col', xl: 'row' }}
        wrap={{ lg: 'wrap' }}
      >
        <Flex.Item flexType="1">
          <Panel size="md" minHeight="md" border="neutral-400" testId="trending-crypto-panel">
            <Block row justify="between" items="center" mb="md" mt="md">
              <Text heading size="xl" weight="bold" px="md">
                {t('overview.trends')}
              </Text>
              <Button size="md" variant="link" onClick={() => navigate('/market#trend')}>
                {t('common.show-all')}
              </Button>
            </Block>
            <CryptoListCard tag="trend" />
          </Panel>
        </Flex.Item>
        <Flex.Item flexType="1">
          <Panel size="md" minHeight="md" border="neutral-400" testId="new-listing-crypto-panel">
            <Block row justify="between" items="center" mb="md" mt="md">
              <Text heading size="xl" weight="bold" px="md">
                {t('overview.new-listings')}
              </Text>
              <Button size="md" variant="link" onClick={() => navigate('/market#new')}>
                {t('common.show-all')}
              </Button>
            </Block>
            <CryptoListCard tag="new" />
          </Panel>
        </Flex.Item>
      </Flex>
    </>
  );
};

export default HypeCards;
