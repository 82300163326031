import { isHttpError } from '@bilira-org/react-utils/src/utils/httpError';

import idpQuery from '@Libs/clientInstances/idpQuery';
import UserQuery from '@Libs/clientInstances/userQuery';

const useCheckHealth = () => {
  const user = UserQuery.useGetUsers({ suspense: false });
  const idpUserInfo = idpQuery.useGetUserInfo({ suspense: false });

  const errorTypeUser = isHttpError(user.error);
  const errorTypeIdp = isHttpError(idpUserInfo.error);

  return {
    isServerError: errorTypeUser.isServerUnavailable || errorTypeIdp.isServerUnavailable,
    isLoading: !user.isSuccess && !user.isError && !idpUserInfo.isSuccess && !idpUserInfo.isError,
    isLoggedOut: errorTypeUser.isLogout || errorTypeIdp.isLogout,
  };
};

export default useCheckHealth;
