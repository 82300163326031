import React from 'react';

import { TransactionType } from '@bilira-org/react-utils';
import type { TransactionsRecordType } from '@bilira-org/react-utils';

import FiatTransactionTitle from '@Components/transaction/components/fiat/TransactionTitle';
import InternalTransactionTitle from '@Components/transaction/components/internal/TransactionTitle';
import OTCTransactionTitle from '@Components/transaction/components/otc/TransactionTitle';
import OtherTransactionTitle from '@Components/transaction/components/other/TransactionTitle';
import PaybackTransactionTitle from '@Components/transaction/components/payback/TransactionTitle';
import SwapTransactionTitle from '@Components/transaction/components/swap/TransactionTitle';

import { TransactionItemSizeType } from '../TransactionTable';

const getTransactionTitleComponent = (type: TransactionType, direction: string) => {
  switch (type) {
    case 'fiat':
    case 'crypto':
      return FiatTransactionTitle;
    case 'otc':
      return OTCTransactionTitle;
    case 'swap':
    case 'conversion':
      return SwapTransactionTitle;
    case 'other':
      switch (direction) {
        case 'payback':
          return PaybackTransactionTitle;
        case 'other':
          return OtherTransactionTitle;
        default:
          return InternalTransactionTitle;
      }
    default:
      return null;
  }
};

type Props = {
  data?: TransactionsRecordType;
  size?: TransactionItemSizeType;
};

const TransactionTitle = ({ data, size }: Props) => {
  if (!data) {
    return null;
  }
  const Element = getTransactionTitleComponent(data.type, data.direction);

  if (!Element) {
    return null;
  }

  return <Element data={data} size={size as TransactionItemSizeType} />;
};

export default TransactionTitle;
