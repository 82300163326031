import React, { ForwardRefRenderFunction, FunctionComponent, ReactNode, Suspense } from 'react';

/**
 * Higher-order component that wraps another component with React's `Suspense` component,
 * enabling the use of code-splitting and a fallback UI while the component is loading.
 */
const withSuspense =
  <T,>(
    Component: FunctionComponent<T & JSX.IntrinsicAttributes> | ForwardRefRenderFunction<unknown, T>,
    fallback?: ReactNode,
  ): FunctionComponent<T> =>
  (props: T) => {
    return (
      <Suspense fallback={fallback}>
        <Component {...(props as T & JSX.IntrinsicAttributes)} />
      </Suspense>
    );
  };

export default withSuspense;
