import React, { memo } from 'react';

import { ChartPriceDataType, LightweightChart, withSuspense } from '@bilira-org/design';
import { CryptoTimeScale, OHLCSType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

// import i18nLanguageToLocale from '@Libs/helpers/i18nLanguageToLocale';
import EmptyGraph from '@Modules/market/detail/components/components/EmptyGraph';
import scaleConfiguration from '@Modules/market/detail/components/priceChart/helpers/scaleConfiguration';
import useUpdatePriceChart from '@Modules/market/detail/components/priceChart/hooks/useUpdatePriceChart';

type Props = {
  timeScale: CryptoTimeScale;
  setPriceInfo: (data?: ChartPriceDataType) => void;
  priceData: OHLCSType;
};
const PriceChart = ({ timeScale, setPriceInfo, priceData }: Props) => {
  const { i18n } = useTranslation();
  const { priceChartData, atlIndex, athIndex } = useUpdatePriceChart({ priceData });
  // const locale = i18nLanguageToLocale(i18n.language); //TODO: check if needed. @sulhadin

  return (
    <>
      <LightweightChart
        scaleConfiguration={scaleConfiguration[timeScale]}
        subscribeCrosshairMove={setPriceInfo}
        data={priceChartData}
        // graphConf={{
        //   athIndex: athIndex,
        //   atlIndex: atlIndex,
        // }}
        locale={i18n.language}
      />
    </>
  );
};

export default memo(withSuspense(PriceChart, <EmptyGraph suspense={true} />));
