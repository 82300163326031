import { Block, Hidden, Loop, Skeleton, withSuspense } from '@bilira-org/design';
import { OTCStatusType, OTCType, SwapQueryKey } from '@bilira-org/react-utils';

import { queryCache } from '@Libs/clientInstances/queryClient';
import SwapQuery from '@Libs/clientInstances/swapQuery';
import useAuth from '@Libs/hooks/userAuth';

import OTCTable from './OTCTable';
import OTCTableResponsive from './OTCTableResponsive';

type Props = {
  acceptRequest: (entry: OTCType) => () => void;
  cancelRequest: (entry: OTCType) => () => void;
  onDetailClick: (id: string) => void;
  filter?: OTCStatusType;
};
const OTCTableContainer = ({ filter, acceptRequest, cancelRequest, onDetailClick }: Props) => {
  const { account } = useAuth();
  const { data: { records: data = [] } = {} } = SwapQuery.useGetOtcTransactions({
    account: account,
    status: filter,
  });

  const expireTimeout = () => {
    queryCache.resetQueries([SwapQueryKey.SWAP_OTC_TRANSACTIONS]);
  };

  return (
    <>
      <Hidden show={{ xs: !!data.length, sm: !!data.length, lg: false }}>
        <OTCTableResponsive
          data={data}
          onDetailClick={onDetailClick}
          acceptRequest={acceptRequest}
          cancelRequest={cancelRequest}
          expireTimeout={expireTimeout}
        />
      </Hidden>
      <Hidden show={{ xs: !data.length, sm: !data.length, lg: true }}>
        <OTCTable
          data={data}
          onDetailClick={onDetailClick}
          acceptRequest={acceptRequest}
          cancelRequest={cancelRequest}
          expireTimeout={expireTimeout}
        />
      </Hidden>
    </>
  );
};

export default withSuspense(
  OTCTableContainer,
  <Block gap="4xl" mt="lg">
    <Loop<number> data={[1, 2, 3]}>
      {(data) => (
        <Block key={`skeleton-${data}`} row justify="between" mx="md">
          <Skeleton height="size-3" width="size-20" />
          <Skeleton height="size-3" width="size-20" />
          <Skeleton height="size-3" width="size-20" />
          <Skeleton height="size-3" width="size-20" />
        </Block>
      )}
    </Loop>
  </Block>,
);
