import React, { HTMLAttributes, memo, useMemo } from 'react';

import classNames from 'classnames';

import { BaseComponentType, GridColSize, JustifySelfType, ResponsiveReturnType } from '../types';
import { buildResponsiveClass } from '../utils';

/**
 * The column span for different breakpoints.
 */
type SpanType = {
  sm?: GridColSize;
  md?: GridColSize;
  lg?: GridColSize;
};

interface ICol extends HTMLAttributes<HTMLElement>, BaseComponentType {
  /** The content of the column. */
  children?: React.ReactNode | undefined;
  /** The column span for different breakpoints. */
  span?: SpanType | GridColSize;
  /** The mode of the column. */
  mode?: string;
  /** The justify self of the column. */
  justifySelf?: JustifySelfType;
  /** Additional class name for the column. */
  className?: string;
}

/**
 * Col component for creating grid columns in a responsive grid layout.
 *
 * @example
 * // Example usage of Col component
 * <Col span={{ sm: 12, md: 6, lg: 4 }} mode="auto" justifySelf="start" testId="grid-column">
 *   {/* Content of the grid column *\/}
 * </Col>
 */
export const Col = memo(
  ({ children, span = { sm: 1, md: 1, lg: 1 }, mode, className, justifySelf, testId, ...props }: ICol) => {
    let spanClass;
    if (span) {
      spanClass = buildResponsiveClass(span, 'column-span-');
    }
    const classes = classNames('grid-col', className, spanClass, mode, {
      [`tw-justify-self-${justifySelf}`]: justifySelf,
    });

    return (
      <div {...props} className={classes} data-testid={testId ?? 'col'}>
        {children}
      </div>
    );
  },
);
