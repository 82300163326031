export const TRACKER_EVENTS = {
  KYC: {
    KYC_STEP_1: 'Kyc-step-1 completed',
    KYC_STEP_2: 'Kyc-step-2 completed',
    KYC_STEP_3: 'Kyc-step-3 completed',
    KYC_STEP_4: 'Kyc-step-4 completed',
    KYC_STEP_5: 'Kyc-step-5 completed',
    KYC_STEP_6: 'Kyc-step-6 completed',
    KYC_STEP_7: 'Kyc-step-7 completed',
    PREVIOUS_STEP: 'Previous step',
  },
  CRYPTO_WITHDRAWAL: {
    DEFAULT: 'Crypto withdrawal',
    SELECT_ASSET: 'Crypto withdrawal asset selected',
    SELECT_NETWORK: 'Crypto withdrawal network selected',
    SELECT_WALLET: 'Crypto withdrawal wallet selected',
    REQUEST_WITHDRAWAL: 'Crypto withdrawal completed',
  },
  CRYPTO_WALLET: {
    ADD_WALLET: 'Visit add wallet',
  },
  FIAT_WITHDRAWAL: {
    DEFAULT: 'Fiat withdrawal',
    SELECT_BANK_ACCOUNT: 'Fiat withdrawal bank account selected',
    REQUEST_WITHDRAWAL: 'Fiat withdrawal completed',
  },
  TWO_FA: {
    REQUEST_TWO_FA: '2fa requested',
    COMPLETE_TWO_FA: '2fa completed',
  },
  REWARDS: {
    REGISTRATION_MODAL_SHOWN: 'PS_EntryPopupShown',
  },
};
