import React, { Suspense } from 'react';

import { initDarkMode } from '@bilira-org/design';
import { DesignStablecoin } from '@bilira-org/design/root';
import { extend } from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear'; // import plugin
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import App from './app/App';
import queryClient, { QueryClientProvider, ReactQueryDevtools } from './app/queryClientConf';
import AppSkeleton from './components/skeletons/AppSkeleton';

import './app/i18Next';
import './app/sentryConf';
import '../src/libs/ws';

extend(isLeapYear);
initDarkMode();

// Added for react hot module replacement.
if (module.hot) {
  module.hot.accept();
}

// Stablecoin render entry point.
const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Failed to find the root element!');
}

const root = createRoot(rootElement, {});

// Test bilira.dev
root.render(
  <React.StrictMode>
    <DesignStablecoin>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<AppSkeleton />}>
            <App />
          </Suspense>
          <ReactQueryDevtools initialIsOpen={false} position="bottom" buttonPosition="bottom-left" />
        </QueryClientProvider>
      </HelmetProvider>
    </DesignStablecoin>
  </React.StrictMode>,
);
