import React, { forwardRef, useImperativeHandle } from 'react';

import { TickerProps, useTicker } from '@bilira-org/react-utils';
import classNames from 'classnames';

import { BaseComponentType, BaseSize, PaddingSizes } from '../types';

export interface SequentialProgressHandle {
  /**
   * Start or stop the countdown.
   * @param start - If `true`, starts the countdown. If `false`, stops the countdown.
   */
  startCountdown: (start: boolean) => void;
  /** Reset the countdown. */
  resetCountdown: () => void;
}

export type ProgressItemProps = {
  key?: string;
  title?: string | React.ReactNode;
  icon?: string | React.ReactNode;
  extra?: React.ReactNode;
  complete?: boolean;
  active?: boolean;
  failed?: boolean;
};

interface ISequentialProgress extends BaseComponentType {
  /** Array of step information for the sequential progress. */
  steps: ProgressItemProps[];
  /** Size of the component */
  size?: BaseSize;
  /** Whether the sequential progress is loading */
  loading?: boolean;
  /** Duration of the countdown timer in milliseconds */
  duration?: number;
  /** Start time of the countdown timer in milliseconds */
  startAt?: number;
  /** Margin top size */
  mt?: PaddingSizes;
  /** Countdown timer configuration */
  timer?: TickerProps;
}

/**
 * Component that represents a sequential progress bar with countdown timer support.
 *
 * Use forwarded ref to control countdown
 *
 * @example
 * <SequentialProgress
 *   steps={[
 *     { title: 'Step 1', icon: '1' },
 *     { title: 'Step 2', icon: '2' },
 *     { title: 'Step 3', icon: '3' },
 *   ]}
 *   size="md"
 *   loading={false}
 *   duration={30000}
 *   startAt={0}
 *   mt="md"
 *   timer={{ startAt: 0, duration: 30000, updateInterval: 1000 }}
 * />
 */
export const SequentialProgress = forwardRef<SequentialProgressHandle, ISequentialProgress>(
  (
    {
      timer = { startAt: 0, duration: 30000, updateInterval: 1000 },
      size = 'md',
      steps,
      loading,
      mt,
      testId,
    }: ISequentialProgress,
    ref,
  ) => {
    const olRef = React.useRef<HTMLOListElement>(null);

    const { pause, reset } = useTicker({
      ...(timer || ({} as TickerProps)),
      tick: (time) => {
        const result = ((timer.duration - time) * 100) / timer.duration;
        setProgressBarWidth(result);
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        startCountdown: () => pause(false),
        resetCountdown: reset,
      }),
      [],
    );

    const setProgressBarWidth = (progress: number) => {
      if (olRef.current) {
        const ol = olRef.current;
        const li = ol.querySelector('.progress-item.step-active') as HTMLLIElement;
        if (li) {
          li.style.setProperty('--pbp', `${progress / 100}`);
        }
      }
    };

    if (loading) {
      return null;
    }

    return (
      <ol
        ref={olRef}
        className={classNames('sequential-progress', {
          [`sequential-progress-${size}`]: size,
          [`mt-${mt}`]: mt,
        })}
        data-testid={testId ?? 'step-progress'}
      >
        {steps.map((step, index) => {
          return (
            <li
              key={step.key ? `step-progress-${step.key}` : `step-progress-${index}`}
              className={classNames('progress-item progress-default', {
                'step-active': step.active,
                'step-completed': step.complete,
                'step-uncompleted': !step.complete && !step.active,
                'step-failed': step.failed,
              })}
            >
              <div className="progress-item-content">
                <div className="progress-item-icon">{step.icon}</div>
                <div className="progress-item-text">{step.title}</div>
              </div>
            </li>
          );
        })}
      </ol>
    );
  },
);
