import { PriceQuery } from '@bilira-org/react-utils/src/apis/price';

import { environment } from '@Libs/constants/environment';

const client = new PriceQuery({
  baseUrl: environment.PRICE_SERVICE_BASE_URL,
});

Object.freeze(client);

export default client;
