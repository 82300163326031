import React from 'react';

import Kyc1Panel from './components/Kyc1Panel';
import Kyc2Panel from './components/Kyc2Panel';
import Kyc3Panel from './components/Kyc3Panel';

function Cards() {
  return (
    <>
      <Kyc3Panel />
      <Kyc2Panel />
      <Kyc1Panel />
    </>
  );
}

export default Cards;
