import React from 'react';

import { TransactionType } from '@bilira-org/react-utils';
import type { TransactionsRecordType } from '@bilira-org/react-utils';

import FiatTransactionIcon from '@Components/transaction/components/fiat/TransactionIcon';
import InternalTransactionIcon from '@Components/transaction/components/internal/TransactionIcon';
import OTCTransactionIcon from '@Components/transaction/components/otc/TransactionIcon';
import SwapTransactionIcon from '@Components/transaction/components/swap/TransactionIcon';

type Props = {
  data: TransactionsRecordType;
};

const amountMap: Record<TransactionType, any> = {
  fiat: FiatTransactionIcon,
  crypto: FiatTransactionIcon,
  otc: OTCTransactionIcon,
  swap: SwapTransactionIcon,
  conversion: SwapTransactionIcon,
  other: InternalTransactionIcon,
};

const TransactionIcon = ({ data }: Props) => {
  const Element = amountMap[data.type];
  if (!Element) {
    return null;
  }

  return <Element data={data} />;
};

export default TransactionIcon;
