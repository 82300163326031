import React, { forwardRef, ReactNode } from 'react';

import classNames from 'classnames';

import { Icon } from '../icons';
import { BaseComponentType, MarginSizes, StatusColorType } from '../types';

export type AlertProps = React.HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional classes to apply to the alert */
    className?: string;
    /** Icon to display in the alert */
    icon?: ReactNode;
    /** Status color to apply to the alert */
    status?: StatusColorType;
    /** Margin on the y-axis */
    my?: MarginSizes;
    /** Margin on the x-axis */
    mt?: MarginSizes;
    /** Margin on the bottom */
    mb?: MarginSizes;
    /** Extra content to display in the alert */
    extra?: React.ReactNode;
  };

/** Icons to display for each status */
const statusIcons = {
  green: <Icon size="lg" type="s:check" color="green-500" />,
  orange: <Icon size="lg" type="s:exclamation-circle" color="orange-500" />,
  red: <Icon size="lg" type="s:exclamation-circle" color="red-500" />,
  yellow: <Icon size="lg" type="s:information-circle" color="yellow-500" />,
  empty: <></>,
};

/**
 * Alert component for displaying styled alerts
 *
 * @example
 * <Alert status="red" mt="md">
 *   Some error occurred. Please refresh the page
 * </Alert>
 */
const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ children, icon, status, my, mt, mb, className, extra, testId, ...props }, ref): JSX.Element => {
    const classes = classNames('alert', className, {
      [`alert-${status}`]: status,
      [`my-${my}`]: my,
      [`mt-${mt}`]: mt,
      [`mb-${mb}`]: mb,
    });

    return (
      <div role="alert" {...props} ref={ref} className={classes} data-testid={testId ?? 'alert'}>
        <div className="flex items-start">
          <div className="mr-3"> {icon || statusIcons[status ?? 'empty']}</div>
          <div className="text-sm font-normal">{children}</div>
          {extra}
        </div>
      </div>
    );
  },
);

Alert.displayName = 'Alert';

export default Alert;
