import React, { useCallback, useMemo } from 'react';

import { SwapPairType, useDeferredFilter } from '@bilira-org/react-utils';

import { useGeneralSessionStore } from '@/store';
import AccountQuery from '@Libs/clientInstances/accountQuery';
import useGetSwapPairs from '@Libs/hooks/useGetSwapPairs';

const useBuySellPairs = () => {
  const { swapPairsList, isPending: isSwapPairsPending } = useGetSwapPairs();
  const { data, isPending: isWatchlistPending } = AccountQuery.useGetWatchList('market');

  const isLoggedIn = useGeneralSessionStore((state) => state.isLoggedIn);

  const isWatchlistLoading = isLoggedIn && isWatchlistPending;

  const filter = useCallback((item: SwapPairType, search: string) => {
    const regExSearch = new RegExp(search.replace('/', '').replace(' ', ''), 'i');

    const concatArr = [
      `${item.base_asset}${item.quote_asset}`,
      `${item.quote_asset}${item.base_asset}`,
      item.quote_asset,
      item.base_asset,
    ];
    return concatArr.some((item) => item.match(regExSearch));
  }, []);

  const {
    searchText,
    onSearch,
    filteredData: filteredSwapPairs,
  } = useDeferredFilter({
    predicate: filter,
    data: swapPairsList,
  });

  const filteredWatchListPairs = useMemo(() => {
    if (!(data && filteredSwapPairs)) {
      return [];
    }

    return filteredSwapPairs?.filter((pair) => data.includes(`${pair.base_asset}/${pair.quote_asset}`));
  }, [data, filteredSwapPairs]);

  return {
    filteredSwapPairs,
    filteredWatchListPairs,
    searchText,
    onSearch,
    isPending: isSwapPairsPending || isWatchlistLoading,
    displayWatchList: isLoggedIn,
  };
};

export default useBuySellPairs;
