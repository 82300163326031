import { WebSocketManager } from '@bilira-org/react-utils';

import { environment } from '@Libs/constants/environment';
import { lp, obu, ohlc, ss } from '@Libs/ws/helpers/subscriptionHandlers';
import { usePriceStore } from '@Store/priceStore';

const wsManager = new WebSocketManager(environment.PRICE_SERVICE_WSS_URL);
wsManager.connect();

const webSocketMessageHandler = (data: any, type: ResponseEventType) => {
  usePriceStore.getState().setLiveData(type, data);
  data?.p && subscriptionMap[type]?.(data);
};

wsManager.handleMessage(webSocketMessageHandler);

const subscriptionMap: Record<Partial<ResponseEventType>, (data: any) => void> = { lp, ohlc, obu, ss };

export default wsManager;
