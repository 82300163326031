export const UserQueryKey = {
  USERS: 'users-key',
  TWO_FA: 'two-fa-key',
  NOTIFICATIONS: 'user-notifications-key',
  LEGAL_DOCUMENTS: 'legal-documents-key',
  LEGAL_DOCUMENT_CONSENTS: 'legal-document-consents-key',
  REFERRAL_CODES: 'referral-codes-key',
  REFERRAL_CODE_TYPES: 'referral-code-types',
  DISCLAIMER_RECORDS: 'disclaimer-records-key',
};

export const SwapQueryKey = {
  SWAP_PAIRS: 'swap-pairs-key',
  TRANSACTION: 'swap-transaction-key',
  TRANSACTIONS: 'swap-transactions-key',
  SWAP_OTC_TRANSACTIONS: 'swap-otc-transactions-key',
  SWAP_OTC_TRANSACTION_DETAIL: 'swap-otc-transaction-detail-key',
  SAVED_COMMISSIONS: 'swap-saved-commissions-key',
  SWAP_COMMISSION_TIER: 'swap-commission-tier',
  SWAP_COMMISSION_TIERS: 'swap-commission-tiers',
};

export const PreferencesQueryKey = {
  ACCOUNT_PREFERENCES: 'account-preferences-key',
  USER_PREFERENCES: 'get-user-preferences-key',
};

export const IdpQueryKey = {
  USER_KYC: 'get-user-kyc-key',
  USER_ACTIVITY: 'user-activity-key',
  ENABLE_OTP: 'enable-otp-key',
  HEALTH: 'idp-health-key',
  USER_INFO: 'idp-user-info-key',
};

export const FiatQueryKey = {
  WITHDRAWAL_FEE: 'fiat-withdrawals-fee-key',
  SYSTEM_BANK_ACCOUNTS: 'system-bank-accounts-key',
  TRANSACTION: 'fiat-transaction-key',
  TRANSACTIONS: 'fiat-transactions-key',
};

export const CryptoQueryKey = {
  DEPOSIT_WALLET: 'deposit-wallet-key',
  CRYPTO_NETWORKS: 'crypto-networks-key',
  CRYPTO_WITHDRAWALS_FEE: 'crypto-withdrawals-fee-key',
  CRYPTO_ASSETS: 'crypto-assets-key',
  ASSET_PAIR_PRICE: 'asset-pair-price-key',
  CRYPTO_ASSET_HISTORY: 'crypto-asset-history-key',
  CRYPTO_SEARCH_ASSETS: 'crypto-search-assets-key',
  CRYPTO_MARKET: 'crypto-market-key',
  CRYPTO_MARKET_BY_ASSET: 'crypto-market-by-asset',
  DISABLED_OPERATIONS: 'disabled-operations-key',
  TRANSACTION: 'crypto-transaction-key',
  TRANSACTIONS: 'crypto-transactions-key',
  ASSET_TAGS: 'crypto-asset-tags-key',
  CRYPTO_WITHDRAWAL_FEES: 'crypto-withdrawals-fees-key',
  TRADE_POPUP_CLICKS: 'crypto-trade-popup-clicks-key',
};

export const CommonQueryKey = {
  COMMON_ANNOUNCEMENT: 'common-announcement-key',
  POPUP: 'common-popup-key',
};

export const BlogQueryKey = {
  BLOG_ANNOUNCEMENTS: 'blog-announcements-key',
  FEATURED_POSTS: 'featured-posts-key',
  BLOG_CATEGORIES: 'blog-categories-key',
  COIN_INFO: 'coin-info-key',
  BLOG_POSTS: 'blog-posts-key',
  BLOG_POST_DETAIL: 'blog-post-detail-key',
  RELATED_POSTS: 'related-posts-key',
  BLOG_LIST: 'blog-list-key',
};

export const AllowancesQueryKey = {
  ALLOWANCES: 'allowances-key',
  NEXT_TRANSACTION: 'allowances-next-transaction-key',
  DEFAULT_ALLOWANCES: 'allowances-default-allowances-key',
  CRYPTO_DEPOSIT_LIMIT: 'allowances-crypto-deposit-limit-key',
};

export const AccountQueryKey = {
  ACCOUNTS: 'accounts-key',
  ACCOUNT_BALANCES: 'account-balance-key',
  ACCOUNT_TOTAL_BALANCE: 'account-total-balance-key',
  CRYPTO_WALLETS: 'crypto-wallets-key',
  ACCOUNT_ALL_TRANSACTIONS: 'account-all-transactions-key',
  ACCOUNT_ALL_TRANSACTION: 'account-all-transaction-key',
  ACCOUNT_INTERNAL_TRANSFER: 'account-internal-transfer-key',
  BANK_ACCOUNTS: 'bank-accounts-key',
  WATCH_LIST: 'watch-list-key',
  WATCH_LIST_DATA: 'watch-list-data-key',
  TRANSACTION_EXPORT_ALLOWANCE: 'transaction-export-allowance-key',
};

export const HealthQueryKey = {
  HEALTH: 'health-key',
};

export const RewardsQueryKey = {
  SEASON_FEATURED_TASKS_KEY: 'season-featured-tasks-key',
  REWARDS_SEASONS_KEY: 'rewards-seasons-key',
  REWARDS_USER_PARTICIPATION_PREREQUISITES_KEY: 'rewards-user-participation-prerequisite-key',
  REWARDS_PARTICIPATION_PREREQUISITES_KEY: 'rewards-participation-prerequisite-key',
  REWARDS_USER_PARTICIPATION_KEY: 'rewards-user-participation-key',
  REWARDS_USER_TASK_HISTORY_KEY: 'rewards-user-task-history-key',
  REWARDS_USER_POINT_KEY: 'rewards-user-point-key',
  REWARDS_LEADER_BOARD_KEY: 'rewards-leader-board-key',
  REWARDS_DAILY_LOGIN_KEY: 'rewards-daily-login-key',
};
