import React from 'react';

import { Alert, Display, Span, Text } from '@bilira-org/design';
import { formatPrice, isCurrentTimeInRange } from '@bilira-org/react-utils';
import { Trans, useTranslation } from 'react-i18next';

import { WORK_HOURS } from '@/libs/constants';
import useAuth from '@/libs/hooks/userAuth';
import allowancesQuery from '@Libs/clientInstances/allowancesQuery';
const WarningMessages = () => {
  const { t, i18n } = useTranslation();
  const { account } = useAuth({ suspense: false });

  const { data: allowance } = allowancesQuery.useGetNextTransaction(
    {
      account,
      domain: 'fiat',
      direction: 'withdrawal',
    },
    { suspense: false },
  );
  const formattedTxMin =
    formatPrice({ value: allowance?.min, decimal: 2, precision: 4, locale: i18n.language }).value || '-';

  return (
    <>
      <Display show={isCurrentTimeInRange(WORK_HOURS.end, WORK_HOURS.start)}>
        <Alert status="orange" mt="2xl">
          {t('fiat.withdraw-warning.bank-limit')}
        </Alert>
      </Display>
      <Alert status="yellow" mt="2xl">
        <Text size="sm" weight="bold" color="secondary-500">
          {t('fiat.withdraw-warning.title')}
        </Text>

        <ul style={{ marginLeft: '18px' }}>
          <li>
            <Text color="secondary-500" size="sm">
              <Trans t={t} i18nKey="fiat.withdraw-warning.desc-1" components={{ bold: <Span weight="semibold" /> }} />
            </Text>
          </li>
          <li>
            <Text color="secondary-500" size="sm">
              {t('fiat.withdraw-warning.desc-2', { amount: formattedTxMin })}
            </Text>
          </li>
          <li>
            <Text color="secondary-500" size="sm">
              <Trans t={t} i18nKey="fiat.withdraw-warning.desc-3" components={{ bold: <Span weight="semibold" /> }} />
            </Text>
          </li>
          <li>
            <Trans t={t} i18nKey="fiat.withdraw-warning.desc-4" components={{ bold: <Span weight="semibold" /> }} />
          </li>
        </ul>
      </Alert>
    </>
  );
};

export default WarningMessages;
