import { useMemo } from 'react';

import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../../client';
import { QueryOptions, RecordsPaginationType } from '../../../model';
import { sortListBy, SortOrder } from '../../../utils';
import reactQueryHelpers from '../../../utils/rq/reactQueryHelpers';
import { IPriceApi } from '../api/interface';
import PriceApi from '../api/PriceApi';
import { PriceQueryKey } from '../constants';
import {
  AllPricesType,
  AssetListParamType,
  AssetListType,
  LimitedOHLCSListParamType,
  LimitedOHLCSParamType,
  OHLCSListType,
  OHLCSParamType,
  OHLCSType,
  PriceAssetTagType,
  SymbolStatisticsDataType,
} from '../model/PriceTypes';

class PriceQuery {
  _api: IPriceApi;

  public constructor(params: HttpClientType) {
    this._api = new PriceApi(params);
  }

  public useGetOHLCS = (params: OHLCSParamType, options?: QueryOptions) =>
    useQuery<OHLCSType, HttpErrorType>({
      queryKey: [PriceQueryKey.OHLCS, params],
      queryFn: () => this._api.getOHLCS(params),
      placeholderData: keepPreviousData,
      ...options,
    });

  public useGetLimitedOHLCS = (params: LimitedOHLCSParamType, options?: QueryOptions) =>
    useQuery<OHLCSType, HttpErrorType>({
      queryKey: [PriceQueryKey.LIMITED_OHLCS, params],
      queryFn: () => this._api.getLimitedOHLCS(params),
      placeholderData: keepPreviousData,
      ...options,
    });

  public useGetLimitedOHLCSList = (params: LimitedOHLCSListParamType, options?: QueryOptions) =>
    useQuery<OHLCSListType, HttpErrorType>({
      queryKey: [PriceQueryKey.LIMITED_OHLCS_LIST, params],
      queryFn: () => this._api.getLimitedOHLCSList(params),
      placeholderData: keepPreviousData,
      ...options,
    });

  public useGetAllPrices = (options?: QueryOptions) =>
    useQuery<AllPricesType[], HttpErrorType>({
      queryKey: [PriceQueryKey.ALL_PRICES],
      queryFn: () => this._api.getAllPrices(),
      placeholderData: keepPreviousData,
      ...options,
    });

  public useGetSymbolStatistics = (symbol: string, options?: QueryOptions) =>
    useQuery<SymbolStatisticsDataType, HttpErrorType>({
      queryKey: [PriceQueryKey.SYMBOL_STATISTICS, symbol],
      queryFn: () => this._api.getSymbolStatistics(symbol),
      ...options,
    });

  public useGetAssetList = (params: Partial<AssetListParamType>, options?: QueryOptions) => {
    const rest = useInfiniteQuery<RecordsPaginationType<AssetListType>, HttpErrorType>({
      queryKey: [PriceQueryKey.SYMBOL_INFO_EXTENDEDS, params],
      queryFn: ({ pageParam }) => {
        const { offset, limit } = reactQueryHelpers.queryFn({ pageParam });

        return this._api.getAssetList({ ...params, offset, limit });
      },
      getNextPageParam: reactQueryHelpers.getNextPageParam,
      getPreviousPageParam: reactQueryHelpers.getPreviousPageParam,
      initialPageParam: 'limit=10&offset=0',
      suspense: false,
      ...options,
    });

    const article = useMemo(
      () =>
        rest?.data?.pages?.reduce((acc, pages) => [...acc, ...pages.records], [] as AssetListType[]) ||
        ([] as AssetListType[]),
      [rest?.data?.pages],
    );

    return {
      pages: article,
      pageParams: rest.data?.pageParams,
      ...rest,
    };
  };

  public useGetAssetTags = (options?: QueryOptions) =>
    useQuery<PriceAssetTagType[], HttpErrorType>({
      queryKey: [PriceQueryKey.ASSET_TAGS],
      queryFn: async () => {
        const assets = await this._api.getAssetTags();
        return sortListBy(assets, 'order', SortOrder.ASC);
      },
      placeholderData: keepPreviousData,
      ...options,
    });
}

export default PriceQuery;
