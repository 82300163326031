import React from 'react';

import { Block, Skeleton, Span, Text, withSuspense } from '@bilira-org/design';
import { CoinSymbolType, NetworkAliasType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import cryptoQuery from '@Libs/clientInstances/cryptoQuery';
import useGetMinCryptoDepositLimit from '@Libs/hooks/useGetMinCryptoDepositLimit';

type Props = { network?: NetworkAliasType; asset?: CoinSymbolType };

const Limits = ({ network, asset }: Props) => {
  const { t } = useTranslation();
  const isNetworkAndAssetPresent = !!(network && asset);

  const { data: networks } = cryptoQuery.useGetNetworks();
  const networkDetails = networks?.find((item) => item.alias === network);
  const { minDepositLimit: minAllowancePerTransaction, isPending } = useGetMinCryptoDepositLimit({
    asset: asset,
    networkAlias: network,
  });

  if (!isNetworkAndAssetPresent) {
    return (
      <Block gap="sm">
        <Block row justify="between">
          <Text size="sm" color="neutral-700">
            {t('crypto.deposit.min-deposit-amount')}
          </Text>
          <Text size="sm" weight="medium" color="neutral-700">
            - {asset}
          </Text>
        </Block>
        <Block row justify="between">
          <Text size="sm" color="neutral-700">
            {t('crypto.deposit.deposit-confirmation-count')}
          </Text>
          <Text size="sm" weight="medium" color="neutral-700">
            <Span weight="bold">-</Span>
          </Text>
        </Block>
      </Block>
    );
  }

  return (
    <Block gap="sm">
      <Block row justify="between">
        <Text size="sm" color="neutral-700">
          {t('crypto.deposit.min-deposit-amount')}
        </Text>
        <Text size="sm" weight="medium" color="neutral-700">
          {minAllowancePerTransaction} {asset}
        </Text>
      </Block>
      <Block row justify="between">
        <Text size="sm" color="neutral-700">
          {t('crypto.deposit.deposit-confirmation-count')}
        </Text>
        <Text size="sm" weight="medium" color="neutral-700">
          <Span weight="bold">{networkDetails?.confirmations || '-'}</Span> {t('crypto.deposit.confirmation')}
        </Text>
      </Block>
    </Block>
  );
};

export default withSuspense(
  Limits,
  <Block gap="sm">
    <Block row justify="between">
      <Skeleton height="size-5" width="size-24" />
      <Skeleton height="size-5" width="size-14" />
    </Block>
    <Block row justify="between">
      <Skeleton height="size-5" width="size-24" />
      <Skeleton height="size-5" width="size-14" />
    </Block>
    {/* <Block row justify="between">
      <Skeleton height="size-5" width="size-24" />
      <Skeleton height="size-5" width="size-14" />
    </Block> */}
  </Block>,
);
