import { Layout } from '@bilira-org/design';
import { Outlet } from 'react-router-dom';

import ErrorBoundary from '../errorBoundary/ErrorBoundary';

function LayoutEmpty() {
  return (
    <ErrorBoundary>
      <Layout>
        <Outlet />
      </Layout>
    </ErrorBoundary>
  );
}

export default LayoutEmpty;
