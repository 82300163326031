import { UserInfo } from '@bilira-org/react-utils';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface GeneralSessionStoreType {
  isNotificationViewed: boolean;
  setIsNotificationViewed: (value: boolean) => void;

  isNewFeatureViewed?: Record<string, boolean>;
  setIsNewFeatureViewed: (value: Record<string, boolean>) => void;

  isLoggedIn: boolean;
  userInfo: UserInfo | null;
  setUserInfo: (value: UserInfo | null) => void;
}

export const useGeneralSessionStore = create<GeneralSessionStoreType>()(
  persist(
    (set) => ({
      isNotificationViewed: false,
      setIsNotificationViewed: (value: boolean) => set({ isNotificationViewed: value }),

      isLoggedIn: false,
      userInfo: null,
      setUserInfo: (value: UserInfo | null) => set(() => ({ userInfo: value, isLoggedIn: !!value })),

      isNewFeatureViewed: {},
      setIsNewFeatureViewed: (value: Record<string, boolean>) =>
        set((state) => ({ isNewFeatureViewed: { ...state.isNewFeatureViewed, ...value } })),
    }),
    {
      name: 'general-session-store', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
