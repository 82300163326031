// import { ReactNode, useContext, useReducer } from 'react';
//
// import GlobalContext, { initialState } from './createContext';
// import reducer from './reducer';
//
// const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
//   const [state, dispatch] = useReducer(reducer, initialState);
//   return (
//     <GlobalContext.Provider
//       value={{
//         state,
//         dispatch,
//       }}
//     >
//       {children}
//     </GlobalContext.Provider>
//   );
// };
//
// export const useGlobalContext: ContextHook = () => {
//   const { state, dispatch } = useContext(GlobalContext);
//   return { state, dispatch };
// };
//
// export default GlobalContextProvider;
export { useGeneralSessionStore } from './generalSessionStore';
export { useGeneralStore } from './generalStore';
export { useGeneralPersistedStore } from './generalPersistedStore';
