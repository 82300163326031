import React, { ChangeEvent, ClipboardEventHandler, forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import fixIosInputFocus from '../../helpers/fixIosInputFocus';
import { BackgroundColorType } from '../color';
import { BaseComponentType, BaseSize, FontWeight, PaddingSizes, TextSizes } from '../types';

export interface InputNumberProps {
  /** The value of the input. Can be a string or null. */
  value?: string | null;
  /** Callback triggered on input change. */
  onChange?: (value: string) => void;
  /** Disables the input. */
  disabled?: boolean;
  /** Size of the input. */
  size?: BaseSize;
  /** Background color of the input. */
  color?: BackgroundColorType;
  /** Padding of the input. */
  padding?: PaddingSizes;
  /** Icon to be displayed with the input. */
  icon?: ReactNode;
  /** Text size of the input. */
  textSize?: TextSizes;
  /** Font weight of the input. */
  weight?: FontWeight;
  /** Icon displayed at the start of the input. */
  iconStart?: ReactNode;
  /** Icon displayed at the end of the input. */
  iconEnd?: ReactNode;
  /** Additional class name for the input. */
  className?: string;
  /** Indicates whether the input is in an invalid state. */
  invalid?: boolean;
  /** Number of decimal places to allow. */
  decimalPlaces?: number;
  /** Disables the zero check. */
  disableZeroCheck?: boolean;
  /** Callback triggered on Enter key press. */
  onEnter?: () => void;
}

type Props = InputNumberProps & Omit<React.HTMLProps<HTMLInputElement>, keyof InputNumberProps> & BaseComponentType;

/**
 * InputNumber component for numeric input.
 *
 * @example
 * <NumberInput
 *  decimalPlaces={4}
 *  onChange={handleChange}
 *  value={value}
 *  color="ghost"
 *  placeholder="Enter a number"
 * />
 */
const InputNumber = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    value: valueFromProps,
    onChange,
    disabled,
    size,
    color,
    padding = 'lg',
    icon,
    textSize,
    weight,
    iconStart,
    iconEnd,
    className,
    invalid,
    decimalPlaces,
    disableZeroCheck,
    testId,
    onEnter,
    onKeyDown,
    onFocus,
    ...rest
  } = props;

  const [innerValueState, setInnerValueState] = useState<string>('');
  const isValidInput = valueFromProps !== undefined && valueFromProps !== null;

  const value = isValidInput ? (valueFromProps as string) : innerValueState;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const result = setInputValue(e.target.value);

    if (!result) {
      e.preventDefault();
    }
  };

  const setInputValue = (inputValue: string): boolean => {
    const formattedValue = inputValue?.replace(/,/g, '.');

    const regex = /^[-+]?[0-9]+(\.[0-9]*)?$/;
    const detectZeroRegex = /^0[0-9]$/;

    if (!formattedValue) {
      setInnerValueState('');
      onChange?.('');
      return true;
    }

    const zeroDecimalPart = formattedValue.split('.')?.[1];
    // Checks if the decimal part is longer than the allowed decimal places
    if (zeroDecimalPart && decimalPlaces && zeroDecimalPart.length > decimalPlaces) {
      return false;
    }

    if (!disableZeroCheck && (detectZeroRegex.test(formattedValue) || !regex.test(formattedValue))) {
      return false;
    }

    setInnerValueState(formattedValue);
    onChange?.(formattedValue);
    return true;
  };

  const format = (val: string) => {
    if (val.indexOf('.') === -1) {
      return val;
    }

    const [integerPart, zeroDecimalPart] = val.split('.');

    if (decimalPlaces === 0) {
      return `${integerPart}`;
    }

    const zeroValuePart = decimalPlaces ? zeroDecimalPart?.substring(0, decimalPlaces) : zeroDecimalPart;
    return `${integerPart}.${zeroValuePart}`;
  };

  const classes = classNames(
    'input w-full',
    {
      [`input-${color}`]: color,
      'has-icon-start': !!iconStart,
      'has-icon-end': !!iconEnd,
      'input-invalid': invalid,
    },
    className,
  );

  const inputValue = useMemo(() => format(value), [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };

  return (
    <label className="input-number-group" data-testid={testId}>
      {iconStart && <span className="input-icon-start">{iconStart}</span>}

      <input
        {...(testId ? { 'data-testid': `${testId}-inner` } : {})}
        ref={ref}
        className={classes}
        onChange={handleChange}
        value={inputValue}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        inputMode="decimal"
        onFocus={(e) => {
          fixIosInputFocus(e.currentTarget);
          onFocus?.(e);
        }}
        {...rest}
      />

      {iconEnd && <span className="input-icon-end">{iconEnd}</span>}
    </label>
  );
});

InputNumber.displayName = 'Input';

export default InputNumber;
