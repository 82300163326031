import { isEmpty } from './isEmpty';

/**
 * Formats a value by replacing it with a placeholder if the value is empty.
 *
 * @template T - The type of the input value.
 * @template P - The type of the placeholder (default is string).
 *
 * @param {T} data - The input value to be formatted.
 * @param {P} [placeholder='-'] - The placeholder value to use when `data` is empty.
 *
 * @returns {T | P} - The formatted value. If `data` is empty, returns the placeholder; otherwise, returns the original `data`.
 *
 * @example
 * // Using a default placeholder
 * formatIfEmpty('Hello'); // 'Hello'
 * formatIfEmpty(''); // '-'
 *
 * // Using a custom placeholder type
 * formatIfEmpty(42, 0); // 42
 */
function formatIfEmpty<T, P = string>(data: T, placeholder: P = '-' as P) {
  return isEmpty(data) ? placeholder : data;
}

export { formatIfEmpty };
