import React from 'react';

import { RouterProvider } from 'react-router-dom';

import router from './createRouter';

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;

// Will be implemented later in profiling integration
// export default Sentry.withProfiler(App);
