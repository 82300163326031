import React from 'react';

import { Flex, Layout, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import BuySellCard from '@Components/trade/buySell/BuySellCard';

const { Content, Hero } = Layout;

function Swap() {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('market.swap.do-swap')} titleSuffix="subpage" />
      <Hero view="strip">
        <div>
          <Text heading size="xl" color="white" weight="bold" my="md">
            {t('market.swap.do-swap')}
          </Text>
        </div>
      </Hero>
      <Content>
        <Flex maxWidth="2xl" justify="center" mx="auto">
          <Flex.Item grow="1">
            <BuySellCard hiddenProps={{ show: { xs: true, sm: true } }} />
          </Flex.Item>
        </Flex>
      </Content>
    </>
  );
}

export default Swap;
