import { useEffect, useRef } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useGeneralPersistedStore } from '@/store/generalPersistedStore';
import PreferencesApi from '@Libs/clientInstances/preferencesQuery';
import UserQuery from '@Libs/clientInstances/userQuery';

const useAuthCallback = () => {
  const setIsNotEligible = useGeneralPersistedStore((state) => state.setIsNotEligible);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const timer = useRef<NodeJS.Timeout>();

  const { mutate, isError, isSuccess, error } = UserQuery.usePostGrantCode();

  useEffect(() => {
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      if (code) {
        mutate({ grant_code: code });
      }
    }, 0);
  }, []);

  return { isError, error, isSuccess, setIsNotEligible };
};

export default useAuthCallback;
