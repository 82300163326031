import React, { forwardRef, memo, ReactNode, useEffect, useImperativeHandle, useRef } from 'react';

import { BaseComponentType } from '../types';
import { wrapWithElementIfInvalid } from '../utils';

export type StepperProps<T> = BaseComponentType & {
  /** The current step in the stepper. */
  step: T;
  /** Array of React nodes representing each step. Use `StepperItem` as children*/
  children?: ReactNode[];
};

export type StepperPreviousHandle<T> = {
  /** Get the previous step in the stepper */
  previous: () => T;
  /** Clears the history of visited steps */
  clearHistory: () => void;
};

/**
 * Stepper component for managing and navigating through a sequence of steps.
 *
 * @example
 * <Stepper step={currentStep} ref={stepperRef}>
 *   <Stepper.Item step={1}>
 *     <StepOneComponent />
 *   </Stepper.Item>
 *   <Stepper.Item step={2}>
 *     <StepTwoComponent />
 *   </Stepper.Item>
 * </Stepper>
 */
const Stepper = <T,>(
  { step, children, testId }: StepperProps<T>,
  ref?: React.ForwardedRef<StepperPreviousHandle<T | undefined>>,
) => {
  const { current: list } = useRef(new Set<T>());

  useImperativeHandle(
    ref,
    () => ({
      previous: () => {
        if (list.size > 0) {
          list.delete(step);
          return Array.from(list).pop() as T;
        }
      },
      clearHistory: () => {
        list.clear();
      },
    }),
    [step],
  );

  useEffect(() => {
    list.add(step);
  }, [step]);

  return (
    <div className="flex flex-col w-full" data-testid={testId ?? 'stepper'}>
      {children?.map((node, index) =>
        wrapWithElementIfInvalid({
          node: node,
          wrapper: <></>,
          props: { step: step, page: index },
        }),
      )}
    </div>
  );
};

export type StepperItemProps = {
  /** Page number or identifier associated with the step */
  page?: number | string;
  /** The name of the page associated with the step. */
  pageName?: string;
  /** The step number. */
  step?: number;
  /** The content to render for the step. */
  children?: ReactNode | ReactNode[];
};

/**
 * Component that represents a step in the Stepper.
 * 
 * @example
 * // Example usage of StepperItem within Stepper component
 * <Stepper.Item step={1}>
 *   <StepOneComponent />
 * </Stepper.Item>
 * <Stepper.Item step={2}>
 *   <StepTwoComponent />
 * </Stepper.Item>

 */
function StepperItem({ step, page, pageName, children }: StepperItemProps) {
  const toCompare = typeof step === 'number' ? page : pageName;

  if (step !== toCompare) {
    return null;
  }
  return <>{children}</>;
}

const StepperToExport = forwardRef(Stepper) as <T>(
  props: StepperProps<T> & { ref?: React.ForwardedRef<StepperPreviousHandle<T | undefined>> },
) => ReturnType<typeof Stepper>;

export default Object.assign(StepperToExport, { Item: StepperItem });
