import React from 'react';

import { Block, Button, Checkbox, Col, Form, Grid, Input, Label, notification, withSuspense } from '@bilira-org/design';
import { ReferralCodeType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import UserQuery from '@Libs/clientInstances/userQuery';

interface Props {
  referralCode?: ReferralCodeType;
  onSubmit: () => void;
  onClose: () => void;
}

function UpdateReferral({ referralCode, onSubmit, onClose }: Props) {
  const { t } = useTranslation();
  const { mutateAsync } = UserQuery.usePatchReferralCode();
  const form = Form.useForm<any>({
    mode: 'onChange', // to validate on field change
    defaultValues: {
      title: referralCode?.title,
      default: referralCode?.default,
    },
  });

  const handleSubmit = (values: any) => {
    if (!referralCode) {
      return;
    }

    mutateAsync({ id: referralCode?.code, title: values.title, default: values.default })
      .then(() => {
        notification.success(t('common.updated'));
        onSubmit();
      })
      .catch(({ response }) => {
        notification.error(response.data.message);
      });
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Grid gap="md">
        <Col>
          <Form.Field
            label={<Label>{t('referral.referral-title')}</Label>}
            name="title"
            rules={{
              required: t('common.validation.required-field'),
            }}
          >
            <Input />
          </Form.Field>
        </Col>
        {!referralCode?.default && (
          <Col>
            <Form.Field controlled name="default">
              {({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChecked={field.onChange}
                  label={t('referral.set-as-standard-referral-code')}
                />
              )}
            </Form.Field>
          </Col>
        )}
        <Col>
          <Block row gap="md">
            <Button variant="outline" justify="center" size="xl" mt="lg" stretch onClick={() => onClose()}>
              {t('referral.cancel')}
            </Button>
            <Button variant="filled" justify="center" size="xl" mt="lg" type="submit" stretch>
              {t('common.accept')}
            </Button>
          </Block>
        </Col>
      </Grid>
    </Form>
  );
}

export default withSuspense(UpdateReferral, <></>);
