import BigNumber from 'bignumber.js';

import { OTCTransactionType, OTCType } from '../../model';

/**
 * Calculates OTC transaction total amount extracting commission.
 * @param {OTCType | OTCTransactionType} otcEntry - The OTC transaction entry.
 * @returns {string} The total amount without commission.
 */
const calculateOTCTotalExtractingCommission = (otcEntry: OTCType | OTCTransactionType) => {
  if (!otcEntry.commission_amount) {
    return otcEntry.to_amount;
  }
  const isDifferentAsset = otcEntry.to_asset !== otcEntry.commission_asset;

  return BigNumber(isDifferentAsset ? otcEntry.from_amount : otcEntry.to_amount)
    .minus(otcEntry.commission_amount || '0')
    .toString();
};

export default calculateOTCTotalExtractingCommission;
