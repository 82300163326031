import React from 'react';

import { Flex } from '@bilira-org/design';
import { BalanceType, concatTicker } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';

import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import { useGeneralPersistedStore } from '@/store/generalPersistedStore';
import useLastLivePrice from '@Libs/hooks/useLastLivePrice';

type Props = {
  balance: BalanceType;
};
const MyAssetTableBalanceField = ({ balance }: Props) => {
  const maskBalance = useGeneralPersistedStore((state) => state.maskBalance);
  const { liveData } = useLastLivePrice({ symbol: concatTicker(balance.asset, balance.quote_asset) });

  const getPrice = () => {
    if (!liveData?.p) {
      return balance.price;
    }

    return BigNumber(balance.balance).multipliedBy(liveData.p).toString();
  };

  return (
    <Flex direction="col" gap="xs" items="end">
      <FormattedNumberByPrice
        type="amount"
        price={balance.unit_price}
        animateChange={true}
        masked={maskBalance}
        color="secondary-500"
        weight="medium"
        suffix=""
        value={balance.balance}
      />

      <FormattedNumberByPrice
        type="price"
        base={balance.asset}
        animateChange={true}
        masked={maskBalance}
        color="neutral-800"
        size="sm"
        weight="regular"
        suffix="TRYB"
        value={getPrice()}
      />
    </Flex>
  );
};

export default MyAssetTableBalanceField;
