import React from 'react';

import { Layout, Panel } from '@bilira-org/design';

import Notifications from '@Modules/notification/components/NotificationContainer';

import Bar from './components/Bar';

const { Content } = Layout;

const Notification = () => {
  return (
    <>
      <Bar />
      <Content>
        <Panel border="neutral-400" size="md" mt="3xl">
          <Notifications />
        </Panel>
      </Content>
    </>
  );
};

export default Notification;
