import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { Block } from '../block';
import { Button } from '../button';
import { Loop } from '../loop';
import { Skeleton } from '../skeleton';
import {
  BackgroundColorType,
  BaseComponentType,
  BreakpointType,
  DirectionType,
  GapSizes,
  PaddingSizes,
  ResponsiveReturnType,
  SpaceSizes,
} from '../types';
import { buildResponsiveClass } from '../utils';

type ListActionsTextType = {
  /** The text to display when showing more items. */
  showMore: string;
  /** The text to display when showing less items. */
  showLess: string;
};

interface IList<T> extends BaseComponentType {
  /** The content of the list.
   * Children is used only when dataSource is not passed
   * @see `List.Item` for styled list item component
   * */
  children?: ReactNode;
  /** The header of the list. */
  header?: ReactNode;
  /** The data source for the list. */
  dataSource?: T[];
  /** A function to render each item in the list. */
  renderItem?: (item: T, index: number) => ReactNode;
  /** Indicates whether a divider should be displayed between items. */
  divider?: boolean;
  /** The background color of the list. */
  color?: BackgroundColorType;
  /** The space size between items in the list. */
  space?: SpaceSizes;
  /** The horizontal space size between items in the list. */
  spaceX?: SpaceSizes;
  /** The vertical space size between items in the list. */
  spaceY?: SpaceSizes;
  /** The number of items to show in each pagination page. */
  paginationSize?: number;
  /** Indicates whether the list is in a loading state. */
  loading?: boolean;
  /** The React element to display when the list is empty. */
  empty?: ReactElement;
  /** The text to be displayed for different list actions. */
  actionsText?: ListActionsTextType;
  /** Indicates whether the list should have borders. */
  bordered?: boolean;
  /** The direction in which the items in the list are displayed. */
  direction?: DirectionType | BreakpointType<DirectionType>;
  /** The React element to display as a skeleton while loading. */
  skeleton?: ReactElement;
  /** The number of skeleton lines to display. */
  skeletonLine?: number;
  /** Additional CSS class names for styling. */
  className?: string;
  /** The space size between items in the list for different breakpoints. */
  gap?: GapSizes | BreakpointType<GapSizes>;
}

/**
 * Flexible and customizable list that supports pagination and loading states.
 *
 * @example
 * const dataSource = [
 *   {
 *     id: 1,
 *     name: 'Item 1',
 *   },
 *   {
 *     id: 2,
 *     name: 'Item 2',
 *   },
 *   {
 *     id: 3,
 *     name: 'Item 3',
 *   },
 * ];
 *
 * <List dataSource={dataSource} paginationSize={2}/>
 *
 * @example
 * const dataSource = [
 *   {
 *     id: 1,
 *     name: 'Item 1',
 *   },
 *   {
 *     id: 2,
 *     name: 'Item 2',
 *   },
 *   {
 *     id: 3,
 *     name: 'Item 3',
 *   },
 * ];
 *
 * <List dataSource={dataSource} paginationSize={2}
 * renderItem={((item) => (
 *   <List.Item key={item.id}>{item.name}</List.Item>
 * ))}
 * />
 */
function List<T>(props: IList<T>) {
  const {
    dataSource,
    empty,
    loading,
    paginationSize,
    children,
    space,
    spaceX,
    spaceY,
    header,
    color,
    divider = false,
    renderItem,
    bordered,
    skeletonLine = 1,
    actionsText = { showMore: 'Show more', showLess: 'Show less' },
    direction = 'col',
    skeleton,
    className,
    gap,
    testId,
  } = props;
  const [page, setPage] = useState(paginationSize ?? dataSource?.length ?? 0);

  // why 'useEffect'
  // 'dataSource' or 'paginationSize' might not be provided at the first render.
  useEffect(() => {
    setPage(paginationSize ?? dataSource?.length ?? 0);
  }, [paginationSize, dataSource]);

  const renderInnerItem = useCallback(
    (item: any, index: number) => {
      if (!renderItem) return null;

      return renderItem(item, index);
    },
    [renderItem],
  );

  const loadMore = () => {
    if (paginationSize) {
      const { length = 0 } = dataSource || [];
      setPage((state) => (length >= page ? state + paginationSize : state));
    }
  };

  const loadLess = () => {
    if (paginationSize) {
      setPage(paginationSize);
    }
  };

  const items = useMemo(
    () => dataSource?.slice(0, page).map((item: T, index: number) => renderInnerItem(item, index)),
    [dataSource, page, renderInnerItem],
  );

  const isMore = (dataSource?.length ?? 0) >= page;
  const showMore = (dataSource?.length ?? 0) > (paginationSize ?? 0);

  let directionClass: ResponsiveReturnType = {};
  let gapClass: ResponsiveReturnType = {};

  if (direction) {
    directionClass = buildResponsiveClass(direction, 'tw-flex-');
  }

  if (gap) {
    gapClass = buildResponsiveClass(gap, 'gap-');
  }

  //TODO: Try making scrollbar-hide optional.
  const classes = classNames(
    'list scrollbar-hide',
    `list-background-${color}`,
    {
      'overflow-x-scroll': direction === 'row',
      'list-divider': divider,
      [`space-y-${spaceY || space}`]: spaceY || (space && direction === 'col'),
      [`space-x-${spaceX || space}`]: spaceX || (space && direction === 'row'),
      [`list-items-bordered`]: bordered,
    },
    directionClass,
    gapClass,
    className,
  );

  if (loading) {
    return skeleton ? (
      <div className={classNames(classes)}>
        <Loop data={skeletonLine}>{skeleton}</Loop>
      </div>
    ) : (
      <div className={classNames(classes)}>
        <Loop data={skeletonLine}>
          <Skeleton repeat={1} />
        </Loop>
      </div>
    );
  } else {
    if (!(items?.length ?? children)) {
      return <>{empty}</>;
    }
  }

  return (
    <>
      <ul className={classes} data-testid={testId ?? 'list'}>
        {header}
        {items ?? children}
      </ul>
      {paginationSize && showMore && (
        <Block justify="center" data-testid={`${testId ?? 'list'}-pagination`}>
          <Button onClick={isMore ? loadMore : loadLess} size="md" variant="link">
            {isMore ? actionsText.showMore : actionsText.showLess}
          </Button>
        </Block>
      )}
    </>
  );
}

type ItemProps = Omit<React.LiHTMLAttributes<HTMLLIElement>, ''> &
  BaseComponentType & {
    /** The content of the list item. */
    children: ReactNode;
    /** The extra content to be displayed beside the list item. */
    extra?: ReactNode | ((props: { hover: boolean }) => ReactNode);
    /** The padding size for the list item. */
    size?: PaddingSizes | BreakpointType<PaddingSizes>;
    /** The vertical padding size for the list item. */
    py?: PaddingSizes;
    /** The horizontal padding size for the list item. */
    px?: PaddingSizes;
    /** The background color of the list item. */
    bgColor?: BackgroundColorType;
    /** The background color of the list item on hover. */
    hoverBgColor?: BackgroundColorType;
  };

/**
 * The `Item` component represents an item within the `List` component.
 *
 * @example
 * <Item bgColor="gray-100" hoverBgColor="gray-200">Item 1</Item>
 *
 * @example
 * <Item extra={<Button>Button</Button>}>Item 1</Item>
 */
function Item({ children, extra, bgColor, hoverBgColor, py, px, size, testId, ...props }: ItemProps) {
  const [hover, setHover] = useState(false);

  const renderExtra = typeof extra === 'function' ? extra({ hover }) : extra;

  let sizeClass: ResponsiveReturnType = {};

  if (size) {
    sizeClass = buildResponsiveClass(size, 'p-');
  }

  return (
    <li
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      tabIndex={0}
      className={classNames('group flex gap-2 justify-between items-center', sizeClass, {
        [`py-${py}`]: py,
        [`px-${px}`]: px,
        [`tw-bg-${bgColor}`]: bgColor,
        [`hover:tw-bg-${hoverBgColor}`]: hoverBgColor,
        'cursor-pointer': props.onClick,
      })}
      data-testid={testId ?? 'list-item'}
      {...props}
    >
      {children}
      {renderExtra && <div className="flex gap-4 items-center">{renderExtra}</div>}
    </li>
  );
}

List.Item = Item;

export default List;
