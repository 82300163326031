import React from 'react';

import { Block, Text, withSuspense } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import AssetInformation from '@Modules/market/detail/components/information/components/AssetInformation';
import AssetStatisticValues from '@Modules/market/detail/components/information/components/AssetStatisticValues';

type Props = {
  assetPair?: string;
};
const AssetInformationContainer = ({ assetPair }: Props) => {
  const { t } = useTranslation();
  const checkBMMF = assetPair?.includes('BMMF');
  return (
    <>
      {!checkBMMF && (
        <Text heading mb="2xl">
          {t('market.market-statistics')}
        </Text>
      )}

      <Block gap="4xl">
        {!checkBMMF && <AssetStatisticValues />}
        <AssetInformation assetPair={assetPair} />
      </Block>
    </>
  );
};

export default withSuspense(AssetInformationContainer);
