import React, { memo, ReactNode } from 'react';

import classnames from 'classnames';

import { BackgroundColorType, BaseComponentType, PaddingSizes, RadiusSizes } from '../types';

export type IndicatorProps = React.HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Content to be indicated */
    children?: ReactNode;
    /** Indicating item */
    item?: ReactNode;
    /** Horizontal indicator position */
    horizontal?: 'start' | 'center' | 'end';
    /** Vertical indicator position */
    vertical?: 'top' | 'middle' | 'bottom';
    /** Indicator inner ref */
    innerRef?: React.Ref<HTMLDivElement>;
    /** Additional class name for the indicator */
    className?: string;
    /** Disables indicator. Hides indicator if true */
    disabled?: boolean;
    /** Background color of the indicator */
    background?: BackgroundColorType;
    /** Padding of the indicator */
    padding?: PaddingSizes;
    /** Border radius of the indicator */
    rounded?: RadiusSizes;
    customIndicator?: boolean;
  };

/**
 * Indicator component that adds passed indicating item to the content
 */
const Indicator = React.forwardRef<HTMLDivElement, IndicatorProps>(
  (
    {
      children,
      item,
      horizontal = 'end',
      vertical = 'top',
      className,
      disabled,
      innerRef,
      testId,
      background,
      padding,
      rounded,
      customIndicator,
      ...props
    },
    ref,
  ): JSX.Element => {
    const classes = classnames('indicator-item', className, {
      [`indicator-${horizontal}`]: horizontal,
      [`indicator-${vertical}`]: vertical,
      [`tw-bg-${background}`]: background,
      [`indicator-item-padded`]: !padding,
      [`p-${padding}`]: padding,
      [`rounded-${rounded}`]: rounded,
    });

    return (
      <div className="indicator" ref={ref} data-testid={testId ?? 'indicator'}>
        {!disabled &&
          (customIndicator ? (
            item
          ) : (
            <div aria-label="Indicator" {...props} className={classes} ref={innerRef}>
              {item}
            </div>
          ))}
        {children}
      </div>
    );
  },
);

export default memo(Indicator);
