import React, { Fragment, ReactNode } from 'react';

import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { Icon } from '../icons';
import { IconProps } from '../icons/icon';
import {
  BaseComponentType,
  BaseSize,
  BreakpointType,
  ButtonVariant,
  CustomWidthType,
  PaddingSizes,
  ResponsiveReturnType,
} from '../types';
import { buildResponsiveClass } from '../utils';

export type PopoverProps = BaseComponentType & {
  /** Content to be rendered inside the popover.
   * Can be a function that receives a close function as a parameter,
   * or a ReactNode.
   */
  children?: ((close: () => void) => ReactNode) | ReactNode;
  /** Content to be rendered at the footer of the popover. */
  footer?: ReactNode;
  /** The variant of the popover button. */
  buttonVariant?: ButtonVariant;
  /** The icon to be rendered inside the popover button. */
  icon?: ReactNode | React.ReactElement;
  /** The size of the popover button. */
  buttonSize?: BaseSize;
  /** Whether the popover is open. */
  isOpen?: boolean;
  /** The custom width of the popover. */
  customWidth?: CustomWidthType | BreakpointType<CustomWidthType>;
  /** The content of the popover button. */
  button?: ReactNode;
  /** Whether the popover should have an arrow icon. */
  appearance?: boolean;
  /** Padding y-axis of the popover button. */
  buttonPy?: PaddingSizes;
  /** Padding x-axis of the popover button. */
  buttonPx?: PaddingSizes;
  /** The position of the popover. */
  position?: 'left' | 'right' | 'center';
  /** Padding y-axis of the popover. */
  py?: PaddingSizes;
  /** Padding x-axis of the popover. */
  px?: PaddingSizes;
  /** Callback when the popover is opened. */
  onOpen?: () => void;
  /** Callback when the popover is blurred. */
  onBlur?: () => void;
  /** The color of the popover icon. */
  iconColor?: IconProps['color'];
};

/**
 * A component that renders a Popover with a button and optional footer.
 *
 * @example
 * // Handles close functionality with callback
 * <Popover>
 *   {({ close }) => (
 *     <Content close={close} />
 *   )}
 * </Popover>
 *
 * @example
 * // Closes only when on blur
 * <Popover>
 *   <Content />
 * </Popover>
 *
 */
const PopoverComponent = ({
  buttonVariant,
  icon,
  buttonSize,
  children,
  button,
  buttonPy,
  buttonPx,
  py = 'md',
  px = 'md',
  footer,
  appearance = true,
  customWidth,
  position = 'right',
  onOpen,
  onBlur,
  iconColor = 'neutral-600',
  testId,
}: PopoverProps) => {
  let customWidthClasses: ResponsiveReturnType = {};

  if (customWidth) {
    customWidthClasses = buildResponsiveClass(customWidth, 'tw-custom-width-');
  }

  const classes = classNames('absolute xs:fixed z-20 mt-3 transform px-4 sm:px-0', customWidthClasses, {
    [`popover-position-${position}`]: position,
  });

  const getActive = (open: boolean) => open;

  return (
    <Popover className="popover" data-testid={testId ?? 'popover'}>
      {({ open, close }) => (
        <>
          <Popover.Button
            onClick={() => !open && onOpen?.()}
            className={classNames('button group', {
              'text-opacity-90': getActive(open),
              [`button-variant-${buttonVariant}`]: buttonVariant,
              'button-empty': !button,
              [`button-size-${buttonSize}`]: buttonSize,
              [`py-${buttonPy}`]: buttonPy,
              [`px-${buttonPx}`]: buttonPx,
            })}
            onBlur={onBlur}
          >
            {icon ? <span className="button-icon">{icon}</span> : button}
            {appearance && (
              <Icon
                size="lg"
                type="s:chevron-down"
                color={iconColor}
                className={classNames('ml-2 transition duration-100 ease-in-out group-hover:text-opacity-80', {
                  'rotate-180 transform': getActive(open),
                })}
              />
            )}
          </Popover.Button>
          {/*{getActive(open) && <Popover.Overlay className="fixed inset-0 bg-neutral-800 opacity-50" />}*/}
          <Transition
            as={Fragment}
            show={getActive(open)}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={classes}
              static
              focus
              onBlur={(e) => {
                // Popover sometimes does not close when focused outside on IOS devices
                // To fix this manually trigger close when focused outside popover panel
                if (!e.currentTarget.contains(e.relatedTarget)) {
                  close();
                }
              }}
            >
              {({ close }) => (
                <div
                  className={classNames(
                    'overflow-hidden border tw-border-neutral-400 rounded-xl shadow-lg tw-bg-theme-wn',
                    {
                      [`py-${py}`]: py,
                      [`px-${px}`]: px,
                    },
                  )}
                >
                  <div className={classNames('relative ')}>
                    {typeof children === 'function' ? children(close) : children}
                  </div>
                  {footer && <div className="relative">{footer}</div>}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PopoverComponent;

// const [show, setShow] = useState(false);
// const buttonProps = !withClick
//   ? { onMouseOver: () => setTimeout(() => setShow(true), 0), onMouseOut: () => setShow(false) }
//   : {};
