import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';
import { useEffect, useMemo } from 'react';

import { CryptoNetworkType, WalletModel } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import AccountApi from '@Libs/clientInstances/accountQuery';
import useNotify from '@Libs/hooks/useNotify';
import useAuth from '@Libs/hooks/userAuth';

const useWallet = ({ filter }: { filter?: CryptoNetworkType }) => {
  const { t } = useTranslation();
  const { account } = useAuth();
  const { data, isFetching, refetch } = AccountApi.useGetCryptoWallets(account, { enabled: false });
  const { setSelectedWallet, selectedWallet } = useWalletStore();

  const del = AccountApi.useDeleteCryptoWallet();

  const { awaitNotify } = useNotify({
    isSuccess: del.isSuccess,
    isError: del.isError,
  });

  const onDelete = (id: string) => {
    del.mutateAsync(`${id}`).then(() => refetch());
    awaitNotify({
      loading: t('common.deleting'),
      success: `${t('common.deleted')}!`,
      error: t('common.could-not-delete'),
    }).then();
  };

  const walletData = useMemo(() => {
    if (!filter) {
      return data;
    }

    return data?.filter((d) => d.network === filter.alias);
  }, [data, filter]);

  useEffect(() => {
    refetch().then();
  }, [filter]);

  const onClickProp = (item: WalletModel, selectionMode?: boolean, callback?: (wallet: WalletModel) => void) => {
    if (!callback || !selectionMode) {
      return {};
    }

    return {
      onClick: () => {
        setSelectedWallet(item);
        callback(item);
      },
    };
  };

  return { walletData, data, isFetching, onDelete, onClickProp, selectedWallet };
};

export default useWallet;
