import { useEffect } from 'react';

import { captureConsoleIntegration, extraErrorDataIntegration, httpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { environment } from '@Libs/constants/environment';

console.debug('NODE_ENV', environment.ENVIRONMENT);

const sentryReplyConfiguration = {
  networkDetailAllowUrls: [/^.*\.bilira\.(co|dev)(\/.*$)?/],
  networkDetailDenyUrls: [/^.*\.(hubspot|hubapi|posthog|hscollectedforms|mixpanel)\.(com|net)(\/.*$)?/],
  networkRequestHeaders: ['X-Custom-Header'],
  networkResponseHeaders: ['X-Custom-Header'],
  maskAllText: false,
  blockAllMedia: false,
};

Sentry.init({
  enabled: environment.ENVIRONMENT !== 'local',
  environment: environment.ENVIRONMENT,
  dsn: environment.SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(sentryReplyConfiguration),
    extraErrorDataIntegration({ captureErrorCause: true }) as Integration,
    captureConsoleIntegration({ levels: ['error'] }) as Integration,
    Sentry.browserTracingIntegration(),
    // Sentry.browserProfilingIntegration(), // Will be implemented later in profiling integration
    httpClientIntegration({
      failedRequestStatusCodes: [
        [501, 599],
        [405, 499],
      ],
    }) as Integration,
  ],
  // Performance Monitoring
  tracesSampleRate: environment.ENVIRONMENT === 'production' ? 0.3 : 5.0, // Capture 100% of the transactions, reduce in production!
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: sentryReplyConfiguration.networkDetailAllowUrls,
  // Session Replay
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: environment.ENVIRONMENT === 'production' ? 0.3 : 0.6,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.5,
  // Will be implemented later in profiling integration
  // profilesSampleRate: 0.3,
});
