import React, { useEffect } from 'react';

import { Block, Layout, Panel } from '@bilira-org/design';

import HeadTags from '@/components/common/HeadTags';
import useTourControl from '@/libs/hooks/useTourControl';
import { LayoutCommon } from '@Components/layouts';

import HypeCards from './components/HypeCards';
import PopularCryptoCards from './components/PopularCryptoCards';
import PrivateWelcomePanel from './components/PrivateWelcomePanel';
import PublicWelcomePanel from './components/PublicWelcomePanel';
import Sidebar from './components/Sidebar';

const { Content, Hero } = Layout;

const Dashboard = () => {
  const { start } = useTourControl();
  useEffect(() => {
    start('onboarding');
  }, [start]);
  return (
    <>
      <HeadTags title="BiLira" titleSuffix="homepage" />
      <Hero view="strip">
        <div>
          <PrivateWelcomePanel />
          <PublicWelcomePanel />
        </div>
      </Hero>
      <Content>
        <Panel size="none">
          <LayoutCommon>
            <LayoutCommon.Content>
              <Block gap="4xl" id="crypto-list-cards">
                <HypeCards />
                <PopularCryptoCards />
              </Block>
            </LayoutCommon.Content>
            <LayoutCommon.Sidebar>
              <Sidebar />
            </LayoutCommon.Sidebar>
          </LayoutCommon>
        </Panel>
      </Content>
    </>
  );
};

export default Dashboard;
