import React from 'react';

import { Button, CircleTimer, Flex, Meta, Text } from '@bilira-org/design';
import { AssetPairPriceType, getOfferStatus, getRemainingTime, OTCType, SwapQueryKey } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import AssetUnitPriceInfo from '@Components/trade/components/AssetUnitPriceInfo';
import { getOTCStatusConfig } from '@/libs/helpers/getStateConfig';
import { getShortId } from '@/libs/utils';

import { HidableOTCCommissionPanel } from '../../components/OTCCommissionPanel';
import OTCInfo from '../../components/OTCInfo';

type FieldProps = {
  title: React.ReactNode;
  children: React.ReactNode;
};

const Field = ({ title, children }: FieldProps) => {
  return (
    <Flex direction="row" justify="between">
      <Text color="neutral-600" size="xs">
        {title}
      </Text>
      {children}
    </Flex>
  );
};

type Props = {
  entry: OTCType;
  acceptRequest: (entry: OTCType) => () => void;
  cancelRequest: (entry: OTCType) => () => void;
  expireTimeout: () => void;
};

const OTCRowActive = ({ entry, acceptRequest, cancelRequest, expireTimeout }: Props) => {
  const { t, i18n } = useTranslation();

  const remaining = getRemainingTime(entry, 'millisecond');
  const status = getOfferStatus(entry);

  return (
    <Flex direction="col" px="2xl" py="4xl" gap="md">
      <Field title={t('otc.responsive-table.offer-time')}>
        <Flex direction="row" items="center">
          <Text size={{ xs: 'xs', sm: 'sm' }} wordBreak="none">
            {t('otc.offer-expire-date')}:&nbsp;
          </Text>
          <Text size={{ xs: 'xs', sm: 'sm' }} color="primary-500" mr="xs">
            <CircleTimer
              digitalDisplay={true}
              startAt={remaining}
              variant="countdown"
              updateInterval={1000}
              durationType="millisecond"
              duration={entry.offer_timeout_in_minutes * 1000 * 60}
            />
          </Text>
          <CircleTimer
            startAt={remaining}
            showLabel={false}
            size="xs"
            updateInterval={1000}
            durationType="millisecond"
            duration={entry.offer_timeout_in_minutes * 1000 * 60}
            onComplete={expireTimeout}
          />
        </Flex>
      </Field>

      <Field title={t('otc.responsive-table.offer-id')}>
        <Text size={{ xs: 'xs', sm: 'sm' }} mb="sm" weight="regular" transform="uppercase">
          #{getShortId(entry.id)}
        </Text>
      </Field>

      <Field title={t('otc.price-with-commission')}>
        <AssetUnitPriceInfo
          disableTooltip
          color="green-500"
          iconColor="green-500"
          weight="medium"
          amount={entry.from_amount}
          assetPairPrice={
            {
              price: entry.to_amount,
              quote_asset: entry.to_asset,
              base_asset: entry.from_asset,
            } as AssetPairPriceType
          }
          equalitySymbol="="
          size={{ xs: 'xs', sm: 'sm' }}
        />
      </Field>

      <OTCInfo entry={entry} />

      <Field title={t('otc.responsive-table.settlement-day')}>
        <Text size={{ xs: 'xs', sm: 'sm' }} color="neutral-800">
          {dayjs(entry.settlement_day, { locale: i18n.language }).format('DD MMM YYYY')}
        </Text>
      </Field>

      <Field title={t('otc.responsive-table.offer-status')}>
        <Text size={{ xs: 'xs', sm: 'sm' }} color={getOTCStatusConfig(status).text}>
          {t(`otc.status.${status}`)}
        </Text>
      </Field>

      <HidableOTCCommissionPanel entry={entry} style={{ minWidth: '330px' }} />

      <Flex
        width="full"
        direction={{ xs: 'col-reverse', sm: 'row' }}
        gap="sm"
        justify={{ xs: 'between', sm: 'between', lg: 'end' }}
      >
        <Button
          flexType={{ xs: '1', sm: '1', md: '1', lg: 'none' }}
          variant="outline"
          size="sm"
          onClick={cancelRequest(entry)}
        >
          {t(`otc.reject-offer`)}
        </Button>
        <Button
          flexType={{ xs: '1', sm: '1', md: '1', lg: 'none' }}
          variant="filled"
          size="sm"
          onClick={acceptRequest(entry)}
        >
          {t(`otc.confirm-offer`)}
        </Button>
      </Flex>
    </Flex>
  );
};

export default OTCRowActive;
