export const environment = {
  BILIRA_ENV: process.env.BILIRA_ENV,
  STABLECOIN_API_BASE_URL: process.env.STABLECOIN_API_BASE_URL || '',
  STABLECOIN_WS_BASE_URL: process.env.STABLECOIN_WS_BASE_URL || '',
  AUTHORIZATION_URL: `${process.env.OAUTH_PROVIDER_URL || ''}/oauth/authorize`,
  TOKEN_URL: `${process.env.OAUTH_PROVIDER_URL || ''}/oauth/token`,
  CLIENT_ID: process.env.STABLECOIN_OAUTH_CLIENT_ID || '',
  CALLBACK_URL: `${process.env.STABLECOIN_WEB_URL || ''}/oauth/callback`,
  SCOPE: 'profile',
  ID_URL: process.env.OAUTH_PROVIDER_URL || '',
  STABLECOIN_WEB_URL: process.env.STABLECOIN_WEB_URL || '',
  V1_WEB_URL: process.env.V1_WEB_URL || '',
  SENTRY_DSN: process.env.SENTRY_DSN || '',
  NODE_ENV: process.env.NODE_ENV || '',
  ENVIRONMENT: process.env.ENVIRONMENT || '',
  INDIVIDUAL_OTC_APPLICATION_ID: process.env.INDIVIDUAL_OTC_APPLICATION_ID || '',
  INSTITUTIONAL_OTC_APPLICATION_ID: process.env.INSTITUTIONAL_OTC_APPLICATION_ID || '',
  MIXPANEL_TOKEN: process.env.MIXPANEL_TOKEN || '',
  APP_IOS_URL: 'https://apps.apple.com/app/bilira-pro/id6466605683',
  APP_ANDROID_URL: 'https://play.google.com/store/apps/details?=co.bilira.pro',
  PRICE_SERVICE_BASE_URL: process.env.PRICE_SERVICE_BASE_URL || '',
  PRICE_SERVICE_WSS_URL: process.env.PRICE_SERVICE_WSS_URL || '',
  IS_LOCAL: process.env.ENVIRONMENT === 'local',
  IS_DEVELOPMENT: process.env.ENVIRONMENT === 'development',
  IS_SANDBOX: process.env.ENVIRONMENT === 'development',
  IS_PROD: process.env.ENVIRONMENT === 'production',
  CDN_BASE_URL: process.env.CDN_BASE_URL || '',
  CONTENT_API_BASE_URL: process.env.CONTENT_API_BASE_URL || '',
};
