import BigNumber from 'bignumber.js';
import Numeral from 'numeral';

import { defaultFormatOption, formatOptionsMap } from './formatOptionsMap';
import { LanguageUniversalType } from '../types';

if (Numeral.locales['en-locale'] === undefined) {
  Numeral.register('locale', 'en-locale', {
    delimiters: {
      thousands: ',',
      decimal: '.',
    },
    abbreviations: {
      thousand: 'K',
      million: 'M',
      billion: 'B',
      trillion: 'T',
    },
    ordinal: function () {
      return '';
    },
    currency: {
      symbol: '$',
    },
  });
}

if (Numeral.locales['tr-locale'] === undefined) {
  Numeral.register('locale', 'tr-locale', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'B',
      million: 'M',
      billion: 'Mr',
      trillion: 'T',
    },
    ordinal: function () {
      return '';
    },
    currency: {
      symbol: '₺',
    },
  });
}

export type StringifyCurrencyType = {
  /** The currency value to be converted. */
  value?: string | number;
  /** The Numeral.js format string to be applied.
   * @see http://numeraljs.com/#format
   */
  format: string;
  /** The locale for formatting. */
  locale: string;
};

/**
 * Converts a currency value to a formatted string using Numeral.js.
 *
 * @param {StringifyCurrencyType} params - The parameters for converting the currency value.
 * @returns {string | undefined} - The formatted currency value as a string, or undefined if the input value is falsy.
 */
function stringifyCurrency({ value, format, locale }: StringifyCurrencyType): string | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }

  const bigValue = BigNumber(value);

  if (bigValue.isLessThan(10000)) {
    return value.toString();
  }

  Numeral.locale(`${locale}-locale`);
  const options = locale ? formatOptionsMap[locale as LanguageUniversalType] : defaultFormatOption;

  return Numeral(bigValue.toFormat(options)).format(format);
}

export default stringifyCurrency;
