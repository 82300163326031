import React from 'react';

import { Panel } from '@bilira-org/design';

// New table widgets. Will be implemented later.
// import CryptoWidgetListContainer from '@Modules/market/market/cards/CryptoWidgetListContainer';
import CryptoCardWidgets from '@Modules/market/market/components/CryptoCardWidgets';
import MarketTableContainer from '@Modules/market/market/table/MarketTableContainer';

const MarketContainer = () => {
  return (
    <>
      <CryptoCardWidgets />

      <Panel border="neutral-400" size={{ xs: 'lg', sm: '2xl' }}>
        <MarketTableContainer />
      </Panel>
    </>
  );
};

export default MarketContainer;
