import { FC, useEffect, useRef } from 'react';

import { Block, Button, Col, Display, Form, Grid, Input, Skeleton, withSuspense } from '@bilira-org/design';
import type { CryptoNetworkType, TwoFAOptionsType, WalletModel } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import TwoFAModal from '@Components/TwoFA/TwoFAModal';
import useCreateWallet from '@Components/walletAddress/components/hooks/useCreateWallet';

import SelectNetwork from './SelectNetwork';

interface CreateWalletProps {
  onFinish: () => void;
  onNetworkClick: () => void;
  network?: CryptoNetworkType;
  wallet?: WalletModel;
  callback: (wallet?: WalletModel) => void;
  disableNetworkSelection?: boolean;
}

const CreateWallet: FC<CreateWalletProps> = ({
  wallet = {} as WalletModel,
  network,
  onNetworkClick,
  callback,
  onFinish,
  disableNetworkSelection,
}) => {
  const { t } = useTranslation();

  const innerNetwork = network?.alias || wallet.network;

  const { onSubmit, form, onVerifyTwoFA, twoFAData, activeTwoFAModal, setActiveTwoFAModal, twoFAType } =
    useCreateWallet({
      initialValues: {
        ...wallet,
        network: innerNetwork,
      },
      onFinish,
    });

  const inputRef = useRef<HTMLInputElement>(null);

  const empty = !wallet.id;

  useEffect(() => {
    if (!empty) {
      inputRef.current?.focus();
    }
  }, [empty]);

  return (
    <>
      <Block gap="3xl">
        <Form form={form} onFinish={onSubmit}>
          <Grid>
            <Col>
              <Form.Field name="id" hidden>
                <Input type="hidden" />
              </Form.Field>
              <Form.Field
                label={t('wallet-address.modal.wallet-title')}
                name="alias"
                rules={{ required: t('common.validation.required-field') }}
              >
                <Input ref={inputRef} placeholder={t('wallet-address.modal.wallet-title-placeholder')} />
              </Form.Field>
            </Col>
            <Display show={empty}>
              <Col>
                <Form.Field
                  label={t('wallet-address.modal.address')}
                  name="address"
                  rules={{ required: t('common.validation.required-field') }}
                >
                  <Input placeholder={t('wallet-address.modal.address-placeholder')} />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field
                  label={t('wallet-address.modal.network')}
                  name="network"
                  rules={{
                    required: t('common.validation.required-field'),
                  }}
                  controlled
                >
                  {({ field }) => (
                    <SelectNetwork
                      onClick={() => {
                        const walletModelFields: (keyof WalletModel)[] = ['network', 'alias', 'address'];
                        const walletModel: any = {};
                        walletModelFields.forEach((walletModelField, acc) => {
                          walletModel[walletModelField] = form.getValues(walletModelField);
                          return acc;
                        });
                        callback(walletModel as WalletModel);
                        onNetworkClick();
                      }}
                      value={field.value}
                      disabled={disableNetworkSelection}
                    />
                  )}
                </Form.Field>
              </Col>
            </Display>
            <Col>
              <Button
                disabled={!form.formState.isValid}
                variant="filled"
                mt="4xl"
                type="submit"
                size="xl"
                stretch
                justify="center"
                loading={false}
              >
                {t('common.save')}
              </Button>
            </Col>
          </Grid>
        </Form>
      </Block>

      <TwoFAModal
        type={twoFAType as TwoFAOptionsType}
        twoFAData={twoFAData}
        active={activeTwoFAModal}
        setActive={setActiveTwoFAModal}
        callback={onVerifyTwoFA}
      />
    </>
  );
};

export default withSuspense(
  CreateWallet,
  <Block gap="3xl">
    <Grid>
      <Col>
        <Skeleton height="size-5" width="size-14" mb="xs" />
        <Skeleton height="size-12" width="size-full" mb="xl" />
      </Col>
      <Col>
        <Skeleton height="size-5" width="size-14" mb="xs" />
        <Skeleton height="size-12" width="size-full" mb="xl" />
      </Col>
      <Col>
        <Skeleton height="size-5" width="size-14" mb="xs" />
        <Skeleton height="size-12" width="size-full" mb="xl" />
      </Col>
      <Col>
        <Button disabled={true} variant="filled" mt="4xl" size="xl" stretch justify="center" loading={false}>
          &nbsp;
        </Button>
      </Col>
    </Grid>
  </Block>,
);
