import React, { memo, useCallback, useRef, useState } from 'react';

import { Block, Button } from '@bilira-org/design';
import { getErrorMessage, TwoFAItemType, TwoFAMethodsType, TwoFAMethodType, TwoFAOptionsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { useGeneralSessionStore } from '@/store';
import AuthenticatorOTP from '@Components/TwoFA/components/AuthenticatorOTP';
import InputOTP from '@Components/TwoFA/components/InputOTP';
import UserQuery from '@Libs/clientInstances/userQuery';
import { OTP_CODE_LENGTH } from '@Libs/config';
import { maskEmail } from '@Libs/helpers';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerTwoFa } from '@Libs/tracker/helpers/addTrackerAccount';

type Props = {
  /** Data for available 2fa authentication methods */
  twoFAData: TwoFAMethodsType;
  /** Action type to be verified with 2fa (e.g. 'addCryptoWallet', 'fiatWithdrawal') */
  type: TwoFAOptionsType;
  /** Additional payload to be sent with 2fa request */
  additionalPayload?: Record<string, string | undefined>;
  /** Function to be called with token after 2fa verified */
  callback: (token: string) => void;
};

/**
 * Component that handles which 2fa inputs to be shown
 */
const TwoFA = ({ type, twoFAData, additionalPayload, callback }: Props) => {
  const { t } = useTranslation();
  const userInfo = useGeneralSessionStore((state) => state.userInfo);
  const { mutateAsync, isPending, failureReason, reset } = UserQuery.usePostVerifyTwoFACode();

  const [codeCompleted, setCodeCompleted] = useState(false);
  const [twoFAItem, setTwoFAItem] = useState<TwoFAItemType>({ totp: undefined, sms: undefined, email: undefined });
  const shouldAllocateTwoFaItemsRef = useRef<TwoFAMethodType[]>([]);

  const handleValidation = () => {
    mutateAsync({ type: type, codes: twoFAItem }).then((data) => {
      addTrackerTwoFa(TRACKER_EVENTS.TWO_FA.REQUEST_TWO_FA, { type, ...additionalPayload });
      callback(data.token);
      setCodeCompleted(false);
      setTwoFAItem({ totp: undefined, sms: undefined, email: undefined });
    });
  };

  const inputCallback = useCallback(
    (type: TwoFAMethodType) => {
      const index = shouldAllocateTwoFaItemsRef.current.findIndex((d) => d === type);
      if (index === -1) {
        shouldAllocateTwoFaItemsRef.current.push(type);
      }

      return (value: string) => {
        const updatedTwoFAItem = {
          ...twoFAItem,
          [type]: value,
        };
        setTwoFAItem(updatedTwoFAItem);

        const completed = shouldAllocateTwoFaItemsRef.current.every(
          (d) => updatedTwoFAItem[d]?.length === OTP_CODE_LENGTH,
        );

        setCodeCompleted(completed);
      };
    },
    [twoFAItem],
  );

  if (!userInfo) {
    return null;
  }

  const onEnter = codeCompleted ? handleValidation : undefined;

  return (
    <>
      <Block gap="xl">
        {twoFAData?.email && (
          <InputOTP
            value={twoFAItem.email as string}
            validationMessage={getErrorMessage(failureReason) as string}
            type={type}
            method="email"
            callback={inputCallback('email')}
            info={t('two-fa.type-code-sent-to-email', { email: maskEmail(userInfo.email) })}
            label={t('two-fa.email-verification-code')}
            additionalPayload={additionalPayload}
            onEnter={onEnter}
            resetValidationMessage={reset}
          />
        )}
        {twoFAData?.sms && (
          <InputOTP
            value={twoFAItem.sms as string}
            validationMessage={getErrorMessage(failureReason) as string}
            type={type}
            method="sms"
            callback={inputCallback('sms')}
            label={t('two-fa.sms-verification-code')}
            info={t('two-fa.type-code-sent-to-phone', { phone: '531****276' })}
            additionalPayload={additionalPayload}
            onEnter={onEnter}
            resetValidationMessage={reset}
          />
        )}
        {twoFAData?.totp && (
          <AuthenticatorOTP
            validationMessage={getErrorMessage(failureReason) as string}
            callback={inputCallback('totp')}
            onEnter={onEnter}
          />
        )}
      </Block>

      <Button
        mt="4xl"
        disabled={!codeCompleted}
        loading={isPending}
        size="md"
        stretch
        justify="center"
        variant="filled"
        onClick={handleValidation}
      >
        {t('common.accept')}
      </Button>
    </>
  );
};
export default memo(TwoFA);
