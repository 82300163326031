import React from 'react';

import { TransactionType } from '@bilira-org/react-utils';
import type { TransactionsRecordType } from '@bilira-org/react-utils';

import FiatTransactionButtons from '@Components/transaction/modal/components/components/fiat/TransactionButtons';

type Props = {
  data: TransactionsRecordType;
  onCancel?: () => void;
};

const amountMap: Record<TransactionType, any> = {
  fiat: FiatTransactionButtons,
  crypto: FiatTransactionButtons,
  otc: null,
  swap: null,
  conversion: null,
  other: null,
};

const TransactionButtons = ({ data, onCancel }: Props) => {
  const Element = amountMap[data.type];
  if (!Element) {
    return null;
  }

  return <Element data={data} onCancel={onCancel} />;
};

export default TransactionButtons;
