import { useEffect, useRef, useState } from 'react';

import { AssetPairType, filterSwapPairByPair, parseAssetPair, SwapPairAndBalanceType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { BuySellRefProps } from '@Components/trade/buySell/BuySellTrade';
import { BuySellDirection, BuySellPairType } from '@Components/trade/types';
import SwapQuery from '@Libs/clientInstances/swapQuery';
import useNavigateToMarket from '@Libs/hooks/useNavigateToMarket';

import { TradeWidgetType } from './store';

type Props = {
  navigateOnSelection?: boolean;
  defaultAssetPair: AssetPairType;
  tradeWidgetType: TradeWidgetType;
};

/**
 * Trade Buy/Sell Hook.
 */
const useBuySellCardHook = ({ navigateOnSelection, defaultAssetPair, tradeWidgetType }: Props) => {
  const { t } = useTranslation();
  const buySellRef = useRef<BuySellRefProps>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigateToMarket();
  const { data: swapPairs } = SwapQuery.useGetSwapPairs({ suspense: false });
  const [swapAssetPair, setSwapAssetPair] = useState<BuySellPairType>();
  const [assetPair, setAssetPair] = useState<AssetPairType>(defaultAssetPair);
  const [buySellDirection, setBuySellDirection] = useState<BuySellDirection>('buy');

  const receivedAsset = buySellDirection === 'buy' ? swapAssetPair?.base?.symbol : swapAssetPair?.quote?.symbol;

  useEffect(() => {
    if (assetPair && !swapAssetPair && swapPairs) {
      const [base, quote] = parseAssetPair(assetPair);
      const selectedAssetPair = filterSwapPairByPair(swapPairs, base, quote);
      selectedAssetPair && setSelectedAsset(selectedAssetPair);
    }
  }, [assetPair, swapPairs, swapAssetPair]);

  const changeDetailAsset = (selectedAsset: SwapPairAndBalanceType) => {
    setSelectedAsset(selectedAsset);

    if (navigateOnSelection) {
      setTimeout(() => navigate({ base: selectedAsset.base_asset, quote: selectedAsset.quote_asset }), 300);
    }
  };

  const setSelectedAsset = (selectedAsset: SwapPairAndBalanceType) => {
    const base = {
      symbol: selectedAsset.base_asset,
      scale: selectedAsset.scale_sell,
    };

    const quote = {
      symbol: selectedAsset.quote_asset,
      scale: selectedAsset.scale_buy,
    };

    setSwapAssetPair({ base, quote });
    setAssetPair(`${selectedAsset.base_asset}_${selectedAsset.quote_asset}`);

    setOpen(false);
  };

  const onBuySellDirectionChange = (value: BuySellDirection) => {
    setBuySellDirection(value);
  };

  return {
    buySellRef,
    changeDetailAsset,
    open,
    setOpen,
    swapAssetPair,
    t,
    assetPair,
    onBuySellDirectionChange,
    buySellDirection,
    receivedAsset,
  };
};

export default useBuySellCardHook;
