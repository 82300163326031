import React from 'react';

import { Block, Layout, Tab } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGeneralSessionStore } from '@/store';
import HeadTags from '@Components/common/HeadTags';
import HeroStrip from '@Components/layouts/components/HeroStrip';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';
import routeMap from '@Libs/route/routeMap';
// import Earn from '@Modules/settings/comission/earn';
import Fee from '@Modules/settings/commission/fee';
import Referral from '@Modules/settings/commission/referral';

const { Content } = Layout;

const tabIndexes = {
  [routeMap.CommissionFee.en]: 0,
  [routeMap.CommissionFee.tr]: 0,
  // '#earn': 1,
  [routeMap.Referral.en]: 1,
};

const Index = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { getLocaleRoute } = useLocaleRoute();
  const isLoggedIn = useGeneralSessionStore((state) => state.isLoggedIn);
  const defaultIndex = tabIndexes[pathname];

  return (
    <>
      <HeadTags title={t('navigation.profile.referral')} titleSuffix="subpage" />
      <HeroStrip title={t('commission.page-title')} />
      <Content>
        <Block gap="4xl">
          {/*<Header />*/}
          <Tab
            key={defaultIndex}
            defaultIndex={defaultIndex}
            variant="bordered"
            headers={[
              {
                name: t('commission.transaction-fees'),
                key: routeMap.CommissionFee.en,
              },
              // {
              //   name: t('earn.title'),
              //   key: 'earn',
              // },
              {
                name: t('navigation.profile.referral'),
                key: routeMap.Referral.en,
                hidden: !isLoggedIn,
              },
            ]}
            onChange={(index) => {
              const newHash = Object.keys(tabIndexes).find((key) => tabIndexes[key] === index) || '';
              navigate(getLocaleRoute(newHash));
            }}
          >
            <Tab.Panel>
              <Fee />
            </Tab.Panel>
            {/*<Tab.Panel>*/}
            {/*  <Earn />*/}
            {/*</Tab.Panel>*/}
            <Tab.Panel>
              <Referral />
            </Tab.Panel>
          </Tab>
        </Block>
      </Content>
    </>
  );
};
export default Index;
