import ICryptoApi from './interface/ICryptoApi';
import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import {
  AssetPairPriceParamType,
  AssetPairPriceType,
  AssetTagType,
  CryptoAssetsHistoryType,
  CryptoAssetsType,
  CryptoDepositWalletParamType,
  CryptoDepositWalletType,
  CryptoMarketItemType,
  CryptoMarketType,
  CryptoNetworkType,
  CryptoSearchAssetType,
  CryptoWithdrawalFeeParamsType,
  CryptoWithdrawalFeesType,
  CryptoWithdrawalFeeType,
  CryptoWithdrawBodyType,
  DisabledOperationType,
  ResultType,
  TransactionsRecordType,
} from '../../model';
import { RecordsPaginationType } from '../../model/CommonTypes';
import { IdType } from '../../model/ResultType';
import { CryptoTransactionsParamsType, CryptoTransactionsType } from '../../model/Transaction';

class CryptoApi extends HttpClient implements ICryptoApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  /**
   * GET
   * /crypto/withdrawals/fees
   * Get withdrawal fees.
   * @param params
   * @return CryptoWithdrawalFeeType
   */
  public getWithdrawalFee = (params: CryptoWithdrawalFeeParamsType) => {
    return this.instance.get<HttpErrorType, CryptoWithdrawalFeeParamsType, CryptoWithdrawalFeeType>(
      `/crypto/withdrawals/fees`,
      { params },
    );
  };

  /**
   * POST
   * /crypto/withdrawals
   * User requests crypto withdrawal.
   * @param payload
   * @return IdType
   */
  public postCryptoWithdraw = (payload: CryptoWithdrawBodyType) => {
    return this.instance.post<HttpErrorType, IdType, CryptoWithdrawBodyType>(`/crypto/withdrawals`, payload);
  };

  /**
   * DELETE
   * /crypto/withdrawals/{id}
   * User requests to cancel crypto withdrawal.
   * @param id
   */
  public deleteWithdrawals = (id: string) => {
    return this.instance.delete<HttpErrorType, ResultType, string>(`/crypto/withdrawals/${id}`);
  };

  /**
   * GET
   * /crypto/networks
   * User gets the information about available networks in the system.
   */
  public getNetworks = () => {
    return this.instance.get<HttpErrorType, CryptoNetworkType[]>(`/crypto/networks`);
  };

  /**
   * GET
   * /crypto/assets
   * User gets the information about available assets in the system.
   */
  public getAssets = () => {
    return this.instance.get<HttpErrorType, CryptoAssetsType[]>(`/crypto/assets`);
  };

  /**
   * GET
   * /crypto/asset-history
   * Anybody gets the price history of asset pair.
   */
  public getAssetHistory = (market?: string) => {
    return this.instance.get<HttpErrorType, CryptoAssetsHistoryType>(`/crypto/market/${market}/history`);
  };
  public getAssetPairPrice = ({ pair, ...params }: AssetPairPriceParamType) => {
    return this.instance.get<HttpErrorType, AssetPairPriceType>(`/crypto/market/${pair}/price`, { params });
  };
  /**
   * GET
   * /crypto/deposit-wallets
   * Get deposit address for given account, asset and networks.
   * @param params
   */
  public getDepositWallet = (params: CryptoDepositWalletParamType) => {
    return this.instance.get<CryptoDepositWalletParamType, CryptoDepositWalletType>(`/crypto/deposit-wallets`, {
      params,
    });
  };

  public getSearch = (text: string) => {
    return this.instance.get<string, RecordsPaginationType<CryptoSearchAssetType>>(`/crypto/assets/search`, {
      params: { text },
    });
  };
  public getMarket = (tags?: string) => {
    return this.instance.get<string, RecordsPaginationType<CryptoMarketType>>(`/crypto/assets/market`, {
      params: { tags },
    });
  };

  public getMarketByAsset = (assetPair?: string) => {
    return this.instance.get<string, CryptoMarketItemType>(`/crypto/market/${assetPair}`);
  };

  public getDisabledOperations = () => {
    return this.instance.get<HttpErrorType, DisabledOperationType[]>(`/crypto/disabled-operations`);
  };

  public getTransaction = (id: string, account: string) => {
    return this.instance.get<HttpErrorType, TransactionsRecordType>(`/crypto/transactions/${id}?account=${account}`);
  };

  public getAssetTags = () => {
    return this.instance.get<HttpErrorType, AssetTagType[]>(`/crypto/assets/tags`);
  };

  public getWithdrawalFees() {
    return this.instance.get<HttpErrorType, CryptoWithdrawalFeesType[]>('/crypto/withdrawal-fees');
  }

  public getTransactions = (params: CryptoTransactionsParamsType) => {
    return this.instance.get<HttpErrorType, CryptoTransactionsType>(`/crypto/transactions/`, {
      params,
    });
  };
}

export default CryptoApi;
