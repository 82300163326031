import React, { useMemo } from 'react';

import { Block, Meta, Skeleton, Span, Text, withSuspense } from '@bilira-org/design';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import useGetTotalBalance from '@Components/balance/useGetTotalBalance';
import HiddenBalanceEyeIcon from '@Components/common/HiddenBalanceEyeIcon';
import GeneralPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import useLastLivePrice from '@Libs/hooks/useLastLivePrice';

/**
 * Popover badge for viewing wallet details
 */
const WalletTotalBalance = () => {
  const { t } = useTranslation();

  const { maskBalance, balance } = useGetTotalBalance();

  const { liveData } = useLastLivePrice({ symbol: maskBalance ? '' : 'USDTTRYB' });
  const usdtPrice = liveData?.p ? BigNumber(balance).dividedBy(liveData.p).toString() : '0.00';

  return (
    <>
      <GeneralPriceSubscription
        channel="wallet-total-balance"
        baseQuoteList={useMemo(() => ['USDTTRYB'], [])}
        events="last_price"
      />
      <Meta
        mx="md"
        title={
          <Text size="sm" color="neutral-600">
            {t('navigation.assets')} <HiddenBalanceEyeIcon />
          </Text>
        }
        description={
          <FormattedNumberByPrice
            price="1"
            type="amount"
            masked={maskBalance}
            font="primary"
            color="secondary-500"
            size="xl"
            weight="medium"
            suffixSize="sm"
            suffix={
              <Span size="sm" color="neutral-700">
                TRYB
              </Span>
            }
            value={balance}
          />
        }
      />
      <Block row items="center" mx="md" gap="xs">
        {!maskBalance && <Span color="neutral-700">≈</Span>}
        <FormattedNumberByPrice
          masked={maskBalance}
          font="primary"
          color="neutral-700"
          size="sm"
          weight="medium"
          suffix="USD"
          type="price"
          value={usdtPrice}
          price={liveData?.p || '1'}
        />
      </Block>
    </>
  );
};

export default withSuspense(WalletTotalBalance, <Skeleton key="price" height="size-4" width="size-24" />);
