import React from 'react';

import { TransactionsRecordType, TransactionType } from '@bilira-org/react-utils';

import { ConversionTransferType } from '@Components/transaction/modal/components/components/conversion/ConversionTransferType';
import { CryptoTransferType } from '@Components/transaction/modal/components/components/crypto/CryptoTransferType';
import { FiatTransferType } from '@Components/transaction/modal/components/components/fiat/FiatTransferType';
import { OTCTransferType } from '@Components/transaction/modal/components/components/otc/OTCTransferType';
import { SwapTransferType } from '@Components/transaction/modal/components/components/swap/SwapTransferType';

type AccountTypeProps = {
  data?: TransactionsRecordType;
};

const ComponentMap: Record<TransactionType, any> = {
  fiat: FiatTransferType,
  crypto: CryptoTransferType,
  swap: SwapTransferType,
  conversion: ConversionTransferType,
  otc: OTCTransferType,
  other: null,
};

const AccountType = ({ data }: AccountTypeProps) => {
  if (!data) {
    return null;
  }

  const Component = ComponentMap[data.type];

  if (!Component) {
    return null;
  }

  return <Component data={data} />;
};

export default AccountType;
