/**
 * Checks if a value is empty or undefined.
 *
 * @template T - The type of the value.
 * @param {T} obj - The value to be checked.
 * @returns {boolean} - `true` if the value is empty or undefined, `false` otherwise.
 *
 * @example
 * isEmpty(''); // true
 * isEmpty({}); // true
 * isEmpty([]); // true
 * isEmpty(undefined); // true
 * isEmpty('something'); // false
 */
export function isEmpty<T>(obj: T) {
  if (typeof obj === 'undefined' || obj === null) {
    return true;
  }

  if (typeof obj === 'string') {
    return !obj || obj === '';
  }

  if (!obj || typeof obj !== 'object') {
    return undefined;
  }

  if (Array.isArray(obj)) {
    return obj.length === 0;
  }

  return Object.keys(obj).length === 0;
}

/**
 * Checks if an object's values are empty or undefined.
 *
 * @param {Record<string, any> | undefined} obj - The object to be checked.
 * @returns {boolean} - `true` if the object's values are empty or undefined, `false` otherwise.
 *
 * @example
 * isObjectValueNull({}); // true
 * isObjectValueNull(); // true
 * isObjectValueNull({ key1: 'value'}); // false
 */
export function isObjectValueNull(obj?: Record<string, any>): boolean {
  if (!obj) {
    return true;
  }

  if (Object.keys(obj).length === 0) {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  return Object.entries(obj).some(([_, value]) => value === null);
}
