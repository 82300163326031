import React, { forwardRef, useRef } from 'react';

import classNames from 'classnames';

import fixIosInputFocus from '../../helpers/fixIosInputFocus';
import { composeRef } from '../ref';
import {
  BaseComponentType,
  BreakpointType,
  CustomWidthType,
  FontType,
  FontWeight,
  ResponsiveReturnType,
  TextAlign,
  TextColorType,
  TextSizes,
} from '../types';
import { buildResponsiveClass } from '../utils';

export type TextareaProps = Omit<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  'size' | 'color' | 'type' | 'placeholder' | 'padding' | 'icon' | 'textSize'
> &
  BaseComponentType & {
    /** Additional class name for the textarea */
    className?: string;
    /** Placeholder text to display when the textarea is empty */
    placeholder?: string;
    /** Custom width of the textarea. Can be a fixed width or responsive breakpoints */
    customWidth?: CustomWidthType | BreakpointType<CustomWidthType>;
    /** If true, the textarea has no borders */
    noBorders?: boolean;
    /** Size of the textarea text. Can be a size or responsive breakpoints */
    size?: TextSizes | BreakpointType<TextSizes>;
    /** Color of the textarea text */
    color?: TextColorType;
    /** Font weight of the textarea text */
    weight?: FontWeight;
    /** Font type of the textarea text */
    font?: FontType;
    /** Text alignment of the textarea */
    align?: TextAlign;
  };

/**
 * Textarea component for multiline text input.
 *
 * @example
 * <Textarea placeholder='Write the detailed description here' value={value} onChange={onChange} />
 */
const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const {
    placeholder,
    noBorders,
    className,
    customWidth = 'full',
    size = 'sm',
    color = 'secondary-500',
    weight = 'semibold',
    align,
    font,
    testId,
    onFocus,
    ...defaultProps
  } = props;
  let customWidthClasses: ResponsiveReturnType = {};

  if (customWidth) {
    customWidthClasses = buildResponsiveClass(customWidth, 'tw-custom-width-');
  }
  const classes = classNames(
    'input',
    {
      [`tw-font-${weight}`]: weight,
      [`tw-text-${size}`]: size,
      [`tw-text-${align}`]: align,
      [`tw-text-${color}`]: color,
      [`font-${font}`]: font,
    },
    className,
    customWidthClasses,
  );

  return (
    <label
      className={classNames('input-group', {
        'input-no-borders': noBorders,
      })}
      data-testid={testId ?? 'input-textarea'}
    >
      <span className="sr-only">Textarea</span>
      <span className="input-wrapper w-full">
        <textarea
          {...defaultProps}
          className={classes}
          ref={composeRef(textareaRef, ref)}
          placeholder={placeholder}
          onFocus={(e) => {
            fixIosInputFocus(e.currentTarget);
            onFocus?.(e);
          }}
        />
      </span>
    </label>
  );
});

Textarea.displayName = 'Textarea';

export default Textarea;
