import React from 'react';

import classNames from 'classnames';
import { motion } from 'framer-motion';

import { MotionSectionProps } from './motionSection';
import { useIsMounted, useMeasure } from '../../hooks';

export type MotionHeightProps = MotionSectionProps & { animateOnMount?: boolean };

/**
 * Motion component for animating height
 *
 * @example
 * <MotionHeight show={show} showInitial={showInitial}>
 *   <div>Content</div>
 * </MotionHeight>
 */
const MotionHeight = ({
  children,
  show,
  showInitial,
  as = 'section',
  testId,
  className,
  animateOnMount = true,
  ...rest
}: MotionHeightProps) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const Component = motion[as];
  const isMounted = useIsMounted();

  const shouldAnimate = animateOnMount || (!animateOnMount && isMounted());

  const animationProps = shouldAnimate
    ? {
        initial: { height: showInitial ? 'auto' : '0', overflow: 'hidden' },
        animate: { height: show ? (height === 0 ? 'auto' : height) : '0', overflow: 'hidden' },
      }
    : {};

  return (
    <Component
      {...animationProps}
      transition={{ duration: 0.35, ease: 'easeInOut' }}
      data-testid={testId ?? 'motion-height'}
      className={classNames('grid', className)}
      {...rest}
    >
      <div ref={ref}>{children}</div>
    </Component>
  );
};

export default MotionHeight;
