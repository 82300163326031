import React from 'react';

import classNames from 'classnames';

import { IModal } from './modal';
import ButtonClose from '../button/buttonClose';

type Props = Pick<IModal, 'color' | 'responsive'> & {
  closeModal?: () => void;
};

const PopupCloseButton = ({ color, responsive, closeModal }: Props) => {
  return (
    <>
      {closeModal && (
        <div
          className={classNames('z-20 mt-3', {
            'close-btn-responsive': responsive,
          })}
        >
          <ButtonClose p="xs" onClick={closeModal} background={color} />
        </div>
      )}
    </>
  );
};

export default PopupCloseButton;
