import React, { useMemo } from 'react';

import { Skeleton, Sparkline } from '@bilira-org/design';
import { concatTicker } from '@bilira-org/react-utils';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import useLastLivePrice from '@Libs/hooks/useLastLivePrice';
import useStatisticsLive from '@Libs/hooks/useStatisticsLive';
import { priceChangeState } from '@Libs/utils';
import EmptyText from '@Modules/settings/commission/fee/components/EmptyText';

type Props = {
  base?: string;
  quote?: string;
};
const H24SparklineLive = ({ base, quote }: Props) => {
  const { liveData } = useLastLivePrice({ symbol: concatTicker(base, quote) });
  const { liveData: h24ChangeLive } = useStatisticsLive({ symbol: concatTicker(base, quote) });

  const { data, isPending } = PriceQuery.useGetLimitedOHLCS(
    {
      bar_count: 24,
      symbol: concatTicker(base, quote),
      period: '1h',
    },
    { suspense: false },
  );

  const prices = useMemo(() => {
    return data && data.closes.map((d) => parseFloat(d));
  }, [data]);

  if (base === 'BMMF') {
    return <EmptyText />;
  }

  if (!prices?.length || isPending) {
    return <Skeleton key="price" height="size-4" width="size-24" />;
  }

  if (liveData) {
    prices[prices.length - 1] = parseFloat(liveData.p);
  }

  const changeState = priceChangeState(h24ChangeLive?.change_24 || '0');

  return <Sparkline size="xs" color={changeState.getColor()} prices={prices} />;
};

export default H24SparklineLive;
