import { useState } from 'react';

import { Block, Button, Icon, IconCustom, Input, notification, Panel, Text } from '@bilira-org/design';
import { httpError } from '@bilira-org/react-utils';
import { Trans, useTranslation } from 'react-i18next';

import { withPrivateRoute } from '@/libs/hocs';
import { handleErrorResponse } from '@/libs/utils';
import MarketingQuery from '@Libs/clientInstances/marketingQuery';

import PromoCodeModal from './PromoCodeModal';

const PromoCode = () => {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = MarketingQuery.usePostPromoCode();

  const [code, setCode] = useState('');
  const [modal, setModal] = useState<{ active: boolean; amount?: string; asset?: string; campaign?: string }>({
    active: false,
  });
  const handleSubmit = async () => {
    if (code === '') {
      return;
    }
    const loadingNotificationId = notification.loading(t('promo-code.using-promo-code'));

    mutateAsync({ code })
      .then((data) => {
        setCode('');
        setModal({
          active: true,
          amount: data.internal_transfer.amount,
          asset: data.internal_transfer.asset,
          campaign: data.internal_transfer.info,
        });
        notification.dismiss(loadingNotificationId);
      })
      .catch((e) => {
        notification.dismiss(loadingNotificationId);

        if (httpError(e).is429) {
          notification.error(t('common.too-many-attempts'));
        } else {
          handleErrorResponse(e);
        }
      })
      .finally(() => {
        setCode('');
      });
  };

  return (
    <>
      <Panel size="2xl" border="neutral-400" testId="trending-crypto-panel">
        <Block row style={{ position: 'relative' }}>
          <Block gap="md" width="size-full">
            <Text heading size="xl" weight="semibold">
              {t('promo-code.reward-center')}
            </Text>
            <Text style={{ width: 'calc(100% - 130px)', zIndex: '2' }}>
              <Trans
                t={t}
                i18nKey="promo-code.earn-prize-with-promo-code"
                components={{ bold: <Text as="span" weight="bold" /> }}
              />
            </Text>
          </Block>
          <IconCustom type="gift" height="size-20" style={{ position: 'absolute', right: '0', bottom: '0' }} />
        </Block>
        <Block mt="2xl">
          <Input
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onEnter={isPending ? undefined : handleSubmit}
            placeholder={t('promo-code.enter-coupon-code')}
            iconEndProps={{ custom: true, space: 'xs' }}
            iconEnd={
              <Button
                disabled={!code}
                loading={isPending}
                variant="filled"
                size="sm"
                onClick={handleSubmit}
                startIcon={<Icon type="o:arrow-right" />}
                style={{ height: '40px', width: '40px' }}
              />
            }
          />
        </Block>
      </Panel>
      <PromoCodeModal
        active={modal.active}
        onClose={() => setModal({ active: false })}
        amount={modal.amount}
        asset={modal.asset}
        campaign={modal.campaign}
      />
    </>
  );
};

export default withPrivateRoute(PromoCode);
