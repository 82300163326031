import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { BackgroundColorType, BaseComponentType } from '../types';

export type RadialProgressProps = React.HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** The progress value as a percentage (0-100). */
    value: number;
    /** The size of the radial progress . */
    size?: string;
    /** The thickness of the radial progress. */
    thickness?: string;
    /** The color of the radial progress. */
    color?: BackgroundColorType;
    /** Additional classes for styling. */
    className?: string;
  };

/**
 * A radial progress bar component.
 *
 * @example
 * <RadialProgress value={50} color="primary-500"/>
 */
const RadialProgress = forwardRef<HTMLDivElement, RadialProgressProps>(
  ({ value, size = '4rem', thickness = '4px', color, className, children, testId, ...props }, ref): JSX.Element => {
    const classes = classNames('radial-progress', className, {
      [`text-${color}`]: color,
    });

    const insetClasses = classNames('radial-progress radial-inset', className, {
      [`text-${color}`]: color,
    });

    /** Clamp the value between 0 and 100 */
    const displayedValue = Math.min(100, Math.max(0, value));

    const progressStyle: Record<string, string | number> = {
      '--value': displayedValue,
      '--size': size,
      '--thickness': thickness,
    };

    const progressDefaultStyle: Record<string, string | number> = {
      '--value': 100,
      '--size': size,
      '--thickness': thickness,
    };

    return (
      <div
        role="progressbar"
        aria-valuenow={displayedValue}
        aria-valuemin={0}
        aria-valuemax={100}
        {...props}
        ref={ref}
        className={classes}
        style={progressStyle}
        data-testid={testId ?? 'radial-progress'}
      >
        <div
          role="progressbar"
          aria-valuenow={displayedValue}
          aria-valuemin={0}
          aria-valuemax={100}
          ref={ref}
          className={insetClasses}
          style={progressDefaultStyle}
        >
          {children}
        </div>
      </div>
    );
  },
);

export default RadialProgress;
