import { isInternalTransferType, TransactionsRecordType, TransactionType } from '@bilira-org/react-utils';

import AccountApi from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';

export type TransactionRecordInfo = {
  id: string;
  type: string;
};

type Props = {
  transactionRecordInfo?: TransactionRecordInfo;
  suspense?: boolean;
};

const useGetTransactionDetail = ({ transactionRecordInfo, suspense = true }: Props) => {
  const { account, isPending: isAuthPending } = useAuth({ suspense: suspense });
  const isInternalTransfer = isInternalTransferType(transactionRecordInfo?.type);
  const { data, isPending: isTransactionPending } = AccountApi.useTransaction(
    {
      id: account,
      transactionId: transactionRecordInfo?.id || '',
      type: transactionRecordInfo?.type as TransactionType,
    },
    {
      suspense: suspense,
      enabled: !isAuthPending && !isInternalTransfer,
    },
  );

  const { data: internalTransferData, isPending: isInternalTransferPending } = AccountApi.useGetInternalTransfer(
    {
      id: account,
      transactionId: transactionRecordInfo?.id || '',
    },
    {
      suspense: suspense,
      enabled: !isAuthPending && isInternalTransfer,
    },
  );

  return isInternalTransfer
    ? {
        data: internalTransferData ? ({ ...internalTransferData } as TransactionsRecordType) : undefined,
        isPending: isAuthPending || isInternalTransferPending,
      }
    : { data, isPending: isAuthPending || isTransactionPending };
};

export default useGetTransactionDetail;
