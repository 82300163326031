import BigNumber from 'bignumber.js';

import { formatOptionsMap } from '../currency/formatOptionsMap';
import { LanguageUniversalType } from '../types';

/**
 * Formats the input value based on the locale.
 *
 * @param {string} value The input value to format.
 * @param {LanguageUniversalType} locale The locale to use for formatting.
 * @returns {string} The formatted value.
 */
export default function format(value: string, locale: LanguageUniversalType, decimalPlaces?: number) {
  if (!value) {
    return '';
  }

  const inputDecimals = value.split('.')[1]?.length || 0;
  const effectiveDecimalPlaces = decimalPlaces ? Math.min(decimalPlaces, inputDecimals) : inputDecimals;

  const bigValue = BigNumber(value);
  let formatted = bigValue.toFormat(
    effectiveDecimalPlaces,
    BigNumber.ROUND_DOWN,
    formatOptionsMap[locale as LanguageUniversalType],
  );
  if (value[value.length - 1] === '.') {
    formatted += formatOptionsMap[locale].decimalSeparator;
  }

  return formatted;
}
