import React, { FC, memo } from 'react';

import { Block, Flex, List, Loop, Meta, Skeleton, Text, withSuspense } from '@bilira-org/design';
import type { CryptoNetworkType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import IconCoin from '@Components/icon/IconCoin';
import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';
import { NETWORK_ETA_MINUTES } from '@Libs/constants';

import NetworkInfo from './NetworkInfo';

interface INetwork {
  onFinish: () => void;
  setNetwork: (network: CryptoNetworkType) => void;
  filter?: string[];
  networks?: CryptoNetworkType[];
  isPending?: boolean;
  activeAsset?: string;
}

const Network: FC<INetwork> = ({ networks, isPending, onFinish, setNetwork, activeAsset }) => {
  const { t } = useTranslation();
  const { data } = CryptoWalletApi.useGetNetworks();
  const { data: withdrawalFees, isLoading: withdrawalFeesLoading } = CryptoWalletApi.useGetWithdrawalFees({
    suspense: false,
    enabled: !!activeAsset,
  });

  return (
    <List
      testId="network-list"
      color="neutral-300"
      space="sm"
      dataSource={networks ?? data}
      loading={isPending}
      skeleton={
        <Block row justify="between" items="center" p="md" gap="lg">
          <Block row items="center" gap="md">
            <Skeleton width="size-9" height="size-9" rounded="full" />

            <Block gap="md">
              <Skeleton height="size-3" width="size-28" />

              <Skeleton height="size-3" width="size-14" />
            </Block>
          </Block>

          <Skeleton height="size-3" width="size-9" />
        </Block>
      }
      skeletonLine={3}
      renderItem={(item) => (
        <List.Item
          size={{ xs: 'sm', sm: 'md' }}
          key={item.name}
          hoverBgColor="theme-n200d800"
          onClick={() => {
            setNetwork(item);
            onFinish();
          }}
        >
          <Block>
            <Meta
              space="sm"
              extra={<IconCoin size={{ xs: '2xl', sm: '2xl', md: '4xl' }} type={item.alias} />}
              title={
                <Text size={{ xs: 'sm', sm: 'sm', md: 'base' }} color="secondary-500" weight="semibold">
                  {item.name}
                </Text>
              }
              description={
                <Text size={{ xs: 'sm', sm: 'sm', md: 'base' }} weight="regular">
                  {item.alias}
                </Text>
              }
            />
            <Flex ml={{ xs: '4xl', sm: '4xl', md: '7xl' }}>
              {activeAsset && (
                <NetworkInfo
                  network={item.alias}
                  activeAsset={activeAsset}
                  fees={withdrawalFees}
                  feesLoading={withdrawalFeesLoading}
                />
              )}
              <Text color="neutral-600" size="xs">
                {t('crypto.select-network-modal.eta-minutes', { minutes: NETWORK_ETA_MINUTES?.[item.alias] ?? '-' })}
              </Text>
            </Flex>
          </Block>
        </List.Item>
      )}
    />
  );
};

export default withSuspense(
  memo(Network),
  <Loop data={3}>
    <Block row justify="between" items="center" p="md" gap="lg">
      <Block row items="center" gap="md">
        <Skeleton width="size-9" height="size-9" rounded="full" />

        <Block gap="md">
          <Skeleton height="size-3" width="size-28" />

          <Skeleton height="size-3" width="size-14" />
        </Block>
      </Block>

      <Skeleton height="size-3" width="size-9" />
    </Block>
  </Loop>,
);
