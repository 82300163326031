import { useCallback, useMemo } from 'react';

import { AssetListType, CoinSymbolType, concatTicker } from '@bilira-org/react-utils';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import useNavigateToMarket from '@Libs/hooks/useNavigateToMarket';

type Props = {
  dataTag?: string;
  quote?: CoinSymbolType;
};

const useAssetCardsHook = ({ dataTag, quote = 'TRYB' }: Props) => {
  const navigate = useNavigateToMarket();

  const {
    pages: assetList,
    fetchNextPage,
    isPending,
  } = PriceQuery.useGetAssetList({
    quote_asset: quote,
    tags: dataTag,
    limit: 10,
  });

  const callback = useCallback((item: AssetListType) => {
    navigate({ base: item.base_asset, quote: item.quote_asset });
  }, []);

  const subscriptionList = useMemo(() => assetList.map((d) => concatTicker(d.base_asset, d.quote_asset)), [assetList]);

  return {
    assetList: assetList,
    isPending: isPending,
    callback,
    subscriptionList,
    fetchNextPage,
  };
};

export default useAssetCardsHook;
