import { TransactionStatusType, TransactionTypes } from '@bilira-org/react-utils';

export const withdrawDepositTransactionTypeData: { data: TransactionTypes; name: string }[] = [
  { data: 'fiat', name: 'transactions.fiat' },
  { data: 'crypto', name: 'transactions.crypto' },
];

export const withdrawDepositDirectionTypeData = [
  { data: undefined, name: 'common.all' },
  { data: 'withdrawal', name: 'transactions.withdrawal' },
  { data: 'deposit', name: 'transactions.deposit' },
];

export const swapDirectionTypeData = [
  { data: undefined, name: 'common.all' },
  { data: 'buy', name: 'transactions.buy' },
  { data: 'sell', name: 'transactions.sell' },
];

export const swapTransactionTypeData: { data: TransactionTypes; name: string }[] = [
  { data: 'swap', name: 'transactions.swap' },
  { data: 'otc', name: 'transactions.otc' },
];

export const transactionDateData = [
  { key: 0, name: 'common.all' },
  { key: 1, name: 'transactions.last-1-months' },
  { key: 2, name: 'transactions.last-2-months' },
  { key: 3, name: 'transactions.last-3-months' },
  { key: 12, name: 'transactions.last-1-year' },
];

/**
 * requested
 * processing: initiated, remote_timeout, remote_error,
 * completed
 * canceled: sys_canceled
 *
 */

const status: TransactionStatusType[] = ['processing', 'completed', 'canceled'];

const transactionStates = status.map((status) => ({ key: status, name: status }));

export const transactionStatusData = [{ key: undefined, name: 'all' }, ...transactionStates];
