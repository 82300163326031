import React, { ComponentProps } from 'react';

import { Text } from '@bilira-org/design';
import Flex from '@bilira-org/design/src/components/flex/flex';

import TotalBalance from '@Components/balance/TotalBalance';
import HiddenBalanceEyeIcon from '@Components/common/HiddenBalanceEyeIcon';
import { useGeneralPersistedStore } from '@Store/generalPersistedStore';

type Props = {
  /** Label to be display in the card */
  label: string;
  /** Text color of `TotalBalance` component */
  color?: ComponentProps<typeof Text>['color'];
  /** Extra content to render */
  extra?: React.ReactNode;
  /** Whether to show daily balance change percentage  */
  showChange24H?: boolean;
};

/**
 * Card to display total balance of the user.
 *
 * Uses `TotalBalance` component
 *
 * Also has a button to mask/unmask the balance
 *
 * @example
 * <TotalBalanceCard label="Total Balance" color="primary-500" />
 */
const TotalBalanceCard = ({ label, color, extra, showChange24H }: Props) => {
  const maskBalance = useGeneralPersistedStore((state) => state.maskBalance);

  return (
    <>
      <Text size={{ xs: 'sm', sm: 'sm', md: 'base' }} weight="regular" color={color}>
        {label} <HiddenBalanceEyeIcon />
      </Text>

      <Flex direction="row" gap="xs" items={maskBalance ? 'center' : 'baseline'}>
        <TotalBalance color={color} showChange24H={showChange24H} />
        {extra}
      </Flex>
    </>
  );
};

export default TotalBalanceCard;
