import React, { memo, useEffect, useRef } from 'react';

import { Block, Button, Confetti, ConfettiRef, Modal, Text } from '@bilira-org/design';
import { useStateEffect } from '@bilira-org/react-utils';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { withPrivateRoute } from '@/libs/hocs';
import useAuth from '@/libs/hooks/userAuth';
import { TRACKER_EVENTS } from '@/libs/tracker/events';
import { addTrackerReward } from '@/libs/tracker/helpers/addTrackerReward';
import { handleErrorResponse } from '@/libs/utils';
import WarningTemplate from '@Components/template/WarningTemplate';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';
import UserQuery from '@Libs/clientInstances/userQuery';

const RewardsRegistrationModal = () => {
  const particleRef = useRef<ConfettiRef>(null);
  const { user } = useAuth();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isPending: isPrerequisitesPending } = RewardsQuery.useGetUserParticipationPrerequisites({
    suspense: false,
  });
  const { mutateAsync: mutateParticipation, isPending: isParticipationPending } =
    RewardsQuery.usePatchUserParticipation();
  const { mutateAsync: mutateDisclaimerRecord, isPending: isDisclaimerPending } = UserQuery.usePostDisclaimerRecords();

  const [open, setOpen] = useStateEffect(
    (data?.can_participate && !data.participated_at && data.is_pop_up_allowed) || false,
    [data],
  );

  const handleParticipation = () => {
    mutateParticipation()
      .then(() => {
        setOpen(false);
        navigate('/rewards');
      })
      .catch((e) => handleErrorResponse(e));
  };

  const handleDisclaimerRecord = () => {
    mutateDisclaimerRecord({ user, type: 'point_system_join_announcement', entity_id: data?.season })
      .then(() => {
        setOpen(false);
      })
      .catch((e) => handleErrorResponse(e));
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => particleRef.current?.boom(), 100);
      addTrackerReward(TRACKER_EVENTS.REWARDS.REGISTRATION_MODAL_SHOWN);
    }
  }, [open]);

  return (
    <>
      <Modal open={open} closable={false}>
        <WarningTemplate icon="completed-big">
          <Block gap="md" items="center" maxWidth="sm" align="center">
            <Text heading size="xl" weight="bold" align="center">
              {t('rewards.eligible-modal.congrats')}
            </Text>

            <Text size="sm" color="neutral-800" align="center">
              <Trans t={t} i18nKey="rewards.eligible-modal.description" components={{ br: <br /> }} />
            </Text>
          </Block>
          <Block gap="md" width="size-full" mt="lg">
            <Button
              size="xl"
              stretch
              variant="filled"
              onClick={handleParticipation}
              loading={isParticipationPending}
              disabled={isDisclaimerPending}
            >
              {t('rewards.join-point-system')}
            </Button>
            <Button
              size="xl"
              stretch
              variant="outline"
              onClick={handleDisclaimerRecord}
              loading={isDisclaimerPending || isPrerequisitesPending}
              disabled={isParticipationPending}
            >
              {t('rewards.later')}
            </Button>
          </Block>
        </WarningTemplate>

        <Confetti ref={particleRef} />
      </Modal>
    </>
  );
};
export default memo(withPrivateRoute(RewardsRegistrationModal));
