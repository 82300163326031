import { Block, Hidden, Icon, Layout, Meta, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useGeneralStore } from '@/store';
import AllTransactionsCardContainer from '@Modules/transactios/components/AllTransactionsCardContainer';

const { Content, Hero } = Layout;

function Home() {
  const { t } = useTranslation();
  const setTxExportModal = useGeneralStore((state) => state.setTxExportModal);
  return (
    <>
      <Hero view="strip">
        <div>
          <Block row justify="between" items="center">
            <Text heading size="xl" color="white" weight="bold" my="md">
              {t('transactions.transaction-history')}
            </Text>

            <Hidden show={{ md: true }}>
              <Meta
                extra={<Icon type="o:document-arrow-down" size="md" color="white" />}
                space="xs"
                title={
                  <Text color="white" weight="medium" onClick={() => setTxExportModal(true)}>
                    {t('transactions.export-all-statements')}
                  </Text>
                }
              />
            </Hidden>
          </Block>
        </div>
      </Hero>
      <Content>
        <AllTransactionsCardContainer />
      </Content>
    </>
  );
}

export default Home;
