import React from 'react';

import { Badge, Rank } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';

import { withPrivateRoute } from '@/libs/hocs';
import useManageWatchlist from '@/libs/hooks/useManageWatchList';

type Props = {
  baseAsset?: CoinSymbolType;
  quoteAsset?: CoinSymbolType;
};

const WatchlistStar = ({ baseAsset, quoteAsset }: Props) => {
  const { toggleWatchlist, isInWatchlist, isPending } = useManageWatchlist({ baseAsset, quoteAsset });

  return (
    <>
      <Badge
        variant="circle"
        size="4xl"
        color={isInWatchlist ? 'white' : 'white/10'}
        onClick={isPending ? undefined : toggleWatchlist}
      >
        <Rank color="white" size="lg" variant="rating" shape="star" ranked={isInWatchlist} disabled={isPending} />
      </Badge>
    </>
  );
};

export default withPrivateRoute(WatchlistStar);
