import React from 'react';

import classNames from 'classnames';

import { BaseComponentType, MarginSizes } from '../types';

interface ILabel
  extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>,
    BaseComponentType {
  /** The content to be displayed inside the label */
  children: React.ReactNode;
  /** Margin size at the top */
  mt?: MarginSizes;
  /** Margin size at the bottom */
  mb?: MarginSizes;
}

/*
 * Label component for displaying html labels.
 *
 * @example
 * <Label mt="sm" mb="xs">Label</Label>
 */
const Label = ({ children, mt, mb = 'xs', testId, ...props }: ILabel) => {
  return (
    <label
      {...props}
      className={classNames('tw-text-neutral-800 block text-sm  font-regular', {
        [`mt-${mt}`]: mt,
        [`mb-${mb}`]: mb,
      })}
      data-testid={testId ?? 'label'}
    >
      {children}
    </label>
  );
};

export default Label;
