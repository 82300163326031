import React from 'react';

import { Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { withPublicRoute } from '@/libs/hocs';

const PublicWelcomePanel = () => {
  const { t } = useTranslation();

  return (
    <Text
      heading
      size="3xl"
      color="white"
      weight="bold"
      my="md"
      transform="capitalize"
      testId="overview-welcome-heading"
    >
      {t('overview.welcome.default')}
    </Text>
  );
};

export default withPublicRoute(PublicWelcomePanel);
