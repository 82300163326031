import React from 'react';

import classNames from 'classnames';

import Illustrator404 from '../../assets/icon/custom/404-illustration.svg';
import AlertShield from '../../assets/icon/custom/alert-shield.svg';
import Badge from '../../assets/icon/custom/badge.svg';
import BiLiraSphere from '../../assets/icon/custom/bilira-sphere.svg';
import CallWave from '../../assets/icon/custom/call-wave.svg';
import Commercial from '../../assets/icon/custom/commercial.svg';
import CompletedBigSolid from '../../assets/icon/custom/completed-big-solid.svg';
import CompletedBig from '../../assets/icon/custom/completed-big.svg';
import Illustrator404Dark from '../../assets/icon/custom/dark/404-illustration.svg';
import AlertShieldDark from '../../assets/icon/custom/dark/alert-shield.svg';
import EmptyFolderDark from '../../assets/icon/custom/dark/empty-folder.svg';
import IdIllustrator2Dark from '../../assets/icon/custom/dark/id-illustrator-2.svg';
import IdIllustratorDark from '../../assets/icon/custom/dark/id-illustrator.svg';
import IndividualDark from '../../assets/icon/custom/dark/individual.svg';
import KYCDrivingLicenseDark from '../../assets/icon/custom/dark/kyc-driving-license.svg';
import KYCIdentityDark from '../../assets/icon/custom/dark/kyc-identity.svg';
import KYCPassportDark from '../../assets/icon/custom/dark/kyc-passport.svg';
import KycDark from '../../assets/icon/custom/dark/kyc.svg';
import OopsIllustrationDark from '../../assets/icon/custom/dark/oops-illustration.svg';
import PreferencesDark from '../../assets/icon/custom/dark/preferences.svg';
import ReferralDark from '../../assets/icon/custom/dark/referral.svg';
import SecurityDark from '../../assets/icon/custom/dark/security.svg';
import StarCoinDark from '../../assets/icon/custom/dark/star-coin.svg';
import DiamondBronze from '../../assets/icon/custom/diamond-bronze.svg';
import DiamondGold from '../../assets/icon/custom/diamond-gold.svg';
import DiamondGreen from '../../assets/icon/custom/diamond-green.svg';
import DiamondGrey1 from '../../assets/icon/custom/diamond-grey1.svg';
import DiamondGrey2 from '../../assets/icon/custom/diamond-grey2.svg';
import Diamond2 from '../../assets/icon/custom/diamond-large-2.svg';
import Diamond from '../../assets/icon/custom/diamond-large.svg';
import DiamondSmall from '../../assets/icon/custom/diamond-small.svg';
import DownloadBig from '../../assets/icon/custom/download-big.svg';
import EmptyFolder from '../../assets/icon/custom/empty-folder.svg';
import FailedBig from '../../assets/icon/custom/failed-big.svg';
import FlagEN from '../../assets/icon/custom/flag-en.svg';
import FlagTR from '../../assets/icon/custom/flag-tr.svg';
import GiftBox from '../../assets/icon/custom/gift-box.svg';
import Gift from '../../assets/icon/custom/gift.svg';
import GlareWave from '../../assets/icon/custom/glare-wave.svg';
import GraphBox from '../../assets/icon/custom/graph-box.svg';
import IdIllustrator2 from '../../assets/icon/custom/id-illustrator-2.svg';
import IdIllustrator from '../../assets/icon/custom/id-illustrator.svg';
import Individual from '../../assets/icon/custom/individual.svg';
import KYCDrivingLicense from '../../assets/icon/custom/kyc-driving-license.svg';
import KYCIdentity from '../../assets/icon/custom/kyc-identity.svg';
import KYCPassport from '../../assets/icon/custom/kyc-passport.svg';
import Kyc from '../../assets/icon/custom/kyc.svg';
import OopsIllustration from '../../assets/icon/custom/oops-illustration.svg';
import PercentageWave from '../../assets/icon/custom/percentage-wave.svg';
import Preferences from '../../assets/icon/custom/preferences.svg';
import Referral from '../../assets/icon/custom/referral.svg';
import Security from '../../assets/icon/custom/security.svg';
import Sparkles from '../../assets/icon/custom/sparkles.svg';
import StarCoin from '../../assets/icon/custom/star-coin.svg';
import Tokens from '../../assets/icon/custom/tokens.svg';
import TrumpetPhone from '../../assets/icon/custom/trumpet-phone.svg';
import TwoCoins from '../../assets/icon/custom/two-coins.svg';
import XMark from '../../assets/icon/custom/x-mark.svg';
import { BaseComponentType, SizeType, TextColorType, Theme } from '../types';

export type IconCustomType =
  | 'empty-folder'
  | 'commercial'
  | 'id-illustrator'
  | 'id-illustrator-2'
  | '404-illustrator'
  | 'individual'
  | 'kyc'
  | 'preferences'
  | 'referral'
  | 'security'
  | 'flag-tr'
  | 'flag-en'
  | 'diamond-small'
  | 'oops-illustration'
  | 'diamond'
  | 'diamond2'
  | 'completed-big'
  | 'completed-big-filled'
  | 'download-big'
  | 'alert-shield'
  | 'failed-big'
  | 'kyc-identity'
  | 'kyc-passport'
  | 'kyc-driving-license'
  | 'tokens'
  | 'graph-box'
  | 'badge'
  | 'diamond-bronze'
  | 'diamond-gold'
  | 'diamond-green'
  | 'diamond-grey1'
  | 'diamond-grey2'
  | 'trumpet-phone'
  | 'call-wave'
  | 'percentage-wave'
  | 'glare-wave'
  | 'gift'
  | 'two-coins'
  | 'gift-box'
  | 'bilira-sphere'
  | 'sparkles'
  | 'x-mark'
  | 'star-coin';

export type IconCustomProps = Omit<React.ComponentProps<'svg'>, 'color' | 'stroke' | 'type' | 'fill' | 'size'> &
  BaseComponentType & {
    /** Color of the icon */
    color?: TextColorType;
    /** Fill color of the icon */
    fill?: TextColorType;
    /** Additional class name for the icon */
    className?: string;
    /** Theme to be applied to the icon */
    theme?: Theme;
    /** Type of the icon */
    type: IconCustomType;
    /** Width of the icon */
    width?: SizeType;
    /** Height of the icon */
    height?: SizeType;
  };

// export type IconCustomProps = IconCustomCommonProps &
//   ((IconCustomLightProps & { theme: 'light' }) | (IconCustomDarkProps & { theme?: 'dark' }));

/**
 * Custom icon component that renders icons based on type and theme
 *
 * @example
 * <IconCustom type="empty-folder" size="sm" />
 */
export function IconCustom({ theme, color, fill, type, className, testId, width, height, ...props }: IconCustomProps) {
  const classes = classNames(
    'icons',
    {
      [`cursor-pointer`]: !!props.onClick,
      [`tw-text-${color}`]: color,
      [`tw-fill-${fill}`]: fill,
      [`tw-w-${width}`]: width,
      [`tw-h-${height}`]: height,
    },
    className,
  );
  const Element = theme === 'light' ? LightCustomIcons[type] : DarkCustomIcons[type];

  if (!Element) {
    return null;
  }

  return <Element {...props} className={classes} data-testid={testId ?? 'icon-custom'} />;
}

const LightCustomIcons: Record<IconCustomType, any> = {
  'empty-folder': EmptyFolder,
  commercial: Commercial,
  'id-illustrator': IdIllustrator,
  '404-illustrator': Illustrator404,
  individual: Individual,
  kyc: Kyc,
  preferences: Preferences,
  referral: Referral,
  security: Security,
  'flag-tr': FlagTR,
  'flag-en': FlagEN,
  'diamond-small': DiamondSmall,
  'oops-illustration': OopsIllustration,
  diamond: Diamond,
  'completed-big': CompletedBig,
  'completed-big-filled': CompletedBigSolid,
  'download-big': DownloadBig,
  'alert-shield': AlertShield,
  'failed-big': FailedBig,
  'kyc-identity': KYCIdentity,
  'kyc-passport': KYCPassport,
  'kyc-driving-license': KYCDrivingLicense,
  tokens: Tokens,
  'graph-box': GraphBox,
  'id-illustrator-2': IdIllustrator2,
  diamond2: Diamond2,
  badge: Badge,
  'diamond-bronze': DiamondBronze,
  'diamond-gold': DiamondGold,
  'diamond-green': DiamondGreen,
  'diamond-grey1': DiamondGrey1,
  'diamond-grey2': DiamondGrey2,
  'trumpet-phone': TrumpetPhone,
  'call-wave': CallWave,
  'percentage-wave': PercentageWave,
  'glare-wave': GlareWave,
  gift: Gift,
  'two-coins': TwoCoins,
  'gift-box': GiftBox,
  'bilira-sphere': BiLiraSphere,
  sparkles: Sparkles,
  'x-mark': XMark,
  'star-coin': StarCoin,
};

const DarkCustomIcons: Record<IconCustomType, any> = {
  'empty-folder': EmptyFolderDark,
  commercial: Commercial,
  'id-illustrator': IdIllustratorDark,
  '404-illustrator': Illustrator404Dark,
  individual: IndividualDark,
  kyc: KycDark,
  preferences: PreferencesDark,
  referral: ReferralDark,
  security: SecurityDark,
  'flag-tr': FlagTR,
  'flag-en': FlagEN,
  'diamond-small': DiamondSmall,
  'oops-illustration': OopsIllustrationDark,
  diamond: Diamond,
  'completed-big': CompletedBig,
  'completed-big-filled': CompletedBigSolid,
  'download-big': DownloadBig,
  'alert-shield': AlertShieldDark,
  'failed-big': FailedBig,
  'kyc-identity': KYCIdentityDark,
  'kyc-passport': KYCPassportDark,
  'kyc-driving-license': KYCDrivingLicenseDark,
  tokens: Tokens,
  'graph-box': GraphBox,
  'id-illustrator-2': IdIllustrator2Dark,
  diamond2: Diamond2,
  badge: Badge,
  'diamond-bronze': DiamondBronze,
  'diamond-gold': DiamondGold,
  'diamond-green': DiamondGreen,
  'diamond-grey1': DiamondGrey1,
  'diamond-grey2': DiamondGrey2,
  'trumpet-phone': TrumpetPhone,
  'call-wave': CallWave,
  'percentage-wave': PercentageWave,
  'glare-wave': GlareWave,
  gift: Gift,
  'two-coins': TwoCoins,
  'gift-box': GiftBox,
  'bilira-sphere': BiLiraSphere,
  sparkles: Sparkles,
  'x-mark': XMark,
  'star-coin': StarCoinDark,
};
