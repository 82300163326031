import React from 'react';

import { Block, Button, Label, Overflow, Skeleton, withSuspense } from '@bilira-org/design';
import { CryptoAssetsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import CryptoQuery from '@Libs/clientInstances/cryptoQuery';

type Props = {
  callback: (asset: CryptoAssetsType) => void;
  disabled?: boolean;
};
const PopularCryptoButtonList = ({ callback, disabled }: Props) => {
  const { t } = useTranslation();
  const { data } = CryptoQuery.useGetAssets();

  if (!data) {
    return null;
  }

  const records = data.filter((record) => record.tags.includes('popular'));

  return (
    <>
      <Label>{t('crypto.popular-cryptos')}</Label>
      <Overflow overflow="x-scroll" flex row>
        <Block row gap="sm" testId="popular-cryptos">
          {records.map((d) => (
            <Button
              key={`popular-crypto-button-${d.symbol}`}
              variant="outline"
              size="xs"
              onClick={() => callback(d)}
              disabled={disabled}
              style={{ margin: '1px' }} // to compensate outline size
            >
              {d.symbol}
            </Button>
          ))}
        </Block>
      </Overflow>
    </>
  );
};

export default withSuspense(
  PopularCryptoButtonList,
  <Block row wrap gap="sm">
    <Skeleton width="size-14" height="size-8" />
    <Skeleton width="size-14" height="size-8" />
    <Skeleton width="size-14" height="size-8" />
    <Skeleton width="size-14" height="size-8" />
    <Skeleton width="size-14" height="size-8" />
  </Block>,
);
