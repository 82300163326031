import ReactQueryCache from '@bilira-org/react-utils/src/utils/reactQuery/ReactQueryCache';
import { withScope } from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

import handleQuerySuccess from '@Libs/reactQuery/handleQuerySuccess';

import handleErrorCapture from '../reactQuery/handleErrorCapture';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
      retry: 0,
      suspense: true,
      throwOnError: false,
    },
    mutations: {
      throwOnError: false,
    },
  },
  mutationCache: new MutationCache({
    onError: (err, variables, context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(Array.from(mutation.options.mutationKey) as string[]);
        }
        handleErrorCapture(err, 'mutation');
      });
    },
  }),
  queryCache: new QueryCache({
    onSuccess: handleQuerySuccess,
    onError: (err, query) => {
      withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint([query.queryHash]);
        handleErrorCapture(err, 'query', query.queryHash);
      });
    },
  }),
});

export const queryCache = new ReactQueryCache(queryClient);
