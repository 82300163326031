import React from 'react';

import { motion, MotionProps } from 'framer-motion';

import { BaseComponentType } from '../types';

type Props = MotionProps & BaseComponentType;

/**
 * Generic motion section from framer-motion
 */
const Motion = ({ children, testId, ...rest }: Props) => {
  return (
    <motion.section data-testid={testId ?? 'motion'} {...rest}>
      {children}
    </motion.section>
  );
};

export default Motion;
