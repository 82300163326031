import React, { useState } from 'react';

import { Tab } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useGeneralSessionStore } from '@/store';
import MarketTableContent from '@Modules/market/market/table/components/MarketTableContent';
import MarketWatchlistTableContent from '@Modules/market/market/watchlist/MarketWatchlistTableContent';
import { useLocalStorage } from '@Store/localStorage';

import GeneralFilterTags from './components/GeneralFilterTags';

const MarketTableContainer = () => {
  const [dataTag, setDataTag] = useState<string>();
  const { t } = useTranslation();
  const isLoggedIn = useGeneralSessionStore((state) => state.isLoggedIn);
  const { localStore, setLocalStore } = useLocalStorage();

  return (
    <>
      <Tab
        panelsMt="lg"
        scrollable
        variant="bordered"
        defaultIndex={localStore.MARKET_TAB ?? 1}
        onChange={(index) => {
          setLocalStore({ MARKET_TAB: index });
        }}
        headers={[
          {
            name: t('watchlist.title'),
            key: 'tab-favorite',
            hidden: !isLoggedIn,
          },
          {
            name: 'TRYB',
            key: 'tab-tryb-pairs',
          },
          {
            name: 'USDT',
            key: 'tab-usdt-pairs',
          },
          {
            name: 'USDC',
            key: 'tab-usdc-pairs',
          },
        ]}
      >
        {isLoggedIn && (
          <Tab.Panel>
            <MarketWatchlistTableContent />
          </Tab.Panel>
        )}

        <Tab.Panel>
          <GeneralFilterTags tag={dataTag} callback={setDataTag} filterType="general" />
          <MarketTableContent dataTag={dataTag} quote="TRYB" />
        </Tab.Panel>
        <Tab.Panel>
          <GeneralFilterTags tag={dataTag} callback={setDataTag} filterType="general" />
          <MarketTableContent dataTag={dataTag} exclude={['USDT', 'TRYB', 'BMMF']} quote="USDT" />
        </Tab.Panel>
        <Tab.Panel>
          <GeneralFilterTags tag={dataTag} callback={setDataTag} filterType="general" />
          <MarketTableContent dataTag={dataTag} exclude={['USDC', 'TRYB', 'BMMF']} quote="USDC" />
        </Tab.Panel>
      </Tab>
    </>
  );
};

export default MarketTableContainer;
