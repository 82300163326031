import React from 'react';

import { Block, Icon, Panel } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';

import AssetName from '@Components/common/AssetName';
import SymbolPair from '@Components/common/SymbolPair';
import H24ChangeLive from '@Components/live/H24ChangeLive';
import LastPriceLive from '@Components/live/LastPriceLive';

type Props = {
  callback: () => void;
  baseAsset: CoinSymbolType;
  quoteAsset: CoinSymbolType;
};

/**
 * Component for selecting crypto assets.
 *
 * This component allows users to select crypto assets and
 * it displays the selected asset's symbol and icon.
 */
function AssetSelector({ callback, baseAsset, quoteAsset }: Props): JSX.Element {
  return (
    <>
      <Panel size="sm" border="neutral-400" onClick={callback}>
        <Block row justify="between">
          <SymbolPair
            quote={quoteAsset}
            icon={baseAsset}
            title={baseAsset}
            description={<AssetName symbol={baseAsset} />}
            size={{
              icon: '3xl',
              title: 'xs',
              description: 'xs',
            }}
          />

          <Block row gap="xs" items="center">
            <div>
              <LastPriceLive
                align="right"
                size="sm"
                weight="regular"
                base={baseAsset}
                suffixColor="neutral-700"
                quote={quoteAsset}
              />
              <H24ChangeLive base={baseAsset} quote={quoteAsset} justify="end" size="sm" weight="regular" />
            </div>
            <Icon type="s:chevron-down" color="neutral-600" size="lg" />
          </Block>
        </Block>
      </Panel>
    </>
  );
}

export default AssetSelector;
