import { Block, Modal, Skeleton, withSuspense } from '@bilira-org/design';
import { TransactionsRecordType } from '@bilira-org/react-utils';

import TransactionDetailModalBody from '@/components/transaction/modal/components/TransactionDetailModalBody';
import useGetTransactionDetail, { TransactionRecordInfo } from '@/libs/hooks/useGetTransactionDetail';

import TransactionButtons from '../components/components/TransactionButtons';

type TransactionDetailModalContentProps = {
  /** Transaction data to be displayed */
  transactionRecordInfo?: TransactionRecordInfo;
  /** Function to be called when modal is closed */
  onClose: (state: boolean) => void;
  /** Function to refetch transaction data */
  refetch?: () => void;
  /** Empty content */
  empty?: React.ReactNode;
  /** Optional custom footer */
  footer?: (data: TransactionsRecordType, isPending: boolean) => React.ReactNode;
};

/**
 * Component for displaying transaction details in a modal
 */
function TransactionDetailModalContent({
  transactionRecordInfo,
  onClose,
  refetch,
  empty,
  footer,
}: TransactionDetailModalContentProps) {
  console.log({ transactionRecordInfo });
  const { data, isPending } = useGetTransactionDetail({
    transactionRecordInfo,
  });

  if (!data) {
    return empty;
  }

  const onCancel = () => {
    onClose(false);
    refetch?.();
  };

  return (
    <>
      <Modal.Body>
        <TransactionDetailModalBody data={data} />
      </Modal.Body>
      {footer ? (
        footer(data, isPending)
      ) : (
        <Modal.Footer>
          <TransactionButtons onCancel={onCancel} data={data} />
        </Modal.Footer>
      )}
    </>
  );
}

export default withSuspense(
  TransactionDetailModalContent,
  <Modal.Body>
    <Block gap="md">
      <Skeleton height="size-4" width="size-full" />
      <Skeleton height="size-4" width="size-1/2" />
      <Skeleton height="size-4" width="size-full" />
      <Skeleton height="size-4" width="size-full" />
      <Skeleton height="size-4" width="size-1/2" />
      <Skeleton height="size-4" width="size-full" />
      <Skeleton height="size-4" width="size-full" />
      <Skeleton height="size-4" width="size-1/2" />
      <Skeleton height="size-4" width="size-full" />
    </Block>
  </Modal.Body>,
);
