import React from 'react';

import { Block, Button, Link, Modal, Text } from '@bilira-org/design';
import { Trans, useTranslation } from 'react-i18next';

import { useGeneralPersistedStore } from '@/store/generalPersistedStore';
import { IconCustom } from '@Components/icon/CustomIcon';
import { navigate } from '@Libs/helpers/navigation';

/**
 * Modal for displaying warning message and blocking user from accessing app if they are not eligible.
 */
const UserEligibleToV2Modal = () => {
  const setIsNotEligible = useGeneralPersistedStore((state) => state.setIsNotEligible);
  const isNotEligible = useGeneralPersistedStore((state) => state.isNotEligible);
  const { t } = useTranslation();

  return (
    <Modal
      title={' '}
      titleSeparator={false}
      open={isNotEligible}
      overlayClosable={false}
      onClose={() => setIsNotEligible(false)}
    >
      <Block justify="center" items="center" mx="auto" mt="sm" mb="8xl" maxWidth="sm">
        <Block mb="2xl">
          <IconCustom type="failed-big" />
        </Block>
        <Text heading size="xl" weight="bold" mb="md" align="center">
          {t('v2-eligible-modal.title')}
        </Text>
        <Text size="sm" align="center">
          {t('v2-eligible-modal.description')}
        </Text>
      </Block>
      <Button stretch variant="filled" size="xl" onClick={() => setIsNotEligible(false)}>
        {t('v2-eligible-modal.go-to-bilira-app')}
      </Button>
    </Modal>
  );
};

export default UserEligibleToV2Modal;
