import React from 'react';

import classNames from 'classnames';

import {
  BaseComponentType,
  BreakpointType,
  FontWeight,
  PaddingSizes,
  ResponsiveReturnType,
  TextColorType,
  TextSizes,
} from '../types';
import { buildResponsiveClass } from '../utils';

interface ISpan extends BaseComponentType {
  /** Content to be display inside the span */
  children?: React.ReactNode | React.ReactNode[];
  /** Text color of the span */
  color?: TextColorType;
  /** Font weight of the span */
  weight?: FontWeight;
  /** Padding on the y-axis */
  py?: PaddingSizes;
  /** Padding on the x-axis */
  px?: PaddingSizes;
  /** Margin on the right */
  mr?: PaddingSizes;
  /** Margin on the left */
  ml?: PaddingSizes;
  /** Font size of the span */
  size?: TextSizes | BreakpointType<TextSizes>;
  /** Additional class name for styling */
  className?: string;
  /** Function to be called on span click */
  onClick?: () => void;
  /** Text transformation */
  transform?: 'capitalize' | 'uppercase' | 'lowercase';
}

/**
 * A styled and customizable span element.
 *
 * @example
 * <Span py="md" color="neutral-400" weight="bold">
 *  Hello World
 * </Span>
 */
const Span = ({
  color,
  py,
  px,
  mr,
  ml,
  weight = 'regular',
  className,
  size,
  onClick,
  testId,
  transform,
  ...props
}: ISpan) => {
  let sizeClass: ResponsiveReturnType = {};

  if (size) {
    sizeClass = buildResponsiveClass(size, 'tw-text-');
  }

  return (
    <span
      onClick={onClick}
      className={classNames(
        'span',
        sizeClass,
        {
          [`tw-text-${color}`]: color,
          [`tw-font-${weight}`]: weight,
          [`py-${py}`]: py,
          [`px-${px}`]: px,
          [`mr-${mr}`]: mr,
          [`ml-${ml}`]: ml,
          [`transform-${transform}`]: transform,
          'cursor-pointer': !!onClick,
        },
        className,
      )}
      data-testid={testId ?? 'span'}
    >
      {props.children}
    </span>
  );
};

export default Span;
