import React from 'react';

import { Block, Hidden, Loop, Skeleton } from '@bilira-org/design';

const PopularCryptoCardsGridSkeleton = () => {
  return (
    <Block gap="5xl" mt="4xl">
      <Loop data={5}>
        <Block row justify="between" mx="md" items="center">
          <Block row gap="sm" items="center">
            <Skeleton rounded="full" height="size-8" width="size-8" />

            <Skeleton height="size-4" width="size-24" />
          </Block>
          <Skeleton height="size-4" width="size-24" />

          <Hidden show={{ xs: false, sm: false, md: true }}>
            <Skeleton height="size-4" width="size-14" />
          </Hidden>

          <Hidden show={{ xs: false, sm: false, md: true }}>
            <Skeleton height="size-4" width="size-16" />
          </Hidden>

          <Hidden show={{ xs: false, sm: false, md: false, lg: true }}>
            <Skeleton height="size-4" width="size-24" />
          </Hidden>
        </Block>
      </Loop>
    </Block>
  );
};

export default PopularCryptoCardsGridSkeleton;
