import React from 'react';

import { useTranslation } from 'react-i18next';

import { LayoutCommon } from '@Components/layouts';
import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';
import FiatWithdrawalSidebar from '@Modules/fiat/withdraw/components/FiatWithdraw';

const WithdrawFiatContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <LayoutCommon>
        <LayoutCommon.Content>
          <FiatWithdrawalSidebar />
        </LayoutCommon.Content>
        <LayoutCommon.Sidebar>
          <LayoutCommon.Sidebar>
            <TransactionPanelCard
              title={t('common.recent-withdraw-transactions')}
              limit={5}
              type="fiat"
              direction="withdrawal"
              showAllButton
            />
          </LayoutCommon.Sidebar>
        </LayoutCommon.Sidebar>
      </LayoutCommon>
    </>
  );
};

export default WithdrawFiatContainer;
