import React from 'react';

import { Block, Loop, Skeleton, Text } from '@bilira-org/design';

const CryptoListCardSkeleton = () => {
  return (
    <Block gap="4xl" mt="lg">
      <Loop<number> data={[1, 2, 3]}>
        {(data) => (
          <Block key={`skeleton-${data}`} row justify="between" mx="md" py="md">
            <Block row gap="md" items="center">
              <Text weight="regular" size="xs" color="neutral-600">
                {data}
              </Text>

              <Skeleton rounded="full" className="ml-1" height="size-6" width="size-6" />

              <Skeleton height="size-3" width="size-20" />
            </Block>
            <Skeleton height="size-3" width="size-9" />
          </Block>
        )}
      </Loop>
    </Block>
  );
};

export default CryptoListCardSkeleton;
