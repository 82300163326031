import React, { memo, ReactNode } from 'react';

import classNames from 'classnames';

import { Flex, FlexProps } from '../flex';
import { Meta, MetaProps } from '../meta';
import { Panel, PanelProps } from '../panel';
import { Separator } from '../separator';
import { BaseComponentType, FlexItemsType, PaddingSizes } from '../types';
import { Typography } from '../typography';

export type CardProps = PanelProps &
  BaseComponentType & {
    /** Header of card.*/
    header?: React.ReactNode | string;
    /** Whether to show shadow. */
    shadow?: boolean;
  };

/**
 * Card component that extends Panel component, providing a styled card container.
 *
 * @example
 * // Example usage of Card component
 * <Card header="Card Title" shadow>
 *   <p>Card content.</p>
 * </Card>
 */
const Card = ({ header, shadow, className, children, testId, ...props }: CardProps): JSX.Element => {
  const classes = classNames('card', { 'card-shadow': shadow }, className);

  return (
    <Panel aria-label="Card" className={classes} testId={testId ?? 'card'} {...props}>
      {header}
      {children}
    </Panel>
  );
};

export type HeaderProps = Omit<MetaProps, ''> &
  BaseComponentType & {
    separator?: boolean;
    rightArea?: ReactNode;
    pt?: PaddingSizes;
    py?: PaddingSizes;
    px?: PaddingSizes;
    responsive?: boolean;
  };

const Header = memo(
  ({
    separator,
    title,
    rightArea,
    description,
    minWidth = 'max',
    position = 'right',
    py,
    pt,
    px,
    testId,
    responsive = true,
    ...props
  }: HeaderProps) => {
    let headerTitle = title;
    if (typeof headerTitle === 'string') {
      headerTitle = (
        <Typography.Text heading size={{ xs: 'lg', sm: 'xl' }} weight="bold">
          {headerTitle}
        </Typography.Text>
      );
    }
    let headerDescription = description;
    if (typeof headerDescription === 'string') {
      headerDescription = <Typography.Text>{headerDescription}</Typography.Text>;
    }

    return (
      <>
        <Flex
          gap="lg"
          justify="between"
          items={responsive ? { sm: 'center' } : 'center'}
          px={px}
          py={py}
          pt={pt}
          direction={responsive ? { sm: 'row' } : 'row'}
          testId={testId}
        >
          <Flex.Item flexType="1">
            <Meta
              position={position}
              title={headerTitle}
              minWidth={minWidth}
              description={headerDescription}
              {...props}
            />
          </Flex.Item>
          {rightArea && (
            <Flex.Item className="empty:hidden" flexType={responsive ? { sm: 'none' } : 'none'}>
              {rightArea}
            </Flex.Item>
          )}
        </Flex>
        {separator && <Separator width="size-full" />}
      </>
    );
  },
);

export default Object.assign(Card, { Header });
