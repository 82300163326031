import React, { ComponentType } from 'react';

import { useGeneralSessionStore } from '@/store';

/**
 * A higher-order component (HOC) that wraps a given component with a public route logic.
 * It renders the wrapped component only if the user is not logged in; otherwise, it returns null.
 *
 * @template T - The props type of the wrapped component.
 * @param {React.ComponentType<T & JSX.IntrinsicAttributes>} Component - The component to be wrapped.
 * @returns {React.ComponentType<T>} - The wrapped component with private route logic.
 *
 * @example
 * // Define a component
 * const MyComponent = () => {
 *   // Component logic
 *   return <div>My Component</div>;
 * };
 *
 * // Wrap the component with private route logic
 * const PublicRouteComponent = withPrivateRoute(MyComponent);
 *
 * // Use the wrapped component in your application
 * const App = () => {
 *   return (
 *     <div>
 *       <PublicRouteComponent />
 *     </div>
 *   );
 * };
 */
const withPublicRoute =
  (Component: ComponentType): ComponentType =>
  () => {
    const isLoggedIn = useGeneralSessionStore((state) => state.isLoggedIn);

    if (isLoggedIn) {
      return null;
    }

    return <Component />;
  };

export default withPublicRoute;
