import { FunctionComponent } from 'react';

import { Navigate } from 'react-router-dom';

import { useGeneralSessionStore } from '@/store';

/**
 * AuthorizedRoute component for handling authorized routes in the application.
 *
 * This component checks the user's authentication status and redirects them accordingly.
 * It renders a skeleton layout while waiting for authentication status.
 * If there's an error during authentication, it redirects the user to an error page.
 * If the user is authenticated, it renders the provided element.
 *
 * @example
 * <AuthorizedRoute element={MyComponent} />
 */
function AuthorizedRoute({ element }: { element: FunctionComponent }): JSX.Element {
  const isLoggedIn = useGeneralSessionStore((state) => state.isLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  const Element = element;
  return <Element />;
}

export default AuthorizedRoute;
