import React from 'react';

import { Dialog } from '@headlessui/react';

import { BaseComponentType } from '../types';

export type LightboxProps = BaseComponentType & {
  /** The source URL of the image to be displayed in the lightbox. */
  src?: string;
  /** The alternate text for the image. */
  alt?: string;
  /** A boolean indicating whether the lightbox is open or closed. */
  isOpen?: boolean;
  /** A function to toggle the open/closed state of the lightbox. */
  toggleOpen?: (value: boolean) => void;
};

/**
 * LightBox component for displaying images in a lightbox overlay.
 *
 * @example
 * <LightBox
 *   src={imageUrl}
 *   alt={imageAlt}
 *   isOpen={isOpen}
 *   toggleOpen={toggleOpen}
 * />
 */
const LightBox = ({ src, alt, isOpen, toggleOpen, testId }: LightboxProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => toggleOpen?.(false)}
      className="relative z-50"
      data-testid={testId ?? 'light-box'}
    >
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto max-w-full rounded bg-white">
          <img src={src} alt={alt} className="w-auto h-[30rem]" />
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default LightBox;
