import React from 'react';

import { Block, IconCustom, IconCustomType, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useGeneralSessionStore } from '@/store';
import { toLocaleCapitalize } from '@Libs/utils';

type Props = {
  icon: IconCustomType;
};

function Completed({ icon }: Props) {
  const { t, i18n } = useTranslation();
  const userInfo = useGeneralSessionStore((state) => state.userInfo);

  return (
    <>
      <Panel size="8xl">
        <Block justify="center" items="center">
          <Panel color="theme-wn" dropShadow="md" width={null} radius="full">
            <IconCustom type={icon} />
          </Panel>
          <Text mt="lg" heading color="primary-500" size="base" weight="medium" transform="capitalize">
            {toLocaleCapitalize(i18n.language, userInfo?.first_name)},
          </Text>
          <Text heading align="center" weight="bold" size="xl">
            {t('account.id-verification.kyc-2.reviewing-application')}
          </Text>
          <Text align="center" mt="md">
            {t('account.id-verification.kyc-2.verifying-identity-email-notification')}
          </Text>
        </Block>
      </Panel>
    </>
  );
}

export default Completed;
