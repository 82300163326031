import { useMemo } from 'react';

import { CoinSymbolType, compareCurrencies } from '@bilira-org/react-utils';

import AccountApi from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';

type Props = {
  /** The symbol of the cryptocurrency asset for which to retrieve the balance. */
  symbol?: CoinSymbolType;
  /** Whether to use suspense mode while fetching data. */
  suspense?: boolean;
};

/**
 * Hook for retrieving the balance of a specific cryptocurrency asset for the authenticated user.
 *
 * @param {Props} options - Options for the get balance hook.
 *
 * @returns {{
 *   freeBalance: string | undefined,
 *   compare: (value?: string) => number
 * }} - An object containing the free balance of the specified cryptocurrency asset and a compare function.
 *
 */
const useGetBalance = ({
  symbol,
  suspense = true,
}: Props): {
  freeBalance: string | undefined;
  compare: (value?: string) => number;
} => {
  const { account } = useAuth();

  const { data: balances } = AccountApi.useGetBalances(account, undefined, {
    enabled: !!account,
    suspense: !!suspense,
  });
  const cryptoBalance = useMemo(() => balances?.find((d) => d.asset === symbol), [symbol, balances]);

  /**
   * Function for comparing balances.
   *
   * @param {string} value - The value to compare against.
   * @returns {number} - A comparison result:
   *  * -1: If the provided value is falsy or '0'.
   *  * 1: If the cryptocurrency balance is not available.
   *  * Comparison result based on compareCurrencies for other cases.
   */
  const compare = (value?: string): number => {
    if (!value || value === '0') {
      return -1;
    }

    if (!cryptoBalance?.free_balance) {
      return 1;
    }

    return compareCurrencies(value, cryptoBalance.free_balance) as number;
  };

  return { freeBalance: cryptoBalance?.free_balance, compare };
};

export default useGetBalance;
