import React, { forwardRef, ReactNode } from 'react';

import classNames from 'classnames';

import {
  BaseComponentType,
  BaseSize,
  BreakpointType,
  DirectionType,
  FlexItemsType,
  GapSizes,
  JustifyType,
  MinWidthType,
  PaddingSizes,
  ResponsiveReturnType,
  SpaceSizes,
} from '../types';
import { buildResponsiveClass } from '../utils';

type AlignType = 'left' | 'right' | 'left-top' | 'top';

type AlignBreakpoints = {
  xs?: AlignType;
  sm?: AlignType;
  md?: AlignType;
  lg?: AlignType;
};

export type MetaProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> &
  BaseComponentType & {
    /** The title of the `Meta` component. */
    title?: string | React.ReactNode;
    /** The description of the `Meta` component. */
    description?: string | React.ReactNode;
    /** The extra content to be displayed in the `Meta` component. */
    extra?: React.ReactNode;
    /** The position of the extra content, or a map of positions based on breakpoints. */
    position?:
      | 'left'
      | 'right'
      | 'left-top'
      | 'top'
      | {
          xs?: 'left' | 'right' | 'left-top' | 'top';
          sm?: 'left' | 'right' | 'left-top' | 'top';
          md?: 'left' | 'right' | 'left-top' | 'top';
          lg?: 'left' | 'right' | 'left-top' | 'top';
        };
    /** The gap size between elements in the `Meta` component, or a map of gap sizes based on breakpoints. */
    gap?: GapSizes | BreakpointType<GapSizes>;
    /** The space size around the `Meta` component, or a map of space sizes based on breakpoints. */
    space?: SpaceSizes | BreakpointType<SpaceSizes>;
    /** The maximum width of the `Meta` component. */
    maxWidth?: BaseSize;
    /** The minimum width of the `Meta` component. */
    minWidth?: MinWidthType;
    /** The direction of the `Meta` component or a map of directions based on breakpoints. */
    direction?: DirectionType | BreakpointType<DirectionType>;
    /** The padding size on the Y-axis */
    my?: PaddingSizes;
    /** The padding size on the X-axis */
    mx?: PaddingSizes;
    /** The margin size on the top  */
    mt?: PaddingSizes;
    /** The margin size on the bottom  */
    mb?: PaddingSizes;
    /** The padding size on the Y-axis */
    py?: PaddingSizes;
    /** The padding size on the X-axis */
    px?: PaddingSizes;
    /** CSS align-items property */
    items?: FlexItemsType;
    /** CSS justify-content property */
    justify?: JustifyType;
    /** CSS align-items property for the content */
    contentItems?: FlexItemsType; //TODO: Try removing this.
  };

/**
 * The `Meta` component is used to create a meta information block with a title, description, and optional extra content.
 *
 * @example
 * <Meta
 *   title="Meta title"
 *   description="Meta description"
 *   extra={<Button>Button</Button>}
 * />
 */
const Meta = forwardRef<HTMLDivElement, MetaProps>(
  (
    {
      extra,
      gap,
      space = 'lg',
      minWidth,
      maxWidth,
      position = 'left',
      direction = 'row',
      title,
      description,
      items = 'center',
      justify,
      contentItems = 'start',
      my,
      mx,
      mt,
      mb,
      px,
      py,
      testId,
      ...props
    },
    ref,
  ) => {
    let extraPositionClass: ResponsiveReturnType = {};
    let contentPositionClass: ResponsiveReturnType = {};
    let directionClass: ResponsiveReturnType = {};
    let gapClass: ResponsiveReturnType = {};
    let spaceClass: ResponsiveReturnType = {};

    if (position) {
      extraPositionClass = buildResponsiveClass(position, 'meta-extra-position-');
      contentPositionClass = buildResponsiveClass(position, 'meta-content-position-');
    }

    if (direction) {
      directionClass = buildResponsiveClass(direction, 'tw-flex-');
    }

    if (gap) {
      gapClass = buildResponsiveClass(gap, 'gap-');
    } else {
      gapClass = { 'gap-0.5': true };
    }

    if (space) {
      spaceClass = buildResponsiveClass(space, 'gap-');
    }

    const classes = classNames('meta', directionClass, spaceClass, {
      [`tw-min-w-${minWidth}`]: minWidth,
      [`tw-max-w-${maxWidth}`]: maxWidth,
      [`tw-items-${items}`]: items,
      [`tw-justify-${justify}`]: justify,
      'with-icon': extra,
      [`my-${my}`]: my,
      [`mx-${mx}`]: mx,
      [`mt-${mt}`]: mt,
      [`mb-${mb}`]: mb,
      [`py-${py}`]: py,
      [`px-${px}`]: px,
    });

    return (
      <article className={classes} ref={ref} data-testid={testId} {...props}>
        {extra && <div className={classNames('meta-icon shrink-0 gap-3', extraPositionClass)}>{extra}</div>}
        <div
          className={classNames('meta-content', gapClass, contentPositionClass, {
            [`tw-items-${contentItems}`]: contentItems,
          })}
        >
          {title && (
            <div
              {...(testId ? { 'data-testid': `${testId}-title` } : {})}
              className={classNames({ 'meta-title': typeof title === 'string' })}
            >
              {title}
            </div>
          )}
          {description && (
            <div {...(testId ? { 'data-testid': `${testId}-description` } : {})} className="meta-description">
              {description}
            </div>
          )}
        </div>
      </article>
    );
  },
);

export default Meta;
